import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { notifyError } from '../../../utilities/notifier';
import * as Colors from '../../../utilities/colors';
import * as GlobalStyles from '../../../utilities/globalStyles';
import {Form} from 'react-bootstrap';
import Loader from '../../shared/presentational/loader';
import SaveButtons from '../../shared/presentational/saveButtons';
import { clearRollupDefinitions, processNewRollupDefinition, receivedRollupDefinition } from '../../../actions/tod/queries.actions';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';
import { RECEIVED_ROLLUP_DEFINITION } from '@app/actions/tod/tod.actionTypes';

const DEFAULT_ROLLUP = {rollupDefinitionId: -1, rollupDescription: '', allSelected: false};
const style = {
  contentContainer: {
    minWidth: '500px',
  },
  dimensionLabel: {
    marginRight: '.5em',
  },
  dimensionRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  selectBox: {
    // width: '20vw',
    height: '50vh',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '1em',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
};

class CreateEditRollupDefinitions extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
    currentDb: PropTypes.object,
    isFetching: PropTypes.bool,
    params: PropTypes.object,
    getRollupDefinition: PropTypes.func,
    createRollupDefinitionHandler: PropTypes.func,
    updateRollupDefinitionHandler: PropTypes.func,
    rollupDefinition: PropTypes.object,
    datasetColumns: PropTypes.array,
    getDatasetColumns: PropTypes.func,
    dataSource: PropTypes.object,
    allRollupDefinitions: PropTypes.array,
    verifyPermission: PropTypes.func,
  };
  static defaultProps = {
    dimensionValues: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      databaseId: null,
      dataSetColumnId: null,
      dimensionName: null,
      selectedDimension: null,
      dimensions: [],
      dataSource: PropTypes.object,
      rollupDefinitionName: '',
      rollupDescription: '',
      selectedDimensionValues: [],
      rollupDefinitionId: null,
      rollupDefinition: null,
      softErrorMode: true,
      dimensionValues: [],
      selectedValuesOption: 'specific',
    };
    this.saveRollupDefinition = this.saveRollupDefinition.bind(this);
    this.goBack = this.goBack.bind(this);
    this.updateRollupDefinitionName = this.updateRollupDefinitionName.bind(
      this
    );
    this.updateRollupDescription = this.updateRollupDescription.bind(this);
    this.selectDimensionValue = this.selectDimensionValue.bind(this);
    this.changeDimension = this.changeDimension.bind(this);
    this.getNameValidationError = this.getNameValidationError.bind(this);
    this.selectValuesOption = this.selectValuesOption.bind(this);
    this.refresh = this.refresh.bind(this);
    this.selectAllDimensionValues = this.selectAllDimensionValues.bind(this);
  }

  
    get dimensions() {
        const { dataSource } = this.props;
        return dataSource && dataSource.values ? dataSource.values.map(ds => ds.name) : [];
    }

    get editMode() {
        const { rollupDefinition } = this.props;
        return rollupDefinition && rollupDefinition.rollupDefinitionId > 0;
    }

    get selectedDimensionName() {
        const { rollupDefinition } = this.props;
        return rollupDefinition ? rollupDefinition.attributeName : '';
    }

    get selectedDimension() {
        const { dataSource } = this.props;

        if (this.selectedDimensionName && dataSource && dataSource.values) {
            return dataSource.values.find(ds => ds.name === this.selectedDimensionName);
        } else {
            return null;
        }
    }
    
    get dimensionValues() {
        return this.selectedDimension ? this.selectedDimension.values.filter(v => v.value.indexOf('@') !== 0) : [];
    }

    get selectedDimensionValues() {
        const { rollupDefinition:r } = this.props;
        return r && r.rollupData ? JSON.parse(r.rollupData) : [];
    }

    componentDidMount() {
        const {dispatch } = this.props;
        dispatch({type: RECEIVED_ROLLUP_DEFINITION, items: null}); // force a reload
    }

    componentDidUpdate(prevProps) {
        const {rollupDefinition, isFetching, currentDb, params} = this.props;
        if (null === rollupDefinition && currentDb && currentDb.id === parseInt(params.databaseId, 10) && !isFetching) {
            // only now can we begin loading things - the state machine resets after currentDb loads from parent...
            this.refresh();
        }
    }

    refresh() {
        const {
            getRollupDefinition,
            userKey,
            getDatasetColumns,
            getQueryDataSource,
            getAllRollupDefinitions,
            params,
            dispatch,
            currentDb
        } = this.props;

        let databaseId = params.databaseId;
        let rollupDefinitionId = parseInt(params.rollupId, 10);

        if (currentDb && !currentDb.useADX){
            getDatasetColumns({ userKey, databaseId });
            getQueryDataSource(userKey, databaseId);
            getAllRollupDefinitions(userKey, databaseId);
            if (rollupDefinitionId) {
                getRollupDefinition({userKey,factDatabaseId: databaseId,rollupDefinitionId});
            } else {
                dispatch({type: RECEIVED_ROLLUP_DEFINITION, items: DEFAULT_ROLLUP});
            }
        }
    }


    setSoftErrorMode(mode) {
        const { softErrorMode } = this.state;
        if (softErrorMode !== mode) {
            this.setState({ softErrorMode: mode });
        }
    }

    getNameValidationError(soft) {
        const { allRollupDefinitions, rollupDefinition } = this.props;
        const { attributeName: dimensionName, rollupName } = rollupDefinition ? rollupDefinition : {};
        const trimmed = rollupName ? rollupName.trim() : '';
        if (soft)
            return;

        if (!trimmed) {
            return 'Please Specify Rollup Definition Name';
        } else if (trimmed.indexOf('"') !== -1 || trimmed.indexOf("'") !== -1) {
            return `Rollup Definition Name Cannot use ' or " in name`; // eslint-disable-line quotes
        }

        const found = allRollupDefinitions.find(
            rd => rd.attributeName === dimensionName &&
                rd.rollupName.trim().toLowerCase() === trimmed.toLowerCase() 
        );
        if (found && found.rollupDefinitionId != rollupDefinition.rollupDefinitionId) {  // eslint-disable-line eqeqeq
            return 'Rollup Definition Name Already Exists';
        }
        return '';
    }

    validate(soft) {
        const rollupNameError = this.getNameValidationError(soft);
        if (rollupNameError) {
            notifyError(`${rollupNameError}`);
            return false;
        }
        return !rollupNameError;
    }

    selectValuesOption(value) {
        const { rollupDefinition, dispatch } = this.props;
         dispatch(receivedRollupDefinition({...rollupDefinition, allSelected: value === 'all'}));
    }

    saveRollupDefinition() {
        const {
            createRollupDefinitionHandler,
            updateRollupDefinitionHandler,
            userKey,
            datasetColumns,
            dispatch,
            browserHistory,
            rollupDefinition:r,
            params
        } = this.props;
        const dataSetColumnId = (datasetColumns.find(
            dmra => dmra.attributeName === this.selectedDimensionName
        ) || {}).dataSetColumnId;
        let databaseId = params.databaseId;

        if (!this.validate(false)) {
            this.setSoftErrorMode(false);
            return;
        }

        if (!r.attributeName) {
            notifyError('Must select Dimension');
            return;
        }

        if (!this.selectedDimensionValues.length && !r.allSelected) {
            notifyError('Must select at least one dimension value');
            return;
        }

        if (this.editMode) {
            updateRollupDefinitionHandler({
                userKey,
                factDatabaseId: databaseId,
                rollupDefinitionId: r.rollupDefinitionId,
                rollupName: r.rollupName.trim(),
                rollupDescription: r.rollupDescription,
                rollupData: r.rollupData,
                allSelected: r.allSelected,
            });
        } else {
            createRollupDefinitionHandler({
                userKey,
                factDatabaseId: databaseId,
                dataSetColumnId,
                rollupDefinitionId: r.rollupDefinitionId,
                rollupName: r.rollupName.trim(),
                rollupDescription: r.rollupDescription,
                rollupData: r.rollupData,
                allSelected: r.allSelected,
            });
        }
        dispatch(clearRollupDefinitions());
        dispatch(processNewRollupDefinition());
        browserHistory.push(`/tod/queries/${databaseId}/rollups`);
    }

    updateRollupDefinitionName(e) {
        const { rollupDefinition, dispatch } = this.props;
        dispatch(receivedRollupDefinition({...rollupDefinition, rollupName: `@${e.target.value}`}));
    }

    updateRollupDescription(e) {
        const { rollupDefinition, dispatch } = this.props;
        dispatch(receivedRollupDefinition({...rollupDefinition, rollupDescription: e.target.value}));
    }

    goBack = () => {
        const { browserHistory, currentDb } = this.props;
        browserHistory.push(`/tod/queries/${currentDb.id}/rollups`);
    }

    changeDimension(e) {
        let val = e.target.value;
        if (val !== 'default') {
            const { rollupDefinition, dispatch } = this.props;
            dispatch(receivedRollupDefinition({...rollupDefinition, 
                attributeName: val,
                rollupData: '[]'}));
        }
    }

    selectDimensionValue(value) {
        const { rollupDefinition, dispatch } = this.props;
        let selectedDimensionValues = [...this.selectedDimensionValues];
        let index = selectedDimensionValues.indexOf(value);

        if (index === -1) {
            selectedDimensionValues.push(value);
        } else {
            selectedDimensionValues.splice(index, 1);
        }

        dispatch(receivedRollupDefinition({...rollupDefinition, rollupData: JSON.stringify(selectedDimensionValues)}));
    }

    selectAllDimensionValues() {
        const { rollupDefinition, dispatch } = this.props;

        let newValues = this.selectedDimensionValues.length ===
            this.dimensionValues.length
            ? []
            : this.dimensionValues.map(x=> x.value);
         dispatch(receivedRollupDefinition({...rollupDefinition, rollupData: JSON.stringify(newValues)}));
    }

    render() {
        const { softErrorMode } = this.state,
        { isFetching, dataSource, verifyPermission, currentDb, rollupDefinition } = this.props,
        rollupNameValidationError = this.getNameValidationError(softErrorMode);

        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        let r = rollupDefinition ? rollupDefinition : {};
        let name = r.rollupName ? r.rollupName.substring(1) : '';
        let selectedDimensionValues = this.selectedDimensionValues;
        let selectedValuesOption = r.allSelected ? 'all' : 'specific';
        let selectedDimension = this.selectedDimension;

        let body = <div />,
        dimensionValuesJSX = <div />,
        dimensionHeaderJSX = (
            <div style={style.dimensionRow}>
            <h4 style={style.dimensionLabel}>Dimension</h4>
            <h4>{r.attributeName}</h4>
            </div>
        ),
        options = this.dimensions.map((d, idx) => (
            <option key={`dimensions-option-${idx}`} value={d}>{d}</option>
        )),
        definitionNameJSX = (
            <Form.Group
            controlId="rollupDefinitionControl"
            style={{ marginTop: 10 }}
            >
            <Form.Label>Rollup Definition Name</Form.Label>
            <h4>{name}</h4>
            </Form.Group>
        );
        definitionNameJSX = (
            <Form.Group controlId="rollupDefinitionControl" style={{ marginTop: 10 }}>
                <Form.Label>Rollup Definition Name</Form.Label>
                <Form.Control
                as="input"
                placeholder="enter name"
                onChange={e => this.updateRollupDefinitionName(e)}
                value={name}
                isInvalid={rollupNameValidationError}
                />
                <Form.Control.Feedback type="invalid">{rollupNameValidationError}</Form.Control.Feedback>
            </Form.Group>
        );

        if (this.dimensionValues && this.dimensionValues.length) {
        dimensionValuesJSX = this.dimensionValues.map((dv, idx) => (
            <Form.Check type="checkbox"
            key={`valuesSelection-${idx}`}
            checked={selectedDimensionValues.indexOf(dv.value) !== -1}
            style={style.checkbox}
            onChange={() => this.selectDimensionValue(dv.value)}
            label={dv.value}/>
        ));
        }

        if (!this.editMode) {
        dimensionHeaderJSX = (
            <div style={style.dimensionRow}>
            <h4 style={style.dimensionLabel}>Dimension</h4>
            <Form.Control
                as="select"
                placeholder="select"
                value={r.attributeName || 'default'}
                onChange={e => this.changeDimension(e)}
            >
                <option value="default">Select a dimension</option>
                {options}
            </Form.Control>
            </div>
        );
        }
    
        if (currentDb && currentDb.useADX){
            body = <AdxQueryWarning/>;
        }
        else if (!rollupDefinition || isFetching) {
        body = (
            <div style={[GlobalStyles.card, style.contentContainer]}>
            <Loader loadingItem="Rollup Information" />
            </div>
        );
        } else {
        body = (
            <div style={[GlobalStyles.card, style.contentContainer]}>
            {dimensionHeaderJSX}
            {definitionNameJSX}
            <Form.Group
                controlId="rollupDescrptionControl"
                style={{ marginTop: 10 }}
            >
                <Form.Label>Rollup Description</Form.Label>
                <Form.Control
                as="input"
                placeholder="enter description"
                onChange={e => this.updateRollupDescription(e)}
                value={r.rollupDescription}
                />
            </Form.Group>
            <div
                style={{ padding: '0 1em 1em 1em', display: 'flex' }}
                key={"selectAllValuesSelection"}
            >
                <Form.Group>
                    <Form.Check
                        id='radio-specific'
                        type="radio"
                        checked={selectedValuesOption === 'specific'}
                        onChange={() => this.selectValuesOption('specific')}
                        inline
                        label='Specific'
                    />
                    <Form.Check
                        id='radio-all'
                        type="radio"
                        checked={selectedValuesOption === 'all'}
                        onChange={() => this.selectValuesOption('all')}
                        inline
                        label='All(*)'
                    />
                </Form.Group>
                {selectedDimension && selectedDimension.limitReached
                ? <span style={{color: Colors.red, fontWeight: 'bold', fontSize: '14px', marginLeft: '1em'}}>
                    Limit of {dataSource.maxDimensionValues} values has been reached
                </span>
                : ''}
                
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={style.selectBox}>
                    <Form.Check type="checkbox"
                    id="selectAllValuesSelection"
                    checked={selectedDimensionValues.length === this.dimensionValues.length &&
                        selectedDimensionValues.length !== 0}
                    style={style.checkbox}
                    onChange={this.selectAllDimensionValues}
                    label="(Select All)" />
                {dimensionValuesJSX}
                </div>
            </div>
            </div>
        );
        }

        return (
        <div>
            <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', height: 'calc(100vh - 106px)' }}>
            {body}
            </div>
            <SaveButtons
            saveHandler={this.saveRollupDefinition}
            backButtonHander={this.goBack}
            backButtonText="Back to Rollups"
            isSaveButtonDisabled={!hasPermissions}
            />
        </div>
        );
    }
}

export default radium(CreateEditRollupDefinitions);
