import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';

function TODNav() {
    return (
        <NavTabs>
            <NavTab label={'Databases'} to={'/tod/databases'}/>
            <NavTab label={'Uploads'} to={'/tod/uploads'}/>
            <NavTab label={'Queries'} to={'/tod/queries'}/>
            <NavTab label={'Advanced Queries'} to={'/tod/advancedQueries'}/>
            <NavTab label={'Totals'} to={'/tod/totals'}/>
        </NavTabs>
    )
}   

export default TODNav;
