import React, { Component } from 'react';
import radium from 'radium';
import { Grid, Button } from '@arius';
import { notAuthorized } from '@app/shared/containers/conditionalLink';

class UploadsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.getActionItems = this.getActionItems.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { uploads } = this.props;
        return nextProps.uploads !== uploads;
    }

    getGrid() {
        const { selectUploadsHandler, uploads: data, hideDetails } = this.props;

        let columns = [
            { type:'checkbox', width:'30', headerTemplate: ()=> ''}, // NOTE: make sure a primary key column is defined when using checkbox column
            { field: 'id', isPrimaryKey: true, visible: false},
            { field: 'displayName', headerText: 'Name', width: 500},
            { field: 'uploadedByUTC', headerText: 'Uploaded'},
            { field: 'uploadedBy', headerText: 'Uploaded By'},
            { field: 'size', headerText: 'Size', width: '70px', template: (r) => <span>{r.sizeFormatted}</span>},
            { headerText: 'Actions', template: this.getActionItems}
        ];
        
        return (<Grid 
            data={data} 
            columns={columns}
            checkBoxHandler={selectUploadsHandler}
            height='calc(100vh - 250px)'
            detailTemplate={hideDetails ? null : this.getDetailTemplate}
        />)
    }

    onDelete(row) {
        const { deleteHandler, canDelete } = this.props;
        if (canDelete){
            deleteHandler(row);
            return;
        }
        notAuthorized();
    }

    getActionItems(row) {
        return <Button toolTip="Delete" iconName="delete" onClick={() => this.onDelete(row)}/>
    }

    getDetailTemplate(row) {
        if (row.files.length === 1 && row.files[0].sourceFileName === row.name){
            return <div>[ Nothing to expand ]</div>
        }

        let columns = [
            { field: 'id', isPrimaryKey: true, visible: false},
            { field: 'name', headerText: 'Name'},
            { field: 'uploadedByUTC', headerText: 'Uploaded'},
            { field: 'uploadedBy', headerText: 'Uploaded By'},
            { field: 'size', headerText: 'Size', width: '70px', template: (r) => <span>{r.sizeFormatted}</span>},
        ];
        
        return (<Grid 
            data={row.files} 
            columns={columns}
            height='auto'
        />)
    }


    render() {
        return this.getGrid();
    }
}
    
export default radium(UploadsGrid);
