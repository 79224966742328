import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/shared/presentational/noResults';
import { Grid } from '@arius';

class AuditLogTable extends Component {
    static propTypes = {
        auditLogContent: PropTypes.array,
        tableHeight: PropTypes.string,
        firstSearchPerformed: PropTypes.bool,
        activePage: PropTypes.number,
        auditLogFetches: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.state = {};
        this.mapToUserFriendlyMethodName = this.mapToUserFriendlyMethodName.bind(this);
        this.mapToUserFriendlyMiscellaneousName = this.mapToUserFriendlyMiscellaneousName.bind(this);
        this.createLink = this.createLink.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    getGrid() {
        const { auditLogContent, searchFilterOpen } = this.props;

        let columns = [
            { field: 'timestamp', headerText: 'Timestamp', type: 'datetime'},
            { field: 'user', headerText: 'User', width: '14%' },
            { field: 'method', headerText: 'Method', width: '6.5%' },
            { field: 'category', headerText: 'Category', width: '8.8%' },
            { field: 'action', headerText: 'Action', width: '12%' },
            { field: 'database', headerText: 'Database', width: '12.8%' },
            { field: 'project', headerText: 'Project', width: '12.8%' },
            { headerText: 'Miscellaneous', width: '20%', template: this.createLink },
        ];
        
        let data = auditLogContent ? auditLogContent.map((row, idx) => {
            const methodFriendly = this.mapToUserFriendlyMethodName(row[2]);
            const miscellaneousFriendly = this.mapToUserFriendlyMiscellaneousName(row[7]);

            return { 
                id: idx,
                timestamp: row[0], 
                user: row[1], 
                method: methodFriendly, 
                category: row[3], 
                action: row[4], 
                database: row[5], 
                project: row[6], 
                miscellaneousFriendly }; 
            }) : [];

            return (
            <Grid
                columns={columns}
                data={data}
                height= {`calc(100vh - ${searchFilterOpen ? 250 : 172}px)`}
                allowPaging={false}
                // params={{
                //     search: params.q,
                //     start: params.ds,
                //     end: params.de,
                //     requiresCounts: params.requiresCounts
                // }}
                //url='auditlogs/search'
                detailTemplate={(r) => {
                    return <pre>{r.miscellaneousFriendly}</pre>
                }} />
            )

    }

    createLink(row) {
        return (
          <span >
              {row.miscellaneousFriendly}
          </span>
        );
    }

    mapToUserFriendlyMethodName(method) {
        if (method === 'POST') return 'Create';
        if (method === 'PUT') return 'Update';
        if (method === 'DELETE') return 'Delete';
        if (method === 'GET') return 'Retrieved';
        return method;
    }

    mapToUserFriendlyMiscellaneousName(miscellaneous) {
        if (typeof miscellaneous === 'object') return JSON.stringify(miscellaneous, null, 4);
        return miscellaneous;
    }

    render() {
        const { auditLogContent, firstSearchPerformed, activePage, auditLogFetches } = this.props;

        let logContent;

        if (auditLogContent && auditLogContent.length) {
        logContent = (
            this.getGrid()
        );
        } else if (auditLogContent && !auditLogContent.length && firstSearchPerformed === true && activePage !== 0) {
        logContent = <div style={{ width: '100vw', margin: '20px auto' }}><NoResults noMoreResults /></div>;
        } else if (auditLogContent && !auditLogContent.length && activePage === 0) {
        logContent = <div style={{ width: '100vw', margin: '20px auto' }}><NoResults /></div>;
        }
        if (auditLogFetches > 0) {
        logContent = (
            <div style={{ width: '100vw', margin: '5vh auto' }}>
            <Loader loadingItem="Audit logs" />
            </div>
        );
        }

        return (
        logContent
        );
    }
}

export default radium(AuditLogTable);
