import React from 'react';
import { Route } from "react-router-dom";
import { connect } from '@app/utilities/routing';
class FeatureRoute extends React.Component {
    render() {
        const {features, flag} = this.props;

        if (!features[flag]) { return null;}
        return <Route {...this.props} />;
    }
}

function mapStateToProps(state, ownProps) {
    const {features} = state;
    return { features };
}

export default connect(mapStateToProps)(FeatureRoute);