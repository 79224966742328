import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { fetchAuditLogs } from '@app/actions/portalManagement/audit.actions';
import AuditLogsMain from '@app/components/utils/presentational/auditLogsMain';

class AuditLogsTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object,
    errorMessage: PropTypes.string,
    users: PropTypes.object,
    userKey: PropTypes.string,
    groups: PropTypes.array,
    isUserUpdated: PropTypes.bool,
    children: PropTypes.object,
    fetchAuditLogs: PropTypes.func,
    auditLogs: PropTypes.array,
    auditLogContent: PropTypes.array,
    router: PropTypes.object,
    route: PropTypes.string,
    auditLogFetches: PropTypes.number,
    auditLogCSVFile: PropTypes.object,
    auditLogCSVFileFetches: PropTypes.number,
  };

  componentWillMount() {}

  componentDidMount() {}

  render() {
    const {
      dispatch,
      users,
      userKey,
      router,
      route,
      groups,
      isUserUpdated,
      application,
      auditLogs,
      auditLogContent,
      errorMessage,
      fetchAuditLogs,
      auditLogFetches,
      auditLogCSVFile,
      auditLogCSVFileFetches,
    } = this.props;

    let childProps = {
        dispatch,
        users,
        userKey,
        router,
        route,
        groups,
        isUserUpdated,
        application,
        auditLogs,
        auditLogContent,
        errorMessage,
        fetchAuditLogs,
        auditLogFetches,
        auditLogCSVFile,
        auditLogCSVFileFetches,
      };

    return (
      <div>
          <Route path="/utilities/auditLogSearch" render={props => (<AuditLogsMain {...childProps}/>)}/>
        {/* {this.props.children &&
          React.cloneElement(this.props.children, childProps)} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.portalManagement.users,
  userKey: state.user.userKey,
  router: state.router,
  route: state.route,
  groups: state.portalManagement.groups.items,
  isUserUpdated: state.portalManagement.users.isUserUpdated,
  application: state.application,
  auditLogs: state.portalManagement.auditLogs,
  auditLogContent: state.portalManagement.auditLogContent,
  auditLogFetches: state.portalManagement.auditLogFetches,
  auditLogCSVFileFetches: state.portalManagement.auditLogCSVFileFetches,
  auditLogCSVFile: state.portalManagement.auditLogCSVFile,
  errorMessage: state.application.errorMessage,
});

const mapDispatchToProps = dispatch => ({
    fetchAuditLogs: query => {
    dispatch(fetchAuditLogs(query));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsTab);
