import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { getAppSettings } from '../../../config';
import { fetchApiKey } from '@app/actions/settings.actions';
import { retrieveExtractDefinitions, deleteExtractDefinition } from '@app/actions/analysis/reporting.actions';
import { extractWithSelectedExtractDefinition, fetchReportsForExtractDefinition } from '../../../actions/analysis/reporting.actions';
import { notifyError } from '../../../utilities/notifier';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { Grid, Button } from '@arius';
import $ from 'jquery';
import { saveAs } from 'file-saver';
import { WORKSPACE_CREATEEDITEXTRACTTABLES, WORKSPACE_DELETEEXTRACTTABLES, WORKSPACE_DOWNLOADEXTRACTTABLES } from '@app/utilities/permissions';

const DEFAULT_MODAL = {
    title: 'Delete Extract table',
    message: '',
    mode: ''
};

const style = {
    clickable: {
        cursor: 'pointer',
    },
    statusLink: {
        display: 'flex',
        alignSelf: 'inherit',
        margin: '10px 0px 0px 20px',
        color: '#337AB7',
        fontWeight: 'lighter',
        textDecoration: 'underline',
        fontSize: 'small',
    },
};

class ExtractList extends Component {
    static propTypes = {
        extractDefinitions: PropTypes.array,
        isFetching: PropTypes.bool,
        firstSearchPerformed: PropTypes.bool,
        extractKickingOff: PropTypes.bool,
        isFetchingWideCsv: PropTypes.bool,
        isFetchingNarrowCsv: PropTypes.bool,
        userKey: PropTypes.string,
        databaseId: PropTypes.number,
        dispatch: PropTypes.func,
        editClickHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        apiKey: PropTypes.string,
        exportCsv: PropTypes.func,
        csv: PropTypes.object,  
    };
    constructor(props) {
        super(props);
        this.state = {
            sortedBy: 'name',
            extractDefinitions: [],
            ascending: true,
            modal: DEFAULT_MODAL,
        };
        this.extract = this.extract.bind(this);
        this.edit = this.edit.bind(this);
        this.add = this.add.bind(this);
        this.downloadExtractCsv = this.downloadExtractCsv.bind(this);
        this.copyCsvUrlToClipboard = this.copyCsvUrlToClipboard.bind(this);
        this.promptToDelete = this.promptToDelete.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    get canCreateEditExtractTable() {
        const { currentWorkspace, userPermissions } = this.props;
        if (currentWorkspace === null) return false;
        if (currentWorkspace.permissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1 &&
            userPermissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1) {
          return false;
        }
        return true;
    }
    
    get canDeleteExtractTable() {
        const { currentWorkspace, userPermissions } = this.props;
        if (currentWorkspace === null) return false;
        if (currentWorkspace.permissions.indexOf(WORKSPACE_DELETEEXTRACTTABLES) === -1 &&
            userPermissions.indexOf(WORKSPACE_DELETEEXTRACTTABLES) === -1) {
          return false;
        }
        return true;
    }

    get canDownloadExtractTable() {
        const { currentWorkspace, userPermissions } = this.props;
        if (currentWorkspace === null) return false;
        if (currentWorkspace.permissions.indexOf(WORKSPACE_DOWNLOADEXTRACTTABLES) === -1 &&
            userPermissions.indexOf(WORKSPACE_DOWNLOADEXTRACTTABLES) === -1) {
          return false;
        }
        return true;
    }

    componentDidMount() {
        const { dispatch, userKey } = this.props;
        this.refresh();
        dispatch(fetchApiKey(userKey)); 
    }

    componentDidUpdate(prevProps) {
        const { csv, currentWorkspace } = this.props;
        const { csvid } = this.state;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }

        if (csv && csvid && csvid === csv.id) {
            $(`#action-overlay-${csvid}`).click(); 
            this.setState({ csvid: '' });
            saveAs(csv.file, csv.fileName);
        }
    }

    refresh() {
        const { userKey, dispatch, currentWorkspace, isFetching } = this.props;
        if (currentWorkspace && !isFetching){
            dispatch(retrieveExtractDefinitions({ userKey, databaseId: currentWorkspace.id }));
        }
    }

    extract(o) {
        const { userKey, currentWorkspace, dispatch } = this.props;
        let definitionId = o.id;
        this.toggle(definitionId);
        dispatch(extractWithSelectedExtractDefinition(
            { userKey, databaseId: currentWorkspace.id, definitionId },()=> this.toggle(definitionId)));
    }

    edit(o) {
        const { currentWorkspace, history } = this.props;
        let definitionId = o.id;
        $(`#action-overlay-${o.id}`).click();
        history.push(`/arius/reporting/${currentWorkspace.id}/extracts/${definitionId}`)
    }

    add() {
        const { currentWorkspace, history } = this.props;

        if (!currentWorkspace){
            notifyError('Please select a database');
            return;
        }

        if (!this.canCreateEditExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        history.push(`/arius/reporting/${currentWorkspace.id}/extracts/new`)
    }

    downloadExtractCsv(o, format) {
        const { apiKey, currentWorkspace, userKey, exportCsv, isFetchingWideCsv, isFetchingNarrowCsv } = this.props;
        const { csvid } = this.state;
        
        if (!this.canDownloadExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }

        if (apiKey === null || apiKey === ""){
            notifyError("You must have API key assigned");
            return;
        }

        if ((isFetchingWideCsv || isFetchingNarrowCsv) && csvid) {
          return;
        }
        
        $(`#action-overlay-${o.id}`).click();  // make action menu close.  TODO find better solution...
        this.setState({ csvid: o.id });
        exportCsv(userKey, o.id, format, currentWorkspace.name, o.name, apiKey);
        setTimeout(()=> {
            $(`#action-overlay-${o.id}`).click(); 
        }, 500);
    }

    copyCsvUrlToClipboard(o, format) {
        const { apiKey, currentWorkspace } = this.props;
                
        if (!this.canDownloadExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
    
        getAppSettings().then(settings => {
            const { baseServiceUrl } = settings;
            const url = 'https://' + baseServiceUrl + '/extract/' + format + '/' + currentWorkspace.name + '/' + o.name + '?apikey=' + apiKey;
        
            const copyText = document.getElementById('csv-url');
            copyText.value = encodeURI(url);
            copyText.style.visibility = '';
            copyText.select();
            document.execCommand('copy');
            copyText.style.visibility = 'hidden';
        });
    }

    toggle(id){
        // make the popup menu refresh - intended for the "waiting" indicators in popup menus
        $(`#action-overlay-${id}`).click();
       setTimeout(() => {
           $(`#action-overlay-${id}`).click();
       }, 200);
    }

    promptToDelete(o) {
        const { userKey, dispatch, currentWorkspace } = this.props;
        let id = o.id;
        let databaseId = currentWorkspace.id;
        if (!this.canDeleteExtractTable) {
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }

        this.setState({modal: {...DEFAULT_MODAL, mode: 'checking'}});
        dispatch(fetchReportsForExtractDefinition(userKey, databaseId, id,
            (data)=> {
                let names = data ? data.names : null;
                if (names && Array.isArray(names)){
                    if (names.length > 0) {
                        this.setState({
                            modal: { ...DEFAULT_MODAL, mode: 'warn',
                                message: <div>
                                    Cannot delete this Extract table that is referenced in the following Reporting tables:
                                <ul>
                                    {names.map(n => <li key={`li-${n}`} style={{ fontWeight: 800 }}>{n}</li>)}
                                </ul>
                            </div>
                            }
                        });
                    } else {
                        this.setState({
                            modal: { ...DEFAULT_MODAL, mode: 'prompt',
                                title: 'Are you sure?',
                                message: <div>
                                    Are you sure you want to delete the following extract table definition?  
                                    <ul>
                                        <li style={{ fontWeight: 800 }}>{o.name}</li>
                                    </ul>
                                </div>,
                                yesHandler: ()=> {
                                    dispatch(deleteExtractDefinition({ userKey, databaseId, definitionId: id }));
                                    this.setState({modal: {...DEFAULT_MODAL, mode: ''}});
                                }
                            }
                        });
                    }
                } else {
                    this.setState({modal: {...DEFAULT_MODAL, mode: 'fail'}})
                }
            }));
    }

    navToStatus() {
        const { currentWorkspace, history } = this.props;
        if (currentWorkspace) {
            history.push(`/arius/automation/${currentWorkspace.id}/status`);
        }
    }

    getDialogModal() {
        const { modal } = this.state;
        let { mode, title, message, yesHandler } = modal;
        let show = mode !== '';
        let handleClose = () => this.setState({modal: {...modal, mode: ''}});
        let buttons = <Button variant="arius" onClick={handleClose}>Close</Button>;
        
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};


        if (mode === 'checking') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
                <div style={textStyle}>Checking dependencies...</div>
            </span>;
        }

        if (mode === 'fail') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
                <div style={textStyle}>Error checking dependencies</div>
            </span>;
        }

        if (mode === 'prompt') {
            buttons = [
                <Button variant="arius" key='y' onClick={yesHandler}>Yes</Button>,
                <Button variant="arius" key='n' onClick={handleClose}>No</Button>
            ];
        }

        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={handleClose} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>{title}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
        );
    }

    getActionItems(o) {
        const { extractKickingOff, isFetchingWideCsv, isFetchingNarrowCsv } = this.props;
        const popover = (
            <Popover id={`todListItemActions-${o.id}`}>
                <Button toolTip="Update Extract Table" 
                    iconName="fa-table" 
                    isWaiting={extractKickingOff}
                    onClick={()=> this.extract(o)}/>
                <Button toolTip="Edit Definition" iconName="fa-pencil" onClick={()=> {this.edit(o)}}/>
                <Button toolTip="Download wide format CSV" 
                    iconName="fa-download" 
                    isWaiting={isFetchingWideCsv}
                    onClick={()=> {this.downloadExtractCsv(o, 'wide')}}/>
                <Button toolTip="Copy wide CSV url to clipboard" 
                    iconName="content_copy"
                    onClick={()=> {this.copyCsvUrlToClipboard(o, 'wide')}}/>
                <Button toolTip="Download narrow format CSV" 
                    iconName="fa-download" 
                    isWaiting={isFetchingNarrowCsv}
                    onClick={()=> {this.downloadExtractCsv(o, 'narrow')}}/>
                <Button toolTip="Copy narrow CSV url to clipboard" 
                    iconName="content_copy"
                    onClick={()=> {this.copyCsvUrlToClipboard(o, 'narrow')}}/>
                <Button toolTip="Delete Table" iconName="delete" onClick={()=> {this.promptToDelete(o)}}/>
            </Popover>
        );

        return (
        <OverlayTrigger  trigger="click" placement="auto" overlay={popover} rootClose={true} >
            <i
                id={`action-overlay-${o.id}`}
                key={`action-items-${o.id}`}
                onClick={(e) => e.stopPropagation()}
                style={{...style.menuIcon, ...style.menuIconHover}}
                className="material-icons"
            >
                more_horiz
            </i>
            
        </OverlayTrigger>
        )
    }

    getGrid(data) {
        const columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'lastModified', headerText: 'Last Modified'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastSubmitted', headerText: 'Last Submitted'},
            { field: 'lastSubmittedBy', headerText: 'Last Submitted By'},
            { field: 'count', headerText: 'Record Count',
                template: (r) => <span>{r.count.toLocaleString()}</span>},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
                columns={columns}
                data={data}
                height='calc(100vh - 232px)' 
                detailTemplate={this.getDetailTemplate}
            />
    }

    render() {
        const { extractDefinitions } = this.props,
        statusLink = (
            <span style={[style.clickable]} onClick={() => this.navToStatus()}>
            See status reports
            </span>
        );

        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Extract Tables</h4>
                        <div style={{ ...style.statusLink }}>
                            {statusLink}
                        </div>
                    </span>
                    <Button
                        toolTip = "Create new table definition"
                        mode="add"
                        onClick={this.add}
                    />
                </div>
                {this.getDialogModal()}
                {this.getGrid(extractDefinitions)}
                <input type="text" id="csv-url" style={{visibility: 'hidden'}}></input>
            </div>
        )
    }
}

export default radium(ExtractList);
