import moment from 'moment';
import {
  RECEIVED_TOTALS_FOR_TOD_DB,
  FETCHING_TOD_TOTALS,
  FETCHING_TOD_TOTALS_TABLE,
  RECEIVED_TOTALS_TABLE,
  RECEIVED_COLUMN_VALIDATION,
  RECEIVED_TOD_CSV_FILE,
  RECEIVED_TOD_EXCEL_FILE,
} from '../../actions/tod/tod.actionTypes';

function parseDate(d) {
  const value = d.value ? moment(d.value) : moment(d.text, 'MM-DD-YYYY');
  return { text: d.text, value };
}

function convertDates(dataSource) {
  if (!dataSource) {
    return null;
  }
  return Object.assign({}, dataSource, {
    asOfDates: dataSource.asOfDates
      .map(parseDate)
      .sort((a, b) => (b.value - a.value)),
  });
}

const totalsReducer = (state = {
  isFetching: false,
  dataSource: null,
  isSaving: false,
  isFetchingTable: false,
  table: null,
  columnValidation: null,
  csvFile: null,
  excelFile: null,
}, action) => {
  switch (action.type) {
    case FETCHING_TOD_TOTALS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVED_TOTALS_FOR_TOD_DB:
      return Object.assign({}, state, {
        isFetching: false,
        dataSource: convertDates(action.dataSource),
      });
    case FETCHING_TOD_TOTALS_TABLE:
      return Object.assign({}, state, {
        isFetchingTable: true,
      });
    case RECEIVED_TOTALS_TABLE:
      return Object.assign({}, state, {
        isFetchingTable: false,
        table: action.table,
      });
    case RECEIVED_COLUMN_VALIDATION:
      return Object.assign({}, state, {
        columnValidation: action.columnValidation,
      });
    case RECEIVED_TOD_CSV_FILE:
      return Object.assign({}, state, {
        csvFile: action.csvFile,
      });
    case RECEIVED_TOD_EXCEL_FILE:
      return Object.assign({}, state, {
        excelFile: action.excelFile,
      });
    default:
      return state;
  }
};

export default totalsReducer;
