import {
    FETCHING_JOB_STATUS,
    RECEIVED_JOB_STATUS,
    CLEAR_JOB_STATUS,
  } from '../actions/jobStatus.actionTypes';
  
  const jobStatus = (state = {
    jobStatuses: {},
    fetchingJobStatuses: {},
  }, action) => {
    switch (action.type) {
      
      case FETCHING_JOB_STATUS:
        return Object.assign({}, state, {
             fetchingJobStatuses: {
                ...state.fetchingJobStatuses,
                [action.tag]: true
              }

        });

      case RECEIVED_JOB_STATUS:

        if (!action.jobStatus)
          return state;
          
        return Object.assign({}, state, {
            jobStatuses: {
                ...state.jobStatuses,
                [action.jobStatus.tag]: action.jobStatus,
            },
            fetchingJobStatuses: {
                ...state.fetchingJobStatuses,
                [action.jobStatus.tag]: false
            }
        });

        case CLEAR_JOB_STATUS:
          return Object.assign({}, state, {
              jobStatuses: {
                ...state.jobStatuses,
                [action.tag]: null,
            },

        });

      default:
        return state;
    }
  };
  
  export default jobStatus;