import {
  RECEIVED_WORKSPACE,
  RECEIVED_WORKSPACES,
  SELECTING_WORKSPACE,
  RECEIVED_ARCHIVED,
  RECEIVED_ARCHIVED_FOR_BACKUP,
  RECEIVED_BACKUPS,
  RECEIVED_PIT_RESTORE_RANGE,
  FETCHING_BACKUPS,
  FETCHING_WORKSPACES_FROM_SERVER,
  FETCHING_WORKSPACE_FROM_SERVER,
  RECEIVED_DATABASE_UDO_VERSIONS,
  RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS,
  FETCHING_REVIEWLEVEL,
  RECEIVED_REVIEWLEVEL,
  RECEIVED_REVIEWLEVELS,
  RECEIVED_AE_DB_SCRIPT_VERSION,
  REQUESTING_EXTRACT_DATA_CSV,
  RECEIVED_EXTRACT_DATA_CSV,
  FAILED_EXTRACT,
  RECEIVED_BACKUPRESTORE_LOG_FILE,
} from '../../actions/analysis/analysis.actionTypes';

const workspacesReducer = (
  state = {
    isFetching: false,
    isFetchingWorkspace: false,
    isSaving: false,
    items: [],
    reviewLevels:[],
    reviewLevelMembers:[],
    backups: [],
    archived: [],
    current: null,
    modal: null,
    databaseUdoVersions: [],
    UdoLibrarylockstatus:false,
    currentAEVersion: null,
    isExtractToCsvProcessing: false,
    isFetchingReviewList:false,
    csvExtractFile: null,
	txtFile: null,
  },
  action,
) => {
  switch (action.type) {
    case FAILED_EXTRACT:
      return Object.assign({}, state, { isExtractToCsvProcessing: false });
    case FETCHING_WORKSPACES_FROM_SERVER:
      return Object.assign({}, state, { isFetching: true, isSaving: false });
    case FETCHING_WORKSPACE_FROM_SERVER:
        return Object.assign({}, state, { isFetchingWorkspace: true });
    case RECEIVED_WORKSPACE:
    case SELECTING_WORKSPACE:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingWorkspace: false,
        current: action.current,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_WORKSPACES:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        items: action.workspaces,
        receivedAt: action.receivedAt,
      });
    case FETCHING_BACKUPS:
      return Object.assign({}, state, {
        isFetching: false,
        backups: action.backups,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_BACKUPS:
      if (state.current.id !== action.workspaceId) {
        return state;
      }
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, { backups: action.backups }),
        isFetching: false,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_PIT_RESTORE_RANGE:
          if (state.current.id !== action.workspaceId) {
              return state;
          }
          return Object.assign({}, state, {
              current: Object.assign({}, state.current, {
                  earliestRestorePoint: action.earliestRestorePoint,
                  latestRestorePoint: action.latestRestorePoint
              }),
              receivedAt: action.receivedAt,
          });
    case RECEIVED_ARCHIVED_FOR_BACKUP:
    case RECEIVED_ARCHIVED:
      if (state.current.id !== action.workspaceId) {
        return state;
      }
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, { archived: action.archived }),
        isFetching: false,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_DATABASE_UDO_VERSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        databaseUdoVersions: action.databaseUdoVersions,
      });
       case RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS:
       return Object.assign({}, state, {
        isFetching: false,
        UdoLibrarylockstatus: action.UdoLibrarylockstatus,
      });
      case FETCHING_REVIEWLEVEL: 
        return Object.assign({}, state, {
          isFetchingReviewLevel: true,
      });
      case RECEIVED_REVIEWLEVEL:
        return Object.assign({}, state, {
          isFetchingReviewLevel: false,
          selectedReviewLevel: action.reviewLevel,
      });
      case RECEIVED_REVIEWLEVELS:
      return Object.assign({}, state, {
        isFetchingReviewList: true,
        reviewLevels: action.reviewLevels,
        selectedReviewLevel: null,
     });
    case RECEIVED_AE_DB_SCRIPT_VERSION:
      return Object.assign({}, state, {
        isFetching: false,
        currentAEVersion: action.currentAEVersion,
      });
    case REQUESTING_EXTRACT_DATA_CSV:
    case RECEIVED_EXTRACT_DATA_CSV:
      return Object.assign({}, state, {
        isExtractToCsvProcessing: action.isExtractToCsvProcessing,
        csvExtractFile: action.csvExtractFile,
      });

    case RECEIVED_BACKUPRESTORE_LOG_FILE:
      return Object.assign({}, state, {
        txtFile: action.txtFile,
      });

    default:
      return state;
  }
};

export default workspacesReducer;
