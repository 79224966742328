import { callApi } from '../utilities/callApi';

export function retrieveUploads(userKey) {
    return callApi(
        '/uploads/ae',
        { 'X-AE-USERKEY': userKey }
    );
}

export function deleteUpload(userKey, uploadId) {
    return callApi(
        `/uploads/ae/${uploadId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE'
    );
}

export function deleteMultipleUploadFiles(userKey, bulkDeleteRequest) {
    return callApi(
        `/uploads/ae/bulkdelete`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        bulkDeleteRequest
    );
}

export function uploadFile(userKey, file) {
    return callApi(
        '/uploads/ae',
        { 'Content-Type': 'multipart/form-data', 'X-AE-USERKEY': userKey },
        'POST',
        file
    );
}

export function retrieveColumnsForFile(userKey, fileId) {
    return callApi(
      `/uploads/ae/${fileId}/columns`,
      { 'X-AE-USERKEY': userKey }
    );
  }

