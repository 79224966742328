import {
  TOGGLED_SETTINGS_MENU,
  FETCHING_API_KEY,
  RECEIVED_API_KEY,
} from './actionTypes';
import * as settingsServiceApi from '../serviceApi/settings.serviceApi';


function settingsMenuToggled(settingsMenuOpen) {
  return {
    type: TOGGLED_SETTINGS_MENU,
    settingsMenuOpen,
  };
}

function toggleSettingsMenu(stateToToggle) {
  return (dispatch, getState) => {
    let { settingsMenuOpen } = getState().settingsMenu;
    if (stateToToggle === 'close') {
      if (settingsMenuOpen === false) return;
      settingsMenuOpen = false;
    } else if (stateToToggle === 'open') {
      if (settingsMenuOpen === true) return;
      settingsMenuOpen = true;
    } else if (!stateToToggle) {
      settingsMenuOpen = !settingsMenuOpen;
    }
    dispatch(settingsMenuToggled(settingsMenuOpen));
  };
}

function fetchingApiKeys() {
  return {
    type: FETCHING_API_KEY,
  };
}

function receivedApiKey(apiKey) {
  return {
    type: RECEIVED_API_KEY,
    apiKey,
  };
}

function fetchApiKey(userKey) {
  return dispatch => {
    dispatch(fetchingApiKeys());
    return settingsServiceApi
      .fetchApiKey(userKey)
      .then((json) => {
        if (json && !json.error) dispatch(receivedApiKey(json));
      });
  };
}

function requestNewKey(userKey) {
  return dispatch => {
    dispatch(fetchingApiKeys());
    return settingsServiceApi
      .requestNewKey(userKey)
      .then((json) => {
        if (!json.error) dispatch(receivedApiKey(json));
      });
  };
}

function deleteApiKey(userKey) {
  return dispatch => {
    dispatch(fetchingApiKeys());
    return settingsServiceApi
      .deleteApiKey(userKey)
      .then((json) => {
        if (!json.error) dispatch(receivedApiKey(null));
      });
  };
}

export {
  toggleSettingsMenu,
  fetchApiKey,
  requestNewKey,
  deleteApiKey,
};
