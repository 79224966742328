import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import radium from 'radium';
import { toggleSettingsMenu, fetchApiKey, requestNewKey, deleteApiKey } from '../../../actions/settings.actions';
// import Admin from '../presentational/admin';
// import General from '../presentational/general';
// import Tod from '../presentational/tod';
// import Advanced from '../presentational/advanced';
// import AriusAnalysis from '../presentational/ariusAnalysis';
import User from '../presentational/user';

const style = {
  container: {
    // cursor: 'move',
    // height: '600px',
    // width: '800px',
    maxWidth: '80vw',
    maxHeight: '80vh',
    border: '1px solid #ddd',
    backgroundColor: 'white',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  boxHeader: {
    width: '100%',
    borderBottom: '1px solid #ddd',
    height: '30px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  boxHeaderTabs: {
    borderBottom: '1px solid #ddd',
    height: '30px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
  },
  boxBody: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#F9F9F9',
    flexFlow: 'column nowrap',
    flexGrow: '1',
  },
  tabs: {
    height: '30px',
  },
  tabLabels: {
    borderRight: '1px solid #ddd',
    padding: '5px 10px',
    cursor: 'pointer',
    display: 'flex',
    width: '80px',
    justifyContent: 'center',
  },
  windowCloseIcon: {
    fontSize: '16px',
    display: 'flex',
  },
};

class Settings extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string,
    settingsMenuOpen: PropTypes.bool,
    apiKey: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedView: 'User',
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.selectView = this.selectView.bind(this);
    this.requestKey = this.requestKey.bind(this);
    this.deleteApiKey = this.deleteApiKey.bind(this);
  }

  componentWillMount() {
    const { dispatch, userKey } = this.props;
    dispatch(fetchApiKey(userKey));
  }

  closeMenu() {
    const { dispatch } = this.props;
    dispatch(toggleSettingsMenu('close'));
  }

  selectView(name) {
    const { selectedView } = this.state;

    if (name !== selectedView) {
      this.setState({ selectedView: name });
    }
  }

  requestKey() {
    const { dispatch, userKey } = this.props;
    dispatch(requestNewKey(userKey));
  }
  
  deleteApiKey() {
    const { dispatch, userKey } = this.props;
    dispatch(deleteApiKey(userKey));
  }

  render() {
    const
      { apiKey } = this.props,
      tabs = [
        // {
        //   label: 'General',
        //   name: 'General',
        //   component: <General />,
        // },
        // {
        //   label: 'Admin',
        //   name: 'Admin',
        //   component: <Admin />,
        // },
        // {
        //   label: 'ToD',
        //   name: 'ToD',
        //   component: <Tod />,
        // },
        // {
        //   label: 'Analysis',
        //   name: 'Arius Analysis',
        //   component: <AriusAnalysis />,
        // },
        // {
        //   label: 'Advanced',
        //   name: 'Advanced',
        //   component: <Advanced />,
        // },
        {
          label: 'User',
          name: 'User',
          component: (
            <User
              apiKey={apiKey}
              requestKey={this.requestKey}
              deleteApiKey={this.deleteApiKey} />
          ),
        },
      ],
      { selectedView } = this.state,
      tabLabels = tabs.map((t, i) => {
        let selectedStyle = {};
        if (t.name === selectedView) {
          selectedStyle = {
            background: 'rgba(0, 0, 0, 0.1)',
          };
        }
        return (
          <div
            key={`${i}-${t.name}`}
            onMouseDown={() => this.selectView(t.name)}
            style={{ ...style.tabLabels, ...selectedStyle }}>
            <span>{t.label}</span>
          </div>
        );
      }),
      tabComponent = tabs.find(t => t.name === selectedView).component;

    return (
      <div style={{...style.container}} id="settings-box">
        <div style={style.boxHeader}>
          <div style={style.boxHeaderTabs}>
            {tabLabels}
          </div>
          <div style={{ display: 'flex' }}>
            <i
              style={style.windowCloseIcon}
              className="fa fa-window-close close-window-icon"
              onMouseDown={(e) => this.closeMenu(e)}
              aria-hidden="true">
            </i>
          </div>
        </div>
        <div style={style.boxBody}>
          {tabComponent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingsMenuOpen: state.settingsMenu.settingsMenuOpen,
  userKey: state.user.userKey,
  apiKey: state.settingsMenu.apiKey,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(Settings));
