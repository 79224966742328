import { callApi } from '../utilities/callApi';

export function retrieveRoles() {
    return callApi('/roles');
}

export function retrieveAriusRoles() {
    return callApi('/roles/authorization');
}

export function retrieveCurrentUserPermissions() {
    return callApi('/authorize/actions');
}

export function retrieveAriusRole(roleId) {
    return callApi(`/roles/authorization/${roleId}`);
}

export function retrieveAriusPermissions() {
    return callApi('/roles/authorization/permissions');
}

export function retrieveAriusPermissionsForRole(roleId) {
    return callApi(`/roles/authorization/${roleId}/permissions`);
}

export function createRole(
    { userKey, roleId, name, permissions, description, application },
) {
    return callApi(
        '/roles/authorization',
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        { roleId, name, description, permissions, application },
    );
}

export function assignRoles(
    { userKey, users, roles, site, databases, operation },
) {
    let queryParam = '';

    if (operation) {
        queryParam = `?operation=${operation}`;
    }
    return callApi(
        `/roles/assignment${queryParam}`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        { userKey, users, roles, site, databases, operation },
    );
}

export function retrieveRoleAssignments() {
    return callApi('/roles/assignment');
}

export function updateUserRole(userKey, roleId, roleInformation) {
    return callApi(
        `/roles/authorization/${roleId}/permissions`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'PUT',
        { ...roleInformation },
    );
}

export function deleteRole(userKey, roleId) {
    return callApi(
        `/roles/authorization/${roleId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE',
    );
}
