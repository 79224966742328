import React, { Component } from 'react';
import radium from 'radium';
import { Form, Row, Card } from 'react-bootstrap';
import * as style from '@app/utilities/globalStyles';
import { Table } from '@arius';

class CsvColumnsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getGrid = this.getGrid.bind(this);
        this.getRow = this.getRow.bind(this);
        this.select = this.select.bind(this);
    }

    select(e, idx) {
        const { data, saveHandler } = this.props;
        let newData = [...data];
        newData[idx][e.target.id] = e.target.value;
        saveHandler(newData);
    }

    getRow(row, idx) {
        const { types, formats, delimiters, disabled } = this.props;
        let {Tr, Td} = Table;
        return (
          <Tr key={`newMapping-${idx}`}>
            <Td style={{ width: '40%' }}>
                {row.name}
            </Td>
            <Td style={{ width: '20%' }}>
                <Form.Control 
                    as="select"
                    id="dataType"
                    value={row.dataType}
                    disabled={disabled}
                    onChange={(e) => this.select(e, idx)}>
                        {types.map((x, index) => (
                            <option key={`${index}-${x}`} value={x}>{x}</option>
                        ))}
                </Form.Control>
            </Td>
            <Td style={{ width: '20%' }}>
                {row.dataType === 'Date' ? <Form.Control 
                    as="select"
                    id="format"
                    value={row.format}
                    disabled={disabled}
                    onChange={(e) => this.select(e, idx)}>
                        {formats.map((x, index) => (
                            <option key={`${index}-${x}`} value={x}>{x}</option>
                        ))}
                </Form.Control> : ''}
            </Td>
            <Td style={{ width: '20%' }}>
                {row.dataType === 'Date' ? <Form.Control 
                    as="select"
                    id="delimiter"
                    value={row.delimiter}
                    disabled={disabled}
                    onChange={(e) => this.select(e, idx)}>
                        {delimiters.map((x, index) => (
                            <option key={`${index}-${x.label}`} value={x.value}>{x.label}</option>
                        ))}
                </Form.Control> : ''}
            </Td>
          </Tr>
        );
    }

    getGrid() {
        const { data } = this.props;
        let {Tr, Th, Tbody, Thead, ResponsiveTable:Tbl} = Table;
        return (
            <Tbl>
                <Thead>
                    <Tr>
                        <Th style={{ width: '40%' }}>CSV Column</Th>
                        <Th style={{ width: '20%' }}>Data Type</Th>
                        <Th style={{ width: '20%' }}>Format</Th>
                        <Th style={{ width: '20%' }}>Delimiter</Th>
                    </Tr>
                </Thead>
                <Tbody style={style.tableBody} scroll="false" id="columnsTable">
                    {data.map((x, idx) => this.getRow(x, idx))}
                </Tbody>
            </Tbl>
        )
    }
    
    render() {
        return (
            <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
                <Card.Body>
                    <Card.Title>Column Definition</Card.Title>  
                    <Row style={{padding: 10}}>
                        {this.getGrid()}
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

export default radium(CsvColumnsTable);
