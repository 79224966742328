import { connect } from '@app/utilities/routing';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';

import { retrieveUserNamesForExtractDefinition } from '@app/actions/analysis/reporting.actions';

const style = {
  radio: {
    margin: '8.5px 2.5px 2.5px 2.5px',
    height:'32px',
  },
};

class LastUpdatedByView extends Component {
  static propTypes ={
    updateParent: PropTypes.func,
    state: PropTypes.object,
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      //selectedUserNameId: 0,
      selectedRadioOption: 'all',
      label: 'All',
    };
    this.handleLastUpdateByNameChange = this.handleLastUpdateByNameChange.bind(this);
    this.handleSelectRadioOption = this.handleSelectRadioOption.bind(this);
    this.updateTheParent = this.updateTheParent.bind(this);
  }

  componentDidMount() {
    const { dispatch, userKey } = this.props;
    const { userNamesForExtractDefinition } = this.props;

    if (!userNamesForExtractDefinition || userNamesForExtractDefinition < 0) {
      dispatch(retrieveUserNamesForExtractDefinition(userKey));
    } 
  }

  componentDidUpdate(prevProps) {
    this.setUserNameIdToDefault();
  }

  componentWillMount() {
    const { state } = this.props;
    
    this.setState(state);
  }

  setUserNameIdToDefault() {
    const { userNamesForExtractDefinition } = this.props;
    const { selectedUserNameId } = this.state;
    if (userNamesForExtractDefinition && userNamesForExtractDefinition.length > 0 && selectedUserNameId < 0) {
      this.setState({ selectedUserNameId: userNamesForExtractDefinition[0].userId }, () => this.updateTheParent());
    }
  }

  handleLastUpdateByNameChange(e) {
    this.setState({ selectedUserNameId: e.target.value }, () => this.updateTheParent());
  }

  handleSelectRadioOption(option) {
    this.setState({ selectedRadioOption: option }, () => this.updateTheParent());
  }

  updateTheParent() {
    const { updateParent } = this.props;
    updateParent(this.state);
  }

  render() {
    const { userNamesForExtractDefinition } = this.props;
    const { selectedRadioOption, selectedUserNameId } = this.state;

    let unfed = userNamesForExtractDefinition ? userNamesForExtractDefinition : [];

    var isDisabled = selectedRadioOption === 'lastUpdatedBy' ? false : true;

    return (
      <Form style={{marginLeft: '20px', marginBottom: '15px'}}>
        <Form.Row>
          <Form.Check type="radio"
                id="lub1"
                style={style.radio}
                name="radioGroup"
                checked={selectedRadioOption === 'all'}
                onChange={() => this.handleSelectRadioOption('all') }
                label="All" />
        </Form.Row>
        <Form.Row>
          <Form.Check type="radio"
                id="lub2"
                style={style.radio}
                name="radioGroup"
                checked={selectedRadioOption === 'lastUpdatedBy'}
                onChange={ () => this.handleSelectRadioOption('lastUpdatedBy') }
                label="Last Updated By:" />
              <select disabled={isDisabled} className="form-control"
                style={{width: '300px', marginLeft: '5px', position: 'relative', top: '3px'}}
                id="userNamesForExtract" value={selectedUserNameId} onChange={this.handleLastUpdateByNameChange}>
                  {unfed.map(col => (
                    <option
                        value={col.userId}
                        key={col.userId}
                      >
                      {col.name}
                    </option>
                  ))}
              </select>
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  userNamesForExtractDefinition: state.analysis.reporting.userNamesForExtractDefinition,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(LastUpdatedByView));
