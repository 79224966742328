export const SELECTING_WORKSPACE = 'SELECTING_WORKSPACE',
  SELECTING_PROJECT = 'SELECTING_PROJECT',
  SELECTING_REPORT = 'SELECTING_REPORT',
  RECEIVED_WORKSPACE = 'RECEIVED_WORKSPACE',
  RECEIVED_WORKSPACES = 'RECEIVED_WORKSPACES',
  RECEIVED_PIT_RESTORE_RANGE = 'RECEIVED_PIT_RESTORE_RANGE',
  RECEIVED_BACKUPS = 'RECEIVED_BACKUPS',
  RECEIVED_ARCHIVED = 'RECEIVED_ARCHIVED',
  RECEIVED_ARCHIVED_FOR_BACKUP = 'RECEIVED_ARCHIVED_FOR_BACKUP',
  RECEIVED_PROJECT = 'RECEIVED_PROJECT',
  RECEIVE_PROJECTS = 'RECEIVE_PROJECTS',
  RECEIVE_SEGMENTS = 'RECEIVE_SEGMENTS',
  RECEIVED_REPORT_ACCESS_TOKEN = 'RECEIVED_REPORT_ACCESS_TOKEN',
  RECEIVED_REPORTS = 'RECEIVED_REPORTS',
  FETCHING_WORKSPACES_FROM_SERVER = 'FETCHING_WORKSPACES_FROM_SERVER',
  FETCHING_WORKSPACE_FROM_SERVER = 'FETCHING_WORKSPACE_FROM_SERVER',
  FETCHING_PROJECTS_FROM_SERVER = 'FETCHING_PROJECTS_FROM_SERVER',
  FETCHING_SEGMENTS_FROM_SERVER = 'FETCHING_SEGMENTS_FROM_SERVER',
  FETCHING_REPORT_FROM_SERVER = 'FETCHING_REPORT_FROM_SERVER',
  FETCHING_REPORTS_FROM_SERVER = 'FETCHING_REPORTS_FROM_SERVER',
  FETCHING_REPORT_ACCESS_TOKEN = 'FETCHING_REPORT_ACCESS_TOKEN',
  FETCHING_BACKUPS = 'FETCHING_BACKUPS',
  FETCHING_ARCHIVED_BACKUPS = 'FETCHING_ARCHIVED_BACKUPS',
  TRY_DELETE_WORKSPACES = 'TRY_DELETE_WORKSPACES',
  CHECKING_OUT_PROJECT = 'CHECKING_OUT_PROJECT',
  RESTORING_BACKUP = 'RESTORING_BACKUP',
  RESTORING_ARCHIVED = 'RESTORING_ARCHIVED',
  RECEIVED_DATABASE_UDO_VERSIONS = 'RECEIVED_DATABASE_UDO_VERSIONS',
  RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS='RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS',
  LOCK_UDO_LIBRARY="LOCK_UDO_LIBRARY",
  UNLOCK_UDO_LIBRARY="UNLOCK_UDO_LIBRARY",
  RECEIVED_REVIEWLEVELS="RECEIVED_REVIEWLEVELS",
  RECEIVED_REVIEWLEVEL="RECEIVED_REVIEWLEVEL",
  FETCHING_REVIEWLEVEL="FETCHING_REVIEWLEVEL",
  SET_SELECTED_REVIEWLEVEL = "SET_SELECTED_REVIEWLEVEL",
  CHANGE_REVIEWLEVEL_STATUS='CHANGE_REVIEWLEVEL_STATUS',
  INITIATING_BATCH_PROCESS = 'INITIATING_BATCH_PROCESS',
  RECEIVED_LONG_RUNNING_OPERATIONS_STATUS =
    'RECEIVED_LONG_RUNNING_OPERATIONS_STATUS',
  RECEIVED_AE_DB_SCRIPT_VERSION = 'RECEIVED_AE_DB_SCRIPT_VERSION',
  FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER =
    'FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER',
  RECEIVED_CURRENT_UDO_VERSION = 'RECEIVED_CURRENT_UDO_VERSION',
  COPYING_PROJECT = 'COPYING_PROJECT',
  REQUESTING_EXTRACT_DATA_CSV = 'REQUESTING_EXTRACT_DATA_CSV',
  RECEIVED_EXTRACT_DATA_CSV = 'RECEIVED_EXTRACT_DATA_CSV',
  FAILED_EXTRACT = 'FAILED_EXTRACT',
  REFRESHING_CUBE = 'REFRESHING_CUBE',
  CUBE_REFRESHED = 'CUBE_REFRESHED',
  FETCHING_SCRIPTS = 'FETCHING_SCRIPTS',
  SCRIPTS_RECEIVED = 'SCRIPTS_RECEIVED',
  FETCHING_SCRIPT = 'FETCHING_SCRIPT',
  SCRIPT_RECEIVED = 'SCRIPT_RECEIVED',
  RECEIVED_SCRIPT_COMMANDS = 'RECEIVED_SCRIPT_COMMANDS',
  FETCHING_SCRIPT_COMMANDS = 'FETCHING_SCRIPT_COMMANDS',
  CREATING_SCRIPT = 'CREATING_SCRIPT',
  SCRIPT_CREATED = 'CREATING_SCRIPT',
  CLEAR_SEGMENTS = 'CLEAR_SEGMENTS',
  MAPPERS_RECEIVED = 'MAPPERS_RECEIVED',
  COMMAND_STATUSES_RECEIVED = 'COMMAND_STATUSES_RECEIVED',
  FETCHING_COMMAND_STATUSES = 'FETCHING_COMMAND_STATUSES',
  RECEIVED_PROJECT_VERSIONS = 'RECEIVED_PROJECT_VERSIONS',
  CHANGED_VALUATION_DATE = 'CHANGED_VALUATION_DATE',
  CHECKING_PROJECT_FOR_UNIQUENESS = 'CHECKING_PROJECT_FOR_UNIQUENESS',
  CHECKED_PROJECT_FOR_UNIQUENESS = 'CHECKED_PROJECT_FOR_UNIQUENESS',
  RECEIVED_COPIED_PROJECT_ID = 'RECEIVED_COPIED_PROJECT_ID',
  RECEIVED_VALUATION_DATES = 'RECEIVED_VALUATION_DATES',
  FETCHING_VALUATION_DATES = 'FETCHING_VALUATION_DATES',
  FETCHING_EXTRACT_DEFINITIONS = 'FETCHING_EXTRACT_DEFINITIONS',
  RECEIVED_EXTRACT_DEFINITION_NAMES = 'RECEIVED_EXTRACT_DEFINITION_NAMES',
  FETCHING_REPORTING_DEFINITIONS = 'FETCHING_REPORTING_DEFINITIONS',
  RECEIVED_REPORTING_DEFINITIONS = 'RECEIVED_REPORTING_DEFINITIONS',
  RETRIEVING_EXPORTS_BY_LIST_TYPE = 'RETRIEVING_EXPORTS_BY_LIST_TYPE',
  RETRIEVED_EXPORTS_BY_LIST_TYPE = 'RETRIEVED_EXPORTS_BY_LIST_TYPE',
  RETRIEVING_GENERIC_LISTS_BY_TYPE = 'RETRIEVING_GENERIC_LISTS_BY_TYPE',
  RETRIEVED_GENERIC_LISTS_BY_TYPE = 'RETRIEVED_GENERIC_LISTS_BY_TYPE',
  INITIATING_EXTRACT = 'INITIATING_EXTRACT',
  EXTRACT_INITIATED = 'EXTRACT_INITIATED',
  CLEAR_SCRIPTS = 'CLEAR_SCRIPTS',
  RECEIVED_REPORTING_CSV_FILE = 'RECEIVED_REPORTING_CSV_FILE',
  FETCHING_REPORTING_WIDE_CSV = 'FETCHING_REPORTING_WIDE_CSV',
  FETCHING_REPORTING_NARROW_CSV = 'FETCHING_REPORTING_NARROW_CSV',
  FETCHING_CSV = 'FETCHING_CSV',
  RECEIVED_BACKUPRESTORE_LOG_FILE = 'RECEIVED_BACKUPRESTORE_LOG_FILE',
  GET_PROJECT_REVIEW_STATE_TYPE = 'GET_PROJECT_REVIEW_STATE',
  GET_PROJECT_REVIEW_HISTORY_TYPE = 'GET_PROJECT_REVIEW_HISTORY',
  SEND_PROJECT_REVIEW_COMMMAND_TYPE = 'SEND_PROJECT_REVIEW_COMMMAND',
  CLEAN_PROJECT_REVIEW_TYPE = 'CLEAN_PROJECT_REVIEW_TYPE',
  FETCHING_ANALYSIS_TASKS = 'FETCHING_ANALYSIS_TASKS',
  FETCHING_DOWNLOADS = 'FETCHING_DOWNLOADS',
  RECEIVED_ANALYSIS_TASKS = 'RECEIVED_ANALYSIS_TASKS',
  RECEIVED_DOWNLOADS = 'RECEIVED_DOWNLOADS',
  RECEIVED_DOWNLOAD_TYPES = 'RECEIVED_DOWNLOAD_TYPES',
  RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION = 'RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION',
  DOWNLOAD_CHANGE_TYPE = 'DOWNLOAD_CHANGE_TYPE',
  CLEAR_WORKSPACE_RELATED = 'CLEAR_WORKSPACE_RELATED',
  RECEIVED_UPLOADS = 'RECEIVED_UPLOADS',
  FETCHING_UPLOADS = 'FETCHING_UPLOADS',
  SAVING_UPLOADS = 'SAVING_UPLOADS',
  FETCHING_TABLE_DEFINITIONS = 'FETCHING_TABLE_DEFINITIONS',
  RECEIVED_TABLE_DEFINITIONS = 'RECEIVED_TABLE_DEFINITIONS',
  FETCHING_TABLE_DEFINITION = 'FETCHING_TABLE_DEFINITION',
  RECEIVED_TABLE_DEFINITION = 'RECEIVED_TABLE_DEFINITION',
  RECEIVED_COLUMNS_FOR_FILE = 'RECEIVED_COLUMNS_FOR_FILE',
  FETCHING_REPORTING_TABLES = 'FETCHING_REPORTING_TABLES',
  RECEIVED_REPORTING_TABLES = 'RECEIVED_REPORTING_TABLES',
  FETCHING_REPORTING_TABLE = 'FETCHING_REPORTING_TABLE',
  RECEIVED_REPORTING_TABLE = 'RECEIVED_REPORTING_TABLE',
  FETCHING_AVAILABLE_TABLES = 'FETCHING_AVAILABLE_TABLES',
  RECEIVED_AVAILABLE_TABLES = 'RECEIVED_AVAILABLE_TABLES',
  FETCHING_RIGHT_COLUMNS = 'FETCHING_RIGHT_COLUMNS',
  RECEIVED_RIGHT_COLUMNS = 'RECEIVED_RIGHT_COLUMNS',
  FETCHING_LEFT_COLUMNS = 'FETCHING_LEFT_COLUMNS',
  RECEIVED_LEFT_COLUMNS = 'RECEIVED_LEFT_COLUMNS',
  RECEIVED_LEFT_FILES = 'RECEIVED_LEFT_FILES',
  RECEIVED_RIGHT_FILES = 'RECEIVED_RIGHT_FILES',
  RETRIEVING_MEASURE_MAPPERS = 'RETRIEVING_MEASURE_MAPPERS',
  RECEIVED_MEASURE_MAPPERS = 'RECEIVED_MEASURE_MAPPERS',
  FETCHING_CATEGORIES = 'FETCHING_CATEGORIES',
  RECEIVED_CATEGORIES = 'RECEIVED_CATEGORIES';
