export const   RECEIVED_ANALYTICS_UPLOADS = 'RECEIVED_ADVANALYTICS_UPLOADS',
FETCHING_ANALYTICS_UPLOADS = 'FETCHING_ADVANALYTICS_UPLOADS',
FETCHING_ANALYTICS_RESULTS = 'FETCHING_ANALYTICS_RESULTS',
RECEIVED_ANALYTICS_RESULTS = 'RECEIVED_ANALYTICS_RESULTS',
RECEIVED_ANALYTICS_PARAMS = 'RECEIVED_ANALYTICS_PARAMS',
REQUEST_ANALYTICS_RUN = 'REQUEST_ANALYTICS_RUN',
REQUESTING_ANALYTICS_UPLOAD = 'REQUESTING_ANALYTICS_UPLOAD',
RECEIVED_ANALYTICS_UPLOAD_RESPONSE = 'RECEIVED_ANALYTICS_UPLOAD_RESPONSE',
SAVING_ANALYTICS_UPLOADS = 'SAVING_ADVANALYTICS_UPLOADS',
FETCHING_ANALYTICS_MODELS='FETCHING_ANALYTICS_MODELS',
RECEIVED_ANALYTICS_MODELS='RECEIVED_ANALYTICS_MODELS',
MODEL_SELECTED='MODEL_SELECTED';
