import {
  RECEIVE_SEGMENTS,
  FETCHING_SEGMENTS_FROM_SERVER,
  CLEAR_SEGMENTS,
} from '../../actions/analysis/analysis.actionTypes';

const segmentsReducer = (state = {
  workspaceId: 0,
  projectId: 0,
  isFetchingSegments: false,
  items: [] }, action) => {
  switch (action.type) {
    case FETCHING_SEGMENTS_FROM_SERVER:
      return Object.assign({}, state, { isFetchingSegments: true });
    case RECEIVE_SEGMENTS:
      return Object.assign({}, state, {
        isFetchingSegments: false,
        items: action.segments,
        receivedAt: action.receivedAt,
      });
    case CLEAR_SEGMENTS:
      return Object.assign({}, state, {
        items: [],
      });
    default:
      return state;
  }
};

export default segmentsReducer;
