import React, { Component } from 'react';
import radium from 'radium';
import { Grid, Button } from '@arius';
import Loader from '@app/shared/presentational/loader';
import Modal from './modalEditElasticPool';

class ElasticPoolsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false, 
            selected: {}
        }
        this.getGrid = this.getGrid.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="edit" 
                onClick={() => this.setState({showModal: true, selected: row})}
                toolTip='Edit'/>
        )
    }

    getGrid() {
        const { info } = this.props;
        let data = info && info.databaseServer ? 
        info.databaseServer.elasticPools : [];

        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'edition', headerText: 'Edition'},
            { field: 'dtu', headerText: 'DTU'},
            // { field: 'databaseDtuMax', headerText: 'DTU Max'},
            // { field: 'databaseDtuMin', headerText: 'DTU min'},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 200px)' 
            allowPaging={false}
            detailTemplate={this.getDetailTemplate}/>
    }

    getDetailTemplate(row) {
        const { databases:data } = row;

        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'type', headerText: 'Type'},
            { field: 'edition', headerText: 'Edition'},
            // { field: 'id', headerText: 'id'},
            { field: 'serviceLevel', headerText: 'Service Level'},
            // { headerText: 'Actions', template: (row) => (
            //     <Button 
            //         iconName="fa-minus-circle"
            //         onClick={() => alert('removed from elastic pool not implemented')}
            //         toolTip='Remove from elastic pool'/>
            // )},
        ];

        return <Grid columns={columns} data={data} height='auto'/>
    }

    handleClose() {
        this.setState({showModal: false});
    }

    render() {
        const { isFetching,  jobStatus, lastCompletion, canEdit, levelsWarningMessage } = this.props;
        const { showModal, selected } = this.state;

        return (  
            <div className="list-container-arius" style={{ padding: 10 }}>
                <div className="list-header-arius" style={{ marginBottom: 10 }}>
                    <h4>Shared Elastic Database Pools</h4>
                    {jobStatus && jobStatus.isBusy === true ? <div>Adjusting performance...</div>: ('')}
                    
                    {lastCompletion === false ? <div style={{color: 'red'}}>Adjustment Failed</div>:
                     lastCompletion === true ? <div style={{color: 'green'}}>Adjustment Completed Successfully</div>:
                     ('')
                    }
                </div>
                <span style={isFetching ? {}: {display: 'none'}}>
                    <Loader loadingItem='Performance Info' />
                </span>
                <span style={isFetching ? {display: 'none'} : {}}>
                    {this.getGrid()}
                </span>
                <Modal
                    show={showModal}
                    data={selected}
                    canEdit={canEdit}
                    levelsWarningMessage={levelsWarningMessage}
                    closeCallback={this.handleClose}
                >
                </Modal>
            </div>
        );
    }
}

export default radium(ElasticPoolsView);
