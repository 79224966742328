import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  RECEIVE_PROJECTS,
  RECEIVED_PROJECT,
  FETCHING_PROJECTS_FROM_SERVER,
  SELECTING_PROJECT,
  INITIATING_BATCH_PROCESS,
  RECEIVED_LONG_RUNNING_OPERATIONS_STATUS,
  FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER,
  RECEIVED_CURRENT_UDO_VERSION,
  COPYING_PROJECT,
  RECEIVED_PROJECT_VERSIONS,
  CHANGED_VALUATION_DATE,
  CHECKING_PROJECT_FOR_UNIQUENESS,
  CHECKED_PROJECT_FOR_UNIQUENESS,
  RECEIVED_COPIED_PROJECT_ID,
  RECEIVED_VALUATION_DATES,
  FETCHING_VALUATION_DATES,
  RETRIEVING_EXPORTS_BY_LIST_TYPE,
  RETRIEVED_EXPORTS_BY_LIST_TYPE,
  RETRIEVING_GENERIC_LISTS_BY_TYPE,
  RETRIEVED_GENERIC_LISTS_BY_TYPE,
  RETRIEVING_MEASURE_MAPPERS,
  RECEIVED_MEASURE_MAPPERS,
  GET_PROJECT_REVIEW_STATE_TYPE,
  GET_PROJECT_REVIEW_HISTORY_TYPE,
  SEND_PROJECT_REVIEW_COMMMAND_TYPE,
  CLEAN_PROJECT_REVIEW_TYPE,
  RECEIVED_REPORTING_CSV_FILE,
  FETCHING_CSV,
  CLEAR_WORKSPACE_RELATED

} from './analysis.actionTypes';
import * as projectServiceApi from '../../serviceApi/projects.serviceApi';
import { notifyError, alertMessage } from '../../utilities/notifier';
import { mapValuationDates } from '../../utilities/projectDataHelper';
import { alphabetize } from '../../utilities/helpers';

function fetchingFromServer() {
  return {
    type: FETCHING_PROJECTS_FROM_SERVER,
  };
}

function checkingProjectForUniqueness() {
  return {
    type: CHECKING_PROJECT_FOR_UNIQUENESS,
  };
}

function checkedProjectForUniqueness() {
  return {
    type: CHECKED_PROJECT_FOR_UNIQUENESS,
  };
}

function fetchingLongRunningOperationsFromServer() {
  return {
    type: FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER,
  };
}

function receivedProject(json) {
  return {
    type: RECEIVED_PROJECT,
    current: {
      id: json.projectId,
      name: json.projectName,
      description: json.projectDesc,
      projectSettings: json.projectSettings,
    },
    receivedAt: Date.now(),
  };
}

function selectProject(project) {
  const current = !project
    ? null
    : {
      id: project.projectId,
      name: project.projectName,
      description: project.projectDesc,
      projectSettings: project.projectSettings,
    };
  return {
    type: SELECTING_PROJECT,
    current,
    receivedAt: Date.now(),
  };
}

function receiveProjects(json) {
  return {
    type: RECEIVE_PROJECTS,
    projects: json,
    receivedAt: Date.now(),
  };
}

function receivedValuationDates(dates) {
  return {
    type: RECEIVED_VALUATION_DATES,
    valuationDates: Array.isArray(dates) ? mapValuationDates(dates) : [],
    receivedAt: Date.now(),
  };
}

function clearValuationDates() {
  return {
    type: RECEIVED_VALUATION_DATES,
    valuationDates: [],
    receivedAt: Date.now(),
  };
}

function clearValuationDate() {
  return {
    type: CHANGED_VALUATION_DATE,
    valuationDate: '',
  };
}

function changeValuationDate(valuationDate, shape='all') {
  return {
    type: CHANGED_VALUATION_DATE,
    valuationDate,
    shape
  };
}

function recievedProjectVersions(projectVersions) {
  return {
    type: RECEIVED_PROJECT_VERSIONS,
    projectVersions,
    receivedAt: Date.now(),
  };
}

function clearProjects() {
  return {
    type: RECEIVE_PROJECTS,
    projects: [],
    receivedAt: Date.now(),
  };
}

function fetchProject(userKey, workspaceId, projectId) {
    return dispatch => {
        dispatch(fetchingFromServer());
        return projectServiceApi
        .retrieveProject(userKey, workspaceId, projectId)
        .then(json => dispatch(receivedProject(json)));
    };
}

function fetchProjects(userKey, workspaceId, optionalDispatch) {
    return dispatch => {
        dispatch(fetchingFromServer());
        return projectServiceApi
            .retrieveProjects(userKey, workspaceId)
            .then((json) => {
                if (optionalDispatch) {
                dispatch(optionalDispatch());
                }
                dispatch(receiveProjects(json));
            });
    };
}

function shouldFetchProjects(state) {
  const projects = state.projects;

  if (!projects) {
    return true;
  }

  if (projects.isFetching) {
    return false;
  }

  return true;
}

function checkProjectForUniqueness(userKey, databaseId, projectId, body) {
  return (dispatch) => {
    projectServiceApi
      .checkProjectForUniqueness(userKey, databaseId, projectId, body)
      .then(() => {
        dispatch(fetchProjects(userKey, databaseId, checkedProjectForUniqueness));
      });
  };
}

function tryFetchProjects(userKey, workspaceId) {
  return (dispatch, getState) => {
    if (shouldFetchProjects(getState())) {
      return dispatch(fetchProjects(userKey, workspaceId));
    }
    return null;
  };
}

function getProjectVersions(userKey, databaseId, projectId) {
  return (dispatch) => {
    projectServiceApi
      .getProjectVersions(userKey, databaseId, projectId)
      .then((res) => dispatch(recievedProjectVersions(res)));
  };
}

function exportSignoffReport(userKey, databaseId, valuationDate) {
  return (dispatch) => {
    dispatch({type: FETCHING_CSV,});
    valuationDate = valuationDate === 'all' ? valuationDate : moment(valuationDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    projectServiceApi
      .exportSignoffReport(userKey, databaseId, valuationDate)
      .then((csv) => {
            const csvFile = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvFile, 'SignoffReport.csv');
            dispatch({type: RECEIVED_REPORTING_CSV_FILE,});
      });
  };
}


function updateProjectVersion(userKey, databaseId, projectId, req) {
  return () => {
    projectServiceApi
      .updateProjectVersion(userKey, databaseId, projectId, req)
      .then(() => {
        // dispatch(recievedProjectVersions(res));
      });
  };
}

function checkOutProject(userKey, workspaceId, projectId) {
  return dispatch =>
    projectServiceApi
      .checkOutProject(userKey, workspaceId, projectId)
      .then((res) => {
        if ((res && res.status === 200) || res.status === 204) {
          window.location.href = `arius:/integration/projects/${workspaceId}/${projectId}/1`;
        } 
        dispatch(fetchProjects(userKey, workspaceId));
      });
}

function undoProjectCheckout(userKey, workspaceId, projectId) {
  return dispatch =>
    projectServiceApi
      .undoProjectCheckout(userKey, workspaceId, projectId)
      .then(() => {
        dispatch(fetchProjects(userKey, workspaceId));
      });
}

function getProjectsByValuationDate(userKey, databaseId, valuationDate) {
    return dispatch => {
        dispatch(fetchingFromServer());
        projectServiceApi
            .getProjectsByValuationDate(userKey, databaseId, valuationDate)
            .then((projects) => {
                dispatch(receiveProjects(projects));
            });
    };
}

function deleteProject(userKey, workspaceId, projectId) {
  return (dispatch, getState) =>
    projectServiceApi
      .deleteProject(userKey, workspaceId, projectId)
      .then(() => {
        const state = getState();
        if (state
          && state.analysis
          && state.analysis.projects
          && state.analysis.projects.valuationDate
          && state.analysis.projects.valuationDate !== 'all') {
          dispatch(getProjectsByValuationDate(
            userKey,
            workspaceId,
            moment(state.analysis.projects.valuationDate, 'MM/DD/YYYY').format('YYYY-MM-DD'))
          );
        } else {
          dispatch(fetchProjects(userKey, workspaceId));
        }
      });
}

function initiatingBatchProcess(projects) {
  return {
    type: INITIATING_BATCH_PROCESS,
    batchProjects: projects,
  };
}

function initiateBatchProcess(userKey, projects, operation) {
  return dispatch => {
    dispatch(initiatingBatchProcess(projects));
    return projectServiceApi.requestBatchProcess(userKey, projects, operation);
  };
}

function receivedLongRunningOperationsStatus(json) {
  return {
    type: RECEIVED_LONG_RUNNING_OPERATIONS_STATUS,
    longRunningOperationsStatus: json,
  };
}

function fetchLongRunningOperationsStatus(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingLongRunningOperationsFromServer());

    return projectServiceApi
      .retrieveLongRunningOperationsStatus(userKey, databaseId)
      .then(data => dispatch(receivedLongRunningOperationsStatus(data)));
  };
}

function getCheckoutStatus(userKey, workspaceId, projectId, body) {
  return dispatch => {
    dispatch(checkingProjectForUniqueness());
    // dispatch(fetchingLongRunningOperationsFromServer());

    return projectServiceApi
      .getCheckoutStatus(userKey, workspaceId, projectId)
      .then((data) => {
        if (data === true) {
          dispatch(checkedProjectForUniqueness());
          notifyError('Unable to update name on a project that is currently checked out');
          dispatch(fetchProjects(userKey, workspaceId));
        } else {
          // call next function only if data is not null
          // null happens when previous call was not authorized
          if (data) {
            dispatch(checkProjectForUniqueness(userKey, workspaceId, projectId, body));
          } else {
            //console.log('getCheckoutStatus not authorized');
            dispatch(checkedProjectForUniqueness());
          }

        }
      });
  };
}

function getValuationDates(userKey, databaseId) {
  return dispatch =>
  {
    dispatch({type: FETCHING_VALUATION_DATES})
    projectServiceApi
      .getValuationDates(userKey, databaseId)
      .then((dates) => {
        dispatch(receivedValuationDates(dates));
      });    
  }
}

function emptyLongRunningOperationsStatus() {
  return {
    type: RECEIVED_LONG_RUNNING_OPERATIONS_STATUS,
    longRunningOperationsStatus: [],
  };
}

function receivedCurrentUdoVersion(data) {
  return {
    type: RECEIVED_CURRENT_UDO_VERSION,
    currentUdoVersionNbr: data,
  };
}

function clearCurrentUdoVersion() {
  return {
    type: RECEIVED_CURRENT_UDO_VERSION,
    currentUdoVersionNbr: 0,
  };
}

function clearWorkspaceRelatedOnWorkspaceChange() {
  return {
    type: CLEAR_WORKSPACE_RELATED
  }
}

function fetchCurrentUdoVersion(userKey, databaseId) {
  return dispatch =>
    projectServiceApi
      .retrieveCurrentUdoVersion(userKey, databaseId)
      .then(data => dispatch(receivedCurrentUdoVersion(data)));
}

function copyingProject(project) {
  return {
    type: COPYING_PROJECT,
    copyingProject: project,
  };
}

function copiedProject() {
  return {
    type: COPYING_PROJECT,
    copyingProject: {},
  };
}

function receivedCopiedProjectId(id) {
  return {
    type: RECEIVED_COPIED_PROJECT_ID,
    copiedProjectId: id,
  };
}

function copyProject(userKey, project, body) {
  return (dispatch, getState) => {
    dispatch(copyingProject(project));
    return projectServiceApi.copyProject(
      userKey,
      project.workspaceId,
      project.projectId,
      body
    ).then((res) => {
      dispatch(copiedProject());
      const state = getState();
      if (state
        && state.analysis
        && state.analysis.projects
        && state.analysis.projects.valuationDate
        && state.analysis.projects.valuationDate !== 'all') {
        dispatch(getProjectsByValuationDate(userKey, project.workspaceId, moment(project.valuationDate).format('YYYY-MM-DD')));
      } else {
        dispatch(fetchProjects(userKey, project.workspaceId));
      }
      dispatch(receivedCopiedProjectId(res));
    });
  };
}

function retrievingExportsByListType(listType) {
    if (listType === 'ExtractTable'){
        return {type: RETRIEVING_EXPORTS_BY_LIST_TYPE}
    } else {
        return {type: RETRIEVING_GENERIC_LISTS_BY_TYPE}
    }
}

function retrievedExportsByListType(list, listType) {
    if (listType === 'ExtractTable'){
        return {
            type: RETRIEVED_EXPORTS_BY_LIST_TYPE, 
            exportList: list
        }
    } else {
        return {
            type: RETRIEVED_GENERIC_LISTS_BY_TYPE,
            listType,
            list
        }
    }
}

function retrieveExportsByListType({ userKey, databaseId, listType }) {
  return (dispatch) => {
    dispatch(retrievingExportsByListType(listType));
    projectServiceApi
      .retrieveExportsByListType({ userKey, databaseId, listType })
      .then((res) => {
        dispatch(retrievedExportsByListType(res, listType));
      });
  };
}

function retrieveMeasureMappers(userKey, databaseId) {
    return (dispatch) => {
      dispatch({type: RETRIEVING_MEASURE_MAPPERS});
      projectServiceApi
        .retrieveMeasureMappers(userKey, databaseId)
        .then((res) => {
            dispatch({type: RECEIVED_MEASURE_MAPPERS, data: res});
        });
    };
  }

function deleteExportListItem({ userKey, databaseId, exportListId, listType }) {
  return (dispatch) => {
    projectServiceApi
      .deleteExportListItem({ userKey, databaseId, exportListId })
      .then((res) => {
        if (res && res.status === 409) {
          let items = alphabetize(res.data || [], 'extractDefinitionName', true);
          items = items.map(d => 
            `<span>${d.extractDefinitionName}</span>`
          );
          const displayString = items.join('<br>');
          alertMessage('Cannot delete this export list while in use by the following extract definitions:', displayString);
        }
        if (res && !res.error) dispatch(retrieveExportsByListType({ userKey, databaseId, listType }));
      });
  };
}

function getProjectReviewState(userKey, dbId, projectId, completeHandler ) {
  console.log(`actions getProjectReviewState. ${dbId}, ${projectId}`);
  // const res = projectServiceApi.getProjectReviewState(userKey, dbId, projectId)
  // console.log(res);
  // return { type: GET_PROJECT_REVIEW_STATE_TYPE, projectReviewState: res};
  return (dispatch) => {
      projectServiceApi.getProjectReviewState(userKey, dbId, projectId)
      .then((res) => { 
          dispatch({ type: GET_PROJECT_REVIEW_STATE_TYPE, projectReviewState: res});
          completeHandler();
      });
  };
}

function getProjectReviewHistory(userKey, dbId, projectId, completeHandler) {
  console.log(`actions getProjectReviewHistory. ${dbId}, ${projectId}`);
  return (dispatch) => {
    projectServiceApi
      .getProjectReviewHistory(userKey, dbId, projectId)
      .then((res) => {
        dispatch({ type: GET_PROJECT_REVIEW_HISTORY_TYPE, projectReviewHistory: res});
        completeHandler();
      });
  };
}

function sendProjectReviewAction(userKey, dbId, projectId, action, comments, currentLevel, completeHandler) {
  console.log(`actions sendProjectReviewAction. ${dbId}, ${projectId}, ${action}, ${comments}, ${currentLevel}`);
  return (dispatch) => {
    projectServiceApi
      .sendProjectReviewAction(userKey, dbId, projectId, action, comments, currentLevel)
      .then((res) => {
        dispatch({ type: SEND_PROJECT_REVIEW_COMMMAND_TYPE });
        dispatch(getProjectReviewState(userKey, dbId, projectId, completeHandler));
        dispatch(getProjectReviewHistory(userKey, dbId, projectId, completeHandler));
      });
  };
}

function cleanProjectReview() {
  console.log(`actions cleanProjectReview`);
  return { type: CLEAN_PROJECT_REVIEW_TYPE };
}

export {
  checkProjectForUniqueness,
  clearProjects,
  selectProject,
  checkOutProject,
  undoProjectCheckout,
  fetchProject,
  fetchProjects,
  tryFetchProjects,
  receiveProjects,
  deleteProject,
  initiateBatchProcess,
  fetchLongRunningOperationsStatus,
  emptyLongRunningOperationsStatus,
  fetchCurrentUdoVersion,
  copyProject,
  clearCurrentUdoVersion,
  getProjectVersions,
  updateProjectVersion,
  changeValuationDate,
  getCheckoutStatus,
  getProjectsByValuationDate,
  getValuationDates,
  clearValuationDates,
  clearValuationDate,
  retrieveExportsByListType,
  retrievedExportsByListType,
  deleteExportListItem,
  getProjectReviewState,
  getProjectReviewHistory,
  sendProjectReviewAction,
  cleanProjectReview, 
  exportSignoffReport,
  clearWorkspaceRelatedOnWorkspaceChange,
  retrieveMeasureMappers
};
