import {
  TOGGLED_SETTINGS_MENU,
  FETCHING_API_KEY,
  RECEIVED_API_KEY,
} from '../actions/actionTypes';

const settingsMenu = (state = {
  settingsMenuOpen: false,
  fetchingApiKey: false,
  apiKey: null,
}, action) => {
  switch (action.type) {
    case TOGGLED_SETTINGS_MENU:
      return Object.assign({}, state, { 
        settingsMenuOpen: action.settingsMenuOpen,
      });
    case RECEIVED_API_KEY:
      return Object.assign({}, state, { 
        apiKey: action.apiKey,
        fetchingApiKey: false,
      });
    case FETCHING_API_KEY:
      return Object.assign({}, state, { 
        fetchingApiKey: true,
      });
    default:
      return state;
  }
};

export default settingsMenu;
