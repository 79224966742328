import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import moment from 'moment';
import { Collapse } from 'react-bootstrap';
import { Button } from '@arius'; 
import AuditLogTable from './auditLogs/auditLogTable';
import { resetScrollTop } from '../../../utilities/helpers';
import { exportAuditLogs } from '../../../actions/portalManagement/audit.actions';
import { saveAs } from 'file-saver';
import SearchFilter from '@app/utils/auditLogs/searchFilter';

const style = {
    disabled: {
        cursor: 'not-allowed',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
        opacity: '.65',
    }
};

class AuditLogsMain extends Component {
    static propTypes = {
        queryLog: PropTypes.func,
        auditLogContent: PropTypes.array,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        auditLogFetches: PropTypes.number,
        auditLogCSVFile: PropTypes.object,
        auditLogCSVFileFetches: PropTypes.number,
        activePage: PropTypes.number,
    };
    constructor(props) {
        super(props);
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate()-10);
        this.state = {
            dateFrom,
            dateTo: new Date(),
            searchFilterOpen: true,
            queryValue: '',
            activePage: 0,
            firstSearchPerformed: false,
            evaluating: false,
            formattedMisc: null,
            contentView: 'logs', 
            resultCount: 50,
            oldResultCount: 50,
            shouldUseOldResultCount: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleSearchFilter = this.toggleSearchFilter.bind(this);
        this.submitQuery = this.submitQuery.bind(this);
        this.handlePageTurn = this.handlePageTurn.bind(this);
        this.handleResultCountChange = this.handleResultCountChange.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
    }

    componentWillMount() {
        this.submitQuery(true);
    }

    componentWillReceiveProps(nextProps) {
        const { auditLogCSVFile } = this.props;
        if (auditLogCSVFile !== nextProps.auditLogCSVFile) {
            saveAs(nextProps.auditLogCSVFile, 'AuditLog.csv');
        }
    }

    componentDidUpdate(prevProps) {
        const { auditLogContent } = this.props;
        const { firstSearchPerformed } = this.state;

        if (!firstSearchPerformed && auditLogContent !== prevProps.auditLogContent) {
            this.setState({ firstSearchPerformed: true });
        }
    }

    toggleSearchFilter() {
        const { searchFilterOpen } = this.state;
        this.setState({ searchFilterOpen: !searchFilterOpen });
    }

    handlePageTurn(pageDirection) {
        let { activePage } = this.state;
        const { resultCount, oldResultCount, shouldUseOldResultCount } = this.state,
        count = shouldUseOldResultCount ? oldResultCount : resultCount;

        if (pageDirection === 'next') { 
            activePage += count; 
            this.setState({ activePage, resultCount: count, shouldUseOldResultCount: false }, () => this.submitQuery(false)); 
        } else if (activePage === 0) {
            return;
        } else { 
            activePage -= count; 
            this.setState({ activePage, resultCount: count, shouldUseOldResultCount: false }, () => this.submitQuery(false));
        }
        resetScrollTop('audit-log-table');
    }

    handleResultCountChange(e) {
        let value = e.target.value;
        value = Number(value.replace(/[^0-9.]/g, ''));

        this.setState({
            resultCount: value,
            shouldUseOldResultCount: true,
        });
    }

    get dateStart() {
        const { dateFrom } = this.state;
        return dateFrom ? moment(dateFrom, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
    }

    get dateEnd() {
        const { dateTo } = this.state;
        return dateTo ? moment(dateTo, 'MM/DD/YYYY').format('YYYY-MM-DD') : null; 
    }

    getQuery(reload) {
        const { queryValue, resultCount, } = this.state;
        let { activePage } = this.state;
        if (reload) {
            activePage = 0;
        }
        return {
            ds: this.dateStart,
            de: this.dateEnd,
            q: queryValue,
            s: activePage,
            c: resultCount,
        };
    }

    submitQuery(reload) {
        const { resultCount } = this.state;
        const { fetchAuditLogs } = this.props;
        let { activePage } = this.state;
        if (reload) {
            activePage = 0;
        }
        fetchAuditLogs(this.getQuery(reload));
        this.setState({ activePage, shouldUseOldResultCount: false, oldResultCount: resultCount });
    }

    exportToCSV() {
        const { userKey, dispatch } = this.props;
        dispatch(exportAuditLogs({ userKey, query: this.getQuery() }));
    }

    handleChange(e) {
        if (e.target.id === 'dateFrom') {
            if (e.target.value === this.state.dateFrom) { return; }
            this.setState({ dateFrom: e.target.value }, () => this.submitQuery(true));
        }
        else if (e.target.id === 'dateTo') {
            if (e.target.value === this.state.dateTo) { return; }
            this.setState({ dateTo: e.target.value }, () => this.submitQuery(true));
        }
        else if (e.target.id === 'queryValue') {
            const queryValue = e.target.value;
            this.setState({ queryValue });
        }
        else if (e.target.id === 'resultCount') {
            const resultCount = parseInt(e.target.value);
            this.setState({ resultCount: isNaN(resultCount) ? 0 : resultCount });
        }
    }

    render() {
        const { 
            dateFrom,
            dateTo,
            searchFilterOpen,
            queryValue,
            firstSearchPerformed,
            activePage,
            resultCount,
        } = this.state;
        const { auditLogContent, auditLogFetches, auditLogCSVFileFetches } = this.props;
        const cogStyle = { marginTop: '6px', fontSize: '1.5em', visibility: 'hidden', pointerEvents: 'none' },

        pagePriorDisabled = activePage === 0,
        pageNextDisabled = !auditLogContent ||
            (auditLogContent && !auditLogContent.length) ||
            (auditLogContent && auditLogContent.length > 0 && auditLogContent.length < resultCount);

        if (auditLogCSVFileFetches > 0) { delete cogStyle.visibility; delete cogStyle.pointerEvents; }

        let navHeight, tableHeight, remainingHeight;
        if (document.getElementById('mainNavbarContainer')) {
            navHeight = document.getElementById('mainNavbarContainer').getBoundingClientRect().height;
            remainingHeight = window.innerHeight - (navHeight);
            tableHeight = `${remainingHeight - 143}px`;
        }
        let logContent = (
            <AuditLogTable
                searchFilterOpen={searchFilterOpen}
                tableHeight={tableHeight}
                remainingHeight={remainingHeight}
                auditLogContent={auditLogContent} 
                firstSearchPerformed={firstSearchPerformed}
                activePage={activePage}
                params={this.getQuery()}
                auditLogFetches={auditLogFetches} />
        );

        return (  
            <div className="list-container-arius" style={{ padding: 0 }}>
                <div className="list-header-arius">
                    <span style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Button
                            iconName="fa-search"
                            key="audit-log-filter-icon"
                            onClick={(e) => this.toggleSearchFilter(e)}
                            toolTip={searchFilterOpen ? 'Hide Search Filter' : 'Show search filter'}/>
                        &nbsp;
                        <h4>Audit Log Search</h4>
                    </span>
                    <span style={{ display: 'flex', justifyContent: 'flex-start'}}>
                        <Button
                            variant="arius"
                            disabled={pagePriorDisabled}
                            style={pagePriorDisabled ? style.disabled : {}}
                            onClick={!pagePriorDisabled ? () => this.handlePageTurn('previous') : () => {} } 
                        >
                            Prev
                        </Button>
                        <Button
                            variant="arius"
                            disabled={pageNextDisabled}
                            style={pageNextDisabled ? {...style.disabled, marginLeft: 5} : {marginLeft: 5}}
                            onClick={!pageNextDisabled ? () => this.handlePageTurn('next') : () => {} } 
                        >
                            Next
                        </Button>
                        <Button
                            variant="arius"
                            style={{marginLeft: 10}}
                            disabled={auditLogContent && !auditLogContent.length && activePage === 0}
                            onClick={this.exportToCSV} 
                        >
                            Export to CSV
                        </Button>
                    </span>
                </div>
            
                <Collapse in={searchFilterOpen}>
                    <SearchFilter
                        queryValue={queryValue}
                        resultCount={resultCount}
                        auditLogContent
                        activePage
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        handleChange={this.handleChange}
                        exportToCSV={this.exportToCSV}
                        submitQuery={this.submitQuery}
                    />   
                </Collapse>
                {logContent}
            </div>
        );
    }
}

export default radium(AuditLogsMain);
