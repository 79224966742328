let configureStore = null;

if (process.env.NODE_ENV === 'production') {
  configureStore = require('./configureStore.prod').default;
} else {
  configureStore = require('./configureStore.dev').default;
}

const state = { };
const store = configureStore(state);

export {
  store,
};
