import {
  RECEIVED_USER_PERMISSIONS,
  FETCHING_PERMISSIONS_FROM_SERVER,
} from './actionTypes';
import * as roleServiceApi from '../serviceApi/roles.serviceApi';

function fetchingPermissionsFromServer() {
  return {
    type: FETCHING_PERMISSIONS_FROM_SERVER,
  };
}

function receivedPermissions(json) {
  return {
    type: RECEIVED_USER_PERMISSIONS,
    permissions: json,
    receivedAt: Date.now(),
  };
}

function fetchCurrentUserPermissions() {
  return dispatch => {
    dispatch(fetchingPermissionsFromServer());
    // const permissions = ['test'];
    // return dispatch(receivedPermissions(permissions));
    return roleServiceApi.retrieveCurrentUserPermissions().then(json => {
      dispatch(receivedPermissions(json));
    });
  };
}

export { fetchCurrentUserPermissions };
