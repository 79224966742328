import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Modal, ProgressBar, Button } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import { notifyError } from '../../../utilities/notifier';
import { fetchJobStatus, clearJobStatus } from '../../../actions/jobStatus.actions';
import { TAG_TOD_CREATE_DB } from '@app/utilities/constants';

class CreateTodDatabaseModal extends Component {
  static propTypes = {
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    clearNotificationHandler: PropTypes.func.isRequired,
    serverNotification: PropTypes.object.isRequired,
    clearErrorMessageHandler: PropTypes.func,
    errorMessage: PropTypes.string,
    getDatabases: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    showModal: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
    };
    this.clearNotificationsAndClose = this.clearNotificationsAndClose.bind(this);
    this.close = this.close.bind(this);
    this.returnToDatabaseListing = this.returnToDatabaseListing.bind(this);
  }

  componentDidMount() {
    //const { serverNotification } = this.props;
    // if (serverNotification && (serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.TOD_DATABASE_CREATE ||
    //   serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.CREATING_DB) &&
    //   serverNotification.message !== SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE) {
    //   this.setState({ isCreating: true });
    // }
  }

  componentDidUpdate(prevProps) {
    const { errorMessage, clearErrorMessageHandler, jobStatus } = this.props;

    if (this.state.showModal && errorMessage) {
      notifyError(errorMessage);
      clearErrorMessageHandler();
      this.close();
     } 

    if (this.state.isCreating && !this.state.timerId) {
      const timerId = this.startInterval();
      this.setState({ timerId });
    }

    // if nothing is in progress stop timer
    if (!this.state.isCreating && this.state.timerId) {
      console.log('stop timer');
      clearInterval(this.state.timerId);
      this.setState({ timerId: null });
    }

    if (jobStatus && jobStatus.jobStatuses) {
      const job = jobStatus.jobStatuses[TAG_TOD_CREATE_DB];
      if (job) {
        if (this.state.timerId) {
          if (job.status === 'Completed' || job.status === 'Error') {
            this.setState({ isCreating: false });
          }
        } else {
          if (job.status !== 'Completed' && job.status !== 'Error') {
            this.setState({ isCreating: true });
          }
        }
      }

    }

  }

  startInterval = () => {
    console.log('start interval');
    const timerId = setInterval(() => {
        this.checkJobStatus(TAG_TOD_CREATE_DB);
    }, 10000);

    return timerId;
  }

  checkJobStatus = (tag) => {
    const {userKey, dispatch, jobStatus} = this.props;
    console.log(`checkJobStatus: ${tag}`);

    const job = jobStatus.jobStatuses[tag];
    console.log(job);

    const jobId = job ? job.jobId : '';

    dispatch(fetchJobStatus(userKey, jobId, tag));
  }

  close() {
    const { dispatch } = this.props;
    this.props.clearNotificationHandler();
    this.props.closeHandler();

    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }

    // cleare job if it is completed
    if (!this.state.isCreating) {
      dispatch(clearJobStatus(TAG_TOD_CREATE_DB));
    }

    this.setState({timerId: null, isCreating: false});
  }

  clearNotificationsAndClose() {
    this.props.clearNotificationHandler();
    this.props.closeHandler();
  }

  returnToDatabaseListing() {
    const { getDatabases, userKey, browserHistory } = this.props;
    this.close();
    getDatabases(userKey);
    browserHistory.push('/tod/databases');
  }

  open() {
    this.setState({ showModal: true });
  }

  getMessageIfTag = (tag) => {
    const { jobStatus } = this.props;

    if (!jobStatus || !jobStatus.jobStatuses || !tag) {
      return '';
    }

    const job = jobStatus.jobStatuses[tag];

    if (!job) {
      return '';
    }

    const msg = `${job.status}: ${job.errorMessage ? job.errorMessage : job.message}`;

    return msg;
  }

  render() {
    const { isCreating } = this.state;

    const message = this.getMessageIfTag(TAG_TOD_CREATE_DB);

    let bodyMarkup = (
      <div>
        Please wait a moment while we prepare to create your database.
      </div>
    );

    let progress='';
    if (isCreating) {
      progress = (
        <div>
          <ProgressBar animated now={100} />
        </div>
      );
    }

    if (message.indexOf('Completed') > -1 ) {
      progress = (
        <div>
          <div><i className="fa fa-check" style={{ display: 'flex', justifyContent: 'center', fontSize: 50, color: 'green' }}></i></div>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.returnToDatabaseListing}>OK</Button>
          </Modal.Footer>
        </div>
      );
    } else if (message.indexOf('Error') > -1 ) {
      progress = (
        <div>
          <Modal.Footer>
              <Button variant="outline-secondary" onClick={this.close}>OK</Button>
          </Modal.Footer>
        </div>
      );
    }

    bodyMarkup = (
      <div className="container">
        <div className="row" style={{ textAlign: 'center' }}>
          <div className="col-sm-12">
              {message}
          </div>
          <div className="col-sm-12">
              {progress}
          </div>
        </div>
      </div>
    );

    return (
      <Modal id={this.props.modalId} show={this.props.showModal} enforceFocus>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
          <Modal.Title><small>Creating New Database</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyMarkup}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
	dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTodDatabaseModal);
