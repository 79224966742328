import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import { databaseUpdate } from '../../../actions/analysis/workspace.actions';

class DbUpdateModal extends Component {
  static propTypes = {
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    showModal: PropTypes.bool,
    dispatch: PropTypes.func,
    dbToUpdateId: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
    };
    this.updateDb = this.updateDb.bind(this);
  }

  updateDb() {
    const input = document.getElementById('db-update-input-file'),
      { dispatch, dbToUpdateId, userKey, closeHandler } = this.props;
    let file;
    if (input) file = input.files[0];
    if (file) {
      const data = new FormData();
      data.append('file', file);
      dispatch(databaseUpdate(userKey, dbToUpdateId, data));
      closeHandler();
    }
  }

  render() {
    const { 
      modalId,
      showModal,
    } = this.props,
      bodyMarkup = (
      <div>
        <input type="file" id="db-update-input-file" />
      </div>
    );

    return (
      <Modal id={modalId} show={showModal} enforceFocus>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
          <Modal.Title>Select File for Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyMarkup}
        </Modal.Body>
        <Button onClick={() => this.updateDb()}>Use selected file</Button>
      </Modal>
    );
  }
}

export default DbUpdateModal;
