import React from 'react';
import { connect } from '@app/utilities/routing';
import { Route, Redirect } from 'react-router-dom';
import { requireAuth } from '@app/utilities/adalAdapter';

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.isAuthenticated = true;
      }

    render() {
        const { component: Component, user, ...rest } = this.props;

        if (!user || (typeof user.isAuthorized !== 'boolean' && !user.isFetching)) {
            requireAuth().then(rc => {
                if (rc && user && user.isAuthorized) {
                    this.isAuthenticated = user.isAuthorized;
                }
            });
        }

        // PE: There is really no reason to redirect to /login from the app. The login page does not actually perform login.
        // PE: It is intended to be the landing page after login has been completed (e.g. through Azure AD for instance).
        // PE: The requireAuth call above should initiate login via redirect if the token is not available or expired.
        // PE: If we can't authenticate, then go directly to jail!  Do not pass go.

        return (
            <Route exact
                {...rest}
                render={props => this.isAuthenticated ? (<Component {...props} />) : (
                    <Redirect
                        to={{
                        pathname: '/unauthorized',
                        }}
                    />
                    )
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    errorMessage: state.application.errorMessage
});


const mapDispatchToProps = dispatch => ({
    dispatch,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);