import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { connect } from '@app/utilities/routing';
import {
  fetchScripts,
  getCommandStatuses,
  createScript,
  updateScript,
  fetchScriptCommands,
  deleteScript,
  fetchSelectedScript,
  cancelBatchProcess,
  fetchMappers,
} from '@app/actions/analysis/automation.actions';
import { fetchWorkspaces, fetchWorkspace, fetchCurrentAEDbScriptVersion } from '@app/actions/analysis/workspace.actions';
import {
  fetchLongRunningOperationsStatus,
  emptyLongRunningOperationsStatus,
} from '@app/actions/analysis/project.actions';
import {
  fetchCurrentUserPermissions,
} from '@app/actions/roles.actions';
import Automation from '@app/components/analysis/presentational/automation';

class AutomationTab extends Component {
    static propTypes = {
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        application: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        userKey: PropTypes.string.isRequired,
        scripts: PropTypes.array,
        fetchScriptsHandler: PropTypes.func,
        fetchMappersHandler: PropTypes.func,
        isFetching: PropTypes.bool,
        scriptCommands: PropTypes.array,
        selectedScript: PropTypes.object,
        createScriptHandler: PropTypes.func,
        fetchScriptCommandsHandler: PropTypes.func,
        deleteScriptHandler: PropTypes.func,
        fetchSelectedScriptHandler: PropTypes.func,
        userId: PropTypes.string,
        params: PropTypes.object,
        editScriptHandler: PropTypes.func,
        longRunningOperationsStatus: PropTypes.array,
        mappers: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        projects: PropTypes.array,
        isFetchingWorkSpaces: PropTypes.bool,
        isFetchingCommandStatuses: PropTypes.bool,
        isFetchingProjects: PropTypes.bool,
        cancelBatch: PropTypes.func,
        getCurrentAEDbScriptVersion: PropTypes.func,
        getCurrentUserPermissions: PropTypes.func,
        userPermissions: PropTypes.array,
        fetchCommandStatuses: PropTypes.func,
        commandStatuses: PropTypes.array,
        collectionSet: PropTypes.array,
    };

    get baseUrl() {
        const { match } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        return `/arius/automation${databaseId ? `/${databaseId}` : ''}`;
    }

    get newUrl() {
        // get the url with databaseId injected (if needed) and child route preserved
        const { location } = this.props;
        return location.pathname.replace(this.baseUrl, `/arius/automation/`);
    }
    
    get newUrlFromCurrentDb() {
        const { currentWorkspace } = this.props;
        return `/arius/automation/${currentWorkspace.id}/status`;  // default to 'status' in LHM
    }

    componentDidMount() {
        const {
            dispatch,
            userKey,
            getCurrentAEDbScriptVersion,
            currentAEDbScriptVersion,
            currentWorkspace, 
            history,
            match
        } = this.props;
        if (!currentAEDbScriptVersion) {
            getCurrentAEDbScriptVersion(userKey);
        }

        let params = match.params;
        const databaseId = params.databaseId ? parseInt(params.databaseId, 10) : null;
        if ( currentWorkspace && !databaseId){
            history.push(this.newUrlFromCurrentDb);
        }

        dispatch(fetchWorkspaces(userKey));
    }

    componentDidUpdate(prevProps) {
        const {
            currentWorkspace,
            userKey,
            match,
            getDatabase,
            isFetchingDatabases,
            isFetchingDatabase,
            history
        } = this.props;
        let params = match.params;
        const databaseId = params.databaseId ? parseInt(params.databaseId, 10) : 0;
        
        if (isFetchingDatabases) {
            return;
        }

        if (currentWorkspace && !databaseId){
            history.push(this.newUrlFromCurrentDb);
            return;
        }

        if (databaseId && !isFetchingDatabase) {
            if ((currentWorkspace && currentWorkspace.id !== databaseId) || !currentWorkspace) {
                getDatabase(userKey, databaseId)
            }
        }
    }

    render() {
        const {
            userKey,
            dispatch,
            history,
            fetchScriptsHandler,
            isFetching,
            scripts,
            scriptCommands,
            selectedScript,
            createScriptHandler,
            deleteScriptHandler,
            fetchSelectedScriptHandler,
            fetchScriptCommandsHandler,
            currentWorkspace,
            workspaces,
            userId,
            params,
            editScriptHandler,
            longRunningOperationsStatus,
            clearLongRunningOperationsStatus,
            getLongRunningOperationsStatus,
            projects,
            isFetchingProjects,
            isFetchingWorkSpaces,
            cancelBatch,
            userPermissions,
            fetchMappersHandler,
            fetchCommandStatuses,
            isFetchingCommandStatuses,
            commandStatuses,
            mappers,      
            collectionSet,
        } = this.props;

        let childProps = {
            dispatch,
            userKey,
            browserHistory: history,
            fetchScriptsHandler,
            isFetching,
            scripts,
            scriptCommands,
            selectedScript,
            createScriptHandler,
            deleteScriptHandler,
            fetchSelectedScriptHandler,
            fetchScriptCommandsHandler,
            currentWorkspace,
            workspaces,
            userId,
            params,
            editScriptHandler,
            longRunningOperationsStatus,
            clearLongRunningOperationsStatus,
            getLongRunningOperationsStatus,
            projects,
            isFetchingProjects,
            isFetchingWorkSpaces,
            cancelBatch,
            userPermissions,
            fetchMappersHandler,
            fetchCommandStatuses,
            isFetchingCommandStatuses,
            commandStatuses,
            mappers,      
            collectionSet,  
            baseUrl: this.baseUrl      
        };

        return (
        <Feature flag="ariusAnalysis">
            <Switch>
                <Route path="/arius/automation/:databaseId/status" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
                <Route path="/arius/automation/:databaseId/scripts/:scriptId" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
                <Route path="/arius/automation/:databaseId/scripts" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
                <Route path="/arius/automation/status" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
                <Route path="/arius/automation/scripts" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
                <Route path="/arius/automation" render={props => <Automation {...{...childProps, params: props.match.params }}/>}/>
            </Switch>
        </Feature>
        );
    }
}

const mapStateToProps = state => ({
    projects: state.analysis.projects.items,
    userKey: state.user.userKey,
    userId: state.user.userId,
    application: state.application,
    scripts: state.analysis.automation.scripts,
    commandStatuses: state.analysis.automation.commandStatuses,
    mappers: state.analysis.automation.mappers,
    isFetching: state.analysis.automation.isFetching,
    isFetchingCommandStatuses: state.analysis.automation.isFetchingCommandStatuses,
    isFetchingWorkSpaces: state.analysis.workspaces.isFetching,
    isFetchingProjects: state.analysis.projects.isFetching,
    currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
    scriptCommands: state.analysis.automation.scriptCommands,
    selectedScript: state.analysis.automation.selectedScript,
    currentWorkspace: state.analysis.workspaces.current,
    workspaces: state.analysis.workspaces.items,
    longRunningOperationsStatus: state.analysis.projects
        .longRunningOperationsStatus,
    userPermissions: state.user.userPermissions,
    collectionSet: state.analysis.projects.collectionSet,
});

const mapDispatchToProps = dispatch => ({
    fetchScriptsHandler: ({ databaseId, userKey }) => {
        dispatch(fetchScripts({ databaseId, userKey }));
    },
    fetchCommandStatuses: ({ databaseId, userKey, jobId }) => {
        dispatch(getCommandStatuses({ databaseId, userKey, jobId }));
    },
    fetchMappersHandler: ({ databaseId, userKey }) => {
        dispatch(fetchMappers({ databaseId, userKey }));
    },
    getDatabase: (userKey, databaseId) => {
        dispatch(fetchWorkspace(userKey, databaseId));
    },
    createScriptHandler: ({
        userKey,
        createBy,
        scriptName,
        scriptDescription,
        commands,
        databaseId,
    }) => {
        dispatch(
        createScript({
            userKey,
            scriptName,
            scriptDescription,
            commands,
            databaseId,
            createBy,
        })
        );
    },
    editScriptHandler: ({
        batchScriptId,
        userKey,
        updateBy,
        scriptName,
        scriptDescription,
        commands,
        databaseId,
    }) => {
        dispatch(
        updateScript({
            batchScriptId,
            userKey,
            scriptName,
            scriptDescription,
            commands,
            databaseId,
            updateBy,
        })
        );
    },
    fetchScriptCommandsHandler: () => {
        dispatch(fetchScriptCommands());
    },
    deleteScriptHandler: () => {
        dispatch(deleteScript());
    },
    fetchSelectedScriptHandler: () => {
        dispatch(fetchSelectedScript());
    },
    getLongRunningOperationsStatus: (userKey, databaseId) => {
        dispatch(fetchLongRunningOperationsStatus(userKey, databaseId));
    },
    cancelBatch: (userKey, databaseId, batchJobId, callback) => {
        dispatch(cancelBatchProcess(userKey, databaseId, batchJobId, callback));
    },
    clearLongRunningOperationsStatus: () => {
        dispatch(emptyLongRunningOperationsStatus());
    },
    getCurrentAEDbScriptVersion: userKey => {
        dispatch(fetchCurrentAEDbScriptVersion(userKey));
    },
    getCurrentUserPermissions: () => {
        dispatch(fetchCurrentUserPermissions());
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationTab);
