import {
    FETCHING_PERFORMANCE_INFO,
    RECEIVED_PERFORMANCE_INFO,
    FETCHING_PERFORMANCE_OPTIONS,
    RECEIVED_PERFORMANCE_DATABASE_OPTIONS,
    RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS,
    RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS,
    FETCHING_PERFORMANCE_JOB_STATUS,
    RECEIVED_PERFORMANCE_JOB_STATUS,
    SENT_PERFORMANCE_COMMAND,
    RECEIVED_PERFORMANCE_POWERBI_OPTIONS,
} from '../../actions/utils/performance.actionTypes';
  
  const performanceReducer = (state = {
    performanceInfo: null,
    fetchingPerformanceInfo: false,
    databaseOptions: null,
    elasticPoolOptions: null,
    appServicePlanOptions: null,
    powerbiOptions: null,
    fetchingPerformanceJobStatus: false,
    jobStatus: null,
  }, action) => {
    switch (action.type) {

        case FETCHING_PERFORMANCE_INFO:
            return Object.assign({}, state, {
                fetchingPerformanceInfo: true,
                performanceInfo: null,
            });

        case FETCHING_PERFORMANCE_OPTIONS:
            return Object.assign({}, state, {
                fetchingPerformanceOptions: true,
            });

        case RECEIVED_PERFORMANCE_INFO:
            return Object.assign({}, state, {
                fetchingPerformanceInfo: false,
                performanceInfo: action.performanceInfo,
            });

        case RECEIVED_PERFORMANCE_DATABASE_OPTIONS:
            return Object.assign({}, state, {
                fetchingPerformanceOptions: false,
                databaseOptions: action.options,
            });
        
        case RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS:
            return Object.assign({}, state, {
                fetchingPerformanceOptions: false,
                elasticPoolOptions: action.options,
            });

        case RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS:
            return Object.assign({}, state, {
                fetchingPerformanceOptions: false,
                appServicePlanOptions: action.options,
            });

        case RECEIVED_PERFORMANCE_POWERBI_OPTIONS:
            return Object.assign({}, state, {
                fetchingPerformanceOptions: false,
                powerbiOptions: action.options,
            });

        case FETCHING_PERFORMANCE_JOB_STATUS:
            return Object.assign({}, state, {
                fetchingPerformanceJobStatus: true,
            });

        case RECEIVED_PERFORMANCE_JOB_STATUS:
            return Object.assign({}, state, {
                fetchingPerformanceJobStatus: false,
                jobStatus: action.jobStatus,
            });

        case SENT_PERFORMANCE_COMMAND:
            return Object.assign({}, state, {
                jobStatus: action.jobStatus,
            });

        default:
      return state;
  }
};

export default performanceReducer;