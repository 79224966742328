import React, { Component } from 'react';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { Button as ArButton } from '@arius';
import { Modal, Button, Form, Row, Col} from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { fetchDateFormats, fetchDateFormatDelimiters } from '@app/actions/tod/todDatabases.actions';
import {
    fetchUploads,
    fetchColumnsForFile,
  } from '@app/actions/analysis/uploads.actions';
import CsvColumnsTable from './csvColumnsTable';
import { validateName } from '@app/utilities/validators';

class ModalEditTable extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: {},
            errors: {},
            record: {name: '', description: '', columns: []},
            dataTypes: ['Dimension', 'Date', 'Measure'],
        };
        this.selectFile = this.selectFile.bind(this);
        this.populateColumns = this.populateColumns.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.open = this.open.bind(this);

        this.getGrid = this.getGrid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
    }

    get hasErrors(){
        const { errors } = this.state;
        return errors.name 
            || errors.columns;
    }

    componentDidMount() {
        const { scheduledJobs } = this.props;
        this.setState({ scheduledJobs });
    }

    componentDidUpdate(prevProps) {
        const { columns } = this.props;
        if (prevProps.columns !== columns){
            this.populateColumns(columns);
        }
    }

    validateName(name, existingNames) {

        if (!name || /^\s*$/.test(name)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let nameError = existingNames.filter(x=> name.toLowerCase().trim() === x).length ? 'Name must be unique' : '';
        nameError = nameError ? nameError : validateName(name);
        return nameError;
    }

    validateColumns(columns){
        let columnsError = 'Select at least one dimension or date field';
        if (!Array.isArray(columns) || columns.length < 1){
            return columnsError;
        }

        for (let i = 0; i < columns.length; i++) {
            let c = columns[i];
            if (!c.name){
                return 'All columns must have a unique name.';
            }
        }

        // check unique names
        let names = [...new Set(columns.map(x=> x.name))];
        if (names.length !== columns.length){
            return 'All columns must have a unique name.';
        }

        // check you have at least 1 dimension or 1 date
        if (columns.filter(x=> x.dataType === 'Date' || x.dataType === 'Dimension').length < 1){
            return columnsError;
        }

        return '';
    }

    handleNameChange(e){
        const { existingNames } = this.props;
        let { record, errors } = this.state;
        let name = e.target.value;
        record.name = name;
        this.setState({record, errors: {...errors, name: this.validateName(name, existingNames)}});
    }

    handleDescChange(e){
        let { record } = this.state;
        record.description = e.target.value;
        this.setState({record});
    }
    
    selectFile(e) {
        const { uploads, getColumns, userKey } = this.props;
        var file = uploads.find(x=> x.id === e.target.value);
        if (file && file.id){
            this.setState({selectedFile: file });
            getColumns(userKey, file.id);
        } else {
            this.setState({selectedFile: {} });
        }
    }

    populateColumns(columns) {
        const { record, errors } = this.state;
        record.columns = columns.map((x,i) => {return {
            tableDefinitionColumnId: i,
            tableDefinitionId: record.tableDefinitionId,
            name: x, 
            dataType: 'Dimension',
            format: '',
            delimiter: ''
        }});
        this.setState({record, errors: {...errors, columns: this.validateColumns(record.columns)}});
    }

    onChange(data){
        let { record, errors } = this.state;
        data = data.map((x, i)=> {
            return {
                tableDefinitionColumnId: i,
                tableDefinitionId: x.tableDefinitionId,
                name: x.name,
                dataType: x.dataType,
                format: x.dataType === 'Date' ? x.format : '',
                delimiter: x.dataType === 'Date' ? x.delimiter : ''
            }
        })
        record.columns = data;
        this.setState({record, errors: {...errors, columns: this.validateColumns(data)}});
    }

    getGrid() {
        const { record, dataTypes } = this.state;
        const { formats, delimiters } = this.props;

        return <CsvColumnsTable 
            data={record.columns}
            formats={formats}
            delimiters={delimiters}
            types={dataTypes}
            saveHandler={this.onChange}
            disabled={record.hasExistingData}
        />;
    }

    getActionItems(row) {
        return <ArButton toolTip="Delete" iconName="delete" onClick={()=> {this.removeColumn(row)}}/>
    }

    close() {
        this.setState({selectedFile: {}, errors: {}});
        this.props.closeHandler();
    }

    modalHandler(modalState) {
        this.setState(modalState);
    }

    open() {
        const { getUploads, getDateFormatDelimiters, getDateFormats } = this.props;
        getUploads();
        getDateFormatDelimiters();
        getDateFormats();
        this.setState({ showModal: true, record: this.props.record });
    }

    save() {
        const { saveHandler, existingNames } = this.props;
        const { record } = this.state;
        let nameError = this.validateName(record.name, existingNames);
        let columnsError = this.validateColumns(record.columns);

        if (nameError || columnsError){
            this.setState({errors: {name: nameError, columns: columnsError}});
            return;
        }
        
        saveHandler(record);
        this.close();
    }

    render() {
        const { show, uploads: sortedUploads, canEdit} = this.props;
        const { selectedFile, record, errors } = this.state;

        var  fileSelect = (
            <Form.Group controlId="fileSelect">
                <Form.Label>Select CSV File to Map</Form.Label>
                <Form.Control as="select"
                    value = {selectedFile.id}
                    onChange={this.selectFile}
                    disabled={record.hasExistingData}
                    isInvalid={errors.columns}
                >
                    <option value="select">-- Select CSV file to map --</option>
                    {sortedUploads.map(u => (
                    <option key={`${u.id}`} value={u.id}>{u.name}</option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.columns}</Form.Control.Feedback>
            </Form.Group>);

    return (
      <Modal id={'modalLoadFile'} show={show} dialogClassName="wait-modal" size="sm"
        onHide={this.close} onShow={this.open} backdrop='static'>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
            Support Table Definition
        </Modal.Header>
        <Modal.Body className="modal-container">
        {/* <div style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}>Name:</div> */}
        <div>
            <Row>
                <Col md={6}>
                    <Form.Group>
                    <Form.Label>Name</Form.Label>
                        <Form.Control
                            className="form-control"
                            as="input" maxLength="255"
                            value={record.name}
                            onChange={this.handleNameChange}
                            isInvalid={errors.name}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                    <Form.Label>Description</Form.Label>
                        <Form.Control
                            className="form-control"
                            as="textarea" maxLength="1500"
                            value={record.description}
                            onChange={this.handleDescChange}
                        />
                        <Form.Control.Feedback type='invalid'>Too long!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {fileSelect}
            </div>
            {this.getGrid()}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="arius" data-dismiss="modal" onClick={this.save} disabled={this.hasErrors || !canEdit}>Save</Button>
            <Button variant="arius" data-dismiss="modal" onClick={this.close}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    columns: state.analysis.uploads.columns,
    uploads: state.analysis.uploads.items,
    formats: state.tod.databases.dateFormats,
    delimiters: state.tod.databases.dateFormatDelimiters,
});

const mapDispatchToProps = dispatch => ({
    getUploads: () => {
        dispatch(fetchUploads());
    },
    getColumns: (userKey, fileId) => {
        dispatch(fetchColumnsForFile(userKey, fileId));
    },
    getColumnsForCsvFile: (userKey, fileId) => {
        dispatch(fetchColumnsForFile(userKey, fileId));
      },
      getDateFormats: userKey => {
        dispatch(fetchDateFormats(userKey));
      },
      getDateFormatDelimiters: userKey => {
        dispatch(fetchDateFormatDelimiters(userKey));
      },
    dispatch,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(radium(ModalEditTable));
