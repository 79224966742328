import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../../shared/presentational/loader';
import * as Colors from '../../../utilities/colors';
import { Grid } from '@arius';

class SegmentPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.close = this.close.bind(this);
    }

    close() {
        this.props.closeHandler();
    }

    getGrid(segmentsPreview) {
        let { fieldNames, records:segments } = segmentsPreview;
        
        // map columns and data into form expected by grid...
        let columns = fieldNames.map((x)=> {return { field: x, headerText: x, width: '200px'}});
        let data = segments.map(row=> {
            let temp = {};
            row.map((x,i)=> {temp[fieldNames[i]] = x;return null;})
            return temp;
        })
        
        return (<Grid data={data} columns={columns} height='calc(100vh - 300px)' allowPaging={false}/>)
    }

    render() {
        const { isFetching, segmentPreview } = this.props;
        let bodyMarkup = (
            <div style={{ margin: '5vh auto' }}><Loader loadingItem="Segments Preview" /></div>
        );
        if (!isFetching && segmentPreview) {
            bodyMarkup = this.getGrid(segmentPreview);
        }
        return (
        <Modal
            id={this.props.modalId}
            show={this.props.showModal}
            onHide={this.close}
            dialogClassName="segmentPreviewModalContainer"
            enforceFocus
        >
            <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                <Modal.Title>Segments Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyMarkup}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-dismiss="modal"
                    onClick={this.close}
                    variant='outline-secondary'
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        );
    }
}

SegmentPreviewModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  segmentPreview: PropTypes.object,
  isFetching: PropTypes.bool,
  dimensions: PropTypes.array,
};

SegmentPreviewModal.defaultProps = {};

export default radium(SegmentPreviewModal);
