export const
  blue = '#337AB7',
  darkPurple = '#402A75',
  lightPurple = '#987BB7',
  purple = '#5C2695',
  lightBlue = '',
  darkGrey = '#5B595C',
  lightGrey = 'rgba(103,58,183,0.1)',
  formGrey = '#F9F9F9',
  grey = '#EAEAEA',
  red = '#b15555',
  black = '#000000';
