import {
  FETCHING_ANALYSIS_TASKS,
  RECEIVED_ANALYSIS_TASKS } 
  from '../../actions/analysis/analysis.actionTypes';

const analysisTasksReducer = (state = {
  isFetchingAnalysisTasks: false,
  analysisTasks: null,
}, action) => {
  switch (action.type) {
    case FETCHING_ANALYSIS_TASKS:
      return Object.assign({}, state, { isFetchingAnalysisTasks: true });
    case RECEIVED_ANALYSIS_TASKS:
      return Object.assign({}, state, {
        isFetchingAnalysisTasks: false,
        tasks: action.data,
      });
    default:
      return state;
  }
};

export default analysisTasksReducer;
