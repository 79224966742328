import { RECEIVED_TOD_UPLOADS, FETCHING_TOD_UPLOADS, SAVING_TOD_UPLOADS, RECEIVED_COLUMNS_FOR_FILE } from '../../actions/tod/tod.actionTypes';

const uploadsReducer = (state = { items: [], isFetching: false, isSaving: false, columns: [] }, action) => {
  switch (action.type) {
    case FETCHING_TOD_UPLOADS:
      return Object.assign({}, state, { isFetching: true });
    case SAVING_TOD_UPLOADS:
      return Object.assign({}, state, { isSaving: true });
    case RECEIVED_TOD_UPLOADS:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        items: action.items,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_COLUMNS_FOR_FILE:
      return Object.assign({}, state, {
        isFetching: false,
        columns: action.columns,
        receivedAt: action.receivedAt,
      });
    default:
      return state;
  }
};

export default uploadsReducer;
