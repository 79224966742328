import React from 'react';
import PropTypes from 'prop-types';
import * as Colors from '../../../utilities/colors';

const Loader = ({ loadingItem, size, loadingText }) => {
    loadingText = loadingText ? loadingText : 'Loading';
    loadingItem = loadingItem ? loadingItem : '';
    return <div style={{ color: Colors.purple, textAlign: 'center', fontSize: size || 20, margin: 10 }}>
    {loadingItem || loadingText ? `${loadingText} ${loadingItem}` : ''}
    <i style={{ marginLeft: 10 }} className="fa fa-spinner fa-spin"></i>
  </div>
};

Loader.propTypes = {
  loadingItem: PropTypes.string,
  size: PropTypes.number,
};

export default Loader;
