import {
    FETCHING_PERFORMANCE_INFO,
    RECEIVED_PERFORMANCE_INFO,
    FETCHING_PERFORMANCE_OPTIONS,
    RECEIVED_PERFORMANCE_DATABASE_OPTIONS,
    RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS,
    RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS,
    SENT_PERFORMANCE_COMMAND,
    RECEIVED_PERFORMANCE_JOB_STATUS,
    FETCHING_PERFORMANCE_JOB_STATUS,
    RECEIVED_PERFORMANCE_POWERBI_OPTIONS,
} from './performance.actionTypes';

import * as api from '../../serviceApi/performance.serviceApi';

function fetchingPerformanceinfo() {
    return {
        type: FETCHING_PERFORMANCE_INFO,
    };
}

function receivedPerformanceinfo(json) {
    return{
        type: RECEIVED_PERFORMANCE_INFO,
        performanceInfo: json,
        receivedAt: Date.now(),
    } ;
}

function sentPerformanceCommand(json) {
    return {
        type: SENT_PERFORMANCE_COMMAND,
        jobStatus: json,
    };
}

function fetchingPerformanceJobStatus() {
    return {
        type: FETCHING_PERFORMANCE_JOB_STATUS,
    };
}

function receivedPerformanceJobStatus(json) {
    return{
        type: RECEIVED_PERFORMANCE_JOB_STATUS,
        jobStatus: json,
    };
}

function fetchPerformanceInfo(userKey) {
    return (dispatch) => {
        dispatch(fetchingPerformanceinfo());
        return api
                .retrievePerformanceInfo(userKey)
                .then(json => {
                    dispatch(receivedPerformanceinfo(json));
                    dispatch(fetchPerformanceJobStatus(userKey, ''));
                });
    };
}

function fetchDatabaseOptions(userKey) {
    return (dispatch) => {
        dispatch({type: FETCHING_PERFORMANCE_OPTIONS});
        return api
            .retrieveDatabaseOptions(userKey)
            .then(json => dispatch({
                type: RECEIVED_PERFORMANCE_DATABASE_OPTIONS,
                options: json,
            }));
    };
}

function fetchElasticPoolOptions(userKey) {
    return (dispatch) => {
        dispatch({type: FETCHING_PERFORMANCE_OPTIONS});
        return api
            .retrieveElasticPoolOptions(userKey)
            .then(json => dispatch({
                type: RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS,
                options: json,
            }));
    };
}

function fetchAppServicePlanOptions(userKey, appPlanServiceName) {
    return (dispatch) => {
        dispatch({type: FETCHING_PERFORMANCE_OPTIONS});
        return api
            .retrieveAppServicePlanOptions(userKey, appPlanServiceName)
            .then(json => dispatch({
                type: RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS,
                options: json,
            }));
    };
}

function updateDatabase(userKey, request) {
    return (dispatch) => {
        return api
            .updateDatabase(userKey, request)
            .then(json => {
                dispatch(sentPerformanceCommand(json));
                console.error('sent request - now what?');
            });
    };
}

function updateElasticPool(userKey, request) {
    return (dispatch) => {
        return api
            .updateElasticPool(userKey, request)
            .then(json => {
                dispatch(sentPerformanceCommand(json));
                console.error('sent request - now what?');
            });
    };
}

function updateAppServicePlan(userKey, request) {
    return (dispatch) => {
        return api
            .updateAppServicePlan(userKey, request)
            .then(json => {
                dispatch(sentPerformanceCommand(json));
                console.error('sent request - now what?');
            });
    };
}

function fetchPerformanceJobStatus(userKey, jobId) {
    return (dispatch) => {
        dispatch(fetchingPerformanceJobStatus());
        return api
                .retrievePerformanceJobStatus(userKey, jobId)
                .then(json => { 
                    if (json && !json.error) {
                        dispatch(receivedPerformanceJobStatus(json));
                    }
                });
    };
}

function fetchPowerBIOptions(userKey, capacityName){
    return (dispatch) => {
        dispatch({type: FETCHING_PERFORMANCE_OPTIONS});
        return api
            .retrievePowerBIOptions(userKey, capacityName)
            .then(json => dispatch({
                type: RECEIVED_PERFORMANCE_POWERBI_OPTIONS,
                options: json,
            }));
    };
}

function updatePowerBI(userKey, request) {
    return (dispatch) => {
        return api
            .updatePowerBI(userKey, request)
            .then(json => {
                dispatch(sentPerformanceCommand(json));
                console.error('sent request - now what?');
            });
    };
}

function powerBIAction(userKey, actionRequest) {
    return (dispatch) => {
        return api
            .powerBIAction(userKey, actionRequest)
            .then(json => {
                dispatch(sentPerformanceCommand(json));
            });
    };
}

export {
    fetchPerformanceInfo,
    fetchDatabaseOptions,
    fetchElasticPoolOptions,
    fetchAppServicePlanOptions,
    updateDatabase,
    updateElasticPool,
    updateAppServicePlan,
    fetchPerformanceJobStatus,
    fetchPowerBIOptions,
    updatePowerBI,
    powerBIAction,
};