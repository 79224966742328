import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';
import Feature from '@app/utilities/routing/routeSafeFeature';

function AdvancedAnalyticsNav() {
    return (
        <Feature flag="advancedAnalytics">
            <NavTabs>
                {/* <NavTab label={'Uploads'} to={'/analytics/uploads'}/> */}
                <NavTab label={'Models'} to={'/analytics/models'}/>
            </NavTabs>
        </Feature>
    );
}   

export default AdvancedAnalyticsNav;
