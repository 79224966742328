import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import {
  fetchAssignedRoles,
  fetchAvailablePermissionsForRole,
  fetchRoleDescriptions,
  fetchAvailablePermissions,
  fetchAvailableUsers,
  fetchAvailableResources,
  clearPermissions,
  createRole,
  deleteRole,
  assignRoles,
  updateUserRole,
} from '@app/actions/portalManagement/roles.actions';
import {
  fetchDatabases,
  selectDatabase,
} from '@app/actions/tod/todDatabases.actions';
import {
  fetchSubscriptionUsers,
} from '@app/actions/portalManagement/subscriptionUser.actions';
import {
  fetchWorkspaces,
  selectWorkspace,
} from '@app/actions/analysis/workspace.actions';
import { fetchCurrentUserPermissions } from '@app/actions/roles.actions';
import { TOD_DB, WORKSPACE } from '@app/utilities/projectDataHelper';
import {
  SITE_MANAGESECURITY,
  WORKSPACE_MANAGESECURITY,
  DATA_MANAGESECURITY,
  createPermissionChecker,
} from '@app/utilities/permissions';
import ViewRoles from '@app/portalManagement/viewRoles';
import DefineRoles from '@app/portalManagement/defineRoles';
import AssignRoles from '@app/portalManagement/assignRoles';
import ShowRolesAndAssignments from '@app/portalManagement/showRolesAndAssignments';
import DatabaseSecurityAssignment from '@app/tod/databaseSecurityAssignment';
import WorkspaceSecurityList  from '@app/analysis/workspaceSecurityList';

class Roles extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        application: PropTypes.object,
        users: PropTypes.array,
        userKey: PropTypes.string,
        newSaveHandler: PropTypes.func,
        editSaveHandler: PropTypes.func,
        groups: PropTypes.array,
        isUserUpdated: PropTypes.bool,
        children: PropTypes.object,
        assignedRoles: PropTypes.array,
        availablePermissions: PropTypes.array,
        roleDescriptions: PropTypes.array,
        assignedRoleHandler: PropTypes.func,
        roleDescriptionHandler: PropTypes.func,
        availablePermissionsHandler: PropTypes.func,
        availableUsersHandler: PropTypes.func,
        availableResourcesHandler: PropTypes.func,
        clearPermissionsHandler: PropTypes.func,
        availableResources: PropTypes.array,
        availableUsers: PropTypes.array,
        deleteRoleHandler: PropTypes.func,
        createRoleHandler: PropTypes.func,
        retrieveWorkspacesHandler: PropTypes.func,
        assignRoleHandler: PropTypes.func,
        workspaces: PropTypes.object,
        fetchRolePermissions: PropTypes.func,
        selectedRole: PropTypes.object,
        getDatabases: PropTypes.func,
        databases: PropTypes.array,
        isFetching: PropTypes.bool,
        userPermissions: PropTypes.array,
        params: PropTypes.object,
        currentWorkspace: PropTypes.object,
        currentDatabase: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
        canEdit: false,
        resourceSource: null,
        };
        let params = props.match ? props.match.params : {};
        if (params) {
            if (params.workspaceId) {
                this.state.selectedResource = params.workspaceId;
                this.state.resourceSource = WORKSPACE;
            } else if (params.databaseId) {
                this.state.selectedResource =params.databaseId;
                this.state.resourceSource = TOD_DB;
            }
        }
    }

    componentDidMount() {
        const {
            dispatch,
            userKey,
            getDatabases,
            retrieveWorkspacesHandler,
            currentWorkspace,
            currentDatabase,
            workspaces,
            databases,
        } = this.props,
        { resourceSource } = this.state;
        dispatch(fetchSubscriptionUsers(userKey));
        getDatabases(userKey);
        retrieveWorkspacesHandler(userKey);

        if ((!currentWorkspace || currentWorkspace.id !== this.state.selectedResource) && resourceSource === WORKSPACE) {
            const ws = workspaces.items.find(
                w => w.workspaceId.toString() === this.state.selectedResource,
            );
            if (ws) {
                dispatch(selectWorkspace(ws));
            }
        }

        if (!currentDatabase && resourceSource === TOD_DB) {
            const db = databases.find(
                d => d.workspaceId.toString() === this.state.selectedResource,
            );
            if (db) {
                dispatch(selectDatabase(db));
            }
        }

        this.updateEditableState();
        // TODO: Fix Site Admin
        // if (!isCurrentUserAdmin) {
        //   browserHistory.push('/home');
        // }
    }

    componentDidUpdate() {
        const {
            dispatch,
            currentWorkspace,
            workspaces,
            currentDatabase,
            databases,
        } = this.props,
        { resourceSource } = this.state;
        this.updateEditableState();

        if (resourceSource === WORKSPACE && (!currentWorkspace || currentWorkspace.id.toString() !== this.state.selectedResource.toString())) {
            const ws = workspaces.items.find(
                w => w.workspaceId.toString() === this.state.selectedResource,
            );
            if (ws) {
                dispatch(selectWorkspace(ws));
            }
        }

        if (resourceSource === TOD_DB && !currentDatabase) {
            const db = databases.find(
                d => d.workspaceId.toString() === this.state.selectedResource,
            );
            if (db) {
                dispatch(selectDatabase(db));
            }
        }
    }

    updateEditableState() {
        const { currentWorkspace, userPermissions, currentDatabase } = this.props,
        { resourceSource } = this.state;
        let canEdit = this.state.canEdit;

        if (this.state.selectedResource) {
            if (
                currentWorkspace &&
                resourceSource === WORKSPACE &&
                currentWorkspace.permissions.indexOf(WORKSPACE_MANAGESECURITY) !== -1
            ) {
                canEdit = true;
            }
            if (
                currentDatabase &&
                resourceSource === TOD_DB &&
                currentDatabase.permissions.indexOf(DATA_MANAGESECURITY) !== -1
            ) {
                canEdit = true;
            }
        } else {
            if (userPermissions.indexOf(SITE_MANAGESECURITY) !== -1) {
                canEdit = true;
            }
        }

        if (canEdit !== this.state.canEdit) {
            this.setState({ canEdit });
        }
    }

    render() {
        const {
            application,
            userKey,
            dispatch,
            history,
            users,
            groups,
            isUserUpdated,
            assignedRoles,
            roleDescriptions,
            assignedRoleHandler,
            roleDescriptionHandler,
            availablePermissionsHandler,
            clearPermissionsHandler,
            availableUsersHandler,
            availableResourcesHandler,
            availableResources,
            availableUsers,
            availablePermissions,
            deleteRoleHandler,
            createRoleHandler,
            retrieveWorkspacesHandler,
            workspaces,
            assignRoleHandler,
            fetchRolePermissions,
            selectedRole,
            getDatabases,
            databases,
            isFetching,
            userPermissions,
            currentWorkspace,
            currentDatabase,
            features
        } = this.props;

        const { selectedResource, canEdit, resourceSource } = this.state;

        const scope = selectedResource ? { selectedResource } : {};
        let childProps =  Object.assign(scope, {
            browserHistory: history,
            userKey,
            application,
            dispatch,
            users,
            groups,
            isUserUpdated,
            assignedRoles,
            roleDescriptions,
            assignedRoleHandler,
            roleDescriptionHandler,
            availablePermissionsHandler,
            clearPermissionsHandler,
            availableUsersHandler,
            availableResourcesHandler,
            availableResources,
            availableUsers,
            availablePermissions,
            deleteRoleHandler,
            createRoleHandler,
            retrieveWorkspacesHandler,
            workspaces,
            assignRoleHandler,
            fetchRolePermissions,
            selectedRole,
            currentWorkspace,
            currentDatabase,
            getDatabases,
            isFetching,
            databases,
            userPermissions,
            verifyPermission: createPermissionChecker(userPermissions),
            canEdit,
            resourceSource,
            features
        });
        return (
            <Switch>
                
                <Route path="/arius/workspaces/:workspaceId/security/assign" render={props => <WorkspaceSecurityList {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/arius/workspaces/:workspaceId/security"  render={props => <ShowRolesAndAssignments {...{...childProps, params: props.match.params}}/>}/>
                
                <Route path="/tod/databases/:databaseId/security/assign" 
                    render={props => <DatabaseSecurityAssignment {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/tod/databases/:databaseId/security" 
                    render={props => <ShowRolesAndAssignments {...{...childProps, params: props.match.params}}/>}/>

                <Route path="/portalManagement/roles/defineRoles/:roleId" 
                    render={props => <DefineRoles {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/portalManagement/roles/defineRoles" render={props => (<DefineRoles {...childProps}/>)}/>
                <Route path="/portalManagement/assignments/assign" render={props => (<AssignRoles {...childProps}/>)}/>
                <Route path="/portalManagement/assignments" 
                    render={props => <ShowRolesAndAssignments {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/portalManagement/roles" render={props => (<ViewRoles {...childProps}/>)}/>
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    features: state.features,
    workspaces: state.analysis.workspaces,
    isFetching: state.portalManagement.roles.isFetching,
    databases: state.tod.databases.items,
    currentDatabase: state.tod.databases.current,
    users: state.portalManagement.users.subscriptionUsers,
    assignedRoles: state.portalManagement.roles.assignedRoles,
    roleDescriptions: state.portalManagement.roles.roleDescriptions,
    userKey: state.user.userKey,
    userPermissions: state.user.userPermissions,
    router: state.router,
    route: state.route,
    groups: state.portalManagement.groups.items,
    isUserUpdated: state.portalManagement.users.isUserUpdated,
    application: state.application,
    availablePermissions: state.portalManagement.roles.availablePermissions,
    availableUsers: state.portalManagement.roles.availableUsers,
    availableResources: state.portalManagement.roles.availableResources,
    selectedRole: state.portalManagement.roles.selectedRole,
    currentWorkspace: state.analysis.workspaces.current,
});

const mapDispatchToProps = dispatch => ({
    assignedRoleHandler: () => {
        dispatch(fetchAssignedRoles());
    },
    roleDescriptionHandler: () => {
        dispatch(fetchRoleDescriptions());
    },
    availablePermissionsHandler: value => {
        dispatch(fetchAvailablePermissions(value));
    },
    clearPermissionsHandler: () => {
        dispatch(clearPermissions());
    },
    createRoleHandler: ({ userKey, roleId, name, permissions, description, browserHistory }) => {
        if (roleId) {
            dispatch(
                updateUserRole(userKey, roleId, { name, permissions, description}, browserHistory),
            );
        } else {
            dispatch(createRole({ userKey, roleId, name, permissions, description, browserHistory  }));
        }
    },
    availableUsersHandler: () => {
        dispatch(fetchAvailableUsers());
    },
    availableResourcesHandler: () => {
        dispatch(fetchAvailableResources());
    },
    deleteRoleHandler: (userKey, roleId) => {
        dispatch(deleteRole(userKey, roleId));
    },
    retrieveWorkspacesHandler: userKey => {
        dispatch(fetchWorkspaces(userKey));
    },
    getDatabases: userKey => {
        dispatch(fetchDatabases(userKey));
    },
    assignRoleHandler: (
        { userKey, users, roles, site, databases, operation, currentWorkspace },
    ) => {
        dispatch(
            assignRoles({ userKey, users, roles, site, databases, operation, currentWorkspace }),
        );
    },
    fetchRolePermissions: roleId => {
        dispatch(fetchAvailablePermissionsForRole(roleId));
    },
    getCurrentUserPermissions: () => {
        dispatch(fetchCurrentUserPermissions());
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
