import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, Button, Form } from 'react-bootstrap';
import * as api from '@app/api/projects.serviceApi';
import * as Colors from '@app/utilities/colors';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import { notifyError } from '../../../utilities/notifier';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';

class ProjectStatusEditor extends Component {
    static propTypes = {
        databaseId: PropTypes.number,
        projectIds: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = { 
            isWaiting: false,
            projectStatusRequest: {}
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleSelectedStatusChange = this.handleSelectedStatusChange.bind(this);
        this.launchModal = this.launchModal.bind(this);
    }

    handleSelectedStatusChange(e) {
        this.setState({selectedStatus: e.target.value}); 
    }

    save() {
        const {databaseId, userKey, refreshProjectsHandler}=this.props;
        let req = this.state.projectStatusRequest;
        req.selectedStatus = this.state.selectedStatus;
        api.processProjectStatusChangeRequest(userKey, databaseId, req).then(
            (data) => {
                this.setState({ isWaiting: false });
                refreshProjectsHandler();
                this.handleClose();
            });
        this.setState({ isWaiting: true });
    }

    handleOpen(){
        const {databaseId, getprojectIds, userKey }=this.props;

        api.getProjectStatusChangeRequest(userKey, databaseId, getprojectIds()).then(
            (data) => {
                this.setState({ isWaiting: false, projectStatusRequest: data });
            }
        )
        this.setState({ selectedStatus: null, isWaiting: true, projectStatusRequest: {} });
    }

    handleClose(){
        this.setState({show: false});
    }

    launchModal(){
        const { canModifyStatus }=this.props;

        if (!canModifyStatus){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
        const {projectStatusRequest}=this.props;
        const {selectedStatus} = this.state;

        if (!selectedStatus && projectStatusRequest && 
            Array.isArray(projectStatusRequest.availableStatuses) && 
            projectStatusRequest.availableStatuses.length > 0) {
            this.setState({ selectedStatus: projectStatusRequest.availableStatuses[0] });
        }

        if (projectStatusRequest === 'success'){
            this.handleClose();
        }
    }

    
    getValidationMessageMarkup(validationMessage) {
        let parts = validationMessage.split("|");
        return parts.length > 1 ? (
            <span style={{fontWeight: 'bold'}}>
                { parts.map((x,i) => (
                    <div key={i} style={{marginBottom: 5, wordWrap: 'break-word'}}>{x}</div>
                ))}
            </span>
        ) : <span style={{fontWeight: 'bold'}}>{validationMessage}</span>
    }
  
    render() {

        const { show, selectedStatus, isWaiting, projectStatusRequest } = this.state;
        let validationMessage = projectStatusRequest ? projectStatusRequest.validationMessage : '';
        let isValid = !validationMessage || validationMessage === '';
        let availableStatuses = projectStatusRequest && projectStatusRequest.availableStatuses ? 
            projectStatusRequest.availableStatuses : [];

        let btnStyle = {
            color: Colors.darkGrey,
            //borderRadius: '5px',
            cursor: 'pointer',
            //fontSize: '4.5vmin',
            //height: '4.5vmin',
            //width: '4.5vmin',
            //textAlign: 'center',
            ':hover': {
                backgroundColor: Colors.purple,
                color: 'white',
                transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
            },
        };

        const msgStyle = {
            fontWeight: 'bold',
        };

        let form = !isValid ? (this.getValidationMessageMarkup(validationMessage)) : (
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        <span style={msgStyle}>Please select the new status to be applied to selected project(s)</span>
                    </div>
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        {availableStatuses.map((status, idx) => (
                            <Form.Check 
                                value={status}
                                key={`stCH${status}`}
                                type="radio"
                                checked={selectedStatus === status} 
                                onChange={this.handleSelectedStatusChange}
                                label={status} 
                            />
                        ))}
                    </div>
                </fieldset> 
            </div>            
        );

        let body = isWaiting ? (<Loader loadingItem=" status information" />) : form;

        let buttons = isValid ? (              
            [
                <Button
                    key="confirmButton"
                    className={'dialog-button'}
                    onClick={this.save}>Save</Button>,
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    onClick={this.handleClose}>Cancel</Button>
            ]) : (
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    onClick={this.handleClose}>Ok</Button>
            );

        return (
            <span>
                 <OverlayTrigger
                    placement="left"
                    focus
                    overlay={<Tooltip id='tootipId_modifyProjectStatuses'>Modify Status</Tooltip>}
                    >
                    <div style={{position:'relative', marginTop: 2}}>
                        <i
                            id="modifyProjectStatuses" 
                            style={btnStyle}
                            className="material-icons"
                            onClick={this.launchModal}
                        >
                            control_point
                        </i>       
                    </div>
                </OverlayTrigger> 
                <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>Modify project statuses</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{buttons}</Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }

    // const mapStateToProps = state => ({
    //     projectStatusRequest:state.analysis.projects.projectStatusRequest,
    //     isWaiting:state.analysis.projects.makingProjectStatusRequest,
    //     userKey: state.user.userKey,
    //   });
      
    // const mapDispatchToProps = dispatch => ({

    //     getRequestHandler:(userKey, workspaceId, projectIds)=>{
    //         dispatch(getProjectStatusChangeRequest(userKey, workspaceId, projectIds));
    //     },

    //     saveRequestHandler: (userKey, workspaceId, valuationDate, request)=>{
    //         dispatch(processProjectStatusChangeRequest(userKey, workspaceId, valuationDate, request));
    //     },

    //     dispatch,
    // });
    
    export default radium(ProjectStatusEditor);
