import moment from 'moment';
import React from 'react';
import { Table } from '@arius';

// TODO - remove?
export const universalFilter = (arrayToFilter, currentFilterValues, field, input) =>
  arrayToFilter.filter((atf) => {
    let match = true;
    const fields = Object.keys(currentFilterValues);
    for (const key of fields) {
      if (!match) break;
      let currentField = atf[key];
      // This is where we modify current field to align it for comparing to our filter (if needs be)
      if (typeof currentField === 'number') currentField = String(currentField);
      // The next section evaluates matches across every field of the filter object, and the current field vs. input
      if (key === field) {
        if (!(currentField && currentField.toLowerCase().indexOf(input.trim().toLowerCase()) !== -1)) match = false;
      } else if (!(currentField && currentField.toLowerCase().indexOf(currentFilterValues[key].toLowerCase()) !== -1)) match = false;
      // Anything below here takes special circumstances into account to allow for a match
      
      // This block was added to accommodate null values for fields
      if (currentField === null && key !== field) {
        if (!currentFilterValues[key]) match = true;
      } else if (currentField === null) {
        if (!input) {
          match = true;
        }
      }
    }
    return match;
  }),
  // use the following to deep copy any object with nested objects to prevent mutating state
  // or store directly. WARNING: This will destroy React elements stored in variables.
  makeClone = (item) => JSON.parse(JSON.stringify(item)),
  translateJargon = (jargon) => {
    const standards = {
      POST: 'Create',
      PUT: 'Update',
      DELETE: 'Delete',
    };
    return standards[jargon] || jargon;
  },
  resetScrollTop = (id, scrollTop) => {
    const el = document.getElementById(id);
    if (el) el.scrollTop = scrollTop || 0;
  },
  alphabetize = (array, prop, ascending, simple) => {
    if (array && Array.isArray(array)) {
      if (array.length) {
        if (simple) {
          return array.sort((a, b) => {
            if (ascending) {
              return a.toString().toLowerCase() <
              b.toString().toLowerCase() ? -1 : 1;
            } else if (!ascending) {
              return a.toString().toLowerCase() <
              b.toString().toLowerCase() ? 1 : -1;
            } else {
              return [];
            }
          });
        } else if (typeof array[0][prop] === 'string') {
          return array.sort((a, b) => {
            if (a[prop] === null) {
              return 1;
            } else if (b[prop] === null) {
              return -1;
            } else if (a[prop] === b[prop]) {
              return 0;
            } else if (ascending) {
              return a[prop].toString().toLowerCase() <
              b[prop].toString().toLowerCase() ? -1 : 1;
            } else if (!ascending) {
              return a[prop].toString().toLowerCase() <
              b[prop].toString().toLowerCase() ? 1 : -1;
            } else {
              return [];
            }
          });
        } else if (typeof array[0][prop] === 'number') {
          if (ascending !== false) {
            return array.sort((a, b) => {
              if (
                a[prop] <
                b[prop]
              ) {
                return -1;
              } else {
                return 1;
              }
            });
          } else {
            return array.sort((a, b) => {
              if (
                a[prop] <
                b[prop]
              ) {
                return 1;
              } else {
                return -1;
              }
            });
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  },
  createMessageTable = (projects) => {
    const tableRows = projects.map(project =>
        `<tr>
          <td class="alert-table-cell">
            ${project.name}
          </td>
          <td class="alert-table-cell">
            ${project.structure}
          </td>
          <td class="alert-table-cell">
            ${moment(project.valuation).format('L')}
          </td>
        </tr>`
      ),
      tableBody = tableRows.join('');
    return `<table className="table table-striped">
      <thead>
        <tr>
          <th class="alert-table-header">
            Project Name
          </th>
          <th class="alert-table-header">
            Data Structure
          </th>
          <th class="alert-table-header">
            Valuation Date
          </th>
        </tr>
      </thead>
      <tbody id="alert-table-body">
        ${tableBody}
      </tbody>
    </table>`;
  },
  deleteConflictURLKeywords = [
    'aeexportedlists',
    'rollups',
  ],
  conflictHelper = (res) => {
    if (res.config.method !== 'delete') return false;
    const found = deleteConflictURLKeywords.find(word => res.config.url.indexOf(word) !== -1);
    return found || false;
  },
  generateColumnFilterMarkup = (column, columnSelected, isDescending) => 
  {
    let s = {marginLeft: 10};
    // TODO go refactor all the copies of this that are used in all the grids...
    if (columnSelected === column) {
      if (isDescending) {
        return <i style={s} className="fa fa-chevron-down" />;
      } else {
        return <i style={s} className="fa fa-chevron-up" />;
      }
    } else {
      return <i style={s} />;
    }
  },
    generateSortedColumn = (columnName, columnLabel, columnSelected, isDescending, sortCallback, style={}) => {
        return (
            <Table.Th onClick={() => sortCallback(columnName)} style={style}>
                {columnLabel}
                { generateColumnFilterMarkup(columnName, columnSelected, isDescending) }
            </Table.Th>
        );
    },
    compareByField = (columnName, isDescending = false) => (obj1, obj2) => {
        var val = 0;
        var a = obj1[columnName];
        var b = obj2[columnName];

        if (typeof a === 'number' && typeof b === 'number') {
            val = a - b;
        } else {
            a = (a || '').toString().toLowerCase();
            b = (b || '').toString().toLowerCase();   
            val = a < b ? -1 : 1;         
        }

        if (a === b) { return 0;}
        return isDescending ? -1 * val : val;
    },
    compareDatesByField = (columnName, isDescending = false) => (obj1, obj2) => {
        var a = obj1[columnName];
        var b = obj2[columnName];

        a = typeof a !== 'object' ? new Date(a) : a;
        b = typeof b !== 'object' ? new Date(b) : b;   
        if (null == a || null == b) {
            console.error('problem::')
        }
        var val = a < b ? -1 : 1;         
        if (a === b) { return 0;};
        return isDescending ? -1 * val : val;
    };
