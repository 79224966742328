import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UtilitiesDashboard extends Component {
    render() {
        return (
        <div className="tile-container">
            <h2>Utilities</h2>
            <div className="tile-row">
                <Link className="tile" to="utilities/auditLogSearch">
                    <div>
                        <img src="assets/audit_log_white.svg" alt="auditLog"/>                    
                    </div>
                    Audit Log
                </Link>
                <Link className="tile" to="utilities/performance">
                    <i className="fa fa-tachometer" />
                    Performance Levels
                </Link>
            </div>
        </div>
        );
    }
}

export default UtilitiesDashboard;
