import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import { fetchQuerySegments } from '@app/actions/tod/advancedQueries.actions';
import * as Colors from '@app/utilities/colors';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import * as GlobalStyles from '@app/utilities/globalStyles';
import { Grid } from '@arius';

class SegmentsPreviewModal extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);
        this.state = { 
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.launchModal = this.launchModal.bind(this);
    }

    handleOpen(){
        const {databaseId, userKey, query, dispatch, validator}=this.props;
        if (!query || !validator()) {return;}
        dispatch(fetchQuerySegments(userKey, databaseId, query));
    }

    handleClose(){
        this.setState({show: false});
    }

    launchModal(){
        const {validator, queryValidationResponse}=this.props;
        let isValidSyntax = queryValidationResponse ? queryValidationResponse.isValid : false;
        if(!isValidSyntax) {return;}
        if (!validator()) {return;}
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
        const {projectStatusRequest}=this.props;
        const {selectedStatus} = this.state;

        if (!selectedStatus && projectStatusRequest && 
            Array.isArray(projectStatusRequest.availableStatuses) && 
            projectStatusRequest.availableStatuses.length > 0) {
            this.setState({ selectedStatus: projectStatusRequest.availableStatuses[0] });
        }

        if (projectStatusRequest === 'success'){
            this.handleClose();
        }
    }

    getGrid(fieldNames, segments) {
        let columns = fieldNames.map((x)=> { return {
            field: x, headerText: x, width: '200px'
        }});
        let data = segments.map(row=> {
            let temp = {};
            row.map((x,i)=> {temp[fieldNames[i]] = x;return null;})
            return temp;
        })
        return (<Grid data={data} columns={columns} height='calc(100vh - 300px)' allowPaging={false}/>)
    }
  
    render() {

        const {queryValidationResponse, querySegments } = this.props;
        const {show} = this.state;

        const isWaiting = null === querySegments;
        //let validationMessage = queryValidationResponse ? queryValidationResponse.validationMessage : '';
        let isValid = queryValidationResponse ? queryValidationResponse.isValid : false;
        const fieldNames = querySegments ? querySegments.fieldNames : [];
        const segments = querySegments && querySegments.records ? querySegments.records : [];
        const segmentsCount = querySegments ? 
                                querySegments.records ? querySegments.records.length : null
                                : null;
        const segmentsCountMessage = segmentsCount ? 
                                        segmentsCount === 1000 ? `Segment limit reached! (Capped to 1000)` : `(${segmentsCount} segments)` 
                                        : null;

        let error = querySegments ? querySegments.error ? querySegments.error.message : null : null;
        error = error ? error.startsWith('Query takes too long') ? error : 'Server Error Loading Segments' : null;


        const btnStyle = {
            color: Colors.darkGrey,
            //borderRadius: '5px',
            cursor: 'pointer',
            //fontSize: '4.5vmin',
            //height: '4.5vmin',
            //width: '4.5vmin',
            //textAlign: 'center',
            ':hover': {
                backgroundColor: Colors.purple,
                color: 'white',
                transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
            },
        };

        let body = (
            <div className="row" style={{}}>
                <div className="col-sm-12" style={{}}>
                {
                    isWaiting ? <Loader loadingItem="Segments ..." /> :
                    error ? error :
                    this.getGrid(fieldNames, segments)
                }
                </div>
            </div>
        );

        let tooltip = isValid ? 'Preview Segments' : 'Preview not available (INVALID SYNTAX)';
        return (
            <span className="pull-right">
                 <OverlayTrigger
                    placement="left"
                    focus
                    overlay={<Tooltip id='tootipId_sqlPreview' style={GlobalStyles.toolTipFix}>{tooltip}</Tooltip>}
                    >
                    <div style={{position:'relative'}}>
                        <i
                            id="modifyProjectStatuses" 
                            style={btnStyle}
                            className="material-icons pull-right"
                            onClick={this.launchModal}
                        >
                            assignment
                        </i>       
                    </div>
                </OverlayTrigger> 
                <Modal show={show} 
                    onHide={this.handleClose} onEnter={this.handleOpen} 
                    dialogClassName="wait-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title>Segments Preview {segmentsCountMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="arius" data-dismiss="modal" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }

    const mapStateToProps = state => ({
        userKey: state.user.userKey,
        queryValidationResponse: state.tod.queries.queryValidationResponse,
        isWaiting: state.tod.queries.isFetchingQueryValidation,
        querySegments: state.tod.queries.querySegments,
      });
      
    const mapDispatchToProps = dispatch => ({
        dispatch,
    });
    
    export default radium(connect(mapStateToProps, mapDispatchToProps)(SegmentsPreviewModal));


