import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, Button } from 'react-bootstrap';
import { hideModal } from '@app/actions/modal.actions';
import * as Colors from '@app/utilities/colors';

class ConfirmationModal extends Component {
    static propTypes = {
        text: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
        ]),
        yesClickHandler: PropTypes.func,
        noClickHandler: PropTypes.func,
        dispatch: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }

    handleYesClick(e) {
        const { yesClickHandler, dispatch } = this.props;
        dispatch(hideModal());
        yesClickHandler(e);
    }
      
    handleNoClick(e) {
        const { noClickHandler, dispatch } = this.props;
        dispatch(hideModal());
        noClickHandler(e);
    }

    render() {
        const { text, show } = this.props;
        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={this.handleNoClick} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>Are you sure?</small></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {text}
            </Modal.Body>

            <Modal.Footer>
                <Button className={'dialog-button'}
                     onClick={this.handleYesClick}>Yes</Button>
                <Button className={'dialog-button'}
                    onClick={this.handleNoClick}>No</Button>
            </Modal.Footer>
        </Modal>
        );
    }
}

export default radium(ConfirmationModal);
