import { callApi } from '../utilities/callApi';

export function getCategories(userKey, databaseId) {
  return callApi(`/projects/${databaseId}/categories`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function getCategory(userKey, databaseId, categoryId) {
    return callApi(`/projects/${databaseId}/categories/${categoryId}`,
      { 'X-AE-USERKEY': userKey },
      'GET',
    );
}

export function createCategory(userKey, databaseId, req) {
    return callApi(
        `/projects/${databaseId}/categories`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        req,
    );
}

export function updateCategory(userKey, databaseId, req) {
    return callApi(
        `/projects/${databaseId}/categories`,
        { 'X-AE-USERKEY': userKey },
        'PUT',
        req,
    );
}

export function deleteCategory(userKey, databaseId, categoryId) {
    return callApi(
        `/projects/${databaseId}/categories/${categoryId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE'
    );
}

export function categorizeProjects(userKey, databaseId, req) {
    return callApi(
        `/projects/${databaseId}/categories/assign`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        req,
    );
}

export function getExtractsByCategoryId(userKey, databaseId, categoryId) {
    return callApi(`extract/definition/${databaseId}/categories/${categoryId}`, {
      'X-AE-USERKEY': userKey,
    });
}
