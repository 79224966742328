import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { MultiSelectComponent,CheckBoxSelection, Inject  } from '@syncfusion/ej2-react-dropdowns';

class ProjectCategoriesView extends Component {
  static propTypes ={
    updateParent: PropTypes.func,
    state: PropTypes.object,
  };
    constructor(props) {
        super(props);
        this.state = {};
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleFilterChange(e){
        const { updateParent } = this.props;
        let checked = e.value;
        if (checked.length < 1){
            checked = ['All'];
        }
        updateParent({checked});

    }

    itemTemplate(c) {
        return <div className="ms-ename">
            <span style={{backgroundColor:`${c.color}`, marginRight: 5,
                borderRadius: 10, marginTop: 5, fontSize: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {c.name}
        </div>;
    }

    render() {
        const { categories, state } = this.props;
        let checked = state ? state.checked : [];
        checked = checked.includes('All') ? [] : checked;
        
        return ( <div style={{width: '100%'}}>
            <MultiSelectComponent id="checkssbox"
                dataSource={categories}
                change={this.handleFilterChange}
                fields= {{ text: 'name', value: 'projectCategoryId' }}
                placeholder="Filter by categories" 
                value={checked}
                mode="CheckBox"
                showDropDownIcon={true} 
                filterBarPlaceholder="Search categories" 
                itemTemplate={this.itemTemplate}
                showSelectAll={false}
                //popupHeight="350px"
                //width='360'
                height='100%'
                >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
        </div>);
    }
}

export default radium(ProjectCategoriesView);
