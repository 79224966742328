import {
    FETCHING_DOWNLOADS,
    RECEIVED_DOWNLOADS,
    RECEIVED_DOWNLOAD_TYPES,
    DOWNLOAD_CHANGE_TYPE,
} 
from '../../actions/analysis/analysis.actionTypes';

const downloadsReducer = (state = {
        isFetchingDownloads: false,
        availableDownloads: null,
    }, action) => {
    switch (action.type) {
    case FETCHING_DOWNLOADS:
        return Object.assign({}, state, { 
            isFetchingDownloads: true 
        });
    case RECEIVED_DOWNLOADS:
        return Object.assign({}, state, {
            isFetchingDownloads: false,
            availableDownloads: action.data,
        });
    case RECEIVED_DOWNLOAD_TYPES:
        return Object.assign({}, state, {
            availableDownloadTypes : action.data,
            // selectedDownloadType : selectedDownloadType ? selectedDownloadType : action.data && action.data.length > 0 ? action.data[0].id : null,
        });
    case DOWNLOAD_CHANGE_TYPE:
        return Object.assign({}, state, {
            selectedDownloadType : action.downloadType,
        });

    default:
        return state;
    }
};

export default downloadsReducer;
