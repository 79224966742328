import {
    FETCHING_JOB_STATUS,
    RECEIVED_JOB_STATUS,
    CLEAR_JOB_STATUS,
} from './jobStatus.actionTypes';

import * as jobStatusServiceApi from '../serviceApi/jobStatus.serviceApi';

function fetchingJobStatus(tag) {
    return {
        type: FETCHING_JOB_STATUS,
        tag: tag,
    };
}

function receivedJobStatus(json) {
    return {
      type: RECEIVED_JOB_STATUS,
      jobStatus: json,
    };
}

function clearJobStatus(tag) {
    return {
      type: CLEAR_JOB_STATUS,
      tag: tag,
    };
}

function fetchJobStatus(userKey, jobId, tag) {
    return dispatch => {
      dispatch(fetchingJobStatus(tag));
      return jobStatusServiceApi
        .retrieveJobStatus(userKey, jobId, tag)
        .then(json => dispatch(receivedJobStatus(json)));
    };
}

export {
    fetchJobStatus,
    receivedJobStatus,
    clearJobStatus,
  };