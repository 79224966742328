import { RECEIVED_MESSAGE_FROM_SERVER, CLEARING_SERVER_NOTIFICATIONS } from './actionTypes';

function receivedMessageFromServer(notification) {
  return {
    type: RECEIVED_MESSAGE_FROM_SERVER,
    receivedAt: Date.now(),
    subject: notification.subject,
    message: notification.message,
    data: notification.data,
  };
}

function clearServerNotifications() {
  return {
    type: CLEARING_SERVER_NOTIFICATIONS,
  };
}

export {
  receivedMessageFromServer,
  clearServerNotifications,
};
