import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { clearErrorMessage } from '@app/actions/application.actions';
import { clearServerNotifications } from '@app/actions/serverNotification.actions';
import {
  fetchDatabaseMappings,
  fetchDatabase,
  loadData,
  cancelDataLoad,
  fetchDataMapper,
  fetchDataLoads,
  selectMapper,
  fetchDataLoadLogFile,
  deleteDataLoadLogFile,
  checkIfTodDataDeleting
} from '@app/actions/tod/todDatabases.actions';
import { fetchUploads, fetchColumnsForFile } from '@app/actions/tod/uploads.actions';
import DbLoader from '@app/tod/dbLoader';

class DbLoaderContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    uploads: PropTypes.array,
    mappings: PropTypes.array,
    csvColumns: PropTypes.array,
    currentMapper: PropTypes.object,
    csvIsFetching: PropTypes.bool,
    mapperIsFetching: PropTypes.bool,
    application: PropTypes.object.isRequired,
    getDatabaseMappings: PropTypes.func,
    getDatabase: PropTypes.func,
    getUploads: PropTypes.func,
    currentDb: PropTypes.object,
    performDataLoad: PropTypes.func,
    serverNotification: PropTypes.object,
    clearNotificationHandler: PropTypes.func,
    cancelDataLoadHandler: PropTypes.func,
    errorMessage: PropTypes.string,
    getCsvColumns: PropTypes.func,
    getMapperColumns: PropTypes.func,
    clearErrorMessageHandler: PropTypes.func,
    getDataLoads: PropTypes.func,
    dataLoads: PropTypes.array,
    selectMapperHandler: PropTypes.func,
    isFetchingDataLoads: PropTypes.bool,
    retrieveLogFile: PropTypes.func,
    deleteLogFileFromServer: PropTypes.func,
  };
  componentDidMount() {
    const {
      userKey,
      getDatabaseMappings,
      getDatabase,
      getUploads,
      getTodDeleteStatus,
      match,
     } = this.props;

    let params = match.params;
    getTodDeleteStatus(userKey, params.databaseId);
    getDatabase(userKey, params.databaseId);
    getDatabaseMappings(userKey, params.databaseId);
    getUploads(userKey);
  }

  render() {
    const {
      userKey,
      dispatch,
      currentDb,
      mappings,
      uploads,
      csvColumns,
      currentMapper,
      performDataLoad,
      serverNotification,
      clearNotificationHandler,
      cancelDataLoadHandler,
      errorMessage,
      getCsvColumns,
      getMapperColumns,
      clearErrorMessageHandler,
      csvIsFetching,
      mapperIsFetching,
      getDataLoads,
      dataLoads,
      selectMapperHandler,
      isFetchingDataLoads,
      retrieveLogFile,
      dataLoadLogFile,
      deleteLogFileFromServer,
      getDatabase,
      jobStatus,
      todDataDeleteInProgress
    } = this.props;
    const mapColumns = currentMapper ? currentMapper.columns.map(c => c.externalColumnName) : [];

    const childProps = {
          userKey,
          dispatch,
          currentDb,
          mappings,
          uploads,
          csvColumns,
          mapColumns,
          performDataLoad,
          serverNotification,
          clearNotificationHandler,
          cancelDataLoadHandler,
          errorMessage,
          getCsvColumns,
          getMapperColumns,
          clearErrorMessageHandler,
          csvIsFetching,
          mapperIsFetching,
          getDataLoads,
          dataLoads,
          selectMapperHandler,
          isFetchingDataLoads,
          retrieveLogFile,
          dataLoadLogFile,
          deleteLogFileFromServer,
          getDatabase,
          jobStatus,
          todDataDeleteInProgress
    };

    return (
    <Switch>
        <Route path="/tod/databases/:databaseId/loader" 
            render={props => <DbLoader {...{...childProps, params: props.match.params}}/>}/>
    </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  userKey: state.user.userKey,
  application: state.application,
  mappings: state.tod.databases.mappings.items,
  uploads: state.tod.uploads.items,
  currentDb: state.tod.databases.current,
  serverNotification: state.serverNotification,
  errorMessage: state.application.errorMessage,
  csvColumns: state.tod.uploads.columns,
  currentMapper: state.tod.databases.mappings.current,
  csvIsFetching: state.tod.uploads.isFetching,
  mapperIsFetching: state.tod.databases.isFetching,
  dataLoads: state.tod.databases.dataLoads,
  isFetchingDataLoads: state.tod.databases.isFetchingDataLoads,
  dataLoadLogFile: state.tod.databases.dataLoadLogFile,
  jobStatus: state.jobStatus,
  todDataDeleteInProgress: state.tod.databases.todDataDeleteInProgress
});

const mapDispatchToProps = (dispatch) => ({
  getDatabaseMappings: (userKey, databaseId) => {
    dispatch(fetchDatabaseMappings(userKey, databaseId));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  getTodDeleteStatus: (userKey, databaseId) => {
    dispatch(checkIfTodDataDeleting(userKey, databaseId));
  },
  getUploads: (userKey) => {
    dispatch(fetchUploads(userKey));
  },
  performDataLoad: ({ userKey, databaseId, mapperId, csvFileName, valuationDate, requestSuccessfulCallback }) => {
    dispatch(loadData({ userKey, databaseId, mapperId, csvFileName, valuationDate, requestSuccessfulCallback }));
  },
  clearNotificationHandler: () => {
    dispatch(clearServerNotifications());
  },
  cancelDataLoadHandler: (userKey, databaseId, successCallback = ()=>{}) => {
    dispatch(cancelDataLoad(userKey, databaseId, successCallback));
  },
  clearErrorMessageHandler: () => {
    dispatch(clearErrorMessage());
  },
  getCsvColumns: (userKey, fileId) => {
    dispatch(fetchColumnsForFile(userKey, fileId));
  },
  getMapperColumns: (userKey, databaseId, mapperId) => {
    dispatch(fetchDataMapper(userKey, databaseId, mapperId));
  },
  getDataLoads: (userKey, databaseId) => {
    dispatch(fetchDataLoads(userKey, databaseId));
  },
  selectMapperHandler: (mapper, databaseId) => {
    dispatch(selectMapper(mapper, databaseId));
  },
  retrieveLogFile: (userKey) => {
    dispatch(fetchDataLoadLogFile(userKey));
  },
  deleteLogFileFromServer: (userKey) => {
    dispatch(deleteDataLoadLogFile(userKey));
  },
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DbLoaderContainer);
