import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { clearErrorMessage } from '@app/actions/application.actions';
import { fetchUploads, deleteUploadedFile, uploadFile } from '@app/actions/analysis/uploads.actions';
import { createPermissionChecker, WORKSPACE_CREATEEDITEXTRACTTABLES, SITE_CREATEDATABASE } from '@app/utilities/permissions';
import UploadsList from '@app/analysis/uploadsList';
import { fetchWorkspacesIfNeeded } from '../../../actions/analysis/workspace.actions';

function checkWriteAccess(user, databases) {
  if (createPermissionChecker(user.userPermissions)(SITE_CREATEDATABASE)) {
    return true;
  }
  if (createPermissionChecker(user.userPermissions)(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
    return true;
  }
  const dbList = databases.items;
  if (dbList) {
    for (let i = 0; i < dbList.length; i++) {
      if (createPermissionChecker(dbList[i].permissions)(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
        return true;
      }
    }
  }
  return false;
}

class UploadsTab extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        userKey: PropTypes.string.isRequired,
        uploads: PropTypes.array.isRequired,
        isSaving: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        application: PropTypes.object.isRequired,
        getUploads: PropTypes.func,
        deleteFile: PropTypes.func,
        uploadNewFile: PropTypes.func,
        errorMessage: PropTypes.string,
        clearError: PropTypes.func,
        hasWriteAccess: PropTypes.bool,
    };

    componentDidMount() {
        const { userKey, getUploads, dispatch} = this.props;
        dispatch(fetchWorkspacesIfNeeded(userKey));
        getUploads(userKey);
    }

    render() {
        const {
            userKey,
            dispatch,
            uploads,
            deleteFile,
            uploadNewFile,
            isSaving,
            isFetching,
            errorMessage,
            clearError,
            hasWriteAccess,
            getUploads
        } = this.props;

        let childProps = {
            userKey,
            dispatch,
            uploads,
            deleteFile,
            uploadNewFile,
            isSaving,
            isFetching,
            hasWriteAccess,
            errorMessage,
            clearError,
            getUploads
        };

        //return (<div>UPLOADS</div>)
        return (
        <Switch>
            <Route path="/arius/uploads" 
                render={props => <UploadsList {...{...childProps, params: props.match.params}}/>}/>
        </Switch>
        )
    }
}

const mapStateToProps = (state) => ({
    userKey: state.user.userKey,
    application: state.application,
    uploads: state.analysis.uploads.items,
    isSaving: state.analysis.uploads.isSaving,
    isFetching: state.analysis.uploads.isFetching,
    errorMessage: state.application.errorMessage,
    hasWriteAccess: checkWriteAccess(state.user, state.analysis.workspaces),
});

const mapDispatchToProps = (dispatch) => ({
    getUploads: (userKey) => {
        dispatch(fetchUploads(userKey));
    },
    deleteFile: (userKey, fileId) => {
        dispatch(deleteUploadedFile(userKey, fileId));
    },
    uploadNewFile: (userKey, file) => {
        dispatch(uploadFile(userKey, file));
    },
    clearError: () => {
        dispatch(clearErrorMessage());
    },
    dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadsTab);
