import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import {
  fetchSubscriptionUsers,
  createSubscriptionUser,
  updateSubscriptonUser,
 } from '@app/actions/portalManagement/subscriptionUser.actions';
import { createPermissionChecker, SITE_MANAGEUSERS } from '@app/utilities/permissions';
import UsersList from '@app/portalManagement/usersList';
import UserAddUpdate from '@app/portalManagement/userAddUpdate';

class Users extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        users: PropTypes.object,
        userKey: PropTypes.string,
        application: PropTypes.object,
        newSaveHandler: PropTypes.func,
        editSaveHandler: PropTypes.func,
        groups: PropTypes.array,
        children: PropTypes.object,
        sitePermissions: PropTypes.array,
    };
    componentDidMount() {
        const { dispatch, userKey } = this.props;
        dispatch(fetchSubscriptionUsers(userKey));
    }

    componentDidUpdate() {}

    render() {
        const {
            userKey,
            newSaveHandler,
            editSaveHandler,
            dispatch,
            users,
            groups,
            sitePermissions,
            history,
            isFetching
        } = this.props;

        const verifyPermissions = createPermissionChecker(sitePermissions);

        let childProps = {
            browserHistory: history,
            userKey,
            dispatch,
            newSaveHandler,
            editSaveHandler,
            users,
            groups,
            isFetching,
            readOnly: !verifyPermissions(SITE_MANAGEUSERS),
        };

        return (
            <Switch>
                <Route path="/portalManagement/users/edit/:userId" 
                    render={props => <UserAddUpdate {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/portalManagement/users/edit" 
                    render={props => <UserAddUpdate {...{...childProps, params: props.match.params}}/>}/>
                <Route path="/portalManagement/users" render={props => (<UsersList {...childProps}/>)}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.portalManagement.users,
    userKey: state.user.userKey,
    router: state.router,
    route: state.route,
    groups: state.portalManagement.groups.items,
    application: state.application,
    sitePermissions: state.user.userPermissions,
    isFetching: state.portalManagement.users.isFetching,
    
});

const mapDispatchToProps = (dispatch) => ({
    newSaveHandler: ({ userKey, description, email, name, roles, groups, isActive, browserHistory }) => {
        dispatch(createSubscriptionUser({ userKey, description, email, name, roles, groups, isActive, browserHistory }));
    },
    editSaveHandler: ({ userId, userKey, description, email, name, roles, groups, isActive, browserHistory }) => {
        dispatch(updateSubscriptonUser({ userId, userKey, description, email, name, roles, groups, isActive, browserHistory }));
    },
    dispatch,
});

export default connect(mapStateToProps,mapDispatchToProps)(Users);
