import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';

const style = {
    border: '1px dashed gray',
    marginBottom: '5px',
    backgroundColor: 'white',
    cursor: 'move',
    padding: '2px 10px',
  },
  cardSource = {
    beginDrag(props) {
      return {
        index: props.index,
        id: props.id,
      };
    },
  },
  cardTarget = {
    hover(props, monitor, component) {
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect(),
        hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
        clientOffset = monitor.getClientOffset(),
        hoverClientY = clientOffset.y - hoverBoundingRect.top,
        container = document.getElementById('draggableListContainer'),
        containerBoundingRect = container.getBoundingClientRect();

      if (clientOffset.y + 20 > containerBoundingRect.bottom) {
        container.scrollTop--;
      } else if (clientOffset.y - 20 < containerBoundingRect.top) {
        container.scrollTop++;
      }

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);
      monitor.getItem().index = hoverIndex;
    },
  },
  cardConstant = 'card';

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function dropCard(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

class DraggableCommand extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveCard: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.object,
    optionalParams: PropTypes.object,
    ieSpecificCSS: PropTypes.object,
  };
  render() {
    const {
      isDragging,
      connectDragSource,
      text,
      connectDropTarget,
      onClick,
      selected,
      optionalParams,
      id,
      ieSpecificCSS,
    } = this.props,
      opacity = isDragging ? 0 : 1;
    return connectDragSource(
      connectDropTarget(
        <div
          id={id}
          style={{ ...style, ...ieSpecificCSS, ...selected, opacity }}
          onClick={onClick}
        >
          {text}{optionalParams}
        </div>
      )
    );
  }
}

export default flow([
  DragSource(cardConstant, cardSource, collect),
  DropTarget(cardConstant, cardTarget, dropCard),
])(DraggableCommand);
