import { callApi, makeApiCall } from '../utilities/callApi';

export function retrieveCurrentUser(accessToken) {
  let header = null;
  if (accessToken) {
    header = { Authorization: `Bearer ${accessToken}` };
    return makeApiCall(
      '/currentuser',
        header
    );
  } else {
    return callApi(
      '/currentuser',
        header
    );
  }
}

export function retrieveSubscriptionUsers(accessToken) {
  let header = null;
  if (accessToken) {
    header = { Authorization: `Bearer ${accessToken}` };
  }

  return callApi(
    '/users',
    header
  );
}

export function retrieveSubscriptionUser(userKey, userId) {
  return callApi(
    `/users/${userId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function updateSubscriptonUser({ userKey, userId, description, email, name, memberTypeId, isActive, roles, groups }) {
  return callApi(
    `/users`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    {
      userId,
      description,
      email,
      name,
      memberTypeId,
      isActive,
      roles,
      groups,
    }
  );
}

export function createSubscriptionUser({ userKey, description, email, name, memberTypeId, isActive, roles, groups }) {
  return callApi(
    '/users',
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    {
      description,
      email,
      name,
      memberTypeId,
      isActive,
      roles,
      groups,
    }
  );
}

export function deleteSubscriptionUser({ userKey, userId }) {
  return callApi(
    `/users/${userId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}
