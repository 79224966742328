import * as roleApi from '../../serviceApi/roles.serviceApi';
import { notifySuccess } from '../../utilities/notifier';
import { fetchCurrentUser } from '@app/actions/user.actions';
import { fetchWorkspace } from '@app/actions/analysis/workspace.actions';
import {
  RECEIVED_ASSIGNED_ROLES,
  FETCHING_ASSIGNED_ROLES,
  RECEIVED_ROLE_DESCRIPTIONS,
  FETCHING_ROLE_DESCRIPTIONS,
  RECEIVED_AVAILABLE_PERMISSIONS,
  FETCHING_AVAILABLE_PERMISSIONS,
  RECEIVED_AVAILABLE_USERS,
  RECEIVED_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_USERS,
  CLEAR_AVAILABLE_PERMISSIONS,
  UPDATED_USER_ROLE,
  RECEIVED_ROLE_DESCRIPTION,
  ASSIGNING_ROLES,
  CLEAR_ROLE_DESCRIPTION,
} from './portalManagement.actionTypes';

const availableUsersData = [
  {
    name: 'Ari',
  },
  {
    name: 'Charles',
  },
  {
    name: 'Don',
  },
  {
    name: 'Mike',
  },
  {
    name: 'Nathalie',
  },
  {
    name: 'Paul',
  },
  ],
  availableResourcesData = [
    {
      value: '0',
      label: 'Site',
    },
    {
      value: '1',
      label: 'TOD DB1',
    },
    {
      value: '2',
      label: 'TOD DB2',
    },
    {
      value: '3',
      label: 'AA DB1',
    },
    {
      value: '4',
      label: 'AA DB2',
    },
  ];

function receivedAssignedRoles(json) {
  return {
    type: RECEIVED_ASSIGNED_ROLES,
    roles: json,
    receivedAt: Date.now(),
  };
}

function receivedRoleDescriptions(json) {
  return {
    type: RECEIVED_ROLE_DESCRIPTIONS,
    roles: json,
    receivedAt: Date.now(),
  };
}

function receivedAvailablePermissions(json) {
  return {
    type: RECEIVED_AVAILABLE_PERMISSIONS,
    permissions: json,
    receivedAt: Date.now(),
  };
}

function receivedAvailableUsersData(json) {
  return {
    type: RECEIVED_AVAILABLE_USERS,
    users: json,
    receivedAt: Date.now(),
  };
}

function receivedRolePermissions(roleId, permissions) {
  return {
    type: RECEIVED_ROLE_DESCRIPTION,
    roleId,
    permissions,
    receivedAt: Date.now(),
  };
}

function clearRolePermissions() {
  return {
    type: CLEAR_ROLE_DESCRIPTION,
    receivedAt: Date.now(),
  };
}

function receivedAvailableResourcesData(json) {
  return {
    type: RECEIVED_AVAILABLE_RESOURCES,
    resources: json,
    receivedAt: Date.now(),
  };
}

function fetchingAvailablePermissionsFromServer() {
  return {
    type: FETCHING_AVAILABLE_PERMISSIONS,
  };
}

function fetchingAssignedRolesFromServer() {
  return {
    type: FETCHING_ASSIGNED_ROLES,
  };
}

function fetchingRoleDescriptionsFromServer() {
  return {
    type: FETCHING_ROLE_DESCRIPTIONS,
  };
}

function assigningRoles() {
  return {
    type: ASSIGNING_ROLES,
  };
}

function fetchingAvailableUsersFromServer() {
  return {
    type: FETCHING_AVAILABLE_USERS,
  };
}

function fetchingAvailableResourcesFromServer() {
  return {
    type: FETCHING_AVAILABLE_RESOURCES,
  };
}

function fetchAvailablePermissions() {
  return dispatch => {
    dispatch(fetchingAvailablePermissionsFromServer());

    return roleApi
      .retrieveAriusPermissions()
      .then(json => dispatch(receivedAvailablePermissions(json)));
    // return dispatch(receivedAvailablePermissions(availablePermissions[value]));
  };
}

function fetchAvailablePermissionsForRole(roleId) {
  return dispatch => {
    dispatch(fetchingAvailablePermissionsFromServer());

    return roleApi
      .retrieveAriusPermissionsForRole(roleId)
      .then(permissions =>
        dispatch(receivedRolePermissions(roleId, permissions)));
  };
}

function clearPermissions() {
  return {
    type: CLEAR_AVAILABLE_PERMISSIONS,
  };
}

function fetchAvailableUsers() {
  return dispatch => {
    dispatch(fetchingAvailableUsersFromServer());

    // return groupServiceApi
    //         .retrieveGroups(userKey)
    //         .then(json => dispatch(receivedGroups(json)));
    return dispatch(receivedAvailableUsersData(availableUsersData));
  };
}

function fetchAvailableResources() {
  return dispatch => {
    dispatch(fetchingAvailableResourcesFromServer());

    // return groupServiceApi
    //         .retrieveGroups(userKey)
    //         .then(json => dispatch(receivedGroups(json)));
    return dispatch(receivedAvailableResourcesData(availableResourcesData));
  };
}

function fetchAssignedRoles() {
  return dispatch => {
    dispatch(fetchingAssignedRolesFromServer());

    return roleApi
      .retrieveRoleAssignments()
      .then(json => dispatch(receivedAssignedRoles(json)));
  };
}

function fetchRoleDescriptions(userKey) {
  return dispatch => {
    dispatch(fetchingRoleDescriptionsFromServer());

    return roleApi
      .retrieveAriusRoles(userKey)
      .then(json => dispatch(receivedRoleDescriptions(json)));
  };
}

function createRole({ userKey, roleId, name, permissions, description, browserHistory }) {
  return dispatch =>
  // dispatch(savingToServer());
    roleApi
      .createRole({ userKey, roleId, name, permissions, description })
      .then(() => dispatch(fetchRoleDescriptions(userKey)))
      .then(() => browserHistory.push('/portalManagement/roles'));
}

function assignRoles({ userKey, users, roles, site, databases, operation, currentWorkspace }) {
  return dispatch => {
    dispatch(assigningRoles());
    return roleApi
      .assignRoles({ userKey, users, roles, site, databases, operation })
      .then(res => {
        if (operation === 'revoke' && typeof res === 'object' && res) {
          notifySuccess('Revocation complete');
        } else if (typeof res === 'object' && res) {
          notifySuccess('Assignment complete');
        }
        dispatch(fetchAssignedRoles());
        dispatch(fetchCurrentUser());
        if (currentWorkspace && currentWorkspace.id){
          dispatch(fetchWorkspace(userKey, currentWorkspace.id));
        }
      });
  };
}

function updatedUserRoles() {
  return {
    type: UPDATED_USER_ROLE,
  };
}

function updateUserRole(userKey, roleId, roleInformation, browserHistory) {
  return dispatch =>
  // dispatch(savingToServer());
    roleApi
      .updateUserRole(userKey, roleId, roleInformation)
      .then(() => dispatch(updatedUserRoles()))
      .then(() => dispatch(fetchRoleDescriptions(userKey)))
      .then(() => browserHistory.push('/portalManagement/roles'));
}

function deleteRole(userKey, roleId) {
  return dispatch => {
    roleApi
      .deleteRole(userKey, roleId)
      .then(() => dispatch(fetchRoleDescriptions(userKey)));
  };
}

export {
  assignRoles,
  clearPermissions,
  clearRolePermissions,
  deleteRole,
  createRole,
  fetchAssignedRoles,
  fetchRoleDescriptions,
  fetchAvailablePermissions,
  fetchAvailableUsers,
  fetchAvailableResources,
  fetchAvailablePermissionsForRole,
  updateUserRole,
};
