import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import * as api from '@app/api/projects.serviceApi';
import * as Colors from '@app/utilities/colors';
import { Button } from '@arius';
import PurpleButton from '@app/shared/presentational/purpleButton';
import Loader from '@app/shared/presentational/loader';
import {
    WORKSPACE_DELETEPROJECT, MESSAGE_NOT_AUTHORIZED_TO_ACCESS
} from '../../../utilities/permissions';
import { notifyError } from '../../../utilities/notifier';

class ProjectBulkDeleteEditor extends Component {
    static propTypes = {
        databaseId: PropTypes.number,
        projectIds: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = { 
            isWaiting: false,
            projectBulkDeleteRequest: {}
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.launchModal = this.launchModal.bind(this);
    }
    
    save() {
        const { currentWorkspace, userKey, refreshProjectsHandler }=this.props;
        let req = this.state.projectBulkDeleteRequest;
        let databaseId = currentWorkspace ? currentWorkspace.id : -1;
        api.processProjectBulkDeleteRequest(userKey, databaseId, req).then(
            (data) => {
                this.setState({ isWaiting: false });
                refreshProjectsHandler();
                this.handleClose();
            });
        this.setState({ isWaiting: true });
    }

    handleOpen(){
        const {currentWorkspace, verifyPermission, selectedProjectIds, userKey}=this.props;
        if (!verifyPermission(WORKSPACE_DELETEPROJECT)){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        let databaseId = currentWorkspace ? currentWorkspace.id : -1;
        api.getProjectBulkDeleteRequest(userKey, databaseId, selectedProjectIds).then(
            (data) => {
                this.setState({ isWaiting: false, projectBulkDeleteRequest: data });
            }
        )
        this.setState({ isWaiting: true, projectBulkDeleteRequest: {} });
    }

    handleClose(){
        this.setState({show: false});
    }

    launchModal(){
        const { canDelete }=this.props;

        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
        const {projectBulkDeleteRequest}=this.props;

        if (projectBulkDeleteRequest === 'success'){
            this.handleClose();
        }
    }

    getValidationMessageMarkup(validationMessage) {
        let parts = validationMessage.split("|");
        return parts.length > 1 ? (
            <span style={{fontWeight: 'bold'}}>
                { parts.map((x,i) => (
                    <div key={i} style={{marginBottom: 5, wordWrap: 'break-word'}}>{x}</div>
                ))}
            </span>
        ) : <span style={{fontWeight: 'bold'}}>{validationMessage}</span>
    }
  
    render() {

        const { show, isWaiting, projectBulkDeleteRequest} = this.state;
        const { selectedProjectIds } = this.props;
        let validationMessage = projectBulkDeleteRequest ? projectBulkDeleteRequest.validationMessage : '';
        let isValid = !validationMessage || validationMessage === '';

        const msgStyle = {
            fontWeight: 'bold',
        };

        let n = selectedProjectIds.length;
        let form = !isValid ? (this.getValidationMessageMarkup(validationMessage)) : (
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{marginBottom: '10px'}}>
                        <span style={msgStyle}>{`${n} project${n > 1 ? 's' : ''} selected.`}</span>
                    </div>
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        <span style={msgStyle}>{`Are you sure you want to delete selected project${n > 1 ? 's' : ''}?`}</span>
                    </div>
                </fieldset> 
            </div>            
        );

        let body = isWaiting ? (<Loader loadingItem=" status information" />) : form;

        let buttons = isValid ? (              
            [
                <Button
                    key="confirmButton"
                    className={'dialog-button btn-danger'}
                    variant='arius'
                    onClick={this.save}>Yes</Button>,
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>No</Button>
            ]) : (
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>Ok</Button>
            );

        return (
            <span>
                <PurpleButton 
                    id="launch" 
                    clickHandler={this.launchModal}
                    message="Delete Selected Projects" 
                    type="delete">
                </PurpleButton>
                <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>Delete selected projects</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{buttons}</Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }
    
    export default radium(ProjectBulkDeleteEditor);
