import {
    RECEIVED_ANALYTICS_UPLOADS,
    FETCHING_ANALYTICS_UPLOADS,
    FETCHING_ANALYTICS_RESULTS,
    RECEIVED_ANALYTICS_RESULTS,
    RECEIVED_ANALYTICS_PARAMS,
    REQUESTING_ANALYTICS_UPLOAD,
    RECEIVED_ANALYTICS_UPLOAD_RESPONSE,
    SAVING_ANALYTICS_UPLOADS,
    FETCHING_ANALYTICS_MODELS,
    RECEIVED_ANALYTICS_MODELS,
    MODEL_SELECTED
} from '../actions/analytics/analytics.actionTypes';

const advAnalytics = (state = {
    isFetching: false,
    isSaving: false,
    isRequestingUpload: false,
    selectedModel: '',
    uploads: [],
    results:[],
    models: [],
    params: []
}, action) => {
    switch (action.type) {
        case MODEL_SELECTED:
            return Object.assign({}, state, { selectedModel: action.selectedModel });
        case REQUESTING_ANALYTICS_UPLOAD: 
            return Object.assign({}, state, { isRequestingUpload: true });
        case RECEIVED_ANALYTICS_UPLOAD_RESPONSE: 
            return Object.assign({}, state, { isRequestingUpload: false });
        case FETCHING_ANALYTICS_UPLOADS:
        case FETCHING_ANALYTICS_RESULTS:
        case FETCHING_ANALYTICS_MODELS:
            return Object.assign({}, state, { isFetching: true });
        case SAVING_ANALYTICS_UPLOADS:
            return Object.assign({}, state, { isSaving: true });
        case RECEIVED_ANALYTICS_MODELS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                models: action.items,
            });
        case RECEIVED_ANALYTICS_UPLOADS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                uploads: action.items,
                receivedAt: action.receivedAt,
            });
        case RECEIVED_ANALYTICS_RESULTS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                results: action.items,
                receivedAt: action.receivedAt,
            });
        case RECEIVED_ANALYTICS_PARAMS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                params: action.params,
                receivedAt: action.receivedAt,
            });
        default:
            return state;
    }
};

export default advAnalytics;
