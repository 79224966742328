import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { deleteWorkspace, reloadExtractTable } from '../../../actions/analysis/workspace.actions';
import { showModal } from '../../../actions/modal.actions';
import DetailRow from './workspaceDetailRow';
import WorkspaceCreateModal from './workspaceCreateModal';
import WorkspaceBackupModal
  from '../../shared/presentational/databaseBackup/databaseBackupModal';
import UdoLibraryModal from './udoLibraryModal';
import * as Colors from '../../../utilities/colors';
import { notifyError } from '../../../utilities/notifier';
import { makeClone } from '../../../utilities/helpers';
import { saveAs } from 'file-saver';
import {
  createPermissionChecker,
  SITE_CREATEDATABASE,
} from '../../../utilities/permissions';
import ActionItems from './workspaceActions';
import Loader from '../../shared/presentational/loader';
import NoResults from '../../shared/presentational/noResults';
import { Grid, Button } from '@arius';

class WorkspaceList extends Component {
    static propTypes = {
        systemDimensions: PropTypes.arrayOf(PropTypes.string),
        newSaveHandler: PropTypes.func,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        workspaces: PropTypes.arrayOf(
            PropTypes.shape({
                workspaceId: PropTypes.number,
                workspaceName: PropTypes.string,
                workspaceDescription: PropTypes.string,
                workspaceDimensions: PropTypes.string,
                workspaceCreateBy: PropTypes.string,
                numOfProjects: PropTypes.number,
            }).isRequired
        ),
        dispatch: PropTypes.func,
        clearNotificationHandler: PropTypes.func,
        getBackups: PropTypes.func,
        getPointInTimeRestoreRange: PropTypes.func,
        getAllArchived: PropTypes.func,
        createBackupHandler: PropTypes.func,
        deleteBackupHandler: PropTypes.func,
        restoreBackupHandler: PropTypes.func,
        restorePointInTimeHandler: PropTypes.func,
        deleteArchivedBackupHandler: PropTypes.func,
        archiveBackupHandler: PropTypes.func,
        restoreArchivedBackupHandler: PropTypes.func,
        archivedBackups: PropTypes.array,
        getWorkspaces: PropTypes.func,
        getTodDatabases: PropTypes.func,
        selectWorkspaceHandler: PropTypes.func,
        serverNotification: PropTypes.object,
        backups: PropTypes.array,
        current: PropTypes.object,
        getDatabaseUdoVersions: PropTypes.func,
        getDatabaseUdoLibrarylockstatus: PropTypes.func,
        databaseUdoVersions: PropTypes.array,
        UdoLibrarylockstatus:PropTypes.bool.isRequired,
        clearDatabaseUdoVersions: PropTypes.func,
        userPermissions: PropTypes.array,
        csvExtractFile: PropTypes.object,
        exportExtractToCsvHandler: PropTypes.func,
        isExtractToCsvProcessing: PropTypes.bool,

        retrieveLogFile: PropTypes.func,
        deleteLogFileFromServer: PropTypes.func,
        logFile: PropTypes.object,
    };
    static defaultProps = {
        isFetching: false,
        workspaces: [],
        systemDimensions: [],
        newSaveHandler: () => {},
        userKey: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            workspaceCreateModalOpen: false,
            workspaceBackupModalOpen: false,
            udoLibraryModalOpen: false,
            alreadyFetched: false,
            udoWorkspace: null,
            workspaces: [],
            dbToUpdateId: null,
            dbModalOpen: false,
        };
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.closeWorkspaceCreateModal = this.closeWorkspaceCreateModal.bind(this);
        this.closeWorkspaceBackupModal = this.closeWorkspaceBackupModal.bind(this);
        this.viewWorkSpaceHandler = this.viewWorkSpaceHandler.bind(this);
        this.openWorkspaceBackupModal = this.openWorkspaceBackupModal.bind(this);
        this.openWorkspaceCreateModal = this.openWorkspaceCreateModal.bind(this);
        this.openUdoLibraryModal = this.openUdoLibraryModal.bind(this);
        this.closeUdoLibraryModal = this.closeUdoLibraryModal.bind(this);
        this.editClickHandler = this.editClickHandler.bind(this);
        this.startFullExtraction = this.startFullExtraction.bind(this);
        this.openDatabaseUpdateModal = this.openDatabaseUpdateModal.bind(this);
        this.closeDbUpdateModal = this.closeDbUpdateModal.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
    }

    componentWillMount() {
        const { workspaces, userPermissions } = this.props;
        if (workspaces && workspaces.length) {
            this.setState({ alreadyFetched: true, workspaces: makeClone(workspaces)});
        }
        if (userPermissions) {
            const verifyPermission = createPermissionChecker(userPermissions);
            this.setState({ verifyPermission });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { workspaces, userPermissions } = this.props;
        if (workspaces !== nextProps.workspaces) {
            this.setState({ alreadyFetched: true, workspaces: makeClone(nextProps.workspaces) });
        }
        if (userPermissions && userPermissions !== nextProps.userPermissions) {
            const verifyPermission = createPermissionChecker(nextProps.userPermissions);
            this.setState({ verifyPermission });
        }
    }

    componentDidUpdate(prevProps) {
        const { csvExtractFile } = this.props;

        if (csvExtractFile) {
            if (prevProps.csvExtractFile !== csvExtractFile) {
                saveAs(csvExtractFile, 'ExportExtractData.csv');
            }
        }
    }

    deleteClickHandler(id, e) {
        e.stopPropagation();

        const { dispatch, workspaces } = this.props;

        if (workspaces.length !== 0) {
            const selectedWorkspace = workspaces.find(ws => ws.workspaceId === id),
                deleteMessageItems = [
                <li key={selectedWorkspace.workspaceId} style={{ fontWeight: 800 }}>
                    {selectedWorkspace.workspaceName}
                </li>,
                ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following workspaces?'
            );

            const yesClickHandler = () => {
                const { userKey } = this.props;
                dispatch(deleteWorkspace(userKey, id));
            };
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    openWorkspaceCreateModal() {
        this.setState({ workspaceCreateModalOpen: true });
    }

    openWorkspaceBackupModal(id) {
        const { workspaces } = this.state,
        selectedWorkspace = workspaces.find(ws => ws.workspaceId === id);
        this.props.selectWorkspaceHandler(selectedWorkspace);
        this.setState({ workspaceBackupModalOpen: true });
    }

    openUdoLibraryModal(id) {
        const { userKey, workspaces, getDatabaseUdoVersions,getDatabaseUdoLibrarylockstatus } = this.props;
        const selectedWorkspace = workspaces.find(ws => ws.workspaceId === id);
    
        getDatabaseUdoVersions(userKey, id);
        getDatabaseUdoLibrarylockstatus(userKey, id);
        this.setState({ udoLibraryModalOpen: true, udoWorkspace: selectedWorkspace });
    
    }

    closeWorkspaceCreateModal() {
        this.setState({ workspaceCreateModalOpen: false, udoWorkspace: null });
    }

    closeWorkspaceBackupModal() {
        this.setState({ workspaceBackupModalOpen: false });
    }

    closeUdoLibraryModal() {
        const { clearDatabaseUdoVersions } = this.props;
        clearDatabaseUdoVersions();
        this.setState({ udoLibraryModalOpen: false });
    }

    viewWorkSpaceHandler(id, e) {
        e.stopPropagation();
        const { workspaces, browserHistory } = this.props,
        workspace = workspaces.find(ws => ws.workspaceId === id);

        this.props.selectWorkspaceHandler(workspace);
        browserHistory.push(`/arius/analysis/workspaces/${id}/projects`);
    }

    editClickHandler(id) {
        this.props.browserHistory.push(`/arius/databases/${id}/edit`);
    }

    startFullExtraction(databaseId) {
        const { userKey, dispatch } = this.props;
        dispatch(reloadExtractTable(userKey, databaseId));
    }

    evalInput() {
        const input = document.getElementById('db-update-input-file');
        if (input) {
            input.click();
            clearInterval(this.watchForIt);
        } 
    }

    watchForInput() {
        this.watchForIt = setInterval(() => this.evalInput(), 200);
    }

    openDatabaseUpdateModal(id) {
        this.setState({ dbModalOpen: true, dbToUpdateId: id }, () => this.watchForInput());
    }

    closeDbUpdateModal() {
        this.setState({ dbModalOpen: false, dbToUpdateId: null });
    }

    
    getActionItems(workspace) {
        return <ActionItems 
            id={workspace.workspaceId}
            isOnline={workspace.isOnline}
            openBackupModalHandler={this.openWorkspaceBackupModal}
            openUdoLibraryModalHandler={this.openUdoLibraryModal}
            viewWorkSpaceHandler={this.viewWorkSpaceHandler}
            deleteClickHandler={this.deleteClickHandler}
            verifyPermission={createPermissionChecker(workspace.permissions)}
            browserHistory = {this.props.browserHistory}
        />;
    }

    getGrid(data) {

        const columns = [
            { field: 'workspaceName', headerText: 'Name', template: (database) => {
                return <span>{database.workspaceName} {database.isOnline ? '' : ' (Offline)'}</span>
            }},
            { field: 'workspaceDescription', headerText: 'Description'},
            { field: 'createdBy', headerText: 'Created By'},
            { field: 'createdDate', headerText: 'Created'},
            { headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
                columns={columns}
                data={data}
                height='calc(100vh - 180px)' 
                detailTemplate={this.getDetailTemplate}
            />
    }

    getDetailTemplate(workspace) {
        return <DetailRow 
            verifyPermission={createPermissionChecker(workspace.permissions)}
            workspace = {workspace}
        />;
    }

    render() {
        const {
            isFetching,
            newSaveHandler,
            userKey,
            systemDimensions,
            serverNotification,
            clearNotificationHandler,
            getBackups,
            getPointInTimeRestoreRange,
            current,
            createBackupHandler,
            deleteBackupHandler,
            restoreBackupHandler,
            restorePointInTimeHandler,
            undoPITRHandler,
            deleteArchivedBackupHandler,
            archiveBackupHandler,
            restoreArchivedBackupHandler,
            getWorkspaces,
            databaseUdoVersions,
            UdoLibrarylockstatus,
            userPermissions,
            isExtractToCsvProcessing,
            dispatch,
            retrieveLogFile,
            deleteLogFileFromServer,
            logFile,
            jobStatus,
        } = this.props,
        { 
            alreadyFetched,
            udoWorkspace,
            verifyPermission,
            workspaces,
        } = this.state;
        const newModalRef = 'newModal',
        backupModalRef = 'backupModal',
        udoModalRef = 'udoModal';
        let headerMarkup = (
        <div className="list-header-arius">
            <h4>Manage Arius Databases</h4>
            {verifyPermission(SITE_CREATEDATABASE)
            ? <div styles={{ display: 'flex' }}>
                {isExtractToCsvProcessing
                    ? <i
                    className="fa fa-cog fa-spin fa-fw"
                    style={{
                        fontSize: '4.5vmin',
                        color: Colors.purple,
                        cursor: 'pointer',
                    }}
                    />
                    : <i
                    className="fa fa-cog fa-spin fa-fw"
                    style={{ fontSize: '4.5vmin', visibility: 'hidden' }}
                    />}
                <Button
                    path="/arius/workspaces/new"
                    toolTip={"Add new Database"}
                    mode="add"/>
                </div>
            : <div
                onClick={() =>
                    notifyError(
                    'You are not authorized to access this functionality'
                    )}>
                <Button toolTip={"Add new Database"} mode="add"/>
                </div>}
        </div>
        );

        let contentMarkup = (
        <div style={{ width: '75vw', marginTop: '5vh auto' }}>
            <Loader loadingItem="Databases" />
        </div>
        );



        if (!isFetching && alreadyFetched) {
            if (workspaces && workspaces.length === 0) {
                contentMarkup = (
                <div style={{ width: '75vw', marginTop: '5vh auto' }}>
                    <NoResults />
                </div>
                );
            } else {
                contentMarkup = this.getGrid(workspaces);
            }
        }

        return (
        <div className="list-container-arius">
            {headerMarkup}
            {contentMarkup}
            <WorkspaceCreateModal
                systemDimensions={systemDimensions}
                saveHandler={newSaveHandler}
                userKey={userKey}
                modalId={newModalRef}
                showModal={this.state.workspaceCreateModalOpen}
                closeHandler={this.closeWorkspaceCreateModal}
                serverNotification={serverNotification}
                clearNotificationHandler={clearNotificationHandler}
            />
            <WorkspaceBackupModal
                userKey={userKey}
                modalId={backupModalRef}
                showModal={this.state.workspaceBackupModalOpen}
                database={current}
                closeHandler={this.closeWorkspaceBackupModal}
                serverNotification={serverNotification}
                clearNotificationHandler={clearNotificationHandler}
                getBackupsHandler={getBackups}
                getRestorePointInTimeRangeHandler={getPointInTimeRestoreRange}
                createBackupHandler={createBackupHandler}
                earliestRestorePoint={current ? current.earliestRestorePoint : ''}
                latestRestorePoint={current ? current.latestRestorePoint : ''}
                availableBackups={current ? current.backups : []}
                archivedBackups={current ? current.archived : []}
                deleteBackupHandler={deleteBackupHandler}
                restoreBackupHandler={restoreBackupHandler}
                restorePointInTimeHandler={restorePointInTimeHandler}
                undoPITRHandler={undoPITRHandler}
                restoreArchivedHandler={restoreArchivedBackupHandler}
                archiveBackupHandler={archiveBackupHandler}
                deleteArchivedHandler={deleteArchivedBackupHandler}
                getDatabases={getWorkspaces}
                retrieveLogFile={retrieveLogFile}
                deleteLogFileFromServer={deleteLogFileFromServer}
                logFile={logFile}
                jobStatus={jobStatus}
                jobStatusPrefix = {'AE'}
            />
            <UdoLibraryModal
                userKey={userKey}
                dispatch={dispatch}
                database={udoWorkspace}
                modalId={udoModalRef}
                showModal={this.state.udoLibraryModalOpen}
                closeHandler={this.closeUdoLibraryModal}
                modalTitle={
                    this.state.udoLibraryModalOpen
                    ? this.state.udoWorkspace.workspaceName
                    : ''
                }
                lockStatus={UdoLibrarylockstatus}
                udoVersions={databaseUdoVersions}
                Permissions={userPermissions}
            />
        </div>
        );
    }
}

export default radium(WorkspaceList);
