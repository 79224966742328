import { callApi, callApiPassingErrors } from '../utilities/callApi';

export function retrieveWorkspaces(userKey) {
  return callApi('/v1/aedatabases', { 'X-AE-USERKEY': userKey });
}

export function retrieveWorkspace(userKey, databaseId) {
  return callApi(`/v1/aedatabases/${databaseId}`, { 'X-AE-USERKEY': userKey });
}

// export function createWorkspace(userKey, workspaceName, workspaceDescription, workspaceDimensions) {
//   return callApi(
//     '/v1/aedatabases',
//     { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
//     'POST',
//     {
//       workspaceName,
//       workspaceDescription,
//       workspaceDimensions,
//       createdBy: 'portal-user',
//     }
//   );
// }

export function createWorkspace({
  userKey,
  createdBy,
  updatedBy,
  workspaceName,
  workspaceId,
  workspaceDescription,
  workspaceDimensions,
}) {
  return callApi(
    '/workspaces',
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    {
      updatedBy,
      workspaceName,
      workspaceId,
      workspaceDescription,
      workspaceDimensions,
      createdBy: createdBy || 'portal-user',
    }
  );
}

export function updateWorkspace({
  userKey,
  createdBy,
  updatedBy,
  workspaceName,
  workspaceId,
  workspaceDescription,
  workspaceDimensions,
}) {
  return callApi(
    `/workspaces/${workspaceId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    {
      updatedBy,
      workspaceName,
      workspaceId,
      workspaceDescription,
      workspaceDimensions,
      createdBy: createdBy || 'portal-user',
    }
  );
}

export function updateUDOComment({ userKey, databaseId, version, comment }) {
  let queryComment = '';
  if (comment) {
    queryComment = `?comment=${encodeURI(comment)}`;
  }
  return callApi(
    `/integration/udodefinition/${databaseId}/${version}${queryComment}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
  );
}

export function deleteWorkspace(userKey, databaseId) {
  return callApi(
    `/v1/aedatabases/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function retrieveBackups(userKey, databaseId) {
  return callApi(`/v1/aedatabases/${databaseId}/backups`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrievePointInTimeRestoreRange(userKey, databaseId) {
    return callApi(`/v1/aedatabases/${databaseId}/pointintimerestorerange`, {
        'X-AE-USERKEY': userKey,
    });
}

// export function retrieveArchived(userKey, databaseId, backupId) {
//   return callApi(`/v1/aedatabases/${databaseId}/backups/${backupId}/archived`, {
//     'X-AE-USERKEY': userKey,
//   });
// }

export function retrieveArchived(userKey, databaseId) {
  return callApi(`/v1/aedatabases/${databaseId}/archived`, {
    'X-AE-USERKEY': userKey,
  });
}

export function deleteBackup(userKey, databaseId, backupId) {
  return callApi(
    `/v1/aedatabases/${databaseId}/backups/${backupId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function deleteArchived(userKey, databaseId, backupId, archiveId) {
  return callApi(
    `/v1/aedatabases/${databaseId}/archived/${archiveId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function createBackup(userKey, databaseId, description) {
  return callApi(
    `/v1/aedatabases/${databaseId}/backups`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { description }
  );
}

export function restoreBackup(userKey, databaseId, backupId) {
  return callApi(
    `/v1/aedatabases/${databaseId}/backups/${backupId}/restore`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function restorePointInTime(userKey, databaseId, timestamp) {
    return callApi(
        `/v1/aedatabases/${databaseId}/pointintimerestore`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        '"' + timestamp + '"'
    );
}

export function undoPITR(userKey, databaseId, backupId) {
    return callApi(
        `/v1/aedatabases/${databaseId}/pointintimerestore/undo/${backupId}`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        ''
    );
}

export function archiveBackup(userKey, databaseId, backupId, description) {
  return callApi(
    `/v1/aedatabases/${databaseId}/backups/${backupId}/archived`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { description }
  );
}

export function restoreArchivedBackup(
  userKey,
  databaseId,
  backupId,
  archiveId
) {
  return callApi(
    `/v1/aedatabases/${databaseId}/archived/${archiveId}/restore`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function retrieveUdoLibraryVersions(userKey, databaseId) {
  return callApi(
    `/integration/udodefinition/${databaseId}/history`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}
export function retrieveDatabaseUdoLibrarylockstatus(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/udolibrary/lockstatus`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function lockUdoLibrary(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/udolibrary/lock`,
    { 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function unLockUdoLibrary(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/udolibrary/unlock`,
    { 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function createReviewLevel(userKey, databaseId, reviewLevel) {
  return callApi(
  
    `/workspaces/${databaseId}/reviewlevels`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    reviewLevel,
  );
}

export function updateReviewLevel(userKey, databaseId, reviewLevel) {
  return callApi(
  
    `/workspaces/${databaseId}/reviewlevels`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    reviewLevel
  );
}

export function deleteReviewLevel(userKey, databaseId, reviewLevelId) {
  return callApi(
  
    `/workspaces/${databaseId}/reviewlevels/${reviewLevelId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'DELETE',
    {}
  );
}

export function retrieveReviewLevels(userKey, databaseId) {
  return callApi(`/workspaces/${databaseId}/reviewlevels`, { 'X-AE-USERKEY': userKey });
}

export function retrieveReviewLevel(userKey, reviewLevelId) {
  return callApi(`/workspaces/reviewLevels/${reviewLevelId}`, { 'X-AE-USERKEY': userKey });
}

export function databaseUpdate(userKey, databaseId, file) {
  return callApiPassingErrors(
    `/workspaces/${databaseId}/update`,
    { 'Content-Type': 'multipart/form-data', 'X-AE-USERKEY': userKey },
    'POST',
    file
  ).catch();
}

export function requestExtractDataToCsv(userKey, databaseId, layoutType) {
  return callApi(
    `/workspaces/${databaseId}/extractTable/${layoutType}/toCsv`,
    { 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function reloadExtractTable(userKey, databaseId) {
  return callApi(
    `extract/admin/full-refresh/${databaseId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveBackupRestoreLogFile(userKey, databaseId) {
  return callApi(`/analysisBackupRestoreLogFile/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function deleteBackupRestoreLogFile(userKey, databaseId) {
  return callApi(`/analysisBackupRestoreLogFile/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}
