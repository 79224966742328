import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { fetchCurrentAEDbScriptVersion, fetchWorkspaces, fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { exportToCsv } from '../../../actions/analysis/reporting.actions';
import { fetchApiKey } from '../../../actions/settings.actions';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateExtract from '../presentational/createExtract';
import ExtractList from '../presentational/extractList';
import ExportLists from '../presentational/exportLists';
import ReportingDefinitionsList from '../presentational/reportingDefinitionsList';
import TableDefinitionsList from '../presentational/reporting/tableDefinitionsList';
import ReportTablesList from '../presentational/reporting/reportTablesList';
import AnalysisToolbar from '../presentational/analysisToolbar';
import SideBar from '../presentational/reporting/sidebar';

class ReportingTab extends Component {
    static propTypes = {
        userKey: PropTypes.string.isRequired,
        application: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        params: PropTypes.object,
        currentAEDbScriptVersion: PropTypes.number,
        getCurrentAEDbScriptVersion: PropTypes.func,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        extractDefinitions: PropTypes.array,
        isFetching: PropTypes.bool,
        isFetchingWideCsv: PropTypes.bool,
        isFetchingNarrowCsv: PropTypes.bool,
        extractKickingOff: PropTypes.bool,
        exportList: PropTypes.array,
        isFetchingExportList: PropTypes.bool,
        apiKey: PropTypes.string,
        exportCsv: PropTypes.func,
        csv: PropTypes.object,
        userPermissions: PropTypes.array, 
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
    }

    get baseUrl() {
        const { match } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        return `/arius/reporting${databaseId ? `/${databaseId}` : ''}`;
    }

    get newUrl() {
        // get the url with databaseId injected (if needed) and child route preserved
        const { location } = this.props;
        return location.pathname.replace(this.baseUrl, `/arius/reporting/`);
    }
    
    get newUrlFromCurrentDb() {
        const { currentDb } = this.props;
        return `/arius/reporting/${currentDb.id}/extracts`;  // default to 'extracts' in LHM
    }

    componentDidMount() {
        const { 
            currentDb,
            dispatch,
            getDatabases,
            userKey,
            match,
            history
        } = this.props;
        let params = match.params;
        const databaseId = params.databaseId ? parseInt(params.databaseId, 10) : null;
        // if (!currentAEDbScriptVersion) {
        //     getCurrentAEDbScriptVersion(userKey);
        // }
        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
        }
        getDatabases(userKey);
        dispatch(fetchApiKey(userKey));
    }

    componentDidUpdate(prevProps) {
        const {
            currentDb,
            userKey,
            match,
            getDatabase,
            isFetchingDatabases,
            isFetchingDatabase,
            history
        } = this.props;
        let params = match.params;
        const databaseId = params.databaseId ? parseInt(params.databaseId, 10) : 0;
        
        if (isFetchingDatabases) {
            return;
        }

        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
            return;
        }

        if (databaseId && !isFetchingDatabase) {
            if ((currentDb && currentDb.id !== databaseId) || !currentDb) {
                getDatabase(userKey, databaseId)
            }
        }
    }

    changeWorkspaceHandler(workspaceId) {
        const { history, location } = this.props;
        
        let newUrl = location.pathname.replace(this.baseUrl, `/arius/reporting/${workspaceId}`);
        history.push(newUrl);
    }

    render() {
        const {
            categories,
            history,
            dispatch,
            userKey,
            currentDb,
            workspaces,
            extractDefinitions,
            reportingDefinitions,
            tableDefinitions,
            tableDefinition,
            reportingTables,
            reportingTable,
            isFetching,
            isFetchingWideCsv,
            isFetchingNarrowCsv,
            isFetchingValuationDates,
            params,
            extractKickingOff,
            exportList,
            isFetchingExportList,
            apiKey,
            exportCsv,
            csv,
            userPermissions,
            user,
        } = this.props;

        let childProps = {
            categories,
            history,
            dispatch,
            userKey,
            databaseId: currentDb ? currentDb.id: null,
            databaseName: currentDb ? currentDb.name : '',
            currentWorkspace: currentDb,
            workspaces,
            extractDefinitions,
            reportingDefinitions,
            tableDefinitions,
            tableDefinition,
            reportingTables,
            reportingTable,
            isFetching,
            isFetchingWideCsv,
            isFetchingNarrowCsv,
            isFetchingValuationDates,
            params,
            extractKickingOff,
            exportList,
            isFetchingExportList, 
            apiKey,
            exportCsv,
            csv,
            userPermissions,
            user,
        };

        let toolbarProps = {
            currentWorkspace: currentDb,
            workspaces,
            dispatch,
            changeWorkspaceHandler: this.changeWorkspaceHandler
        }

        return (
        <div style={{display: 'flex'}}>
            <SideBar baseUrl={this.baseUrl}/>
            <div>
                <Switch>
                    <Route path="/arius/reporting/:databaseId/extracts/new" render={props => <span></span>}/>
                    <Route path="/arius/reporting/:databaseId/extracts/:extractId" render={props => <span></span>}/>
                    <Route path="/arius/reporting" render={props => <AnalysisToolbar {...{...toolbarProps}}/>}/>
                </Switch>
                <Switch>
                    <Route path="/arius/reporting/:databaseId/extracts/new" render={props => <CreateExtract {...{...childProps, params: props.match.params}}/>}/> 
                    <Route path="/arius/reporting/:databaseId/extracts/:extractId" render={props => <CreateExtract {...{...childProps, params: props.match.params}}/>}/> 
                    <Route path="/arius/reporting/:databaseId/extracts" render={props => <ExtractList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/:databaseId/exportlists" render={props => <ExportLists {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/:databaseId/support" render={props => <TableDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/:databaseId/reports" render={props => <ReportTablesList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/:databaseId/powerbi" render={props => <ReportingDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/extracts" render={props => <ExtractList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/exportlists" render={props => <ExportLists {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/support" render={props => <TableDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/reports" render={props => <ReportTablesList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting/powerbi" render={props => <ReportingDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
                    <Route path="/arius/reporting" render={props => <Redirect to="/arius/reporting/extracts"/>}/>
                </Switch>
            </div>

        </div>
        );
    }
}

const mapStateToProps = state => ({
    application: state.application,
    userKey: state.user.userKey,
    currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
    currentDb: state.analysis.workspaces.current,
    workspaces: state.analysis.workspaces.items,
    isFetchingDatabases: state.analysis.workspaces.isFetching,
    isFetchingDatabase: state.analysis.workspaces.isFetchingWorkspace,
    extractDefinitions: state.analysis.reporting.extractDefinitions,
    reportingDefinitions: state.analysis.reporting.reportingDefinitions,
    tableDefinitions: state.analysis.reporting.tableDefinitions,
    tableDefinition: state.analysis.reporting.tableDefinition,
    reportingTables: state.analysis.reporting.reportingTables,
    reportingTable: state.analysis.reporting.reportingTable,
    isFetching: state.analysis.reporting.isFetching,
    isFetchingWideCsv: state.analysis.reporting.isFetchingWideCsv,
    isFetchingNarrowCsv: state.analysis.reporting.isFetchingNarrowCsv,
    isFetchingValuationDates: state.analysis.projects.isFetchingValuationDates,
    extractKickingOff: state.analysis.reporting.extractKickingOff,
    exportList: state.analysis.projects.exportList,
    isFetchingExportList: state.analysis.projects.isFetchingExportList,
    apiKey: state.settingsMenu.apiKey,
    csvFile: state.analysis.reporting.csvFile,
    csv: state.analysis.reporting.csv,
    userPermissions: state.user.userPermissions,
    user: state.user,
    categories: state.analysis.projects.categories
});

const mapDispatchToProps = dispatch => ({
    getDatabase: (userKey, databaseId) => {
        dispatch(fetchWorkspace(userKey, databaseId));
    },
    getDatabases: userKey => {
        dispatch(fetchWorkspaces(userKey));
    },
    getCurrentAEDbScriptVersion: userKey => {
        dispatch(fetchCurrentAEDbScriptVersion(userKey));
    },
    exportCsv: (userKey, id, format, databaseName, extractDefinitionName, apiKey) => {
        dispatch(exportToCsv({
            userKey,
            id,
            format,
            databaseName,
            extractDefinitionName,
            apiKey
        }));
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingTab);
