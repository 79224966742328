import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from './queryUtils';
import { notifyError } from '@app/utilities/notifier';

class ColumnContainer extends Component {
    static propTypes = {
        columns: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.state = {};
        this.onDrop = this.onDrop.bind(this);
        this.removeAt = this.removeAt.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onDrop = (ev, ndx) => {
        let o = Utils.parseDragObject(ev);
        
        Utils.handleOnDragLeave.call(this, ev);
        let column = this.props.factColumns.find(x=> x.factColumnGuid === o.factColumnGuid);
        let isTrulyADimension = column && column.type === 'factColumn' && column.columnType === 'Dimension';
        if (!isTrulyADimension) { 
            notifyError("Only dimensions can be included in Group By")
            return; // only dimensions can be a group by element (no claimIds or 'property' columns)
        }
        if (Number.isInteger(ndx) && ndx > -1){
            // just reorder the existing items
            let items = this.props.columns;
            const remainingItems = items.filter((item, index) => item.order !== o.order);        
            let reorderedItems = [
                ...remainingItems.slice(0, ndx+1), // position after...
                o,
                ...remainingItems.slice(ndx+1)
            ];
            reorderedItems = reorderedItems.filter((value, index, self) => self.findIndex((x) => x.factColumnGuid === value.factColumnGuid) === index); // only unique values
            this.props.handleChange({target: {id: 'groupBy', value: this.reIndex(reorderedItems)}});
            return;
        }

        if (o && o.type === 'factColumn' && o.columnType === 'Dimension' && o.order === undefined){
            // add a new item
            let items = this.props.columns.concat([o]);
            items = items.filter((value, index, self) => self.findIndex((x) => x.factColumnGuid === value.factColumnGuid) === index); // only unique values
            items = this.reIndex(items);
            this.props.handleChange({target: {id: 'groupBy', value: items}});
        }
    }

    reIndex(items) {
        for (let i = 0; i < items.length; i++) { 
            items[i].order = i;
        } 
        return items;
    }

    removeAt = (ndx) => {
        let items = this.props.columns.filter((item, index) => index !== ndx); 
        this.props.handleChange({target: {id: 'groupBy', value: items}});
    }

    render() {
        
        let colStyle = {
            marginBottom: '3px', marginRight: '3px', borderRadius: '5px',
            fontSize: '10px', padding:'2px',
            textAlign: 'center', float: 'left'};
        let columns = this.props.columns ? this.props.columns : [];
        let height = `${Math.ceil(columns.length/3)*27+5}px`;
        let columnElements = columns.map((cs, idx) => 
            <div className='col-sm-3 bg-info-old text-primary-old' style={colStyle}
                onDragStart={(e)=> Utils.handleOnDragStart(e, cs)} 
                onDrop={(e)=>{this.onDrop(e, idx);}}   
                key={'col'+idx}    
                draggable>
                <span className='pull-right delete-button' onClick={()=> {this.removeAt(idx)}}>&times;</span>
                {cs.name}
            </div>
        );

        let borderStyle = this.state.hovering ? 'red solid 1px' : '';
        return columns && columns.length > 0 ? (
            <div className='col-sm-12 form-control' style={{border: borderStyle, height, marginBottom: '20px'}}
                onDragOver={Utils.handleOnDragOver}
                onDragLeave={Utils.handleOnDragLeave} 
                onDrop={(e)=>{this.onDrop(e, null)}}>   
                    {columnElements}                     
            </div> 
        ) : (
            <div className='col-sm-12 form-control' 
                style={{margin: '3px', textAlign: 'center', marginBottom: '20px', border: 'dashed 1px', borderRadius: '5px'}}
                onDragOver={Utils.handleOnDragOver}
                onDragLeave={Utils.handleOnDragLeave} 
                onDrop={(e)=>{this.onDrop(e, null)}}>
                drag dimensions here...</div>
        );
    }
}

export default ColumnContainer;
