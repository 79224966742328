export const
FETCHING_PERFORMANCE_INFO = 'FETCHING_PERFORMANCE_INFO',
RECEIVED_PERFORMANCE_INFO = 'RECEIVED_PERFORMANCE_INFO',
FETCHING_PERFORMANCE_OPTIONS = 'FETCHING_PERFORMANCE_OPTIONS',
RECEIVED_PERFORMANCE_DATABASE_OPTIONS = 'RECEIVED_PERFORMANCE_DATABASE_OPTIONS',
RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS = 'RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS',
RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS = 'RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS',
FETCHING_PERFORMANCE_JOB_STATUS = 'FETCHING_PERFORMANCE_JOB_STATUS',
RECEIVED_PERFORMANCE_JOB_STATUS = 'RECEIVED_PERFORMANCE_JOB_STATUS',
SENT_PERFORMANCE_COMMAND = 'SENT_PERFORMANCE_COMMAND',
RECEIVED_PERFORMANCE_POWERBI_OPTIONS = 'RECEIVED_PERFORMANCE_POWERBI_OPTIONS';