import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { 
    retrieveExtractDefinitions,
    createExtractDefinition, 
    editExtractDefinition,
    isExtractRunning,
} from '@app/actions/analysis/reporting.actions';
import * as GlobalStyles from '../../../utilities/globalStyles';
import { notifyError } from '../../../utilities/notifier';
import { showModal } from '@app/actions/modal.actions';
import { Form } from 'react-bootstrap';
import { Button } from '@arius';
import { retrieveExportsByListType, retrievedExportsByListType } from '../../../actions/analysis/project.actions';
import { retrieveCategories } from '../../../actions/analysis/category.actions';
import MasterViewComponent from './masterViewComponent';
import ValuationDatesView from './valuationDatesView';
import ProjectStatusView from './projectStatusView';
import ProjectCategoriesView from './projectCategoriesView';
import LastUpdatedByView from './lastUpdatedByView';
import { validateName } from '../../../utilities/validators';
import { WORKSPACE_CREATEEDITEXTRACTTABLES } from '@app/utilities/permissions';

const style = {
    mainContainer: {
        width: '85vw',
        height: 'calc(100vh - 106px)',
        maxHeight: 'calc(100vh - 106px)',
        overflowY: 'auto',
    },
    masterCustomStyle: {
        display: 'flex',
        minHeight: '20vh',
        width: '100%',
        margin: '0px 0px 20px 0px',
        border: '1px solid #ccc',
        backgroundColor: 'white',
    },
    selectItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        flex: '1 1 auto',
    },
    radioDouble: { 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        marginTop: '6px',
    },
    radioRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        flex: '1 1 auto',
        paddingRight: '3px',
        justifyContent: 'space-between',
    },
    radioColumn: {
        display: 'flex',
        flexFlow: 'column nowrap',
        flex: '1 1 auto',
        paddingRight: '3px',
        justifyContent: 'space-between',
        minHeight: '203px',
    },
    selectRow: {
        display: 'flex',
        flexFlow: 'nowrap',
        justifyContent: 'space-between',
    },
    saveCancelButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

class CreateExtract extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        databaseId: PropTypes.number,
        params: PropTypes.object,
        extractDefinitions: PropTypes.array,
        clearExtractTableDefinition: PropTypes.func,
        exportList: PropTypes.array,
        isFetchingExportList: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            tableName: '',
            tableDescription: '',
            nameError: '',
            softErrorMode: true,
            selectedExport: 'select',
            valuationDatesState: {
                databaseId: -1,
                selectedValuationDate: '',
                latestValue: 4,
                selectedRadioOption: 'all',
                label: 'All',
                field: 'ValuationDate',
            },
            projectStatusState: {
                checked: ['All'],
                label: 'All',
                field: 'ProjectStatus',
            },
            projectCategoriesState: {
                checked: [],
                label: 'All',
                field: 'ProjectCategories',
            },
            lastUpdatedByState: {
                selectedUserNameId: -1,
                selectedRadioOption: 'all',
                label: 'All',
                field: 'LastUpdatedBy',
            },
            lastDiagonalOnly: true,
            includeCumulative: true,
            childStateNames: ['valuationDatesState', 'projectStatusState', 'lastUpdatedByState', 'projectCategoriesState'],
        };
        this.handleTableNameChange = this.handleTableNameChange.bind(this);
        this.handleTableDescriptionChange = this.handleTableDescriptionChange.bind(this);
        this.save = this.save.bind(this);
        this.backNav = this.backNav.bind(this);
        this.selectExportOption = this.selectExportOption.bind(this);
        this.updateValuationDatesState = this.updateValuationDatesState.bind(this);
        this.setValuationDatesState = this.setValuationDatesState.bind(this);
        this.updateProjectStatusState = this.updateProjectStatusState.bind(this);
        this.setProjectStatusState = this.setProjectStatusState.bind(this);
        this.updateLastUpdatedByState = this.updateLastUpdatedByState.bind(this);
        this.setLastUpdatedByState = this.setLastUpdatedByState.bind(this);
        this.selectBuildWith = this.selectBuildWith.bind(this);
        this.setDataMode = this.setDataMode.bind(this);
        this.setRecordState = this.setRecordState.bind(this);
        this.refresh = this.refresh.bind(this);
        this.updateProjectCategoriesState = this.updateProjectCategoriesState.bind(this);
    }

    get definitionId() {
        const { params } = this.props;
        return !params.extractId || params.extractId === 'create' ? null : Number(params.extractId);  
    }

    get editingMode() {
        return null !== this.definitionId; 
    }


    get canEdit() {
        const { currentWorkspace, userPermissions } = this.props;
        if (currentWorkspace === null) return false;
        if (currentWorkspace.permissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1 &&
            userPermissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1) {
          return false;
        }
        return true;
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(retrievedExportsByListType(null,'ExtractTable'));  // clear out the exportlists
    }

    componentDidUpdate(prevProps) {
        const { exportList, databaseId, extractDefinitions } = this.props;
        const {  databaseId: prevDatabaseId } = prevProps;

        if ((databaseId && databaseId !== prevDatabaseId) || null === exportList) {
            this.refresh();
            return;
        }

        if (extractDefinitions.length && null !== exportList && exportList !== prevProps.exportList) {
            // the exportList was updated and extraDefinitions are already loaded
            this.setRecordState();
        }

        if (null !== exportList && extractDefinitions.length && extractDefinitions !== prevProps.extractDefinitions) {
            // the extractDefinitions were updated and the exportLists are already loaded
            this.setRecordState();
        }
    }

    refresh() {
        const { userKey, dispatch, currentWorkspace, isFetching, extractDefinitions, exportList, isFetchingExportList } = this.props;
        if (currentWorkspace ){
            dispatch(retrieveCategories(userKey, currentWorkspace.id));
            if (!extractDefinitions.length && !isFetching){
                dispatch(retrieveExtractDefinitions({ userKey, databaseId: currentWorkspace.id }));
            }
            
            if (null === exportList && !isFetchingExportList){
                dispatch(
                    retrieveExportsByListType({ 
                        userKey, 
                        databaseId: currentWorkspace.id,
                        listType: 'ExtractTable',
                    })
                );                
            }
        }
    }

    setRecordState(){
        const { extractDefinitions, databaseId } = this.props;
        let extractTableDefinition = null;
        if (this.definitionId){
            extractTableDefinition = extractDefinitions.find(x=> x.id === this.definitionId);
        }

        
        if (extractTableDefinition) {
            let valuationDatesState, projectStatusState, lastUpdatedByState, projectCategoriesState;
        
            extractTableDefinition.filters.forEach((f) => {
                if (f.field === 'ValuationDate') {
                    valuationDatesState = this.setValuationDatesState(f);
                    valuationDatesState.databaseId = databaseId;
                } else if (f.field === 'ProjectStatus') {
                    projectStatusState = this.setProjectStatusState(f);
                } else if (f.field === 'LastUpdatedBy') {
                    lastUpdatedByState = this.setLastUpdatedByState(f);
                }else if (f.field === 'ProjectCategories') {
                    projectCategoriesState = this.setProjectCategoriesState(f);
                }
            });
            if (!extractTableDefinition.filters.find(x=> x.field === 'ProjectCategories')){
                // handle existing definitions that predate proj categories
                projectCategoriesState = this.state.projectCategoriesState;
            }
            const tableName = (extractTableDefinition && extractTableDefinition.name) || '',
                tableDescription = (extractTableDefinition && extractTableDefinition.description) || '',
                selectedExport = (extractTableDefinition && extractTableDefinition.exportListId) || 'select',
                { includeCumulative, includeIncremental, lastDiagonalOnly } = extractTableDefinition;

            
            this.setState({
                tableName,
                tableDescription,
                selectedExport,
                valuationDatesState,
                projectStatusState,
                lastUpdatedByState,
                includeCumulative,
                includeIncremental,
                lastDiagonalOnly,
                projectCategoriesState,
            });
        }
    }

    setValuationDatesState(f) {
        let selectedRadioOption, latestValue, selectedValuationDate;
        if (f.values.length > 0) {
            if (f.values[0] === 'selectedValuationDate') {
                latestValue = 4;
                selectedValuationDate = f.values[1];
                selectedRadioOption = 'selectedValuationDate';
            }
            else if (f.values[1] > 1) {
                latestValue = f.values[1];
                selectedValuationDate = '';
                selectedRadioOption = 'lastFewByAmount';
            } else {
                latestValue = 4;
                selectedValuationDate = '';
                selectedRadioOption = 'latest';
            }
        } else {
            latestValue = 4;
            selectedValuationDate = '';
            selectedRadioOption = 'all';
        }

        let label;
        switch (selectedRadioOption) {
            case 'all': 
                label = 'All';
                break;
            case 'latest': 
                label = 'Most Recent';
                break;
            case 'lastFewByAmount': 
                label = `Last ${latestValue}`;
                break;
            case 'selectedValuationDate':
                label = selectedValuationDate;
                break;
            default: label = 'All';
        }
        return {
            latestValue,
            selectedValuationDate,
            selectedRadioOption,
            label,
            field: 'ValuationDate',
        };
    }

    setProjectStatusState(f) {
        let checked = [];
        if (!f.values.length) {
            checked.push('All');
        } else {
            checked = f.values;
        }
        const labelArray = [];
        if (checked.indexOf('All') !== -1) {
            labelArray.push('All');
        } else {
            if (checked.indexOf('Not Reviewed') !== -1) {
                labelArray.push('NR');
            }
            if (checked.indexOf('Not Started') !== -1) {
                labelArray.push('NS');
            }
            if (checked.indexOf('In Progress') !== -1) {
                labelArray.push('IP');
            }
            if (checked.indexOf('Complete') !== -1) {
                labelArray.push('C');
            }
            if (checked.indexOf('Level 1 Review') !== -1) {
                labelArray.push('L1');
            }
            if (checked.indexOf('Level 2 Review') !== -1) {
                labelArray.push('L2');
            }
            if (checked.indexOf('Level 3 Review') !== -1) {
                labelArray.push('L3');
            }
            if (checked.indexOf('Level 4 Review') !== -1) {
                labelArray.push('L4');
            }
            if (checked.indexOf('Level 5 Review') !== -1) {
                labelArray.push('L5');
            }
        }
        const label = labelArray.join(', ');
        return {
            checked,
            label,
            field: 'ProjectStatus',
        };
    }

    setProjectCategoriesState(f) {
        let checked = [];
        if (!f.values.length) {
            checked.push('All');
        } else {
            checked = f.values;
        }
        return {
            checked,
            label: f.values.length ? `(${f.values.length})` : 'All',
            field: 'ProjectCategories',
        };
    }

    setLastUpdatedByState(f) {
        let selectedRadioOption, selectedUserNameId;
        if (f.values.length === 0) {
            selectedRadioOption = 'all';
            selectedUserNameId = -1;
        } else {
            selectedUserNameId = f.values[1];
            selectedRadioOption = 'lastUpdatedBy';
        }
        let label;
        switch (selectedRadioOption) {
            case 'all': 
                label = 'All';
                break;
            case 'lastUpdatedBy': 
                label = '';
                break;
            default: label = 'All';
        }
        return {
            selectedUserNameId,
            selectedRadioOption,
            label,
            field: 'LastUpdatedBy',
        };
    }

    setDataMode(bool) {
        this.setState({ includeCumulative: bool });
    }

    getValidationState() {
        const { tableName } = this.state;
        const { extractDefinitions } = this.props;
        const comparingName = tableName ? tableName.toLowerCase().trim() : '';

        let found = extractDefinitions.find(ed => ed.id !== this.definitionId && ed.name.toLowerCase() === comparingName);
        let error = validateName(comparingName, this.state.softErrorMode);
        if (error) return error;
        return found ? 'Name must be unique' : '';
    }

    handleTableNameChange(e) {
        this.setState({ tableName: e.target.value });
    }

    handleTableDescriptionChange(e) {
        let s = e.target.value;
            s = s && s.length > 149 ? s.substring(0, 149) : s;
            this.setState({ tableDescription: s });
    }

    selectExportOption(e) {
        const val = e.target.value,
        selectedExport = val === 'select' ? val : Number(val);
        this.setState({ selectedExport });
    }

    updateValuationDatesState(childState) {
        const { latestValue, selectedRadioOption, selectedValuationDate } = childState,
        valuationDatesState = {
            latestValue,
            selectedValuationDate,
            selectedRadioOption,
            field: 'ValuationDate',
        };
        let label;
        switch (selectedRadioOption) {
            case 'all': 
                label = 'All';
                break;
            case 'latest': 
                label = 'Most Recent';
                break;
            case 'lastFewByAmount': 
                label = `Last ${latestValue}`;
                break;
            case 'selectedValuationDate':
                label = selectedValuationDate;
                break;
            default: label = 'All';
        }
        valuationDatesState.label = label;
        this.setState({ valuationDatesState });
    }

    updateProjectCategoriesState(childState) {
        const { checked } = childState,
        projectCategoriesState = {
            checked,
            label: checked.includes('All') ? 'All' : `(${checked.length})`,
            field: 'ProjectCategories',
        };
        this.setState({ projectCategoriesState });
    }

    updateLastUpdatedByState(childState) {
        const { selectedRadioOption, selectedUserNameId } = childState,
        lastUpdatedByState = {
            selectedUserNameId,
            selectedRadioOption,
            field: 'LastUpdatedBy',
        };
        let label;
        switch (selectedRadioOption) {
            case 'all': 
                label = 'All';
                lastUpdatedByState.selectedUserNameId = -1;
                break;
            case 'lastUpdatedBy': 
                label = '';
                break;
            default: label = 'All';
        }
        lastUpdatedByState.label = label;
        this.setState({ lastUpdatedByState });
    }

    updateProjectStatusState(childState) {
        const { checked } = childState,
        projectStatusState = {
            checked,
            field: 'ProjectStatus',
        },
        labelArray = [];
        if (checked.indexOf('All') !== -1) {
            labelArray.push('All');
        } else {
            if (checked.indexOf('Ad Hoc') !== -1) {
                labelArray.push('AH');
            }
            if (checked.indexOf('Not Reviewed') !== -1) {
                labelArray.push('NR');
            }
            if (checked.indexOf('Not Started') !== -1) {
                labelArray.push('NS');
            }
            if (checked.indexOf('In Progress') !== -1) {
                labelArray.push('IP');
            }
            if (checked.indexOf('Complete') !== -1) {
                labelArray.push('C');
            }
            if (checked.indexOf('Level 1 Review') !== -1) {
                labelArray.push('L1');
            }
            if (checked.indexOf('Level 2 Review') !== -1) {
                labelArray.push('L2');
            }
            if (checked.indexOf('Level 3 Review') !== -1) {
                labelArray.push('L3');
            }
            if (checked.indexOf('Level 4 Review') !== -1) {
                labelArray.push('L4');
            }
            if (checked.indexOf('Level 5 Review') !== -1) {
                labelArray.push('L5');
            }
        }
        projectStatusState.label = labelArray.join(', ');
        this.setState({ projectStatusState });
    }

    save() {
        const { 
            tableName,
            tableDescription,
            selectedExport: exportListId,
            childStateNames,
            lastDiagonalOnly,
            includeCumulative,
        } = this.state;
        const { 
            userKey,
            dispatch,
            databaseId,
        } = this.props;
        const req = {
            name: tableName.trim(),
            description: tableDescription,
            filters: [],
            includeCumulative, 
            includeIncremental: !includeCumulative, 
            lastDiagonalOnly, 
        };
        if (!req.name) {
            notifyError('Name cannot be blank');
            return;
        }
        if (exportListId !== 'select') req.exportListId = exportListId;
        
        childStateNames.forEach((cs) => {
            const filter = {
                field: this.state[cs].field,
                values: [],
            };
            if (cs === 'valuationDatesState') {

                if (this.state[cs].selectedRadioOption === 'selectedValuationDate') {
                    filter.values.push('selectedValuationDate');
                    filter.values.push(this.state[cs].selectedValuationDate); 
                }
                else if (this.state[cs].selectedRadioOption !== 'all') {
                    filter.values.push('latest');
                    if (this.state[cs].selectedRadioOption === 'lastFewByAmount') {
                        filter.values.push(this.state[cs].latestValue); 
                    } else {
                        filter.values.push(1);
                    }
                }

            } else if (cs === 'projectStatusState') {
                const isAny = this.state[cs].checked.indexOf('All') !== -1 || !this.state[cs].checked.length;
                filter.values = isAny ? [] : this.state[cs].checked;
            } else if (cs === 'projectCategoriesState') {
                const isAny = this.state[cs].checked.indexOf('All') !== -1 || !this.state[cs].checked.length;
                filter.values = isAny ? [] : this.state[cs].checked;
            }  else {
                if (this.state[cs].selectedRadioOption !== 'all') {
                    filter.values.push('lastUpdatedBy');
                    filter.values.push(this.state[cs].selectedUserNameId);
                }
            }
            req.filters.push(filter);
        });
        
        if (this.getValidationState()) {
            const error = this.getValidationState();
            notifyError(error);
            this.setState({ nameError: error });
            return;
        } 
        if(validateName(req.name.toLowerCase().trim(), this.state.softErrorMode)){
            const error=validateName(req.name.toLowerCase().trim(), this.state.softErrorMode);
            notifyError(error);
            this.setState({ nameError:error})
            return;
        }

        if (this.editingMode){
            dispatch(isExtractRunning(userKey, databaseId, this.definitionId,
                (data)=> {
                    if (!data || !data.isRunning) {
                        let messageItems = [
                            <span key={`warn-1`} style={{ fontWeight: 800 }}>
                                Saving will clear any existing records from your current Extract table.  Do you want to continue? 
                            </span>,
                        ];

                        const yesClickHandler = () => {
                            dispatch(editExtractDefinition({ userKey, databaseId, definitionId: this.definitionId, req }));
                            this.backNav();
                        }
                        const noClickHandler = () => {};
                        const action = showModal(
                            'confirmation',
                            messageItems,
                            yesClickHandler,
                            noClickHandler
                        );
                        dispatch(action);
                    } else {
                        notifyError('Cannot save while updates to Extract Table are in progress.');
                        return;
                    }
                }));
            } else {
                dispatch(createExtractDefinition({ userKey, databaseId, req }));
                this.backNav();
            }
    }
  
    selectBuildWith(bool) {
        this.setState({ lastDiagonalOnly: bool });
    }

    backNav() {
        const { currentWorkspace, history } = this.props;
        if (currentWorkspace) {
            history.push(`/arius/reporting/${currentWorkspace.id}/extracts`);
        }
    }

    render() {
        const { 
            tableName,
            tableDescription,
            selectedExport,
            selectedView,
            valuationDatesState,
            lastDiagonalOnly,
            includeCumulative,
            projectStatusState,
            lastUpdatedByState,
            projectCategoriesState
        } = this.state;
        const nameError = this.getValidationState();
        const { exportList, databaseId, isFetchingValuationDates, categories } = this.props;
        const exportListOptions = exportList && exportList.map((el, idx) => 
            <option key={`export-list-option-${idx}`} value={el.exportListId}>{el.listName}</option>
        ),
        editTitle = this.editingMode ? <span>Editing </span> : '',
        views = [
            {
                name: 'Valuation_Dates',
                displayName: 'Valuation Dates',
                component: (
                    <ValuationDatesView
                    updateParent={this.updateValuationDatesState}
                    state={valuationDatesState} 
                    isFetchingValuationDates={isFetchingValuationDates}/>
                ),
                viewState: valuationDatesState,
            },
            {
                name: 'Project_Status',
                displayName: 'Project Status',
                component: (
                    <ProjectStatusView
                    updateParent={this.updateProjectStatusState}
                    state={projectStatusState} />
                ),
                viewState: projectStatusState,
            },
            {
                name: 'Last_Updated_By',
                displayName: 'Last Updated By',
                component: (
                    <LastUpdatedByView
                    updateParent={this.updateLastUpdatedByState}
                    state={lastUpdatedByState} />
                ),
                viewState: lastUpdatedByState,
            },
            {
                name: 'Project_Categories',
                displayName: 'Project Categories',
                component: (
                    <ProjectCategoriesView
                        updateParent={this.updateProjectCategoriesState}
                        state={projectCategoriesState}
                        categories={categories} />
                ),
                viewState: projectCategoriesState,
            },
        ],
        mainContent = (
        <div>
            <div
                style={{
                borderBottom: '2px solid #BDBDBD',
                margin: 'initial',
                height: '75px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}
            >
                <div style={{ margin: 'initial', paddingLeft: 15 }}>
                <h3>{editTitle}Extract Table Definition</h3>
                </div>
                <Button mode='back' onClick={this.backNav} toolTip='Extract Table List'/>
            </div>
            <div style={[
                GlobalStyles.card,
                { marginTop: '0px',
                marginRight: '0px',
                marginBottom: '0px',
                marginLeft: '0px',
                padding: '20px',
                },
            ]}>
                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flex: '1 1', marginRight: '10px' }}>
                        <Form.Group
                        style={{ width: '100%', paddingRight: '3px' }}
                        controlId="tableFormName">
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            isInvalid={nameError}
                            style={{ width: '100%' }}
                            value={tableName}
                            placeholder="enter name"
                            onChange={e => this.handleTableNameChange(e)}
                        />
                        <Form.Control.Feedback type='invalid'>{nameError}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div style={{ display: 'flex', flex: '1 1', marginLeft: '10px' }}>
                        <Form.Group 
                        controlId="tableFormDesc"
                        style={{ width: '100%', paddingLeft: '3px' }}>
                        <Form.Label>
                            Description
                        </Form.Label>
                        <Form.Control
                            style={{ width: '100%', resize: 'vertical' }}
                            as="textarea"
                            rows="1"
                            placeholder="enter description"
                            value={tableDescription}
                            onChange={e => this.handleTableDescriptionChange(e)}
                        />
                        </Form.Group>
                    </div>
                </div>
                <div style={style.selectRow}>
                    <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginRight: '10px' } }}>
                        <Form.Label>Extract Table List</Form.Label>
                        <Form.Control
                            as="select"
                            placeholder="select"
                            onChange={this.selectExportOption}
                            value={selectedExport}>
                            <option value="select">Default List: All Tables</option>
                            {exportListOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group
                        controlId="formControlsSelect"
                        style={{ ...style.selectItem, ...{ marginLeft: '10px', visibility: 'hidden', pointerEvents: 'none' } }}>
                        <Form.Label>Extract Table List</Form.Label>
                        <Form.Control
                            as="select"
                            placeholder="select"
                            onChange={this.selectExportOption}
                            value={selectedExport}>
                            <option value="select">Default List: All Tables</option>
                            {exportListOptions}
                        </Form.Control>
                    </Form.Group>
                </div>
                <Form.Label style={{ marginTop: '20px', fontWeight: 'bold' }}>
                    Export Filtering
                </Form.Label>
                <MasterViewComponent
                    selectedView={selectedView}
                    views={views}
                    customStyle={style.masterCustomStyle} />
                <div style={style.radioColumn}>
                    <Form.Label style={{fontWeight: 'bold'}}>Options</Form.Label>
                    <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginLeft: '10px' } }}>
                        <div>Triangle Data</div>
                        <div style={style.radioDouble}> 
                        <Form.Check type="radio"
                            id="build-rad-one"
                            checked={lastDiagonalOnly}
                            onChange={() => this.selectBuildWith(true)}
                            inline
                            label="Last Diagonal Only" />
                        <span style={{ height: '8px' }}></span>
                        <Form.Check type="radio"
                            id="build-rad-two"
                            checked={!lastDiagonalOnly}
                            onChange={() => this.selectBuildWith(false)}
                            inline
                            label="Full triangle" />
                        </div> 
                    </Form.Group>
                    <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginLeft: '10px' } }}>
                        <div>Mode</div>
                        <div style={style.radioDouble}>
                        <Form.Check type="radio"
                            id="set-rad-one"
                            checked={includeCumulative}
                            onChange={() => this.setDataMode(true)}
                            inline
                            label="Cumulative" />
                        <span style={{ height: '8px' }}></span>
                        <Form.Check type="radio"
                            id="set-rad-two"
                            checked={!includeCumulative}
                            onChange={() => this.setDataMode(false)}
                            inline
                            label="Incremental" />
                        </div>
                    </Form.Group>
                </div>
                <div style={style.saveCancelButtons}>
                    <Button variant="arius" disabled={!this.canEdit} onClick={this.save}>Save</Button>
                    <Button variant="arius" onClick={this.backNav}>Cancel</Button>
                </div>
            </div>
        </div>
        );

        if (!this.editingMode) {
            valuationDatesState.databaseId = databaseId;
        }
        return (
        <div style={style.mainContainer}>{mainContent}</div>
        );
    }
}

export default radium(CreateExtract);
