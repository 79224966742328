import {
  REFRESHING_CUBE,
  CUBE_REFRESHED,
} from '../../actions/analysis/analysis.actionTypes';

const cubeReducer = (
  state = {
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case REFRESHING_CUBE:
      return Object.assign({}, state, { isFetching: true });

    case CUBE_REFRESHED:
      return Object.assign({}, state, {
        isFetching: false,
      });

    default:
      return state;
  }
};

export default cubeReducer;
