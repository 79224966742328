import React, { Component } from 'react';
import * as api from '@app/serviceApi/todDatabases.serviceApi';
import Loader from '@app/shared/presentational/loader';
import { Grid } from '@arius';
import * as Colors from '@app/utilities/colors';

class TodDatabaseDetailRow extends Component {
    constructor(props) {
        super(props);
        this.state = {data: {}};
        const { columnTypes } = props;
        this.columns = [
            {headerText: 'Column Name', field: 'factColumnDisplayName' },
            {headerText: 'Column Type', field: 'columnType', template: (c) => {
                let colType = c.columnType;
                const found = columnTypes.find(ct => ct.value === colType);
                if (found) {
                    colType = found.label;
                }
                return <span>{colType}</span>
            } },
            {headerText: 'Cumulative', field: 'isCumulative', width: '100px', template: (c) => c.isCumulative
                ? (<span>
                    <i
                        className="fa fa-check-circle-o"
                        style={{ color: Colors.blue }}
                        aria-hidden="true"
                    />
                </span>)
                : ('')},
            {headerText: 'Formula', field: 'formula' }
        ];
    }

    componentDidMount() {
        const { row, userKey } = this.props;
        this.fetchDatabase(userKey, row.workspaceId);
    }

    fetchDatabase(userKey, databaseId){
        api.retrieveDatabase(userKey, databaseId)
            .then(json => {
                this.setState({data: json})
            });
    }

    getPanel(data) {
        let panelStyle = {        
            display: 'flex',
            justifyContent: 'space-between',
            height: 'auto',
            marginBottom: '20px', 
            marginRight: '20px'
        }

        return (
            <div>
                <small style={panelStyle}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Description:&nbsp;</span>
                            <span>&nbsp;{data.workspaceDescription}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>DB Version</span>
                            <span>&nbsp;{data.version}</span>
                        </div>
                    </small>
                    <Grid data={data.columns} columns={this.columns} allowPaging={false}/>
            </div>
        )
    }

    render() {
        const { data } = this.state;

        let loaderStyle = data ? {display: 'none'} : {};
        let gridStyle = data ? {}: {display: 'none'};
        return (
            <div>
                <div style={loaderStyle}>
                    <Loader loadingItem="Database Info" />
                </div>
                <div style={gridStyle}>
                    {this.getPanel(data)}
                </div>
            </div>    
        ) 
    }
}

export default TodDatabaseDetailRow;
