import React from 'react';
import PropTypes from 'prop-types';
import * as Colors from '../../../utilities/colors';

const NoResults = ({ size, noMoreResults }) => (
  <div style={{ color: Colors.purple, textAlign: 'center', fontSize: size || 20, margin: 10 }}>
    {noMoreResults ? 'No more results' : 'No Results'}
  </div>
);

NoResults.propTypes = {
  size: PropTypes.number,
  noMoreResults: PropTypes.bool,
};

export default NoResults;
