import {
    RECEIVED_ANALYTICS_UPLOADS,
    FETCHING_ANALYTICS_UPLOADS,
    RECEIVED_ANALYTICS_RESULTS,
    RECEIVED_ANALYTICS_PARAMS,
    REQUESTING_ANALYTICS_UPLOAD,
    RECEIVED_ANALYTICS_UPLOAD_RESPONSE,
    SAVING_ANALYTICS_UPLOADS,
    FETCHING_ANALYTICS_MODELS,
    RECEIVED_ANALYTICS_MODELS
} from './analytics.actionTypes';
import * as api from '../../serviceApi/analytics.serviceApi';
import { notifySuccess } from '@app/utilities/notifier';

function fetchingFromServer() {
    return {
        type: FETCHING_ANALYTICS_UPLOADS,
    };
}

function savingToServer() {
    return {
        type: SAVING_ANALYTICS_UPLOADS,
    };
}

function receivedUploads(json) {
    return {
        type: RECEIVED_ANALYTICS_UPLOADS,
        items: json,
        receivedAt: Date.now(),
    };
}

function fetchModels(userKey) {
    return (dispatch) => {
        dispatch({type: FETCHING_ANALYTICS_MODELS});
        return api.getModels(userKey)
                .then(json => dispatch({
                    type: RECEIVED_ANALYTICS_MODELS,
                    items: json
                }));
    };
}

function fetchUploads(userKey) {
    return (dispatch) => {
        dispatch(fetchingFromServer());
        return api.retrieveUploads(userKey)
                .then(json => dispatch(receivedUploads(json)));
    };
}

function fetchResults(userKey, modelId) {
    return (dispatch) => {
        dispatch(fetchingFromServer());
        return api.getResultsFiles(userKey, modelId)
                .then(json => dispatch({
                    type: RECEIVED_ANALYTICS_RESULTS,
                    items: json,
                    receivedAt: Date.now(),
                }));
    };
}

function fetchParams(userKey, modelId) {
    return (dispatch) => {
        return api.getAvailableParams(userKey, modelId)
                .then(r => dispatch({
                    type: RECEIVED_ANALYTICS_PARAMS,
                    params: r,
                    receivedAt: Date.now(),
                }));
    };
}

function uploadFile(userKey, file) {
    return (dispatch) => {
        dispatch(savingToServer());
        return api.uploadFile(userKey, file)
                .then(() => dispatch(fetchUploads(userKey)));
    };
}

function deleteUploadedFile(userKey, uploadId) {
    return (dispatch) => {
        dispatch(savingToServer());
        return api.deleteUpload(userKey, uploadId)
                .then(() => dispatch(fetchUploads(userKey)));
    };
}

function requestRun(userKey, request) {
    return (dispatch) => {
        return api.requestRun(userKey, request)
                .then((res) => {
                    if (res === 'requested'){
                        notifySuccess('Run requested');
                    }
                    dispatch(fetchResults(userKey, request.modelId));
                });
    };
}

function requestUploadToTOD(userKey, req) {
    return (dispatch) => {
        dispatch({type: REQUESTING_ANALYTICS_UPLOAD});
        return api.requestUploadToTod(userKey, req)
                .then((r) => {
                    dispatch({type: RECEIVED_ANALYTICS_UPLOAD_RESPONSE});
                    if (r === true){    
                        notifySuccess('Upload complete');
                    }
                });
    };
}

export {
    fetchUploads,
    fetchResults,
    fetchParams,
    uploadFile,
    deleteUploadedFile,
    requestRun,
    requestUploadToTOD,
    fetchModels
};
