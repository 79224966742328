import alertify from 'alertifyjs';

export function notify(message) {
  alertify.message(message);
}

export function notifyError(message) {
  alertify.error(message);
}

export function notifySuccess(message) {
  alertify.success(message);
}

export function alertMessage(caption, message) {
  alertify.alert(caption, message);
}

export function alertMessageWithResize(caption, message) {
  alertify.alert(caption, message).set('resizable', true).resizeTo('70%', 400);
}

export function alertMessageMaximizable(caption, message){
    alertify.alert(caption, message).set('maximizable', true); 
}

export function alertCustom(message, customName, fadeTime, callBack) {
  alertify.message(message, customName, fadeTime, callBack);
}

export function notifyWithPosition(method, message, position) {
  switch (method) {
    case 'success':
      alertify.set('notifier', 'position', position);
      alertify.success(message);
      break;
    case 'error':
      alertify.set('notifier', 'position', position);
      alertify.error(message);
      break;
    case 'message':
      alertify.set('notifier', 'position', position);
      alertify.message(message);
      break;
    default:
      alertify.set('notifier', 'position', position);
      alertify.message(message);
  }
}
