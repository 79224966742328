import { callApi } from '../utilities/callApi';

export function retrieveSegments(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}/segments`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveSegmentsForWorkspace(userKey, workspaceId) {
  return callApi(
    `/workspaces/${workspaceId}/segments`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function deleteSegment(userKey, workspaceId, projectId, segmentId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}/segments/${segmentId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}
