import { callApi } from '../utilities/callApi';

export function retrieveTableDefinitions({ userKey, databaseId }) {
    return callApi(`/reporting/${databaseId}/tabledefinitions`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
}

export function retrieveTableDefinition({ userKey, databaseId, tableDefinitionId }) {
    return callApi(`/reporting/${databaseId}/tabledefinitions/${tableDefinitionId}`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
  }

export function createTableDefinition({ userKey, databaseId, req }) {
    return callApi(
        `/reporting/${databaseId}/tabledefinitions`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        req,
    );
}

export function updateTableDefinition({ userKey, databaseId, req }) {
    return callApi(
        `/reporting/${databaseId}/tabledefinitions`,
        { 'X-AE-USERKEY': userKey },
        'PUT',
        req,
    );
}

export function deleteTableDefinition({ userKey, databaseId, tableDefinitionId }) {
    return callApi(`/reporting/${databaseId}/tabledefinitions/${tableDefinitionId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE',
    );
}

export function requestTableLoad({ userKey, databaseId, req }) {
    return callApi(
        `reporting/${databaseId}/tables/loads`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        req,
    );
}

export function deleteTableLoad({ userKey, databaseId, tableDefinitionId, tableFileId }) {
    return callApi(
        `reporting/${databaseId}/tables/loads/${tableDefinitionId}/${tableFileId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE'
    );
}

export function getLoadStatus({ userKey, databaseId, tableFileId }) {
    return callApi(
        `reporting/${databaseId}/tables/loads/${tableFileId}/status`,
        { 'X-AE-USERKEY': userKey },
        'GET'
    );
}

export function requestRunReport({ userKey, databaseId, reportDefinitionId }) {
    return callApi(
        `reporting/${databaseId}/reportingtables/runs`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        { reportDefinitionId },
    );
}

export function downloadReport({ userKey, databaseName, reportDefinitionName, apiKey }) {
    return callApi(
        `reporting/wide/${databaseName}/${reportDefinitionName}?apikey=${apiKey}`,
        { 'X-AE-USERKEY': userKey, 'Accept': 'application/csv' },
        'GET'
    );
}

export function getRunStatus({ userKey, databaseId, reportDefinitionRunId }) {
    return callApi(
        `reporting/${databaseId}/reportingtables/runs/${reportDefinitionRunId}/status`,
        { 'X-AE-USERKEY': userKey },
        'GET'
    );
}

export function retrieveReportingTables({ userKey, databaseId }) {
    return callApi(`/reporting/${databaseId}/reportingtables`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
}

export function retrieveReportingTable({ userKey, databaseId, reportingTableId }) {
    return callApi(`/reporting/${databaseId}/reportingtables/${reportingTableId}`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
  }

export function createReportingTable({ userKey, databaseId, req }) {
    return callApi(
        `/reporting/${databaseId}/reportingtables`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        req,
    );
}

export function validateFormula({ userKey, columns, formula }) {
    return callApi(
        `/reporting/formulas/validate`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        {columns, formula},
    );
}

export function updateReportingTable({ userKey, databaseId, req }) {
    return callApi(
        `/reporting/${databaseId}/reportingtables`,
        { 'X-AE-USERKEY': userKey },
        'PUT',
        req,
    );
}

export function deleteReportingTable({ userKey, databaseId, reportingTableId }) {
    return callApi(`/reporting/${databaseId}/reportingtables/${reportingTableId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE',
    );
}

export function getAvailableReportingTables({ userKey, databaseId }) {
    return callApi(`/reporting/${databaseId}/tables`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
}

export function getExtractColumnsByTableName({ userKey, databaseId, physicalTableName }) {
    return callApi(`/reporting/${databaseId}/extracts/${physicalTableName}/columns`,
        { 'X-AE-USERKEY': userKey },
        'GET',
    );
}

export function getReportsByTableDefinition(userKey, databaseId, tableDefinitionId) {
    return callApi(`/reporting/${databaseId}/tabledefinitions/${tableDefinitionId}/reportingtables`, {
      'X-AE-USERKEY': userKey,
    });
}

export function getReportsByExtractDefinition(userKey, databaseId, extractId) {
    return callApi(`reporting/${databaseId}/extracts/${extractId}/reportingtables`, {
      'X-AE-USERKEY': userKey,
    });
}

export function isReportingRunning(userKey, databaseId, reportingDefinitionId) {
    return callApi(`/reporting/isrunning/${databaseId}/${reportingDefinitionId}`,
      { 'X-AE-USERKEY': userKey },
      'GET'
    );
  }