const appSettingsPromise = new Promise((resolve) => {
  const xhr = new XMLHttpRequest();
  xhr.overrideMimeType('application/json');
  xhr.open('GET', '/appSettings.json', true);
  xhr.responseType = 'json';

  xhr.onreadystatechange = () => {
    const status = xhr.status;
    if (xhr.readyState === 4) {
      if (status === 200) {
        let response = xhr.response;
        if (typeof xhr.response === 'string') {
          response = JSON.parse(response);
        }
        resolve(response);
      }
    }
  };
  xhr.send();
});

function getAppSettings() {
  return appSettingsPromise;
}

const env = process.env.NODE_ENV;
const VERSION = process.env.__VERSION__;
export {
  getAppSettings,
  VERSION,
  env,
};
