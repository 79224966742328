import React, { Component } from 'react';
import radium from 'radium';

const reportingStatuses = {completed: 'Completed', failed: 'Failed', executing: 'Executing'
    , na: 'N/A' }

class StatusIndicator extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            status: 'retrieving...',
            count: 0
        }
        this.getStatus = this.getStatus.bind(this);
    }

    componentDidMount(){
        const { initialStatus, initialCount } = this.props;
        this.getStatus(initialStatus);
        this.setState({status: initialStatus, count: initialCount});
    }

    getStatus(initialStatus){
        const { statusCallback } = this.props;
        let terminalStatuses = [reportingStatuses.completed, reportingStatuses.failed, reportingStatuses.na];

        if (terminalStatuses.includes(initialStatus)){
            return;
        }

        statusCallback().then((s) => {
            let status = s.status;
            let count = s.recordCount;
            this.setState({status, count});
            if (status !== reportingStatuses.completed && status !== reportingStatuses.failed){
                setTimeout(()=> {this.getStatus();}, 2000);
            }
        });
    }

    render() {
        const { status, count:c } = this.state;
        let count = c ? c.toLocaleString() : 0;
        if (status === reportingStatuses.failed){
            return <span style={{color: 'red'}}>0</span>
        }

        if (status === reportingStatuses.na){
            return <span>0</span>
        }

        if (status === reportingStatuses.completed){
            return <span >{count}</span>
        }
        return <span><i className="fa fa-refresh fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;retrieving...</span>
    }
}

export default radium(StatusIndicator);
