import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PortalManagementDashboard extends Component {
    render() {
        return (
        <div className="tile-container">
            <h2>Portal Management</h2>
            <div className="tile-row">
                <Link className="tile" to="portalManagement/users">
                    <i className="fa fa-user" />
                    Users
                </Link>
            </div>
            <div className="tile-row">
                <Link className="tile" to="portalManagement/roles">
                    <i className="fa fa-shield" />
                    Roles
                </Link>
                <Link className="tile" to="portalManagement/assignments">
                    <div>
                        <img src="assets/assign_roles_white.svg" alt="assign roles"/>                    
                    </div>
                    Assign Roles
                </Link>
            </div>
        </div>
        );
    }
}

export default PortalManagementDashboard;