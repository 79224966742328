import {
    FETCHING_CATEGORIES,
    RECEIVED_CATEGORIES,
} from './analysis.actionTypes';
import * as api from '../../serviceApi/categories.serviceApi';

function fetchingCategories() {
    return {
        type: FETCHING_CATEGORIES,
    };
}

function receivedCategories(list) {
    return {
      type: RECEIVED_CATEGORIES,
      categories: list,
    };
}

function retrieveCategories(userKey, databaseId) {
    return (dispatch) => {
        dispatch(fetchingCategories());
        return api
            .getCategories(userKey, databaseId)
            .then((res) => {
                if (Array.isArray(res)) {
                    dispatch(receivedCategories(res));
                }
                else dispatch(receivedCategories([]));
                });
    };
}

function createCategory(userKey, databaseId, req) {
    return (dispatch) => {
        return api
            .createCategory(userKey, databaseId, req)
            .then((res) => {
                dispatch(retrieveCategories(userKey, databaseId));
            });
    };
}

function updateCategory(userKey, databaseId, req) {
    return (dispatch) => {
        return api
            .updateCategory(userKey, databaseId, req)
            .then((res) => {
                dispatch(retrieveCategories(userKey, databaseId));
            });
    };
}

function deleteCategory(userKey, databaseId, categoryId) {
    return (dispatch) => {
        return api
            .deleteCategory(userKey, databaseId, categoryId)
            .then((res) => {
                dispatch(retrieveCategories(userKey, databaseId));
            });
    };
}

export {
    retrieveCategories,
    createCategory,
    updateCategory,
    deleteCategory
};
