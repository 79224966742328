import * as api from '../../serviceApi/application.serviceApi';
import { 
  RECEIVED_AUDIT_LOG_CONTENT,
  AUDIT_LOG_CALL_MADE,
  AUDIT_LOG_CSV_CALL_MADE,
  RECEIVED_AUDIT_LOG_CSV,
} from './portalManagement.actionTypes';

function receivedAuditLogContent(log, auditLogFetches) {
  return {
    type: RECEIVED_AUDIT_LOG_CONTENT,
    content: log,
    auditLogFetches,
  };
}

function fetchingAuditLogs(auditLogFetches) {
  return {
    type: AUDIT_LOG_CALL_MADE,
    auditLogFetches,
  };
}

function fetchingAuditLogCSV(auditLogCSVFileFetches) {
  return {
    type: AUDIT_LOG_CSV_CALL_MADE,
    auditLogCSVFileFetches,
  };
}

function receivedAuditLogCSVFile(file, auditLogCSVFileFetches) {
  const auditLogCSVFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
  return {
    type: RECEIVED_AUDIT_LOG_CSV,
    auditLogCSVFile,
    auditLogCSVFileFetches,
  };
}

function fetchAuditLogs(query) {
  return (dispatch, getState) => {
    let auditLogFetches = getState().portalManagement.auditLogFetches;
    auditLogFetches++;
    dispatch(fetchingAuditLogs(auditLogFetches));
    api.retrieveAuditLogs(query)
      .then((log) => {
        auditLogFetches = getState().portalManagement.auditLogFetches;
        auditLogFetches--;
        dispatch(receivedAuditLogContent(log, auditLogFetches));
      });
  };
}

function exportAuditLogs({ userKey, query }) {
  return (dispatch, getState) => {
    let auditLogCSVFileFetches = getState().portalManagement.auditLogCSVFileFetches;
    auditLogCSVFileFetches++;
    dispatch(fetchingAuditLogCSV(auditLogCSVFileFetches)); 
    api.exportAuditLogsToCSV({ userKey, query })
      .then((res) => {
        auditLogCSVFileFetches = getState().portalManagement.auditLogCSVFileFetches;
        auditLogCSVFileFetches--;
        dispatch(receivedAuditLogCSVFile(res, auditLogCSVFileFetches));
      });
  };
}

export { fetchAuditLogs, exportAuditLogs };
