import AuthenticationContext from 'adal-angular';
import { getAppSettings } from '../config';

const CONSTANTS = {
  SAML_BASED_TOKEN: 'samlBasedToken',
};

window.Logging = {
  level: 3,
  log: message => { console.log(message); }, // eslint-disable-line no-console
};

const authCtx = getAppSettings().then(settings => {
  const {
    oAuth2TenantId,
    oAuth2ClientId,
    oAuth2RedirectUrl,
  } = settings;
  const { host } = window.location;

  const ctx = new AuthenticationContext({
    instance: 'https://login.microsoftonline.com/',
    tenant: oAuth2TenantId,
    clientId: oAuth2ClientId,
    redirectUri: `https://${oAuth2RedirectUrl}`,
    postLogoutRedirectUri: `https://${host}/logoff`,
    cacheLocation: 'localStorage', // enable this for IE, as sessionStorage does not work for localhost.
  });

  window.AuthenticationContext = () => ctx;

  if (window.samlBasedToken && window.samlBasedToken !== 'samlTokenString') {
    ctx._saveItem(CONSTANTS.SAML_BASED_TOKEN, window.samlBasedToken);
    const user = ctx._createUser(window.samlBasedToken);
    if (user) {
      ctx._user = user;
    }
  }
  return ctx;
});

function getToken() {
  return new Promise((resolve, reject) => {
    authCtx.then(ctx => {
      const saml = ctx._getItem(CONSTANTS.SAML_BASED_TOKEN);
      if (saml) {
        resolve(saml);
        return;
      }
      const cached = ctx.getCachedToken('https://graph.windows.net');
      if (cached) {
        resolve(cached);
      } else {
        ctx.acquireToken('https://graph.windows.net', (error, token) => {
          if (error) {
            if (error.includes('AADSTS50058')) {
              ctx.clearCache();
              ctx.login();
            }
            if (error === 'login required' || (error && !!ctx.getCachedUser())) {
              ctx.login();
            }
            if (!token) {
              reject(error);
            }
          } else {
            resolve(token);
          }
        });
      }
    });
  });
}

function getUserFromSamlToken(ctx) {
  const samlToken = ctx._getItem(CONSTANTS.SAML_BASED_TOKEN);
  if (samlToken) {
    const user = ctx._createUser(samlToken);
    if (user) {
      window.samlBasedToken = samlToken;
      ctx._user = user;
    }
    return user;
  }
  return null;
}

function requireAuth() {
  return authCtx.then(ctx => {
    if (ctx.isCallback(window.location.hash)) {
      ctx.handleWindowCallback();
      return !!ctx.getCachedUser();
    }
    if (!ctx.getCachedUser() && !getUserFromSamlToken(ctx)) {
      ctx.login();
      return false;
    }
    return true;
  });
}

function processTokenResponse() {
  authCtx.then(ctx => {
    ctx.handleWindowCallback();
  });
}

function logoff() {
  authCtx.then(ctx => { 
    ctx._saveItem(CONSTANTS.SAML_BASED_TOKEN, '');
    ctx.logOut(); 
  });
}

function getCachedUser() {
  const ctx = window.AuthenticationContext ? window.AuthenticationContext() : null;
  if (ctx) {
    return ctx.getCachedUser();
  }
  return undefined;
}

export { getToken, requireAuth, processTokenResponse, logoff, getCachedUser };
