export const SELECTING_TOD_DATABASE = 'SELECTING_TOD_DATABASE',
  CLEAR_TOD_DATABASE = 'CLEAR_TOD_DATABASE',
  RECEIVED_TOD_DATABASE = 'RECEIVED_TOD_DATABASE',
  RECEIVED_TOD_DATA_DELETE_STATUS = 'RECEIVED_TOD_DATA_DELETE_STATUS',
  RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE = 'RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE',
  RECEIVED_TOD_DATABASE_BACKUPS = 'RECEIVED_TOD_DATABASE_BACKUPS',
  RECEIVED_TOD_DATABASE_ARCHIVED = 'RECEIVED_TOD_DATABASE_ARCHIVED',
  RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP =
    'RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP',
  RECEIVED_TOD_DATABASE_INFO = 'RECEIVED_TOD_DATABASE_INFO',
  RECEIVED_TOD_DATABASES = 'RECEIVED_TOD_DATABASES',
  RECEIVED_DATABASE_MAPPINGS = 'RECEIVED_DATABASE_MAPPINGS',
  RECEIVED_DATABASE_MAPPING = 'RECEIVED_DATABASE_MAPPING',
  RECEIVED_TOD_UPLOADS = 'RECEIVED_TOD_UPLOADS',
  RECEIVED_COLUMNS_FOR_FILE = 'RECEIVED_COLUMNS_FOR_FILE',
  RECEIVED_DATE_FORMATS = 'RECEIVED_DATE_FORMATS',
  RECEIVED_DATE_FORMAT_DELIMITERS = 'RECEIVED_DATE_FORMAT_DELIMITERS',
  RECEIVED_COLUMN_TYPES = 'RECEIVED_COLUMN_TYPES',
  RECEIVED_COLUMNS_FOR_TOD_DB = 'RECEIVED_COLUMNS_FOR_TOD_DB',
  RECEIVED_TOTALS_FOR_TOD_DB = 'RECEIVED_TOTALS_FOR_TOD_DB',
  RECEIVED_QUERIES = 'RECEIVED_QUERIES',
  RECEIVED_QUERY = 'RECEIVED_QUERY',
  RECEIVED_QUERY_DATASOURCE = 'RECEIVED_QUERY_DATASOURCE',
  RECEIVED_SEGMENT_PREVIEW = 'RECEIVED_SEGMENT_PREVIEW',
  RECEIVED_TOTALS_TABLE = 'RECEIVED_TOTALS_TABLE',
  RECEIVED_COLUMN_VALIDATION = 'RECEIVED_COLUMN_VALIDATION',
  RECEIVED_TOD_DB_VERSION = 'RECEIVED_TOD_DB_VERSION',
  RECEIVED_TOD_DATABASE_DATALOADS = 'RECEIVED_TOD_DATABASE_DATALOADS',
  RECEIVED_TOD_CSV_FILE = 'RECEIVED_TOD_CSV_FILE',
  RECEIVED_TOD_EXCEL_FILE = 'RECEIVED_TOD_EXCEL_FILE',
  FETCHING_TOD_DATABASES = 'FETCHING_TOD_DATABASES',
  SAVING_TOD_UPLOADS = 'SAVING_TOD_UPLOADS',
  SAVING_DATA_MAPPINGS = 'SAVING_DATA_MAPPINGS',
  SAVING_TOD_DATABASE = 'SAVING_TOD_DATABASE',
  SAVING_TOD_QUERY = 'SAVING_TOD_QUERY',
  FETCHING_TOD_UPLOADS = 'FETCHING_TOD_UPLOADS',
  FETCHING_TOD_DATA_LOADS = 'FETCHING_TOD_DATA_LOADS',
  FETCHING_TOD_DATABASE_INFO = 'FETCHING_TOD_DATABASE_INFO',
  FETCHING_QUERIES = 'FETCHING_QUERIES',
  FETCHING_QUERY_DATA_SOURCE = 'FETCHING_QUERY_DATA_SOURCE',
  FETCHING_TOD_TOTALS = 'FETCHING_TOD_TOTALS',
  FETCHING_TOD_TOTALS_TABLE = 'FETCHING_TOD_TOTALS_TABLE',
  RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS =
    'RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS',
  FETCHING_ROLLUP_DEFIINITION_FROM_SERVER =
    'FETCHING_ROLLUP_DEFIINITION_FROM_SERVER',
  RECEIVED_ROLLUP_DEFINITION = 'RECEIVED_ROLLUP_DEFINITION',
  RECEIVED_DATASET_COLUMNS = 'RECEIVED_DATASET_COLUMNS',
  CLEAR_ROLLUPDEFINITIONS = 'CLEAR_ROLLUPDEFINITIONS',
  FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION =
    'FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION',
  PROCESSING_NEW_ROLLUP_DEFINITION = 'PROCESSING_NEW_ROLLUP_DEFINITION',
  STORE_QUERY = 'STORE_QUERY',
  CLEAR_QUERY = 'CLEAR_QUERY',
  FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER =
    'FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER',
  RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER =
    'RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER',
  DELETED_DATA_LOADS = 'DELETED_DATA_LOADS',
  DELETED_VALUATION_DATE = 'DELETED_VALUATION_DATE',
  DELETING_DATA_LOADS = 'DELETING_DATA_LOADS',
  DELETING_VALUATION_DATE = 'DELETING_VALUATION_DATE',
  RECEIVED_QUERIES_USING_ROLLUP = 'RECEIVED_QUERIES_USING_ROLLUP',
  RECEIVED_TOD_DATA_LOAD_LOG_FILE = 'RECEIVED_TOD_DATA_LOAD_LOG_FILE',
  RECEIVED_BACKUPRESTORE_LOG_FILE = 'RECEIVED_BACKUPRESTORE_LOG_FILE',
  RECEIVED_V2_QUERY = 'RECEIVED_V2_QUERY',
  FETCHING_DATAGROUPS = 'FETCHING_DATAGROUPS',
  FETCHING_DATAGROUP = 'FETCHING_DATAGROUP',
  RECEIVED_DATAGROUPS = 'RECEIVED_DATAGROUPS',
  FETCHING_ROLLUPS = 'FETCHING_ROLLUPS',
  RECEIVED_ROLLUPS = 'RECEIVED_ROLLUPS',
  RECEIVED_DATAGROUP = 'RECEIVED_DATAGROUP',
  FETCHING_FACTCOLUMNS = 'FETCHING_FACTCOLUMNS',
  FETCHING_OPERATORS = 'FETCHING_OPERATORS',
  RECEIVED_FACTCOLUMNS = 'RECEIVED_FACTCOLUMNS',
  RECEIVED_OPERATORS = 'RECEIVED_OPERATORS',
  FETCHING_QUERY_VALIDATION = 'FETCHING_QUERY_VALIDATION',
  RECEIVED_QUERY_VALIDATION = 'RECEIVED_QUERY_VALIDATION',
  RECEIVED_QUERY_SQL = 'RECEIVED_QUERY_SQL',
  RECEIVED_QUERY_SPLITS = 'RECEIVED_QUERY_SPLITS',
  FETCHING_VALUES = 'FETCHING_VALUES',
  RECEIVED_VALUES = 'RECEIVED_VALUES',
  RECEIVED_QUERY_SEGMENTS = 'RECEIVED_QUERY_SEGMENTS',
  FETCHING_QUERY_SEGMENTS = 'FETCHING_QUERY_SEGMENTS',
  FETCHING_LINKED_DATABASES = 'FETCHING_LINKED_DATABASES',
  RECEIVED_LINKED_DATABASES = 'RECEIVED_LINKED_DATABASES',
  FETCHING_LINKED_DATABASE_MAPPING = 'FETCHING_LINKED_DATABASE_MAPPING',
  RECEIVED_LINKED_DATABASE_MAPPING = 'RECEIVED_LINKED_DATABASE_MAPPING',
  CHANGE_LINKED_DATABASE_COLUMN_MAPPING = 'CHANGE_LINKED_DATABASE_COLUMN_MAPPING',
  SAVING_LINKED_DATABASE_MAPPING = 'SAVING_LINKED_DATABASE_MAPPING',
  DELETING_LINKED_DATABASE_MAPPING = 'DELETING_LINKED_DATABASE_MAPPING',
  CLEAR_LINKED_DATABASES = 'CLEAR_LINKED_DATABASES',
  DELETED_LINKED_DATABASE_MAPPING = 'DELETED_LINKED_DATABASE_MAPPING',
  SAVED_LINKED_DATABASE_MAPPING = 'SAVED_LINKED_DATABASE_MAPPING';
