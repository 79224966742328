import React from 'react';
import { Link } from 'react-router-dom';

const ProcessLogoff = () => (
  <div style={{ display: 'flex', justifyContent: 'center', margin: '25vh' }}>
    <span>You were logged out. <Link to="/home">Log in</Link> again.</span>
  </div>
);

export default ProcessLogoff;
