import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form, Row, Col} from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import {
  fetchSubscriptionUser,
} from '../../../actions/portalManagement/subscriptionUser.actions';
import { notAuthorized } from '../../shared/containers/conditionalLink';
import SaveButtons from '../../shared/presentational/saveButtons';
import { validateName, validateEmail } from '@app/utilities/validators';

const DEFAULT_USER = {name: '', email: '', description: '', isActive: true};

class UserAddUpdate extends Component {
    static propTypes = {
        users: PropTypes.object,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        params: PropTypes.object,
        newSaveHandler: PropTypes.func,
        editSaveHandler: PropTypes.func,
        readOnly: PropTypes.bool,
    };
    static defaultProps = {
        availableRoles: [],
        assignedRoles: [],
        admin: false,
        userName: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            availableGroups: [],
            assignedGroups: [],
            editing: false,
            record: DEFAULT_USER
        };
        this.saveUser = this.saveUser.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.backToUsers = this.backToUsers.bind(this);
    }

    get isInvalid() {
        const { record } = this.state;
        return record.errorName || record.errorEmail;
    }

    componentDidMount() {
        const { params, dispatch, userKey, browserHistory } = this.props,
        userId = params.userId,
        editing = !!userId;
        
        if (editing && userId) {
            dispatch(fetchSubscriptionUser(userKey, userId));
        } else {
            this.setState({record: {...DEFAULT_USER, errorName: '', errorEmail: ''}});
        }

        if (this.props.readOnly) {
            browserHistory.replace('/portalManagement/users');
        }
        this.setState({ userId, editing });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.users && prevProps.users) {
            const { users } = this.props;
            if (
                users.subscriptionUser &&
                prevProps.users.subscriptionUser !== users.subscriptionUser
            ) {
                const user = users.subscriptionUser;
                this.setState({record: user });
            }
        }
    }

    backToUsers() {
        const { browserHistory } = this.props;
        browserHistory.push('/portalManagement/users');
    }

    saveUser() {
        let { record, editing } = this.state;
        const { userKey, readOnly, browserHistory, newSaveHandler, editSaveHandler } = this.props;
        if (readOnly) {
            notAuthorized();
            return;
        }

        // validate before save
        record.errorEmail = this.validateEmail(record);
        record.errorName = this.validateName(record);
        if (record.errorEmail || record.errorName){
            this.setState({record});
            return;
        }

        if (editing) {
            editSaveHandler({userKey,...record, browserHistory});
        } else {
            newSaveHandler({userKey,...record, browserHistory});
        }
    }

    getTextControls() {
        const { record, editing } = this.state;
        if (this.props.isFetching) { return <div style={{ width: '85vw', margin: '5vh auto' }}>
                <Loader loadingItem='User' />
            </div> }
        if (!record) { return '';}

        return <Row>
            <Col md={6}>
                <Form.Group>
                <Form.Label>Name</Form.Label>
                    <Form.Control
                        className="form-control"
                        as="input" maxLength="255"
                        value={record.name}
                        onChange={this.handleNameChange}
                        isInvalid={record.errorName}
                    />
                    <Form.Control.Feedback type='invalid'>{record.errorName}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                <Form.Label>Email</Form.Label>
                    <Form.Control
                        className="form-control"
                        as="input" maxLength="255"
                        value={record.email}
                        onChange={this.handleEmailChange}
                        isInvalid={record.errorEmail}
                    />
                    <Form.Control.Feedback type='invalid'>{record.errorEmail}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group>
                <Form.Label>Description</Form.Label>
                    <Form.Control
                        className="form-control"
                        as="textarea" maxLength="1500"
                        value={record.description}
                        onChange={this.handleDescChange}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group controlId="fileSelect">
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select"
                        value = {record.isActive ? "1" : "0"}
                        onChange={this.handleStatusChange}
                        disabled={!editing}
                    >
                        <option key={`isactive-1`} value={1}>Active</option>
                        <option key={`isactive-0`} value={0}>Inactive</option>
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>;
    }

    handleNameChange(e){
        let { record } = this.state;
        record.name = e.target.value;
        record.errorName = this.validateName(record);
        this.setState({record});
    }

    handleEmailChange(e){
        let { record } = this.state;
        record.email = e.target.value;
        record.errorEmail = this.validateEmail(record);
        this.setState({record});
    }

    handleDescChange(e){
        let { record:r } = this.state;
        let record = {...r, description: e.target.value};
        this.setState({record});
    }

    handleStatusChange(e){
        let { record:r } = this.state;
        let record = {...r, isActive: e.target.value === "1"};
        this.setState({record});
    }

    validateName(user) {
        const { allActiveAndInactiveUsers:users } = this.props.users;
        let existing = users.filter(x=> x.userId !== user.userId).map(x=> x.name.toLowerCase().trim());
        let s = user.name;
        if (!s || /^\s*$/.test(s)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let error = existing.filter(x=> s.toLowerCase().trim() === x).length ? 'Already exists' : '';
        error = error ? error : validateName(s);
        return error;
    }

    validateEmail(user) {
        const { allActiveAndInactiveUsers:users } = this.props.users;
        let existing = users.filter(x=> x.userId !== user.userId).map(x=> x.email.toLowerCase().trim());
        let s = user.email;
        if (!s || /^\s*$/.test(s)) {
            // name must exist and not be whitespace
            return 'Required';
        }

        let error = existing.filter(x=> s.toLowerCase().trim() === x).length ? 'Already exists' : '';
        error = error ? error : validateEmail(s);
        return error;
    }

    render() {
        const {editing} = this.state;

        return (
        <div>
            <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', height: 'calc(100vh - 106px)' }}>
                <div style={{borderBottom: '2px solid #BDBDBD', marginBottom: 20,}}>
                    <h2 style={{ paddingLeft: 15 }}>{editing ? 'Edit' : 'Add'} User</h2>
                </div>
                {this.getTextControls()}
            </div>
            <SaveButtons
                saveHandler={this.saveUser}
                backButtonHander={this.backToUsers}
                backButtonText="Back to Users"
                isSaveButtonDisabled={this.isInvalid}
            />
        </div>
        );
    }
}

export default radium(UserAddUpdate);
