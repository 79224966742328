import React, { Component } from 'react';
import radium from 'radium';
import { Form } from 'react-bootstrap';

import { MODEL_SELECTED } from '@app/actions/analytics/analytics.actionTypes';
import Loader from '@app/shared/presentational/loader';
import { Grid, Button } from '@arius';
import ModalRunModel from './modalRunModel';
import { notifyError } from '@app/utilities/notifier';
import { notAuthorized } from '@app/shared/containers/conditionalLink';

class AdvancedAnalyticsMain extends Component {
    static defaultProps = {
        uploads: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            lastTODUpload: '',
            selectedModel: null
        };
        this.getGrid = this.getGrid.bind(this);
        this.showModal = this.showModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.upload = this.upload.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.selectModel = this.selectModel.bind(this);
    }

    get runInProgress(){
        const { results } = this.props;
        return results.length ? !results[0].isPipelineAvailable : false;
    }

    componentDidMount() {
        const { selectedModel, history} = this.props;
        if (selectedModel){
            history.push(`/analytics/models/${selectedModel.modelId}`);
            return;
        }
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        const { models, params } = this.props;
        const { selectedModel } = this.state;
        let modelId = selectedModel ? selectedModel.modelId : '';

        if (models.length && params.modelId && params.modelId !== modelId){
            var m = models.find(x=> params.modelId === x.modelId);
            this.setState({selectedModel: m});
            return;
        }
        if (models !== prevProps.models || selectedModel !== prevState.selectedModel){
            this.refresh();
        }
    }

    refresh(){
        const { userKey, getResults, getModels, models, isFetching } = this.props;
        const { selectedModel } = this.state;
        if (isFetching){
            return;
        }
        if (models.length && selectedModel){
            getResults(userKey, selectedModel.modelId);
        } else if (!models.length) {
            getModels(userKey)
        }
    }

    selectModel(e) {
        const { models } = this.props;
        let modelId = e.target.value;
        let model = models.find(x=> x.modelId === modelId);
        
        let url = modelId ? `/analytics/models/${modelId}` : `/analytics/models`;
        
        this.props.dispatch( {type: MODEL_SELECTED, selectedModel: model});
        this.props.history.push(url);
            
        if (!modelId){
            this.setState({selectedModel: null});
        }
	}

    upload(fileName){
        const { canUtilize } = this.props;
        if (!canUtilize){
            notAuthorized();
            return;
        }

        if (this.runInProgress){
            notifyError('A run is currently in progress');
            return;
        }

        const { userKey, requestUploadToTOD, isRequestingUpload } = this.props;
        if (isRequestingUpload){
            return;
        }

        this.setState({lastTODUpload: fileName})
        requestUploadToTOD(userKey, {sourceBlobName: fileName, modelId: this.state.selectedModel.modelId});
    }

    getActionItems(row){
        const { isRequestingUpload } = this.props;
        const { lastTODUpload } = this.state;

        return <Button key="tt"
            toolTip="Upload to TOD" 
            iconName="fa-upload" 
            isWaiting={isRequestingUpload && lastTODUpload === row.name}
            onClick={(e) => {this.upload(row.name)}}
        />
    }

    getGrid() {
        const { results } = this.props;
        let columns = [
            { field: 'id', isPrimaryKey: true, visible: false},
            { field: 'name', headerText: 'ToD Output File'}
        ];

        if (results.length){
            let paramColumns = results[0].params.map(k => {return {field: k.key, headerText: k.name, width: 120}});
            columns.push(...paramColumns);    
        }

        columns.push(...[
            { field: 'status', headerText: 'Status', width: 120},
            { field: 'requestedBy', headerText: 'Last Submitted By'},
            { field: 'requested', headerText: 'Last Submitted', type: 'datetime'},
            { field: 'lastUpdated', headerText: 'Last Updated'},
            { headerText: 'Actions', template: this.getActionItems}
        ]);
        
        let data = results.map(r => {
            r.params.map(p => r[p.key] = p.value);
            return r;
        })
        return (<Grid 
            data={data} 
            columns={columns}
            height='calc(100vh - 250px)'
        />)
    }

    showModal() {
        const { canUtilize } = this.props;
        if (!canUtilize){
            notAuthorized();
            return;
        }
        
        if (this.runInProgress){
            notifyError('A run is currently in progress');
            return;
        }
        this.setState({showModal: true});
    }

    getPBILink() {
        const { history } = this.props;
        return <span className='clickable project-link' onClick={() => history.push(`/arius/powerbi`)}>See PBI reports</span>;
    }

    render() {
        const { isFetching, results, getParams, userKey, inputParams, requestModelRun, models } = this.props;
        const { showModal, selectedModel } = this.state;
        let contentMarkup = (<div></div>);

        if (isFetching) {
            contentMarkup = (<Loader loadingItem="Uploads" />);
        }

        if (selectedModel && results.length > 0) {
            contentMarkup = this.getGrid();
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <span style={{display:'flex'}}>
                    <h4>Advanced Analytics</h4>
                </span>
                <span style={{display:'flex', width: 270, justifyContent: 'space-between'}}>
                    {this.getPBILink()}
                    <Button disabled={!selectedModel} onClick={this.refresh} width='30' variant='outline-secondary' toolTip='refresh status'>
                        <i className="fa fa-refresh" aria-hidden="true"/>
                    </Button>
                    <Button variant="arius" disabled={!selectedModel} onClick={this.showModal}>Run Analysis</Button>
                </span>
            </div>
            <Form.Group
				controlId="selectModelControl"
				style={{ width: '25vw', marginTop: 10 }}
			>
				<Form.Label>Select Model</Form.Label>
				<Form.Control
					as="select"
					placeholder="select"
					value={selectedModel ? selectedModel.modelId : ''}
					onChange={this.selectModel}
				>
					<option value="">Select a model</option>
					{models.map(m => <option key={`${m.modelId}`} value={m.modelId}>{m.name}</option>)}
				</Form.Control>
			</Form.Group>
            {contentMarkup}

            <ModalRunModel
                show={showModal} 
                closeHandler={()=> this.setState({showModal: false})}
                getParams={getParams}
                userKey={userKey}
                params={inputParams}
                requestModelRun={requestModelRun}
                selectedModel={selectedModel}
            />
        </div>
        )
    }
}

export default radium(AdvancedAnalyticsMain);
