import {
  FETCHING_SCRIPTS,
  SCRIPTS_RECEIVED,
  FETCHING_SCRIPT,
  SCRIPT_RECEIVED,
  RECEIVED_SCRIPT_COMMANDS,
  FETCHING_SCRIPT_COMMANDS,
  CREATING_SCRIPT,
  SCRIPT_CREATED,
  MAPPERS_RECEIVED,
  COMMAND_STATUSES_RECEIVED,
  FETCHING_COMMAND_STATUSES,
  CLEAR_SCRIPTS,
} from '../../actions/analysis/analysis.actionTypes';

const automationReducer = (
  state = {
    isFetching: false,
    isFetchingCommandStatuses: false,
    scripts: [],
    commandStatuses: [],
    mappers: [],
    scriptCommands: [],
    selectedScript: null,
    isCreatingScript: false,
  },
  action
) => {
  switch (action.type) {
    case FETCHING_SCRIPTS:
      return Object.assign({}, state, { isFetching: true });

    case SCRIPTS_RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        scripts: action.scripts,
      });
    
    case CLEAR_SCRIPTS:
      return Object.assign({}, state, {
        isFetching: false,
        scripts: [],
      });
    
    case MAPPERS_RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        mappers: action.mappers,
      });

    case FETCHING_SCRIPT:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case SCRIPT_RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        selectedScript: action.script,
      });

    case FETCHING_SCRIPT_COMMANDS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    
    case FETCHING_COMMAND_STATUSES:
      return Object.assign({}, state, {
        isFetchingCommandStatuses: true,
      });

    case CREATING_SCRIPT:
      return Object.assign({}, state, {
        isCreatingScript: true,
      });

    case SCRIPT_CREATED:
      return Object.assign({}, state, {
        isCreatingScript: false,
      });

    case RECEIVED_SCRIPT_COMMANDS:
      return Object.assign({}, state, {
        isFetching: false,
        scriptCommands: action.scriptCommands,
      });
    
    case COMMAND_STATUSES_RECEIVED:
      return Object.assign({}, state, {
        isFetchingCommandStatuses: false,
        commandStatuses: action.commandStatuses,
      });

    default:
      return state;
  }
};

export default automationReducer;
