import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Button } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

const style = {
    listBox: {
        height: '30vh',
        //minWidth: 300,
        listStyle: 'none',
        backgroundColor: 'white',
        padding: 20,
        overflowY: 'scroll',
    },
    activeItem: {
        backgroundColor: Colors.lightGrey,
        cursor: 'pointer',
    },
    inactiveItem: {
        cursor: 'pointer',
    },
    button: {
        marginBottom: 10,
        width: 100,
        textAlign: 'center'
    },
};

class AssignReviewDualListBox extends Component {
    static propTypes = {
        available: PropTypes.array,
        assigned: PropTypes.array,
        onChanges: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            available: [],
            assigned: [],
        };
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addAllItems = this.addAllItems.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
    }

    componentWillReceiveProps(nextProps) 
    {
        this.setState({
            available: this.alphabetizeItems(nextProps.available),
            assigned: this.alphabetizeItems(nextProps.assigned),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { assigned, available} = this.state;

        if (prevState.assigned !== assigned) {
            this.props.onChanges(assigned, available);
        }
    }

    addItem() {
        const
            { selectedItem } = this.state,
            available = this.state.available.slice(),
            assigned = this.state.assigned.slice();

        if (selectedItem) {
            if (!assigned.includes(selectedItem) && available.includes(selectedItem)) {
                assigned.push(selectedItem);
                available.splice(available.indexOf(selectedItem), 1);
                this.setState({ assigned: this.alphabetizeItems(assigned), available: this.alphabetizeItems(available) });
            }
        }
    }

    addItems = (items) => {
        let available = this.state.available.slice();
        let assigned = this.state.assigned.slice();
        items = Array.isArray(items) ? items : [items];

        for (let i = 0, l = items.length; i < l; i++) {
            let selectedItem = items[i];
            if (!assigned.includes(selectedItem) && available.includes(selectedItem)) {
                assigned.push(selectedItem);
                available.splice(available.indexOf(selectedItem), 1);
            }
        }
        this.setState({ assigned: this.alphabetizeItems(assigned), available: this.alphabetizeItems(available)});
    }

    removeItem() {
        const
            { selectedItem } = this.state,
            available = this.state.available.slice(),
            assigned = this.state.assigned.slice();

        if (selectedItem) {
            if (!available.includes(selectedItem) && assigned.includes(selectedItem)) {
                available.push(selectedItem);
                assigned.splice(assigned.indexOf(selectedItem), 1);
                this.setState({ assigned: this.alphabetizeItems(assigned), available: this.alphabetizeItems(available) });
            }
        }
    }

    removeItems = (items) => {
        let available = this.state.available.slice();
        let assigned = this.state.assigned.slice();
        items = Array.isArray(items) ? items : [items];

        for (let i = 0, l = items.length; i < l; i++) {
            let selectedItem = items[i];
            if (!available.includes(selectedItem) && assigned.includes(selectedItem)) {
                available.push(selectedItem);
                assigned.splice(assigned.indexOf(selectedItem), 1);
            }
        }
        this.setState({ assigned: this.alphabetizeItems(assigned), available: this.alphabetizeItems(available)});
    }

    addAllItems() {
        let { available, assigned } = this.state;
        this.setState({ assigned: this.alphabetizeItems([...assigned, ...available]), available:[] });
    }

    removeAllItems() {
        let { available, assigned } = this.state;
        this.setState({ assigned: [], available: this.alphabetizeItems([...assigned, ...available]) });
    }

    selectItem(item, e) {
        e.stopPropagation();
        let { selectedItem } = this.state;
        selectedItem = selectedItem === item ? null : item;
        this.setState({ selectedItem });
    }

    alphabetizeItems(items) {
        return items && Array.isArray(items) ? items.sort((a, b) => {
            if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        }) : [];
    }

    render() {
        const { available, selectedItem, assigned } = this.state;

        let listBox= (

    <div className="row">
        <div className="col-sm-5" style={{}}>
            <div className="form-group">
                <label>Available Users</label>
                <ul style={style.listBox} className="form-control">
                    {available.map(item =>
                    <li key={item.userId}
                        value={item.userId}
                        style={selectedItem === item ? style.activeItem : style.inactiveItem }
                        onClick={(e) => this.selectItem(item, e)}
                        onDoubleClick={(e) => {this.addItems(item)}}
                        >{item.name}</li>
                    )}
                </ul>
            </div>
        </div>
        <div className="col-sm-2" style={{textAlign: 'center'}}>
            <label>&nbsp;&nbsp;</label>
            <div className=''>
                <Button variant="outline-secondary" style={style.button} onClick={this.addAllItems}>
                    <i className="material-icons">list</i>
                    <i className="material-icons">keyboard_arrow_right</i>
                </Button>
                <Button variant="outline-secondary" style={style.button} onClick={this.addItem}>
                    <i className="material-icons">keyboard_arrow_right</i>
                </Button>
                <Button variant="outline-secondary" style={style.button} onClick={this.removeItem}>
                    <i className="material-icons">keyboard_arrow_left</i>
                </Button>
                <Button variant="outline-secondary" style={style.button} onClick={this.removeAllItems}>
                    <i className="material-icons">keyboard_arrow_left</i>
                    <i className="material-icons">list</i>
                </Button>
            </div>
        </div>
        <div className="col-sm-5" style={{}}>
            <div className="form-group">
                <label>Assigned Users</label>
                <ul style={style.listBox} className="form-control">
                    {assigned.map(item =>
                    <li key={item.userId}
                        value={item.userId}
                        style={selectedItem === item ? style.activeItem : style.inactiveItem}
                        onClick={(e) => this.selectItem(item, e)}
                        onDoubleClick={(e) => {this.removeItems(item)}}
                        >{item.name}</li>
                    )}
                </ul>
            </div>
        </div>
    </div> 
        )
    
    return listBox;
  }
}

export default radium(AssignReviewDualListBox);
