import * as React from 'react';
import { Modal, Form } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

class ProjectEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { newName: '', projectId: 0, isDirty: false };
        this.save = this.save.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    save() {
        const { saveHandler } = this.props;
        const { newName, projectId } = this.state;
        saveHandler(newName, projectId);
    }

    handleOpen() {
        const { project } = this.props;
        let projectName = project ? project.projectName : 'none';
        let projectId = project ? project.projectId : 0;
        this.setState({newName: projectName, projectId, isDirty: false });
    }

    handleChange(e) {
        let s = e.target.value;
        let isValid = /^[a-zA-Z]/.test(s) && /^[a-zA-Z0-9]{2,154}$/.test(s);// starts with a letter and length 2-155
        this.setState({newName: s, isDirty: true, isValid });
    }

    render() {
        const { show, project, closeHandler } = this.props;
        let projectName = project ? project.projectName : 'none';
        const { newName, projectId } = this.state;
        return (
            <Modal show={show} onHide={closeHandler} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>{`${projectId} - ${projectName}`}</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="newProjectName">
                                <Form.Label>New Project Name:</Form.Label>
                                <Form.Control value={newName} onChange={this.handleChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <button key="confirmButton"
                        className="btn btn-info dialog-button"
                        onClick={this.save}
                    >Save</button>
                    <button key="nobutton"
                        className="btn btn-info dialog-button"
                        onClick={closeHandler}>Cancel</button>
                    </Modal.Footer>
            </Modal>
        );
    }
}

export default ProjectEditModal;