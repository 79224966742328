import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Route, Switch, Redirect } from 'react-router-dom';
import AutomationTabSidebar from './automationTabSidebar';
import Scripts from './scripts';
import CreateScripts from './createScripts';
import Status from './status';
import { clearValuationDates, clearValuationDate } from '../../../actions/analysis/project.actions';
import { WORKSPACE_MANAGEJOBS, createPermissionChecker } from '../../../utilities/permissions';

class Automation extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        scripts: PropTypes.array,
        fetchScriptsHandler: PropTypes.func,
        isFetching: PropTypes.bool,
        scriptCommands: PropTypes.array,
        fetchScriptCommandsHandler: PropTypes.func,
        selectedScript: PropTypes.object,
        createScriptHandler: PropTypes.func,
        updateScriptHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        userId: PropTypes.string,
        params: PropTypes.object,
        editScriptHandler: PropTypes.func,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        projects: PropTypes.array,
        isFetchingWorkSpaces: PropTypes.bool,
        isFetchingProjects: PropTypes.bool,
        isFetchingCommandStatuses: PropTypes.bool,
        cancelBatch: PropTypes.func,
        userPermissions: PropTypes.array,
        mappers: PropTypes.array,
        fetchMappersHandler: PropTypes.func,
        fetchCommandStatuses: PropTypes.func,
        commandStatuses: PropTypes.array,
        collectionSet: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            commandStatusHeight: '30',
            openedCommandStatus: null,
            commandJob: null,
            commandProject: null,
            commandStatuses: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const { commandStatuses, currentWorkspace, dispatch } = this.props;
        if (commandStatuses !== nextProps.commandStatuses) {
            if (nextProps.commandStatuses.length) {
                this.setState({ commandStatuses: nextProps.commandStatuses.sort(this.compareCommandOrderNumbers) });
            } else {
                this.setState({ commandStatuses: nextProps.commandStatuses });
            }
        }
        if (currentWorkspace !== nextProps.currentWorkspace) {
            dispatch(clearValuationDates());
            dispatch(clearValuationDate());
        }
    }

    compareCommandOrderNumbers(a, b) {
        if (a.commandOrder < b.commandOrder) return -1;
        if (a.commandOrder > b.commandOrder) return 1;
        return 0;
    }

    render() {
        const { openedCommandStatus, commandJob, commandProject, commandStatuses } = this.state,
        {
            isFetching,
            isFetchingProjects,
            isFetchingWorkSpaces,
            scripts,
            fetchScriptsHandler,
            scriptCommands,
            selectedScript,
            createScriptHandler,
            updateScriptHandler,
            fetchScriptCommandsHandler,
            currentWorkspace,
            workspaces,
            dispatch,
            userKey,
            userId,
            params,
            editScriptHandler,
            longRunningOperationsStatus,
            clearLongRunningOperationsStatus,
            getLongRunningOperationsStatus,
            projects,
            cancelBatch,
            userPermissions,
            fetchMappersHandler,
            fetchCommandStatuses,
            isFetchingCommandStatuses,
            mappers,
            isFetchingGenericLists,
            collectionSets,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            browserHistory,
            location,
            secureDataManagerFactory,
            baseUrl
        } = this.props,
        verifyPermission = createPermissionChecker(userPermissions);

        let tableHeight,maxHeight;

        let childProps = {
            browserHistory,
            cancelBatch,
            clearLongRunningOperationsStatus,
            isFetchingGenericLists,
            collectionSets,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            commandStatuses,
            createScriptHandler,
            currentWorkspace,
            dbChangeAllowed:verifyPermission(WORKSPACE_MANAGEJOBS),
            dispatch,
            editScriptHandler,
            fetchCommandStatuses,
            fetchMappersHandler,
            fetchScriptCommandsHandler,
            fetchScriptsHandler,
            getLongRunningOperationsStatus,
            isFetching,
            isFetchingCommandStatuses,
            isFetchingProjects,
            isFetchingWorkSpaces,
            job:commandJob,
            longRunningOperationsStatus,
            mappers,
            maxHeight:maxHeight,
            openedCommandStatus,
            params,
            project:commandProject,
            projects,
            scriptCommands,
            scripts,
            selectedScript,
            tableHeight:tableHeight,
            updateScriptHandler,
            userId,
            userKey,
            workspaces,
            secureDataManagerFactory,
            location
        };

        return (
        <div style={{display: 'flex'}}>
            <AutomationTabSidebar baseUrl={baseUrl}/>
            <Switch>
                <Route path="/arius/automation/:databaseId/status" render={props => (<Status {...childProps}/>)}/>
                <Route path="/arius/automation/:databaseId/scripts/:scriptId" render={props => (<CreateScripts {...childProps}/>)}/>
                <Route path="/arius/automation/:databaseId/scripts" render={props => (<Scripts {...childProps}/>)}/>
                <Route path="/arius/automation/status" render={props => (<Status {...childProps}/>)}/>
                <Route path="/arius/automation/scripts" render={props => (<Scripts {...childProps}/>)}/>
                <Route path="/arius/automation" render={props => <Redirect to='/arius/automation/status'/>}/>
            </Switch>
        </div>
        );
    }                                 
}

const mapStateToProps = state => ({
  currentWorkspace: state.analysis.workspaces.current,
  segments: state.analysis.segments.items,
  workspaces: state.analysis.workspaces.items,
  projects: state.analysis.projects.items,
  isFetching: state.analysis.projects.isFetching,
  isFetchingProjects: state.analysis.projects.isFetchingProjects,
  isFetchingWorkspaces: state.analysis.workspaces.isFetching,
  isFetchingSegments: state.analysis.segments.isFetchingSegments,
  isFetchingGenericLists: state.analysis.projects.isFetchingGenericLists,
  userKey: state.user.userKey,
  application: state.application,
  batchProjects: state.analysis.projects.batchProjects,
  longRunningOperationsStatus: state.analysis.projects
    .longRunningOperationsStatus,
  currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
  currentUdoVersionNbr: state.analysis.projects.currentUdoVersionNbr,
  copyingProject: state.analysis.projects.copyingProject,
  projectVersions: state.analysis.projects.projectVersions,
  scripts: state.analysis.automation.scripts,
  userPermissions: state.user.userPermissions,
  valuationDate: state.analysis.projects.valuationDate,
  shape: state.analysis.projects.shape,
  checkingUniqueness: state.analysis.projects.checkingUniqueness,
  copiedProjectId: state.analysis.projects.copiedProjectId,
  valuationDates: state.analysis.projects.valuationDates,
  exportList: state.analysis.projects.exportList,
  isFetchingExportList: state.analysis.projects.isFetchingExportList,
  excelList: state.analysis.projects.excelList,
  printLists: state.analysis.projects.printLists,
  collectionSets: state.analysis.projects.collectionSets,
  exhibitOptions: state.analysis.projects.exhibitOptions,
  dataDefaults: state.analysis.projects.dataDefaults,
  exhibitDefaults: state.analysis.projects.exhibitDefaults,
  current: state.analysis.projects.current,
  projectReviewState: state.analysis.projects.projectReviewState,
  projectReviewHistory: state.analysis.projects.projectReviewHistory,
  secureDataManagerFactory: state.user.secureDataManagerFactory
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Automation);
