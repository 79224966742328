import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { clearErrorMessage } from '@app/actions/application.actions';
import {
  selectDatabase,
  fetchDatabases,
  fetchDatabase,
  fetchDatbaseColumns,
  fetchDatabaseInfo,
  deleteDatabase,
  createDatabase,
  deleteValuationDate,
  deleteDataLoad,
  fetchColumnTypes,
  updateDatabase,
  fetchBackups,
  createBackup,
  deleteBackup,
  restoreBackup,
  restorePointInTime,
  undoPITR,
  archiveBackup,
  deleteArchivedBackup,
  restoreArchivedBackup,
  validateCMFormulas,
  fetchCurrentDbVersion,
  fetchPointInTimeRestoreRange,
  fetchBackupRestoreLogFile,
  deleteBackupRestoreLogFile, 
  fetchDataLoadLogFile
} from '@app/actions/tod/todDatabases.actions';
import {
  clearServerNotifications,
} from '@app/actions/serverNotification.actions';
import {
  fetchUploads,
  fetchColumnsForFile,
} from '@app/actions/tod/uploads.actions';
import { createPermissionChecker } from '@app/utilities/permissions';
import TodDatabasesList from '@app/tod/todDatabasesList';
import CreateTodDatabasePage from '@app/tod/createTodDatabasePage';
import LinkDatabase from '@app/tod/linkDatabase';
import LinkDatabaseEdit from '@app/tod/linkDatabaseEdit';

class TodDatabasesTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    columnTypes: PropTypes.array,
    application: PropTypes.object,
    getDatabases: PropTypes.func,
    getCurrentDbVersion: PropTypes.func,
    selectDatabaseHandler: PropTypes.func,
    currentDatabase: PropTypes.object,
    getDatabase: PropTypes.func,
    getDatabaseColumns: PropTypes.func,
    getColumnTypes: PropTypes.func,
    deleteDatabaseHandler: PropTypes.func,
    databases: PropTypes.array,
    clearNotificationHandler: PropTypes.func,
    newSaveHandler: PropTypes.func,
    updateSaveHandler: PropTypes.func,
    serverNotification: PropTypes.object,
    uploads: PropTypes.array,
    getUploads: PropTypes.func,
    getColumnsForCsvFile: PropTypes.func,
    fileColumns: PropTypes.array,
    getDatabaseInfo: PropTypes.func,
    deleteValuationDateHandler: PropTypes.func,
    deleteDataLoadHandler: PropTypes.func,
    getBackups: PropTypes.func,
    createBackupHandler: PropTypes.func,
    deleteBackupHandler: PropTypes.func,
    deleteArchivedBackupHandler: PropTypes.func,
    restoreBackupHandler: PropTypes.func,
    restorePointInTimeHandler: PropTypes.func,
    restoreArchivedBackupHandler: PropTypes.func,
    archiveBackupHandler: PropTypes.func,
    backups: PropTypes.array,
    archivedBackups: PropTypes.array,
    isSaving: PropTypes.bool,
    errorMessage: PropTypes.string,
    validateFormulas: PropTypes.func,
    clearErrorMessageHandler: PropTypes.func,
    userPermissions: PropTypes.array,
    dataLoadDeletesInProgress: PropTypes.array,
    valuationDateDeletesInProgress: PropTypes.array,
    getPointInTimeRestoreRange: PropTypes.func,

    retrieveLogFile: PropTypes.func,
    deleteLogFileFromServer: PropTypes.func,
    logFile: PropTypes.object,

    isFetchingLinkedDatabases: PropTypes.bool,
    linkedDatabases: PropTypes.object,
  };
  componentDidMount() {
    const {
      userKey,
      getDatabases,
      getCurrentDbVersion,
    } = this.props;
    getCurrentDbVersion(userKey);
    getDatabases(userKey);
  }

  componentDidUpdate() {
    const {
      currentDatabase,
      getDatabase,
      match,
      userKey,
      getColumnTypes,
      isFetching,
    } = this.props;

    let params = match.params;

    if (isFetching) {
      return;
    }

    if (this.props.columnTypes.length === 0) {
      getColumnTypes(userKey);
    }

    if (typeof params.databaseId === "undefined")
    {
      this.needsRefresh = true;
    }

    if (params.databaseId) {
      const databaseId = parseInt(params.databaseId, 10);
      if (databaseId) {
        if (this.needsRefresh ||
          !currentDatabase ||
          currentDatabase.id !== databaseId ||
          (currentDatabase && !currentDatabase.withColumns)
        ) {
          getDatabase(userKey, databaseId);
          this.needsRefresh = false;
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.selectDatabaseHandler(null);
  }

  render() {
    const {
      userKey,
      dispatch,
      match,
      history,
      deleteDatabaseHandler,
      selectDatabaseHandler,
      currentDatabase,
      getDatabases,
      getDatabaseColumns,
      databases,
      clearNotificationHandler,
      newSaveHandler,
      updateSaveHandler,
      serverNotification,
      isFetching,
      uploads,
      getUploads,
      getColumnsForCsvFile,
      fileColumns,
      getColumnTypes,
      columnTypes,
      getDatabaseInfo,
      deleteValuationDateHandler,
      deleteDataLoadHandler,
      getBackups,
      createBackupHandler,
      deleteBackupHandler,
      deleteArchivedBackupHandler,
      restoreBackupHandler,
      undoPITRHandler,
      restorePointInTimeHandler,
      restoreArchivedBackupHandler,
      archiveBackupHandler,
      backups,
      archivedBackups,
      isSaving,
      errorMessage,
      validateFormulas,
      clearErrorMessageHandler,
      dataLoadDeletesInProgress,
      valuationDateDeletesInProgress,
      userPermissions,
      getDatabase,
      getPointInTimeRestoreRange,

      retrieveLogFile,
      deleteLogFileFromServer,
      logFile,
      dataLoadLogFile,
      jobStatus,

      isFetchingLinkedDatabases,
      linkedDatabases,
      isFetchingLinkedDatabaseMapping,
      linkedDatabaseMapping,
      isSavingLinkedDatabaseColumnMapping,
      isDeletingLinkedDatabaseMapping,
      savedLinkedDatabaseMapping,
      retrieveDataLoadLogFile
    } = this.props;

    let childProps = {
        userKey,
        dispatch,
        browserHistory: history,
        params: match.params,
        deleteDatabaseHandler,
        selectDatabaseHandler,
        currentDatabase,
        databases,
        getDatabases,
        getDatabaseColumns,
        clearNotificationHandler,
        newSaveHandler,
        updateSaveHandler,
        serverNotification,
        isFetching,
        uploads,
        getUploads,
        getColumnsForCsvFile,
        fileColumns,
        getColumnTypes,
        columnTypes,
        getDatabaseInfo,
        deleteValuationDateHandler,
        deleteDataLoadHandler,
        getBackups,
        createBackupHandler,
        deleteBackupHandler,
        deleteArchivedBackupHandler,
        restoreBackupHandler,
        undoPITRHandler,
        restorePointInTimeHandler,
        restoreArchivedBackupHandler,
        archiveBackupHandler,
        backups,
        archivedBackups,
        isSaving,
        validateFormulas,
        errorMessage,
        clearErrorMessageHandler,
        dataLoadDeletesInProgress,
        valuationDateDeletesInProgress,
        verifyPermission: createPermissionChecker(userPermissions),
        userPermissions,
        getDatabase,
        getPointInTimeRestoreRange,

        retrieveLogFile,
        deleteLogFileFromServer,
        logFile,
        dataLoadLogFile,
        jobStatus,

        isFetchingLinkedDatabases,
        linkedDatabases,
        isFetchingLinkedDatabaseMapping,
        linkedDatabaseMapping,
        isSavingLinkedDatabaseColumnMapping,
        isDeletingLinkedDatabaseMapping,
        savedLinkedDatabaseMapping,
        retrieveDataLoadLogFile
    };

    return (
          <Switch >
            <Route path="/tod/databases/new" render={props => (<CreateTodDatabasePage {...childProps}/>)}/>
            <Route path="/tod/databases/:databaseId/edit" 
                render={props => <CreateTodDatabasePage {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases/:databaseId/link/edit" 
                render={props => <LinkDatabaseEdit {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases/:databaseId/link/:secondaryDatabaseId/edit/" 
                render={props => <LinkDatabaseEdit {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases/:databaseId/link" 
                render={props => <LinkDatabase {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases" render={props => (<TodDatabasesList {...childProps}/>)}/>
            
        </Switch>   
    )
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  databases: state.tod.databases.items,
  backups: state.tod.databases.backups,
  archivedBackups: state.tod.databases.archived,
  currentDatabase: state.tod.databases.current,
  serverNotification: state.serverNotification,
  isFetching: state.tod.databases.isFetching,
  isSaving: state.tod.databases.isSaving,
  uploads: state.tod.uploads.items,
  fileColumns: state.tod.uploads.columns,
  dataLoadDeletesInProgress: state.tod.databases.dataLoadDeletesInProgress,
  valuationDateDeletesInProgress: state.tod.databases.valuationDateDeletesInProgress,
  columnTypes: state.tod.databases.columnTypes,
  errorMessage: state.application.errorMessage,
  currentDbVersion: state.tod.databases.currentVersion,
  userPermissions: state.user.userPermissions,
  logFile: state.tod.databases.txtFile,
  dataLoadLogFile: state.tod.databases.dataLoadLogFile,
  jobStatus: state.jobStatus,
  isFetchingLinkedDatabases: state.tod.databases.isFetchingLinkedDatabases,
  linkedDatabases: state.tod.databases.linkedDatabases,
  isFetchingLinkedDatabaseMapping:state.tod.databases.isFetchingLinkedDatabaseMapping,
  linkedDatabaseMapping: state.tod.databases.linkedDatabaseMapping,
  isSavingLinkedDatabaseColumnMapping: state.tod.databases.isSavingLinkedDatabaseColumnMapping,
  isDeletingLinkedDatabaseMapping: state.tod.databases.isDeletingLinkedDatabaseMapping,
  savedLinkedDatabaseMapping: state.tod.databases.savedLinkedDatabaseMapping,
});

const mapDispatchToProps = dispatch => ({
  deleteDatabaseHandler: (userKey, databaseId) => {
    dispatch(deleteDatabase(userKey, databaseId));
  },
  getCurrentDbVersion: userKey => {
    dispatch(fetchCurrentDbVersion(userKey));
  },
  getDatabases: userKey => {
    dispatch(fetchDatabases(userKey));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  getDatabaseInfo: (userKey, databaseId, factTableGuid) => {
    dispatch(fetchDatabaseInfo(userKey, databaseId, factTableGuid));
  },
  getDatabaseColumns: (userKey, databaseId, factTableGuid) => {
    dispatch(fetchDatbaseColumns(userKey, databaseId, factTableGuid));
  },
  getColumnTypes: userKey => {
    dispatch(fetchColumnTypes(userKey));
  },
  validateFormulas: ({ userKey, columns }) => {
    dispatch(validateCMFormulas({ userKey, columns }));
  },
  selectDatabaseHandler: databases => {
    dispatch(selectDatabase(databases));
  },
  clearNotificationHandler: () => {
    dispatch(clearServerNotifications());
  },
  newSaveHandler: ({ userKey, name, description, accByDiagonals, columns, useADX }) => {
    dispatch(
      createDatabase({ userKey, name, description, accByDiagonals, columns, useADX })
    );
  },
  updateSaveHandler: ({
    userKey,
    databaseId,
    name,
    description,
    accByDiagonals,
    columns,
    browserHistory
  }) => {
    dispatch(
      updateDatabase({
        userKey,
        databaseId,
        name,
        description,
        accByDiagonals,
        columns,
        browserHistory
      })
    );
  },
  getUploads: userKey => {
    dispatch(fetchUploads(userKey));
  },
  getColumnsForCsvFile: (userKey, fileId) => {
    dispatch(fetchColumnsForFile(userKey, fileId));
  },
  deleteValuationDateHandler: (
    userKey,
    databaseId,
    factTableGuid,
    valuationDate
  ) => {
    dispatch(
      deleteValuationDate(userKey, databaseId, factTableGuid, valuationDate)
    );
  },
  deleteDataLoadHandler: (
    userKey,
    databaseId,
    dataLoadId,
    factTableGuid,
  ) => {
    dispatch(
      deleteDataLoad(userKey, databaseId, dataLoadId, factTableGuid)
    );
  },
  getBackups: (userKey, workspaceId) => {
    dispatch(fetchBackups(userKey, workspaceId));
  },
  createBackupHandler: (userKey, workspaceId, description) => {
    dispatch(createBackup(userKey, workspaceId, description));
  },
  deleteBackupHandler: (userKey, workspaceId, backupId) => {
    dispatch(deleteBackup(userKey, workspaceId, backupId));
  },
  deleteArchivedBackupHandler: (userKey, workspaceId, backupId, archiveId) => {
    dispatch(deleteArchivedBackup(userKey, workspaceId, backupId, archiveId));
  },
  restoreBackupHandler: (userKey, workspaceId, backupId) => {
    dispatch(restoreBackup(userKey, workspaceId, backupId));
  },
  undoPITRHandler: (userKey, workspaceId, backupId) => {
    dispatch(undoPITR(userKey, workspaceId, backupId));
  },
  restorePointInTimeHandler: (userKey, workspaceId, timestamp) => {
      dispatch(restorePointInTime(userKey, workspaceId, timestamp));
  },
  restoreArchivedBackupHandler: (
    userKey,
    workspaceId,
    backupId,
    archivedId
  ) => {
    dispatch(restoreArchivedBackup(userKey, workspaceId, backupId, archivedId));
  },
  archiveBackupHandler: (userKey, workspaceId, backupId, description) => {
    dispatch(archiveBackup(userKey, workspaceId, backupId, description));
  },
  clearErrorMessageHandler: () => {
    dispatch(clearErrorMessage());
  },
  getPointInTimeRestoreRange: (userKey, databaseId) => {
      dispatch(fetchPointInTimeRestoreRange(userKey, databaseId));
  },
  retrieveLogFile: (userKey, databaseId) => {
    dispatch(fetchBackupRestoreLogFile(userKey, databaseId));
  },
  retrieveDataLoadLogFile: (userKey) => {
    dispatch(fetchDataLoadLogFile(userKey));
  },
  deleteLogFileFromServer: (userKey, databaseId) => {
    dispatch(deleteBackupRestoreLogFile(userKey, databaseId));
  },

  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TodDatabasesTab);
