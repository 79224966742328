import {
    FETCHING_ANALYSIS_TASKS,
    RECEIVED_ANALYSIS_TASKS
} from './analysis.actionTypes';
import * as api from '@app/api/analysisTasks.serviceApi';

function fetchTasks(userKey) {
  return (dispatch) => {
    dispatch({type: FETCHING_ANALYSIS_TASKS});
    return api.getAnalysisTasks(userKey)
        .then(data => {
            dispatch({type: RECEIVED_ANALYSIS_TASKS, data})
        });
  };
}

function initiateTask(userKey, databaseId, initiateTaskRequest) {
    return (dispatch) => {
        return api.initiateTask(userKey, databaseId, initiateTaskRequest)
            .then(data => {
                // TODO ??
            });
    };
}

export {
    fetchTasks,
    initiateTask,
};
