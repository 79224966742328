import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import radium from 'radium';
import { Link } from 'react-router-dom';
import * as reportServiceApi from '@app/api/powerbi.serviceApi';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective  } from '@syncfusion/ej2-react-navigations';
import { NavTab } from '@app/utilities/routing';
// import { Button } from '@arius';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import * as GlobalStyles from '@app/utilities/globalStyles';
import { notifySuccess, notifyError } from '@app/utilities/notifier';
import { showModal } from '@app/actions/modal.actions';
import { WORKSPACE_UPDATEEXTRACTTABLES } from '@app/utilities/permissions';

class Sidebar extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = { isRequestingRefresh: false};
        this.refreshDataSet = this.refreshDataSet.bind(this);
        this.getReportInfo = this.getReportInfo.bind(this);
    }

    refreshDataSetHandler(report) {
        const { dispatch, verifyPermission } = this.props;
        const { isRequestingRefresh } = this.state;
        let authorized = verifyPermission(WORKSPACE_UPDATEEXTRACTTABLES);

        if (!authorized) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        if (isRequestingRefresh) {
            notifyError('Refresh request in progress');
            return;
        }

        reportServiceApi.retrievePowerBIInfo(this.props.userKey).then(
            (data) => {
                if (data && (data.state === 'Active' || data.state === 'Succeeded')){
                    const messageItems = [
                        <b key={10}>{`Refresh Connection to Data Model for ${report.name}?`}</b>,
                        <div key={11} style={{marginTop: 10}}>
                            Note: This will refresh the embedded Power BI dashboards with the latest data from the 
                            Power BI data source.  The PBI data source can be refreshed via the "Reporting" tab 
                            within Arius Enterprise (e.g. see Power BI Data Model, Extract Table, or Reporting Table).
                        </div> 
                    ];

                    const yesClickHandler = () => this.refreshDataSet(report.datasetId);
                    const noClickHandler = () => {};
                    const action = showModal(
                        'confirmation',
                        messageItems,
                        yesClickHandler,
                        noClickHandler
                    );
                    dispatch(action);
                } else {
                    notifyError("Cannot refresh dataset while Power BI service is paused")
                }
            }
        )
    }

    refreshDataSet(datasetId){
        this.setState({isRequestingRefresh: true});
        reportServiceApi.refreshPBIDataset(this.props.userKey, datasetId).then(
            (data) => {
                if (data === "Refresh requested"){
                    notifySuccess('Dataset refresh requested');
                }
                this.setState({isRequestingRefresh: false});
            }
        )
    }

    getReportInfo(data) {
        const { report, ndx } = data;
        const { isRequestingRefresh } = this.state;
        var infoStyle = {textAlign: 'center', fontSize: 'smaller', marginBottom: 10}
        return <div id={ `accordian${ndx}`} key={`accordian${report.name}`} style={{display:'none'}}>
            <div><b>Last Refresh:</b></div> 
            <div style={infoStyle}>{report.lastRefresh ? moment.utc(report.lastRefresh).local().format('L LT') : '-'}</div> 
            <div><b>Refresh Status:</b></div> 
            <div style={infoStyle}>{report.refreshStatus ? report.refreshStatus : 'In progress'}</div> 
            <OverlayTrigger
            placement='top'
            rootClose={true}
            overlay={<Tooltip className='tool-tip-fix'>Request refresh</Tooltip>}
        >
            <Button
                    key="confirmButton"
                    style={{width: '100%'}}
                    size='sm'
                    disabled={isRequestingRefresh}
                    variant='outline-dark'
                    onClick={() => this.refreshDataSetHandler(report)}>{
                        isRequestingRefresh ? <span>
                            <i className={`fa fa-refresh fa-spin menu-icon`}/>Refreshing
                        </span> :<span style={{width:'100%', textAlign: 'center'}}>
                            <i className={`fa fa-refresh menu-icon`}/>Dataset
                        </span>}
            </Button>
        </OverlayTrigger>
        </div>;
    }

    render() {
        const { reports, isFetching } = this.props;
        var list = reports ? reports : [];
        return (
        <div className='sidebar' style={GlobalStyles.sidebar}>
            <div className="analysis-home-link" style={{marginTop: 20, padding: '0px 20px' }}>
                <Link to={"/arius"}>
                    <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ paddingRight: 2 }}
                    aria-hidden="true"
                    />
                    Analysis Home
                </Link>
            </div>
            {list.map((r, i) => (this.getReportInfo({report:r, ndx: i})))}
            <AccordionComponent>
            <AccordionItemsDirective>
                { isFetching ? 
                    <span style={{textAlign: 'center'}}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;Loading...</span> :
                    list.map((r, i) => <AccordionItemDirective 
                        key={`reportnav${r.name}`}
                        header={`#r${r.id}`}
                        content={ `#accordian${i}`} 
                        cssClass='pbi-report-link' />)
                }
            </AccordionItemsDirective>
        </AccordionComponent>
            {/* <NavSideTabs>
                { isFetching ? 
                    <span style={{textAlign: 'center'}}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;Loading...</span> :
                    list.map((r) => <NavTab id={`r${r.name}`} key={`rpttb${r.name}`} label={r.name} to={`/arius/powerbi/${r.id}`}/>)
                }
            </NavSideTabs>  */}
            { isFetching ? 
                    <span style={{textAlign: 'center'}}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;Loading...</span> :
                    list.map((r) => <div id={`r${r.id}`} key={`rpttb${r.name}`}>
                       <NavTab  label={r.name} to={`/arius/powerbi/${r.id}`}/>
                         </div>)
                }
{/*                 
            { isFetching ? 
                    <span style={{textAlign: 'center'}}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;Loading...</span> :
                    list.map((r) => <p id={`r${r.id}`} key={`rpttb${r.name}`}>
                         <NavTab  label={r.name} to={`/arius/powerbi/${r.id}`}/>
                         </p>)
                } */}
        </div>
        );
    }
}

export default radium(Sidebar);
