import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createPermissionChecker, WORKSPACE_MANAGEJOBS } from '../../../utilities/permissions';
import { fetchCurrentAEDbScriptVersion, fetchWorkspaces, fetchWorkspace, clearWorkspace } from '@app/actions/analysis/workspace.actions';
import {
    changeValuationDate,
    initiateBatchProcess,
    clearWorkspaceRelatedOnWorkspaceChange
} from '../../../actions/analysis/project.actions';
import {
    fetchLongRunningOperationsStatus,
    emptyLongRunningOperationsStatus,
    fetchCurrentUdoVersion,
    copyProject,
    getProjectVersions,
    updateProjectVersion,
    getCheckoutStatus,
    getProjectsByValuationDate,
    getValuationDates,
} from '../../../actions/analysis/project.actions';
import { runRollForwardBatchProcess, fetchScripts } from '../../../actions/analysis/automation.actions';
import AnalysisTabMain from '../presentational/analysisTabMain';
import SideBar from '@app/analysis/analysisTabSideBar';
import GenericList from '@app/analysis/genericList';
import MeasureMappersList from '@app/analysis/measureMappersList';
import CategoriesList from '@app/analysis/categoriesList';
import Reviewlevels from '@app/analysis/reviewLevels/summary';
import AnalysisToolbar from '@app/analysis/analysisToolbar';
import ProjectReview from '@app/analysis/projectReview/projectReview';
import { clearScripts } from '../../../actions/analysis/automation.actions';



class AnalysisTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStructures: []
        }
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.getPermissionChecker = this.getPermissionChecker.bind(this);
        this.changeValuationDateHandler = this.changeValuationDateHandler.bind(this);
        this.changeDataStructureHandler = this.changeDataStructureHandler.bind(this);
        this.setDataStructures = this.setDataStructures.bind(this);
        // this.filterByValuationDate = this.filterByValuationDate.bind(this);
        // this.refreshProjects = this.refreshProjects.bind(this);
        // this.reRender = this.reRender.bind(this);
        // this.getPermissionChecker = this.getPermissionChecker.bind(this);
    }
  
    get baseUrl() {
        const { match } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        return `/arius/analysis${databaseId ? `/${databaseId}` : ''}`;
    }

    get newUrl() {
        // get the url with databaseId injected (if needed) and child route preserved
        const { location } = this.props;
        return location.pathname.replace(this.baseUrl, `/arius/analysis/`);
    }

    get newUrlFromCurrentDb() {
        const { currentDb } = this.props;
        return `/arius/analysis/${currentDb.id}/projects`;  // default to 'projects' in LHM
    }

    componentDidMount() {
        const { 
            currentDb,
            getDatabases,
            userKey,
            match,
            history
        } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
        }
        getDatabases(userKey);
    }

    componentDidUpdate(prevProps) {
        const {
            currentDb,
            userKey,
            getDatabase,
            match,
            isFetchingDatabases,
            isFetchingDatabase,
            history
        } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        
        if (isFetchingDatabases) {
            return;
        }

        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
            return;
        }

        if (databaseId && !isFetchingDatabase) {
            if ((currentDb && currentDb.id !== databaseId) || !currentDb) {
                getDatabase(userKey, databaseId)
            }
        }
    }

    componentWillMount() {
        const {
            userKey,
            getCurrentAEDbScriptVersion,
            currentAEDbScriptVersion,
        } = this.props;
        if (!currentAEDbScriptVersion) {
            getCurrentAEDbScriptVersion(userKey);
        }
    }

    changeWorkspaceHandler(workspaceId) {
        const { history, location, dispatch,
            userKey,
            fetchScriptsHandler,
            workspaceId: currentWorkspaceId} = this.props;
        const verifyPermission = this.getPermissionChecker();
        
        if (currentWorkspaceId === workspaceId) { return; }
        dispatch(clearWorkspace());
        dispatch(clearWorkspaceRelatedOnWorkspaceChange());
        this.setState({ selectedScript: 'select', alreadyFetched: true });
        dispatch(fetchWorkspace(userKey, workspaceId));
        dispatch(clearScripts());
        if (verifyPermission(WORKSPACE_MANAGEJOBS)) {
            fetchScriptsHandler({ userKey, databaseId: workspaceId });
        }
        let newUrl = location.pathname.replace(this.baseUrl, `/arius/analysis/${workspaceId}`);
        history.push(newUrl);
    }

    changeValuationDateHandler(e, dataStructure) {
        const { dispatch } = this.props,
            date = e && e.target && e.target.value ? e.target.value : e;

        if (e && e.target && e.target.value){
            dataStructure = 'all';  // the val date has change, reset dataStructure
        } else if (!dataStructure){
            dataStructure = this.props.shape;
        }
        dispatch(changeValuationDate(date, dataStructure));
    }

    changeDataStructureHandler(e) {
        const {dispatch} = this.props;
        dispatch(changeValuationDate(this.props.valuationDate, e.target.value));
    }

    getPermissionChecker() {
        const { currentDb, userPermissions, } = this.props;
  
        return createPermissionChecker(
            currentDb ? [...userPermissions, ...currentDb.permissions] : userPermissions);
    }

    setDataStructures(list) {
        // clunky way for analysisTabMain to set these here so analysisToolbar has access...
        this.setState({dataStructures: list});
    }

    render() {
        const {
            batchProjects,
            categories,
            checkingUniqueness,
            clearLongRunningOperationsStatus,
            cloneProject,
            collectionSets,
            printLists,
            excelLists,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            copiedProjectId,
            copyingProject,
            current,
            currentDb,
            currentUdoVersionNbr,
            dispatch,
            exportList,
            fetchScriptsHandler,
            getCheckoutStatusHandler,
            getCurrentUdoVersion,
            getLongRunningOperationsStatus,
            getProjectsByValuationDateHandler,
            getProjectVersionsHandler,
            getValuationDatesHandler,
            history,
            initiateBatchProcessHandler,
            isFetching,
            isFetchingGenericLists,
            isFetchingDatabases,
            isFetchingExportList,
            isFetchingMeasureMappers,
            isFetchingProjects,
            isFetchingSegments,
            isFetchingValuationDates,
            jobStatus,
            location,
            longRunningOperationsStatus,
            match,
            measureMappers,
            projectReviewHistory,
            projectReviewState,
            projects,
            projectVersions,
            runRollForward,
            scripts,
            segments,
            shape,
            updateProjectVersionHandler,
            userKey,
            userPermissions,
            valuationDate,
            valuationDates,
            workspaces,
        } = this.props;
        let { params } = match;
        const workspaceId = parseInt(params.databaseId,10);

        let childProps = {
            batchProjects,
            categories,
            changeWorkspaceHandler:this.changeWorkspaceHandler,
            changeValuationDateHandler: this.changeValuationDateHandler,
            changeDataStructureHandler: this.changeDataStructureHandler,
            checkingUniqueness,
            clearLongRunningOperationsStatus,
            cloneProject,
            collectionSets,
            printLists,
            excelLists,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            copiedProjectId,
            copyingProject,
            current,
            currentUdoVersionNbr,
            currentWorkspace: currentDb,
            dataStructure: shape,
            dataStructures: this.state.dataStructures,
            dispatch,
            exportList,
            fetchScriptsHandler,
            getCheckoutStatusHandler,
            getCurrentUdoVersion,
            getLongRunningOperationsStatus,
            getProjectsByValuationDateHandler,
            getProjectVersionsHandler,
            getValuationDatesHandler,
            history,
            initiateBatchProcessHandler,
            isFetching,
            isFetchingGenericLists,
            isFetchingDatabases,
            isFetchingExportList,
            isFetchingMeasureMappers,
            isFetchingProjects,
            isFetchingSegments,
            isFetchingValuationDates,
            jobStatus,
            location,
            longRunningOperationsStatus,
            measureMappers,
            params,
            projectReviewHistory,
            projectReviewState,
            projects,
            projectVersions,
            runRollForward,
            scripts,
            segments,
            setDataStructures: this.setDataStructures,
            shape,
            updateProjectVersionHandler,
            userKey,
            userPermissions,
            valuationDate,
            valuationDates,
            verifyPermission: this.getPermissionChecker(),
            workspaceId,
            workspaces,            
        };

        let showProjectControls = location.pathname.includes('projects');
        let showLHM = !params.projectId;
        let showToolbar = !params.projectId;

        return <div style={{display: 'flex'}}>
            {showLHM ? <SideBar baseUrl={this.baseUrl}/> : ''}
            <div>
                <Switch>
                    <Route path="/arius/analysis" render={p=> showToolbar ? <AnalysisToolbar {...{...childProps, showProjectControls}}/> : ''}/>
                </Switch>
                <Switch>
                    <Route path="/arius/analysis/lists/:listType" render={p=> <GenericList {...childProps}/>}/>
                    <Route path="/arius/analysis/:databaseId/projects/:projectId/review" render={p=> <ProjectReview {...childProps}/>}/> 
                    <Route path="/arius/analysis/:databaseId/projects/:date/:shape" render={p=> <AnalysisTabMain {...childProps}/>}/> 
                    <Route path="/arius/analysis/:databaseId/projects" render={p=> <AnalysisTabMain {...childProps}/>}/> 
                    <Route path="/arius/analysis/:databaseId/lists/:listType" render={p=> <GenericList {...childProps}/>}/>
                    <Route path="/arius/analysis/:databaseId/reviewlevels" render={p=> <Reviewlevels {...childProps}/>}/>
                    <Route path="/arius/analysis/:databaseId/mappers" render={p=> <MeasureMappersList {...childProps}/>}/>
                    <Route path="/arius/analysis/:databaseId/categories" render={p=> <CategoriesList {...childProps}/>}/>
                    <Route path="/arius/analysis/projects" render={p=> <AnalysisTabMain {...childProps}/>}/>
                    <Route path="/arius/analysis/reviewlevels" render={p=> <Reviewlevels {...childProps}/>}/>
                    <Route path="/arius/analysis/mappers" render={p=> <MeasureMappersList {...childProps}/>}/> 
                    <Route path="/arius/analysis/categories" render={p=> <CategoriesList {...childProps}/>}/> 
                    <Route path="/arius/analysis" render={props => <Redirect to="/arius/analysis/projects"/>}/>
                </Switch>
            </div>
        </div>;
    }
}

const mapStateToProps = state => ({
    currentDb: state.analysis.workspaces.current,
    segments: state.analysis.segments.items,
    workspaces: state.analysis.workspaces.items,
    projects: state.analysis.projects.items,
    isFetching: state.analysis.projects.isFetching,
    isFetchingGenericLists: state.analysis.projects.isFetchingGenericLists,
    isFetchingProjects: state.analysis.projects.isFetchingProjects,
    isFetchingValuationDates: state.analysis.projects.isFetchingValuationDates,
    isFetchingDatabases: state.analysis.workspaces.isFetching,
    isFetchingDatabase: state.analysis.workspaces.isFetchingWorkspace,
    isFetchingSegments: state.analysis.segments.isFetchingSegments,
    userKey: state.user.userKey,
    application: state.application,
    batchProjects: state.analysis.projects.batchProjects,
    longRunningOperationsStatus: state.analysis.projects
        .longRunningOperationsStatus,
    currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
    currentUdoVersionNbr: state.analysis.projects.currentUdoVersionNbr,
    copyingProject: state.analysis.projects.copyingProject,
    projectVersions: state.analysis.projects.projectVersions,
    scripts: state.analysis.automation.scripts,
    userPermissions: state.user.userPermissions,
    valuationDate: state.analysis.projects.valuationDate,
    shape: state.analysis.projects.shape,
    checkingUniqueness: state.analysis.projects.checkingUniqueness,
    copiedProjectId: state.analysis.projects.copiedProjectId,
    valuationDates: state.analysis.projects.valuationDates,
    exportList: state.analysis.projects.exportList,
    isFetchingExportList: state.analysis.projects.isFetchingExportList,
    collectionSets: state.analysis.projects.collectionSets,
    printLists: state.analysis.projects.printLists,
    excelLists: state.analysis.projects.excelLists,
    exhibitOptions: state.analysis.projects.exhibitOptions,
    dataDefaults: state.analysis.projects.dataDefaults,
    exhibitDefaults: state.analysis.projects.exhibitDefaults,
    measureMappers: state.analysis.projects.measureMappers,
    isFetchingMeasureMappers: state.analysis.projects.isFetchingMeasureMappers,
    current: state.analysis.projects.current,
    projectReviewState: state.analysis.projects.projectReviewState,
    projectReviewHistory: state.analysis.projects.projectReviewHistory,
    jobStatus: state.jobStatus,
    categories: state.analysis.projects.categories
});

const mapDispatchToProps = dispatch => ({
    initiateBatchProcessHandler: (userKey, projects, operation) => {
        dispatch(initiateBatchProcess(userKey, projects, operation));
    },
        getDatabase: (userKey, databaseId) => {
            dispatch(fetchWorkspace(userKey, databaseId));
        },
        getDatabases: userKey => {
            dispatch(fetchWorkspaces(userKey));
        },
    getLongRunningOperationsStatus: (userKey, databaseId) => {
        dispatch(fetchLongRunningOperationsStatus(userKey, databaseId));
    },
    getCheckoutStatusHandler: (userKey, databaseId, projectId, body) => {
        dispatch(getCheckoutStatus(userKey, databaseId, projectId, body));
    },
    getValuationDatesHandler: (userKey, databaseId) => {
        dispatch(getValuationDates(userKey, databaseId));
    },
    getProjectsByValuationDateHandler: (userKey, databaseId, valuationDate) => {
        dispatch(getProjectsByValuationDate(userKey, databaseId, valuationDate));
    },
    runRollForward: ({ userKey, databaseId, scriptId, projects }) => {
        dispatch(runRollForwardBatchProcess({ userKey, databaseId, scriptId, projects }));
    },
    clearLongRunningOperationsStatus: () => {
        dispatch(emptyLongRunningOperationsStatus());
    },
    getCurrentAEDbScriptVersion: userKey => {
        dispatch(fetchCurrentAEDbScriptVersion(userKey));
    },
    getCurrentUdoVersion: (userKey, databaseId) => {
        dispatch(fetchCurrentUdoVersion(userKey, databaseId));
    },
    cloneProject: (userKey, project, body) => {
        dispatch(copyProject(userKey, project, body));
    },
    fetchScriptsHandler: ({ databaseId, userKey }) => {
        dispatch(fetchScripts({ databaseId, userKey }));
    },
    getProjectVersionsHandler: (userKey, databaseId, projectId) => {
        dispatch(getProjectVersions(userKey, databaseId, projectId));
    },
    updateProjectVersionHandler: (userKey, databaseId, projectId, req) => {
        dispatch(updateProjectVersion(userKey, databaseId, projectId, req));
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisTab);
