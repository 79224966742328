import React, { Component } from 'react';
import * as pbi from 'powerbi-client';
import * as util from './util';
import * as reportServiceApi from '@app/api/powerbi.serviceApi';
import Loader from '@app/shared/presentational/loader';
//declare var powerbi: pbi.service.Service;

export class Report extends Component {
    constructor(props) {
        super(props);
        this.component = null;
        this.state = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            isFetching: true
        };
        this.component = null; // pbi.Embed;
        this.rootElement = null; // HTMLElement;
        this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    }

    componentDidMount() {
        const { userKey, id:reportId } = this.props;
        reportServiceApi.retrievePBIReportToken(userKey, reportId).then(
            (token) => {
                this.updateState({...this.props, accessToken: token, isFetching: false});
            }
        )
    }

    componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    componentDidUpdate() {
        if (this.validateConfig(this.state)) {
            this.embed(this.state);
        }
    }

    componentWillUnmount() {
        this.reset();
    }

    embed(config) {
        this.component = this.powerbi.embed(this.rootElement, config);
        if (this.props.onEmbedded) {
            this.props.onEmbedded(this.component);
        }
        return this.component;
    }

    reset() {
        this.powerbi.reset(this.rootElement);
        this.component = null;
    }

    updateState(props) {
        const nextState = util.assign({}, this.state, props, {
        settings: {
            filterPaneEnabled: this.props.filterPaneEnabled,
            navContentPaneEnabled: this.props.navContentPaneEnabled
        }
        });
        /**
         * This property must be removed from the state object so that it doesn't get used in the embedConfig.
         * This would be passed to `powerbi.embed(element, embedConfig)` and attempted to be sent over postMessage;
         * however, functions cannot be cloned and it will fail.
         */
        delete nextState.onEmbedded;
        this.setState(nextState);
    }

    validateConfig(config) {
        const errors = pbi.models.validateReportLoad(config);
        return (errors === undefined);
    }

    render() {
        const { isFetching } = this.state;
        return isFetching ? 
            <Loader loadingItem="Report" /> :
            <div className="powerbi-frame" style={{width:'100%', height: 'calc(100vh - 56px)'}} ref={(ref) => this.rootElement = ref}></div>
        
    }
}

// Report.propTypes = {
//   accessToken: React.PropTypes.string,
//   embedUrl: React.PropTypes.string
// }

export default Report;