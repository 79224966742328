import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Clause from './clause';
import Operator from './operator';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import Utils from './queryUtils';

class Sequence extends Component {
    static propTypes = {
        column: PropTypes.string,
        group: PropTypes.string,
    };
    constructor(props) {
        super(props);
        this.state = {  
            hoverStyle: {}      
        }
        this.onDrop = this.onDrop.bind(this);
        this.remove = this.remove.bind(this);
        this.insert = this.insert.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
    }

    onDrop = (ev, ndx) => {
        //console.error('dropped in sequence');
        const {serialNumber, parentSerialNumber, order, items, isBase} = this.props;

        let o = Utils.parseDragObject(ev);
        Utils.handleOnDragLeave.call(this, ev);

        if (isBase && items.length){
            return; // don't allow drop in the base sequence once it has anything in it
        }
        let psn = parentSerialNumber > 0 ? parentSerialNumber : 1;
        let position = order > -1 ? order : this.props.items.length;
        if (ndx === -1) { psn = serialNumber; }  // this is the initial item for this sequence

        this.insert(o, psn, position);
    }

    remove = (serialNumber) => {
        if (this.props.parentSerialNumber === 0) {
            let o = this.getMyInfo(this.props.items);
            Utils.removeTreeItem(o, serialNumber);
            Utils.numberItems(o, 0, 0);
            this.props.handleChange(o);
        } else {
            this.props.remove(serialNumber);  // pass it up the tree
        }
    }

    insert = (item, parentSerialNumber, position) => {
        if (item.serialNumber === parentSerialNumber) {return;} // don't let this happen
        if (this.props.parentSerialNumber === 0) {
            if (!item.serialNumber){
                // this is a new item
                item = Utils.createFilterItem(this.props.operators, item);            
            }

            let o = this.getMyInfo(this.props.items);
            Utils.removeTreeItem(o, item.serialNumber);
            Utils.insertTreeItem(o, item, parentSerialNumber, position);
            Utils.numberItems(o, 0, 0);
            this.props.handleChange(o);
        } else {
            this.props.insert(item, parentSerialNumber, position); // pass it up the tree
        }
    }

    updateAt = (value, ndx) => {
        let items = this.props.items;
        items[ndx] = value;
        this.props.handleChange(this.getMyInfo(items));
    }

    getMyInfo(items) {
        return {
            serialNumber: this.props.serialNumber,
            parentSerialNumber: this.props.parentSerialNumber,
            queryFragmentOperatorId: this.props.queryFragmentOperatorId,
            type: 'sequence',
            order: this.props.order,
            collapsed: this.props.collapsed,
            items,
            isBase: this.props.isBase
        };
    }

    handleExpandClick = () => {
        let info = this.getMyInfo(this.props.items);
        info.collapsed = !info.collapsed;
        this.props.handleChange(info);
    }

    getChildElements(){
        const {items, id} = this.props;
        
        if (!items || items.length <1) { 
            return (
                <div>  {/* THIS EXTRA DIV SEEMS NECESSARY FOR D&D ISSUE IN IE8*/}
                    <div className='' 
                        style={{textAlign: 'center', width: '100%', border: '1px dashed', borderRadius: '5px', ...this.state.hoverStyle}}
                        onDragOver={Utils.handleOnDragOver}
                        onDragLeave={Utils.handleOnDragLeave} 
                        onDrop={(e)=>{
                            //console.error('dropped in the tiny box');
                            this.onDrop(e, -1)}}>
                        drag operator here
                    </div>
                </div>
            );
        }
        let elements = [];

        for (let i = 0; i < items.length; i++) { 
            let key = `item ${id}-${i}`;
            let lineFeed = i === items.length -1 ? '' : (<br></br>);
            switch(items[i].type) {
                case 'clause':
                    elements.push(<span key={key}>
                        <Clause
                        
                        data={items[i]}
                        handleChange= {(e) => this.updateAt(e, i)} // can prob be handled in child
                        remove={this.remove}
                        insert={this.insert}
                        includeOtherColumns={this.props.includeOtherColumns}
                        ></Clause>{lineFeed}
                        </span>);
                    break;
                case 'sequence':
                    elements.push(<div key={key}  style={{}}>
                        <Sequence
                               
                            handleRemove={(e) => this.remove(items[i].serialNumber)}
                            handleChange= {(e) => this.updateAt(e, i)}
                            order={items[i].order}
                            collapsed={items[i].collapsed}
                            serialNumber={items[i].serialNumber}
                            parentSerialNumber={items[i].parentSerialNumber}
                            operators={this.props.operators}  // not sure why mapStateToProps isn't working for children
                            queryFragmentOperatorId = {items[i].queryFragmentOperatorId}
                            items={items[i].items ? items[i].items : []} 
                            handleDrop={(e)=> this.onDrop(e, i)}
                            remove={this.remove}
                            insert={this.insert}
                            includeOtherColumns={this.props.includeOtherColumns}>
                        </Sequence>
                        </div>);
                    break;
                default:
                    elements.push(
                        <Operator
                            data={items[i]}
                            key={key}
                            remove={this.remove}
                            insert={this.insert}
                        >
                        </Operator>);
            }
        }  
        return elements;
    }

    getCollapsedContent() {
        return <div>
            (<span className='object-expand' onClick={this.handleExpandClick}>&nbsp;...</span>)
        </div>
    }

    getExpandedContent() {
        return <span>
           <div>
                (<span className='object-collapse' onClick={this.handleExpandClick}></span>
            </div>
            <div style={{paddingLeft: '20px'}}>{this.getChildElements()}</div>
            <div>)
                <span className='remove' onClick={()=> {this.props.handleRemove()}}>
                    <span className='serial-number'>{this.props.serialNumber}</span>
                </span>
            </div> 
        </span>
    }

    getContent() {
            const { collapsed } = this.props;
        return (
            <div className='sequence' 
                onDragStart={(e)=> {
                   let json = e.dataTransfer.getData("text");
                   if (json) {return;} // some child is being dragged
                    Utils.handleOnDragStart(e, this.getMyInfo(this.props.items));
                }} 
                onDragOver={Utils.handleOnDragOver}
                onDragLeave={Utils.handleOnDragLeave} 
                onDrop={(e)=>{this.onDrop(e)}}  
                key={'opre'}     
                draggable
                >
                    {collapsed ? this.getCollapsedContent() : this.getExpandedContent()}
            </div>
        );

    }


    render() {
        const {isBase, totalItems, items} = this.props;
        let baseStyle = {height: `${(totalItems+2)*(28+10)}px`};
        if (items && items.length >0) { 
            baseStyle = {...baseStyle, ...this.state.hoverStyle};
        }
        let content = isBase ? (
            <div id="THE_BASE_SEQUENCE" className='' style={baseStyle}
                onDragOver={(e) => {
                    if (items.length){
                        return;// don't allow drop in the base sequence once it has anything in it
                    }
                    Utils.handleOnDragOver(e);
                }}
                onDragLeave={Utils.handleOnDragLeave} 
                onDrop={(e)=>{this.onDrop(e)}}  
                key={'opre'}     
                >
                <div className='' style={{}}>{this.getChildElements()}</div>
            </div>
        ) : this.getContent();
        return content;
    } 
}

const mapStateToProps = state => ({
    operators: state.tod.queries.operators,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(Sequence));

