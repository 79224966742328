import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import * as Colors from '@app/utilities/colors';

import radium from 'radium';
import { Button, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SaveButtons from '@app/shared/presentational/saveButtons';
import Loader from '@app/shared/presentational/loader';

import {
    WORKSPACE_UNLOCKPROJECT,
    createPermissionChecker2,
  } from '@app/utilities/permissions';

import moment from 'moment';

import { 
    getProjectReviewState, 
    getProjectReviewHistory, 
    sendProjectReviewAction, 
    cleanProjectReview 
} from '@app/actions/analysis/project.actions';
import ProjectReviewConfirmationModal from './projectReviewConfirmationModal';

class ProjectReview extends Component {
    static propTypes = {
        projectReviewState: PropTypes.object,
        projectReviewHistory: PropTypes.array,
        projectReviewRefresh: PropTypes.bool,
        // getProjectReviewStateHandler: PropTypes.func,
        // getProjectReviewHistoryHandler: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            databaseId: null,
            projectId: null,
            comments: null,
            isWaiting: true
        };
        this.handleStartWait = this.handleStartWait.bind(this);
        this.handleStopWait = this.handleStopWait.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    get timeZoneOffset() {
        let d = new Date();
        return -Math.floor(d.getTimezoneOffset()/60);
    }


    componentDidMount() {
        const workspaceId = this.props.params.databaseId;
        const projectId = this.props.params.projectId;

        const { dispatch, userKey } = this.props;
        this.setState({ databaseId: workspaceId, projectId: projectId });
        dispatch(getProjectReviewState(userKey, workspaceId, projectId, this.handleStopWait));
        dispatch(getProjectReviewHistory(userKey, workspaceId, projectId, this.handleStopWait));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(cleanProjectReview());
    }

    onSubmit = (comments) => {
        const { dispatch, userKey, projectReviewState } = this.props;
        const { databaseId, projectId } = this.state;

        const level = projectReviewState.level; 
        this.handleStartWait('Submitting for Review');
        dispatch(sendProjectReviewAction(userKey, databaseId, projectId, 1, comments, level, this.handleStopWait));
    }

    onApprove = (comments) => {
        const { dispatch, userKey, projectReviewState } = this.props;
        const { databaseId, projectId } = this.state;
        this.handleStartWait('Saving approval Level ' + projectReviewState.level);
        dispatch(sendProjectReviewAction(userKey, databaseId, projectId, 2, comments, projectReviewState.level, this.handleStopWait));
    }

    onReject = (comments) => {
        const { dispatch, userKey, projectReviewState } = this.props;
        const { databaseId, projectId } = this.state;
        this.handleStartWait('Saving rejection');
        dispatch(sendProjectReviewAction(userKey, databaseId, projectId, 3, comments, projectReviewState.level, this.handleStopWait));
    }

    onUnlock = (comments) => {
        const { dispatch, userKey, projectReviewState } = this.props;
        const { databaseId, projectId } = this.state;
        this.handleStartWait('Saving approval Level ' + projectReviewState.level);
        dispatch(sendProjectReviewAction(userKey, databaseId, projectId, 6, comments, projectReviewState.level, this.handleStopWait));
    }

    handleStartWait(message){
        this.setState({isWaiting: true, waitMessage: message, showConfirmationModal: false});
    }

    handleStopWait(){
        this.setState({isWaiting: false});
    }

    createPermissionChecker = () => {
        const { 
            userPermissions,
            currentWorkspace,
            workspaces,
            workspaceId,
        } = this.props;

        let workspace = currentWorkspace;
        if (!workspace) {
            workspace = workspaces.find(function(item) {
                return item.workspaceId === workspaceId;
            });
        }
        return createPermissionChecker2(userPermissions, workspace ? workspace.permissions:[]);
    }

    confirm(action){
        const { projectReviewState } = this.props;

        let projectId = projectReviewState ? projectReviewState.projectId : 'not found';
        let projectName = projectReviewState ? projectReviewState.projectName : 'not found';
        let title = 'Project ' + projectId + ': ' + action;
        let confirmationMessage = 'Are you sure you want to ' + action + ' project ' + projectId + ' - ' + projectName + '?';
        let isFinalReview = false;
        let saveCallback = null;
        switch (action) {
            case 'Submit for Review':
                saveCallback = this.onSubmit;
                break;
            case 'Approve':
                saveCallback = this.onApprove;
                isFinalReview = projectReviewState ? projectReviewState.level === projectReviewState.numberOfReviewLevels : false;
                break;
            case 'Reject':
                saveCallback = this.onReject;
                break;
            case 'Unlock':
                const verifyPermission = this.createPermissionChecker();
                // CHECKING AGAIN TO PREVENT DOUBLE CLICKS IN IE FROM SNEAKING PAST THE DISABLED CONTROL
                if (projectReviewState && projectReviewState.locked && projectReviewState.status === "Complete" && verifyPermission(WORKSPACE_UNLOCKPROJECT)) {
                    saveCallback = this.onUnlock;
                }
                else {
                    return;
                }
                break;
            default:
        }
        this.setState({title, confirmationMessage, saveCallback, isFinalReview, showConfirmationModal: true});
    }

    handleClose(){
        this.setState({showConfirmationModal: false});
    }

    render() {
        const { 
            projectReviewState,
            projectReviewHistory,
        } = this.props;
        const timeZoneOffset = this.timeZoneOffset;

        const verifyPermission = this.createPermissionChecker();

        const { isWaiting, isFinalReview } = this.state;
        let projectId = projectReviewState ? projectReviewState.projectId : null;
        let projectName = projectReviewState ? projectReviewState.projectName : 'not found';
        let projectValuationDate = projectReviewState ? projectReviewState.valuationDate : 'not found';
        let projectDataStructure = projectReviewState ? projectReviewState.dataStructure : 'not found';
        let projectStatus = projectReviewState ? projectReviewState.status : 'not found';
        let projectLocked = projectReviewState ? projectReviewState.locked === true : false;
        let messageSubmit = projectReviewState ? projectReviewState.messageSubmit : '';
        let messageApproveReject = projectReviewState ? projectReviewState.messageApproveReject : '';
        let messageUnlock = projectReviewState ? projectReviewState.messageUnlock : '';

        let actions = null;
        if (projectReviewState && 
            Array.isArray(projectReviewState.actions) && 
            projectReviewState.actions.length > 0) {

                actions = projectReviewState.actions;
        }

        const submitDisabled = isWaiting || !(actions && actions.includes('submit'));
        const approveDisabled = isWaiting || !(actions && actions.includes('approve'));
        const rejectDisabled = isWaiting || !(actions && actions.includes('reject'));

        let btnStyle = {marginLeft: '5px', width:'150px'};
        const submitTooltip = submitDisabled ? (<Tooltip id="stool">{messageSubmit}</Tooltip>) : (<span></span>);
        const approveTooltip = approveDisabled ? (<Tooltip id="atool">{messageApproveReject}</Tooltip>) : (<span></span>);
        const rejectTooltip = rejectDisabled ? (<Tooltip id="rtool">{messageApproveReject}</Tooltip>) : (<span></span>);
        
        
        let historyList = null;
        if (projectReviewHistory && 
            Array.isArray(projectReviewHistory) && 
            projectReviewHistory.length > 0)  {
                
                historyList = projectReviewHistory;
        }

        let unlockDisabled = true;
        
        if (projectReviewState && projectReviewState.locked && projectReviewState.status === "Complete" && verifyPermission(WORKSPACE_UNLOCKPROJECT)) {
            unlockDisabled = false;
        }

        const unlockTooltip = unlockDisabled ? (<Tooltip id="utool">{messageUnlock}</Tooltip>) : (<span></span>);
        const unlockControl = ( 
            <OverlayTrigger placement="bottom" overlay={unlockTooltip}>
                <div style={{display: 'inline-block', cursor: unlockDisabled ? 'not-allowed' : 'pointer'}}>
                    <input 
                        name="isLocked" 
                        type="checkbox" 
                        style={{marginLeft: '5px', marginTop: '0', verticalAlign:'middle'}} 
                        checked={projectLocked} 
                        disabled={unlockDisabled}
                        onChange={() => {this.confirm('Unlock')}} />
                </div>
            </OverlayTrigger>
         ); 
        
        const projectInfo = (
<div>
    <div className="col-sm-12" >
        <h4 style={{marginTop:'0'}}>{projectId} - {projectName}</h4>
    </div>
    <div className="col-sm-12" style={{}}>
        Valuation Date: <b>{projectValuationDate}</b>
    </div>
    <div className="col-sm-12" style={{}}>
        Data Structure: <b>{projectDataStructure}</b>
    </div>
    <div className="col-sm-12" style={{}}>
        Status: <b>{projectStatus}</b>
    </div>
    <div className="col-sm-12" style={{}}>
        Locked: 
        {unlockControl}
    </div>

    <div className="col-sm-12 text-right" style={{ fontSize: 'small'}}>
            <OverlayTrigger placement="bottom" overlay={submitTooltip}>
                <div style={{display: 'inline-block', cursor: submitDisabled ? 'not-allowed' : 'pointer'}}>
                <Button size="sm" variant="primary"
                        disabled = {submitDisabled}
                        style = {{...btnStyle,...{pointerEvents: submitDisabled ? 'none' : 'auto'}}}
                        onClick={() => {this.confirm('Submit for Review')}}>Submit for Review</Button>
                </div>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={approveTooltip}>
                <div style={{display: 'inline-block', cursor: approveDisabled ? 'not-allowed' : 'pointer'}}>
                <Button size="sm" variant="success"
                        disabled = {approveDisabled}
                        style = {{...btnStyle,...{pointerEvents: approveDisabled ? 'none' : 'auto'}}}
                        onClick={() => {this.confirm('Approve')}}>
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Approve</Button>
                </div>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={rejectTooltip}>
                <div style={{display: 'inline-block', cursor: rejectDisabled ? 'not-allowed' : 'pointer'}}>
                <Button size="sm" variant="danger"
                        disabled = {rejectDisabled}
                        style = {{...btnStyle,...{pointerEvents: rejectDisabled ? 'none' : 'auto'}}}
                        onClick={() => {this.confirm('Reject')}}>
                    <i className="fa fa-times" aria-hidden="true"></i>&nbsp;
                    Reject</Button>
                </div>
            </OverlayTrigger>
    </div>
</div>
        );

    const history = isWaiting ? (<Loader loadingItem="Review History" />) :(
<Table responsive>
            <thead>
                <tr>
                    <th>Prior Status</th>
                    <th>Action</th>
                    <th>Action Taken By</th>
                    <th>Action Taken Date</th>
                    <th>Status</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>

                { historyList ? historyList.map((h, i) => (
                    <tr key={i}>
                        <td>{h.priorStatus}</td>
                        <td>{h.action}</td>
                        <td>{h.user}</td>
                        <td>{moment.utc(h.timestamp).add(timeZoneOffset, 'hours').format("L LT")}</td>
                        <td>{h.status}</td>
                        <td>{h.comments}</td>
                    </tr>
                    ))
                :null}
            </tbody>
        </Table> 
        );

        return (
            <div className='container-fluid' style={{width: '95%','maxHeight': 'calc(100vh - 100px)', 'overflowY': 'auto', fontSize: 'small'}}>
                <div className="row">
                    <div className="col-sm-12" style={{color: Colors.purple}}>
                        <h2>Project Review</h2>
                    </div>
                    <div className="col-sm-12">
                        {projectInfo}
                    </div>
                    <div className="col-sm-12">
                        <h4 style={{margin: '5px'}}>History</h4>
                        {history}
                    </div>
                </div>

                <ProjectReviewConfirmationModal 
                        show={this.state.showConfirmationModal} 
                        title={this.state.title} 
                        message={this.state.confirmationMessage} 
                        saveCallback={this.state.saveCallback} 
                        closeCallback={this.handleClose}
                        isFinalReview={isFinalReview}/>

                <SaveButtons
                    isSaveButtonDisabled = {true}
                    backButtonHander={() => this.props.history.goBack()}
                    backButtonText="Back"
                    />

            </div>
            
        );
    }
}

const mapStateToProps = state => ({
    projectReviewState: state.analysis.projects.projectReviewState,
    projectReviewHistory: state.analysis.projects.projectReviewHistory,
    projectReviewRefresh: state.analysis.projects.projectReviewRefresh,

    userKey: state.user.userKey,
  });
  
    const mapDispatchToProps = dispatch => ({
        dispatch,
    });

export default connect(mapStateToProps, mapDispatchToProps)(radium(ProjectReview));
