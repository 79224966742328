import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { retrieveExportsByListType, deleteExportListItem } from '@app/actions/analysis/project.actions';
import { showModal } from '@app/actions/modal.actions';
import * as api from '@app/api/projects.serviceApi';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import Loader from '@app/shared/presentational/loader';
import { Grid, Button } from '@arius';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { notifyError } from '../../../utilities/notifier';

const DEFAULT_MODAL = {
    title: 'Delete Measure Mapper',
    message: '',
    mode: ''
};

class GenericList extends Component {
    static propTypes = {
        data: PropTypes.array,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        currentWorkspace: PropTypes.object,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modal: DEFAULT_MODAL
        };
        this.listTypes = {
            collectionSets: 'collectionSets',
            printLists: 'printLists',
            excelLists: 'excelLists',
            exhibitOptions: 'exhibitOptions',
            dataDefaults: 'dataDefaults',
            exhibitDefaults: 'exhibitDefaults'
        };
        this.deleteItem = this.deleteItem.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    get listType() {
        let param = this.props.params.listType;
        const key = Object.keys(this.listTypes).find(x=> x.toLowerCase() === param);
        return key ? this.listTypes[key] : 'UNKNOWN LIST TYPE';
    }

    get label() {
        switch(this.listType) {
            case this.listTypes.collectionSets:
                return 'Collection Sets';
            case this.listTypes.printLists:
                return 'Print Lists';
            case this.listTypes.excelLists:
                return 'Excel Lists';
            case this.listTypes.exhibitOptions:
                return 'Exhibit Options';
            case this.listTypes.dataDefaults:
                return 'Data Defaults';
            case this.listTypes.exhibitDefaults:
                return 'Exhibit Defaults';
            default:
                return 'UNKNOWN LIST';
        }
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace, params } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
            return;
        }
        if (params.listType !== prevProps.params.listType){
            this.refresh();
            return;
        }
    }
    
    refresh() {
        const { userKey, dispatch, currentWorkspace } = this.props;
        if (currentWorkspace){
            dispatch(retrieveExportsByListType({ 
                userKey, 
                databaseId: currentWorkspace.id, 
                listType: this.listType}));
        }
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="delete" 
                onClick={() => this.deleteItem(row.exportListId)}
                toolTip='Delete'/>
        )
    }

    getGrid() {
        const data = this.props[this.listType];
        let columns = [
            { field: 'listName', headerText: 'Name'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModifiedDate', headerText: 'Last Modified'},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    deleteItem(exportListId) {
        const { dispatch, userKey, currentWorkspace } = this.props;
        const data = this.props[this.listType];

        if (this.listType === this.listTypes.collectionSets ||
            this.listType === this.listTypes.exhibitOptions ||
            this.listType === this.listTypes.dataDefaults ||
            this.listType === this.listTypes.exhibitDefaults){
            // need to check if its used in a script...
            this.deleteWithScriptCheck(exportListId);
            return;
        }

        if (data.length !== 0) {
            const selectedExport = data.find(
                def => def.exportListId === exportListId
            );
            const deleteMessageItems = [
                <li key={exportListId} style={{ fontWeight: 800 }}>
                    {selectedExport.listName}
                </li>,
                ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following item?'
            );

            const yesClickHandler = () => {
                dispatch(deleteExportListItem({ 
                    userKey, 
                    exportListId, 
                    databaseId: currentWorkspace.id || currentWorkspace.workspaceId, 
                    listType: this.listType}));
            };
            const noClickHandler = () => {},
                action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
                );
            dispatch(action);
        }
    }

    deleteWithScriptCheck(exportListId) {
        const data = this.props[this.listType];
        const { userKey, currentWorkspace } = this.props;
        const list = data.find(def => def.exportListId === exportListId);
        if (!list){return;}

        let canDelete = true; // TODO verifyPermission(??);
        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({modal: {...DEFAULT_MODAL, mode: 'checking'}});
        api.retrieveBatchScriptsContainingList(userKey, currentWorkspace.id, exportListId).then((data)=> {
            let names = data ? data.map(x=> x.scriptName) : null;
            let label = this.label.slice(0, -1); // remove the trailing 's'...
            if (names && Array.isArray(names)){
                if (names.length > 0) {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'warn',
                            title: `Delete ${label}`,
                            message: <div>
                            {`Cannot delete this ${label} that is referenced in the following Batch Scripts:`}  
                            <ul>
                                {names.map(n => <li key={`li-${n}`} style={{ fontWeight: 800 }}>{n}</li>)}
                            </ul>
                        </div>
                        }
                    });
                } else {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'prompt',
                            title: 'Are you sure?',
                            message: <div>
                                {`Are you sure you want to delete the following ${label}?`}  
                                <ul>
                                    <li style={{ fontWeight: 800 }}>{list.listName}</li>
                                </ul>
                            </div>,
                            yesHandler: ()=> {
                                api.deleteExportListItem({ userKey, databaseId: currentWorkspace.id || currentWorkspace.workspaceId, exportListId }).then(()=>{
                                    this.refresh();
                                })
                                this.setState({modal: {...DEFAULT_MODAL, mode: ''}});
                            }
                        }
                    });
                }
            } else {
                this.setState({modal: {...DEFAULT_MODAL, mode: 'fail'}})
            }
        })
    }

    getDialogModal() {
        const { modal } = this.state;
        let { mode, title, message, yesHandler } = modal;
        let show = mode !== '';
        let handleClose = () => this.setState({modal: {...modal, mode: ''}});
        let buttons = <Button variant="arius" onClick={handleClose}>Close</Button>;
        
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};

        if (mode === 'checking') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
                <div style={textStyle}>Checking dependencies...</div>
            </span>;
        }

        if (mode === 'fail') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
                <div style={textStyle}>Error checking dependencies</div>
            </span>;
        }

        if (mode === 'prompt') {
            buttons = [
                <Button variant="arius" key='y' onClick={yesHandler}>Yes</Button>,
                <Button variant="arius" key='n' onClick={handleClose}>No</Button>
            ];
        }

        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={handleClose} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>{title}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
        );
    }

    render() {
        const { currentWorkspace, isFetchingGenericLists:isFetching } = this.props;
        let body = <div></div>;

        if (isFetching) {
            body = (
                <div style={{ width: '85vw', margin: '5vh auto' }}>
                    <Loader loadingItem={this.label} />
                </div>
            );
        } else if (!currentWorkspace) {
            body = (
                <div
                style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={'Please select a database'} />
                </div>
            );
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>{this.label}</h4>
            </div>
            {body}
            <div style={currentWorkspace && !isFetching ? {} : {display: 'none'}}>
                {this.getGrid()}
            </div>
            {this.getDialogModal()}
        </div>
        )
    }
}

export default radium(GenericList);
