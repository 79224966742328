import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import Loader from '@app/shared/presentational/loader';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import { 
    retrieveCategories, 
    createCategory, 
    updateCategory,
} from '@app/actions/analysis/category.actions';
import * as api from '@app/api/categories.serviceApi';

import { Grid, Button } from '@arius';
import { 
    //WORKSPACE_MANAGEMAPPERS, 
    MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { notifyError } from '../../../utilities/notifier';
import ModalEditCategory from './projects/modalEditCategory';

const DEFAULT_MODAL = {
    title: 'Delete Project Category',
    message: '',
    mode: ''
};

class ProjectCategoriesList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        uploads: PropTypes.array.isRequired,
        isSaving: PropTypes.bool,
        isFetching: PropTypes.bool,
        getMeasureMappers: PropTypes.func,
        deleteFile: PropTypes.func,
        uploadNewFile: PropTypes.func,
        errorMessage: PropTypes.string,
        clearError: PropTypes.func,
        uploadNewFile2: PropTypes.func,
        hasWriteAccess: PropTypes.bool,
    };
    static defaultProps = {
        uploads: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            modal: DEFAULT_MODAL,
            currentRecord: {}
        };
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.promptToDelete = this.promptToDelete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);  
    }

    get existingNames(){
        let { currentRecord } = this.state;
        const { categories:data } = this.props;
        return data ? data.filter(x=> x.projectCategoryId !== currentRecord.projectCategoryId)
            .map(x=> x.name.toLowerCase().trim()) : [];
    }


    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    refresh() {
        const { dispatch, userKey, currentWorkspace, isFetching } = this.props;
        if (currentWorkspace && !isFetching){
            dispatch(retrieveCategories(userKey, currentWorkspace.id));
        }
    }

    create() {

        // TODO let verifyPermission = this.getPermissionChecker();
        // if (!verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES)) {
        //     notAuthorized();
        //     return;
        // }
        const { currentWorkspace } = this.props;
        if (!currentWorkspace){
            notifyError('Please select a database');
            return;
        }
        var record = {projectCategoryId: -1, name: '', description: '', color: '#ff0000'};
        this.setState({showModalEdit: true, currentRecord: record});
    }

    edit(currentRecord) {
        this.setState({showModalEdit: true, currentRecord: JSON.parse(JSON.stringify(currentRecord))});
    }

    save(req) {
        const { userKey, currentWorkspace, dispatch } = this.props;
        this.setState({showModalEdit: false});
        if (req.projectCategoryId < 1){
            dispatch(createCategory(userKey, currentWorkspace.id, req));
        } else {
            dispatch(updateCategory(userKey, currentWorkspace.id, req));
        }
    }

    promptToDelete(o, e) {
        const { userKey, currentWorkspace } = this.props;
        let canDelete = true;// TODO verifyPermission(WORKSPACE_MANAGEMAPPERS);
        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({modal: {...DEFAULT_MODAL, mode: 'checking'}});
        api.getExtractsByCategoryId(userKey, currentWorkspace.id, o.projectCategoryId).then((names)=> {
            if (names && Array.isArray(names)){
                if (names.length > 0) {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'warn',
                            title: 'Delete Project Category',
                            message: <div>
                            Cannot delete this Project Category that is referenced in the following Extract Definitions:  
                            <ul>
                                {names.map(n => <li key={`li-${n}`} style={{ fontWeight: 800 }}>{n}</li>)}
                            </ul>
                        </div>
                        }
                    });
                } else {
                    this.setState({
                        modal: { ...DEFAULT_MODAL, mode: 'prompt',
                            title: 'Are you sure?',
                            message: <div>
                                Are you sure you want to delete the following category?  
                                <ul>
                                    <li style={{ fontWeight: 800 }}>{o.name}</li>
                                </ul>
                            </div>,
                            yesHandler: ()=> {
                                api.deleteCategory(userKey, currentWorkspace.id, o.projectCategoryId).then(()=>{
                                    this.refresh();
                                })
                                this.setState({modal: {...DEFAULT_MODAL, mode: ''}});
                            }
                        }
                    });
                }
            } else {
                this.setState({modal: {...DEFAULT_MODAL, mode: 'fail'}})
            }
        })
    }

    getGrid() {
        const { categories } = this.props;
        let data = categories ? categories : [];
        let columns = [
            { field: 'projectCategoryId', headerText: 'Id', visible: false},
            { field: 'color', headerText: 'Color', width: 50, template: (r)=> <div 
                style={{backgroundColor:`${r.color}`, height: '15px', width: '15px', marginRight: '2px',
                borderRadius: 0}}></div>},
            { field: 'name', headerText: 'Name'},
            { field: 'description', headerText: 'Description'},
            { field: 'updateBy', headerText: 'Last Modified By'},
            { field: 'updateDate', headerText: 'Last Modified'},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    getActionItems(row) {
        return  <span>
            <Button toolTip="Edit" iconName="edit" onClick={(e)=> this.edit(row)}/>
            <Button 
                iconName="delete" 
                onClick={(e) => this.promptToDelete(row, e)}
                toolTip='Delete'/>
        </span>;
    }

    getDialogModal() {
        const { modal } = this.state;
        let { mode, title, message, yesHandler } = modal;
        let show = mode !== '';
        let handleClose = () => this.setState({modal: {...modal, mode: ''}});
        let buttons = <Button variant="arius" onClick={handleClose}>Close</Button>;
        
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};

        if (mode === 'checking') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
                <div style={textStyle}>Checking dependencies...</div>
            </span>;
        }

        if (mode === 'fail') {
            message = <span style={containerStyle}>
                <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
                <div style={textStyle}>Error checking dependencies</div>
            </span>;
        }

        if (mode === 'prompt') {
            buttons = [
                <Button variant="arius" key='y' onClick={yesHandler}>Yes</Button>,
                <Button variant="arius" key='n' onClick={handleClose}>No</Button>
            ];
        }

        return (
        <Modal show={show} dialogClassName="confirmModal" onHide={handleClose} style={{ fontSize: 'smaller' }}>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF'}}>
                <Modal.Title><small>{title}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
        );
    }


    render() {
        const { currentWorkspace, isFetching } = this.props;
        const { showModalEdit, currentRecord } = this.state;
        const canEdit = true; //verifyPermission(WORKSPACE_CREATEEDITEXTRACTTABLES);
        let body = <div></div>;

        if (isFetching) {
            body = (
                <div style={{ width: '85vw', margin: '5vh auto' }}>
                    <Loader loadingItem='Project Categories' />
                </div>
            );
        } else if (!currentWorkspace) {
            body = (
                <div
                style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={'Please select a database'} />
                </div>
            );
        }

        return (
        <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Project Categories</h4>
                    </span>
                    <Button
                        toolTip = "Create new project category"
                        mode="add"
                        onClick={this.create}
                    />
                </div>
            {body}
            <div style={currentWorkspace && !isFetching ? {} : {display: 'none'}}>
                {this.getGrid()}
            </div>
            {this.getDialogModal()}
            <ModalEditCategory 
                    show={showModalEdit} 
                    record={currentRecord} 
                    closeHandler={()=> {
                        this.setState({showModalEdit: false});
                    }}
                    saveHandler={this.save}
                    existingNames={this.existingNames}
                    canEdit={canEdit}
                />
        </div>
        )
    }
}

export default radium(ProjectCategoriesList);
