import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';

function UtilitiesNav() {
    return (
        <NavTabs>
            <NavTab label={'Audit Log'} to={'/utilities/auditLogSearch'}/>
            <NavTab label={'Performance Levels'} to={'/utilities/performance'}/>
        </NavTabs>
    )
}   

export default UtilitiesNav;
