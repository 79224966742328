import React from 'react';
import * as adal from '../utilities/adalAdapter';

const Unauthorized = () => {
  const user = adal.getCachedUser();
  const name = user ? user.userName : 'you have used to log in';
  return (
    <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', marginTop: 50 }}>
      <h1>Arius Enterprise</h1>
      <div>The account {name} is not authorized to access that resource.</div>
      <div><button onClick={adal.logoff}>Log off</button> and try another account if you have one.</div>
    </div>
  );
};

export default Unauthorized;
