import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import {receivedQuery, validateSyntax} from '@app/actions/tod/advancedQueries.actions';
import {RECEIVED_QUERY} from '@app/actions/tod/tod.actionTypes';
import ColumnContainer from './columns';
import Sequence from './sequence';
import Utils from './queryUtils';

class QueryContainer extends Component {
    static propTypes = {
        columns: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.state = {
            stack: [], 
            defaultQuery: {
                name: '',
                description: '',
                where: {}
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleHover = this.handleHover.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        const {query, databaseExposureDates, dispatch} = this.props;
        if (query && !query.exposureColumnName && databaseExposureDates.length>0){
            // set default exposure date column
            let q = JSON.parse(JSON.stringify(query));
            q.exposureColumnName = databaseExposureDates[0].name;
            dispatch({type: RECEIVED_QUERY, current: q});
        }
    }
    
    handleChange(e){
        const {databaseId, userKey, dispatch}=this.props;
        let query = JSON.parse(JSON.stringify(this.props.query));
        query[e.target.id] = e.target.value;
        Utils.numberItems(query.where, 0, 0);
        this.props.dispatch(receivedQuery(query));
        if (e.target.id === 'where' || e.target.id === 'groupBy'){
            dispatch(validateSyntax(userKey, databaseId, query));
        };
        this.setState({nameError: this.props.validateName(query.name), stack: []})
    }

    handleHover(e){
        // let stack = this.state.stack;

        // if (e.isEntering) {
        //     if (stack.indexOf(e.id) < 0){
        //         console.error("pushing: ", e.id, stack);
        //         stack.push(e.id);
        //         this.setState({stack});
                
        //     }
            
        // } else {
        //     if (stack.indexOf(e.id) > -1){
        //         console.error("POP: ", e.id, stack, stack.filter((o) => o != e.id));
        //         stack = stack.filter((o) => o != e.id);
        //         this.setState({stack});
                
        //     }
        // }
    }

    getTotalItems(o){
        if (!o) {return 0;}
        let total = o.type !== 'sequence' ? 1 : 0;
        for (let i = 0; i < (o.items ? o.items.length : -1); i++) { 
            total += this.getTotalItems(o.items[i]);
        }  
        return total;
    }

    render() {
        let query = this.props.query ? this.props.query : this.state.defaultQuery;
        let description = query.description ?? '';
        let name = query.name ?? '';
        let exposureDates = this.props.databaseExposureDates ? this.props.databaseExposureDates : [];
        let factColumns = this.props.factColumns ? this.props.factColumns : [];
        var n = this.getTotalItems(query.where);
        let nameStyle = this.state.nameError ? {borderColor: '#a94442'} : {};

        return (
            <div className="row" style={{}}>
                <div className="col-6" style={{}}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            id="name"
                            style= {nameStyle}
                            value={name}
                            onChange={this.handleChange}
                            onDrop={(e) => e.preventDefault()}
                            className="form-control"
                            type="text"
                            placeholder='enter a name'
                        />
                        <div  style= {{color: '#a94442'}}>{this.state.nameError}</div>
                    </div>
                </div>
                <div className="col-6" style={{}}>
                    <div className="form-group">
                        <label htmlFor="name">Exposure Date:</label>
                        <select className="form-control" id="exposureColumnName" value={query.exposureColumnName} onChange={this.handleChange} >
                            <option value="">
                                -- Select an Exposure Date --
                            </option>
                            {exposureDates.map(col => (

                                <option
                                    value={col.name}
                                    key={`${col.name}`}
                                >
                                    {col.name}
                                </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-sm-12" style={{}}>
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <input
                            id="description"
                            value={description}
                            onChange={this.handleChange}
                            onDrop={(e) => e.preventDefault()}
                            className="form-control"
                            type="text"
                            placeholder='enter a description'
                        />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="name">Group By:</label>
                        <ColumnContainer 
                                columns={query.groupBy}
                                handleChange={this.handleChange}
                                factColumns={factColumns}
                            ></ColumnContainer>    
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group" >
                        <label htmlFor="name">Filters:</label>
                        <Sequence 
                                items={query && query.where ? query.where.items : []}
                                isBase={true}
                                totalItems= {n}
                                queryFragmentOperatorId = {query && query.where ? query.where.queryFragmentOperatorId : null}
                                order={0}
                                serialNumber = {query && query.where ? query.where.serialNumber : ''}
                                parentSerialNumber = {query && query.where ? query.where.parentSerialNumber : ''}
                                handleChange = {(val) => {this.handleChange(
                                    {target:{id: 'where', value: val}}
                                )}}
                                handleHover={this.handleHover}
                                expelItem={this.expelItem}
                                includeOtherColumns={this.props.includeOtherColumns}
                            ></Sequence>  
                    </div>
                </div>
            </div> 
        );
    }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  query: state.tod.queries.current,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryContainer);
