import React, { Component } from 'react';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { Modal, Button, Form } from 'react-bootstrap';
import * as reportingApi from '@app/api/reporting.serviceApi';
import { fetchUploads } from '@app/actions/analysis/uploads.actions';
import * as Colors from '@app/utilities/colors';

const reportingStatuses = {completed: 'Completed', requested: 'Requested', failed: 'Failed', executing: 'Executing'
    , na: 'N/A' }
const terminalStatuses = [reportingStatuses.completed, reportingStatuses.failed, reportingStatuses.na];

class ModalLoadFile extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            status: '',
            count: 0, 
            message: ''
        };
        this.selectFile = this.selectFile.bind(this);
        this.load = this.load.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.getStatus = this.getStatus.bind(this);
    }

    componentDidMount() {
        const { scheduledJobs } = this.props;
        this.setState({ scheduledJobs });
    }

    componentDidUpdate(prevProps) {
    }

    close() {
        const { closeHandler } = this.props;
        this.setState({status: '', selectedFile: ''});
        closeHandler();
    }

    open() {
        const { getUploads } = this.props;
        getUploads();
        this.setState({ showModal: true });
    }

    load() {
        const { userKey, tableDefinition, databaseId } = this.props;
        const { selectedFile } = this.state;
        this.setState({status: reportingStatuses.executing});
        var req = {
            blobPath: selectedFile.uniqueName,
            tableDefinitionId: tableDefinition.tableDefinitionId
        };
        reportingApi
            .requestTableLoad({ userKey, databaseId, req })
            .then((response) => {
                console.error('response: ', response);
                if (response && response.tableFileId){
                    this.getStatus(response.tableFileId);
                } else {
                    this.setState({status: reportingStatuses.failed, message: response ? response.message : ''});
                }
            });
    }

    selectFile(e) {
        const { uploads } = this.props;
        var file = uploads.find(x=> x.id === e.target.value);
        this.setState({selectedFile: file ? file : ''});
    }

    get statusIndicator(){
        const { status, count, message } = this.state;
        let containerStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '10px' };
        let iconStyle = {fontSize: 50,display: 'flex', justifyContent: 'center'};
        let textStyle = {width: '100%',textAlign: 'center', marginTop: 10};

        if (status === reportingStatuses.requested || status === reportingStatuses.executing){
            return <span style={containerStyle}>
            <div><i style={{...iconStyle, color: Colors.purple}} className="fa fa-spinner fa-spin"></i></div>
            <div style={textStyle}>Processing data load</div>
        </span>
        }

        if (status === reportingStatuses.completed){
            return <span style={containerStyle}>
            <div><i style={{...iconStyle, color: 'green'}} className="fa fa-check"></i></div>
            <div style={textStyle}>Data load complete.</div>
            <div style={textStyle}>{`Loaded ${count} records.`}</div>
        </span>
        }

        if (status === reportingStatuses.failed){
            return <span style={containerStyle}>
            <div><i style={{...iconStyle, color: Colors.red}} className="fa fa-times"></i></div>
            <div style={textStyle}>Data load failed.</div>
            <div style={textStyle}>{message}</div>
        </span>
        }

        return '';
    }

    getStatus(tableFileId){
        const { userKey, databaseId } = this.props;
        const { status } = this.state;

        if (terminalStatuses.includes(status)){
            return;
        }

        reportingApi.getLoadStatus({userKey, databaseId, tableFileId})
            .then((s) => {
                let status = s.status;
                let count = s.recordCount;
                let message = s.message;
                this.setState({status, count, message});
                if (status !== reportingStatuses.completed && status !== reportingStatuses.failed){
                    setTimeout(()=> {this.getStatus(tableFileId);}, 2000);
                }
            });
    }

    render() {
    const { show, uploads  } = this.props;
    const { selectedFile, status } = this.state;
          
    return (
      <Modal id={'modalLoadFile'} show={show} dialogClassName="wait-modal" size="sm"
        onHide={this.props.closeHandler} onShow={this.open}>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
            Load CSV into Table
        </Modal.Header>
        <Modal.Body className="modal-container">
          <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                <div style={{ width: '100%'}}>
                    <div className="col-md-12">
                        <Form.Group controlId="fileSelect">
                            <Form.Label>Select File to Load</Form.Label>
                            <Form.Control as="select" placeholder="select"
                                disabled={false}
                                onChange={this.selectFile}
                                value = {selectedFile.id}
                                >
                                <option value="default">-- Select a CSV file --</option>
                                {uploads.map(upload => (
                                    <option key={`${upload.id}`} value={upload.id}>{upload.name}</option>
                                ))}
                            </Form.Control>
                            {/* <Form.Control.Feedback type="invalid">{fileValidation.error}</Form.Control.Feedback> */}
                        </Form.Group>
                    </div>
            </div>
        </div>
        {this.statusIndicator}
        </Modal.Body>
        <Modal.Footer>
            {!status ? <Button variant="arius" data-dismiss="modal" onClick={this.load} 
                disabled={!selectedFile}>Load</Button> : ''}
         <Button variant="arius" data-dismiss="modal" onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    uploads: state.analysis.uploads.items,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    getUploads: () => {
        dispatch(fetchUploads());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(ModalLoadFile));
