export const
  RECEIVED_GROUPS = 'RECEIVED_GROUPS',
  RECEIVED_GROUP = 'RECEIVED_GROUP',
  RECEIVED_GROUP_USERS = 'RECEIVED_GROUP_USERS',
  RECEIVED_SUBSCRIPTION_USERS = 'RECEIVED_SUBSCRIPTION_USERS',
  RECEIVED_SUBSCRIPTION_USER = 'RECEIVED_SUBSCRIPTION_USER',
  RECEIVED_AUDIT_LOGS = 'RECEIVED_AUDIT_LOGS',
  RECEIVED_AUDIT_LOG_CONTENT = 'RECEIVED_AUDIT_LOG_CONTENT',
  FETCHING_SUBSCRIPTION_USERS_FROM_SERVER = 'FETCHING_SUBSCRIPTION_USERS_FROM_SERVER',
  FETCHING_GROUP_USERS_FROM_SERVER = 'FETCHING_GROUP_USERS_FROM_SERVER',
  FETCHING_SUBSCRIPTION_USER_FROM_SERVER = 'FETCHING_SUBSCRIPTION_USER_FROM_SERVER',
  FETCHING_GROUPS_FROM_SERVER = 'FETCHING_GROUPS_FROM_SERVER',
  FETCHING_GROUP_FROM_SERVER = 'FETCHING_GROUP_FROM_SERVER',
  FETCHING_USERS_FROM_SERVER = 'FETCHING_USERS_FROM_SERVER',
  SAVING_GROUP = 'SAVING_GROUP',
  UPDATED_USER = 'UPDATED_USER',
  UPDATED_GROUP = 'UPDATED_GROUP',
  RECEIVED_ASSIGNED_ROLES = 'RECEIVED_ASSIGNED_ROLES',
  FETCHING_ASSIGNED_ROLES = 'FETCHING_ASSIGNED_ROLES',
  RECEIVED_AVAILABLE_ROLES = 'RECEIVED_AVAILABLE_ROLES',
  FETCHING_AVAILABLE_ROLES = 'FETCHING_AVAILABLE_ROLES',
  RECEIVED_ROLE_DESCRIPTIONS = 'RECEIVED_ROLE_DESCRIPTIONS',
  RECEIVED_ROLE_DESCRIPTION = 'RECEIVED_ROLE_DESCRIPTION',
  FETCHING_ROLE_DESCRIPTIONS = 'FETCHING_ROLE_DESCRIPTIONS',
  RECEIVED_AVAILABLE_PERMISSIONS = 'RECEIVED_AVAILABLE_PERMISSIONS',
  FETCHING_AVAILABLE_PERMISSIONS = 'FETCHING_AVAILABLE_PERMISSIONS',
  RECEIVED_AVAILABLE_USERS = 'RECEIVED_AVAILABLE_USERS',
  RECEIVED_AVAILABLE_RESOURCES = 'RECEIVED_AVAILABLE_RESOURCES',
  FETCHING_AVAILABLE_RESOURCES = 'FETCHING_AVAILABLE_RESOURCES',
  FETCHING_AVAILABLE_USERS = 'FETCHING_AVAILABLE_USERS',
  CLEAR_AVAILABLE_PERMISSIONS = 'CLEAR_AVAILABLE_PERMISSIONS',
  UPDATED_USER_ROLE = 'UPDATED_USER_ROLE',
  ASSIGNING_ROLES = 'ASSIGNING_ROLES',
  CLEAR_ROLE_DESCRIPTION = 'CLEAR_ROLE_DESCRIPTION',
  AUDIT_LOG_CALL_MADE = 'AUDIT_LOG_CALL_MADE',
  AUDIT_LOG_CSV_CALL_MADE = 'AUDIT_LOG_CSV_CALL_MADE',
  RECEIVED_AUDIT_LOG_CSV = 'RECEIVED_AUDIT_LOG_CSV';

