import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import * as GlobalStyles from '../../../utilities/globalStyles';

const style = {
    table: {
        boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        backgroundColor: 'white',
        overflow: 'scroll',
    },
    bodyStyle: {
        boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        backgroundColor: 'Colors.grey',
    },
};

class TotalsTable extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.rows !== this.props.rows;
    }
    render() {
        const { rows } = this.props;
        return (
            <div
                id="reconciliationTotalsTable"
                style={[GlobalStyles.card, { margin: '20px 0 15px 0' }]}
            >
                <h4>Pivot Table</h4>
                <div style={style.table}>
                <table>
                    <tbody style={style.bodyStyle}>
                    {rows.map(row => row)}
                    </tbody>
                </table>
                </div>
            </div>
        );
    }
}

TotalsTable.propTypes = {
    rows: PropTypes.array,
};

export default radium(TotalsTable);
