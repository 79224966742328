import {
  SHOW_MODAL,
  HIDE_MODAL,
} from '../actions/actionTypes';

const modal = (
  state = { modalType: null, message: null, yesClickHandler: null, noClickHandler: null },
  action
) => {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        modalType: action.modalType,
        message: action.message,
        yesClickHandler: action.yesClickHandler,
        noClickHandler: action.noClickHandler,
      });
    case HIDE_MODAL:
      return Object.assign({}, state, {
        modalType: null,
        message: null,
        yesClickHandler: null,
        noClickHandler: null,
      });
    default:
      return state;
  }
};

export default modal;
