import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';

function PortalManagementNav() {
    return (
        <NavTabs>
            <NavTab label={'Users'} to={'/portalManagement/users'}/>
            <NavTab label={'Roles'} to={'/portalManagement/roles'}/>
            <NavTab label={'Assign Roles'} to={'/portalManagement/assignments'}/>
        </NavTabs>
    )
}   

export default PortalManagementNav;

