import {
  UNAUTHORIZED_USER,
  RECEIVED_USER,
  FETCHING_USER_FROM_SERVER,
  SET_SECURE_DATA_MANAGER_FACTORY,
} from '../actions/actionTypes';

const user = (state = {
  userId: '', userKey: null,
  isAuthorized: null,
  isAdmin: false,
  userRoles: [],
  userGroups: [],
  userPermissions: [],
  isFetching: false,
  secureDataManagerFactory: {}
}, action) => {
  switch (action.type) {
    case FETCHING_USER_FROM_SERVER:
      return Object.assign({}, state, { isFetching: true });
    case UNAUTHORIZED_USER:
      return Object.assign({}, state, {
        isFetching: false,
        userId: 0,
        userKey: null,
        receivedAt: action.receivedAt,
        isAuthorized: false,
      });
    case RECEIVED_USER:
      return Object.assign({}, state, {
        isFetching: false,
        userId: action.userId,
        userKey: action.userKey,
        userPermissions: action.userPermissions,
        siteSettings: action.siteSettings,
        receivedAt: action.receivedAt,
        isAuthorized: true,
      });
    case SET_SECURE_DATA_MANAGER_FACTORY: 
      return Object.assign({}, state, {
          secureDataManagerFactory: action.factory,
      })
    default:
      return state;
  }
};

export default user;
