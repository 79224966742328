import {
  FETCHING_REPORT_FROM_SERVER,
  FETCHING_REPORTS_FROM_SERVER,
  FETCHING_REPORT_ACCESS_TOKEN,
  RECEIVED_REPORT_ACCESS_TOKEN,
  SELECTING_REPORT,
  RECEIVED_REPORTS } from '../../actions/analysis/analysis.actionTypes';

const reportsReducer = (state = {
  isFetching: false,
  current: null,
  accessToken: null }, action) => {
  switch (action.type) {
    case FETCHING_REPORT_ACCESS_TOKEN:
    case FETCHING_REPORT_FROM_SERVER:
    case FETCHING_REPORTS_FROM_SERVER:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVED_REPORT_ACCESS_TOKEN:
      return Object.assign({}, state, {
        isFetching: false,
        accessToken: action.accessToken,
        receivedAt: action.receivedAt,
      });
    case SELECTING_REPORT:
      return Object.assign({}, state, {
        isFetching: true,
        current: action.current,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_REPORTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.items,
        receivedAt: action.receivedAt,
      });
    default:
      return state;
  }
};

export default reportsReducer;
