import { callApi } from '../utilities/callApi';

export function createQuery(userKey, databaseId, query){
  return callApi(
    `queries/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    query,
  );
}

export function updateQuery(userKey, databaseId, query){
  return callApi(
    `/queries/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    query,
  );
}

export function deleteQuery(userKey, databaseId, queryId) {
  return callApi(
    `/queries/${databaseId}/${queryId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function validateQuery(userKey, databaseId, query){
  return callApi(
    `/queries/${databaseId}/validate`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    query,
  );
}

export function validateDataGroup(userKey, databaseId, o){
    return callApi(
      `/queries/${databaseId}/datagroup/validate`,
      { 'X-AE-USERKEY': userKey },
      'POST',
      o,
    );
}

export function validateSyntax(userKey, databaseId, query){
    return callApi(
      `/queries/${databaseId}/syntax`,
      { 'X-AE-USERKEY': userKey },
      'POST',
      query,
    );
}

export function getValues(userKey, databaseId, factColumnName, search, skip, take, desc) {
    let url = `/queries/${databaseId}/values?factColumnName=${factColumnName}&search=${search}&skip=${skip}&take=${take}&desc=${desc}`;
    return callApi(url, {'X-AE-USERKEY': userKey,});
}

export function getFactValues(userKey, databaseId, factColumnName, search, skip, take, desc) {
    let url = `/queries/${databaseId}/factvalues?factColumnName=${factColumnName}&search=${search}&skip=${skip}&take=${take}&desc=${desc}`;
    return callApi(url, {'X-AE-USERKEY': userKey,});
}

export function getFactColumns(userKey, databaseId) {
  return callApi(`/queries/${databaseId}/factcolumns`, {
    'X-AE-USERKEY': userKey,
  });
}

export function getOperators(userKey, databaseId) {
  return callApi(`/queries/${databaseId}/operators`, {
    'X-AE-USERKEY': userKey,
  });
}

export function getDataGroups(userKey, databaseId) {
  return callApi(`/queries/${databaseId}/datagroups`, {
    'X-AE-USERKEY': userKey,
  });
}

export function getDataGroup(userKey, databaseId, dataGroupId) {
  return callApi(`/queries/${databaseId}/datagroups/${dataGroupId}`, {
    'X-AE-USERKEY': userKey,
  });
}

export function getRollups(userKey, databaseId) {
    return callApi(`/queries/${databaseId}/rollups`, {
      'X-AE-USERKEY': userKey,
    });
}

export function getQueriesForDataGroup(userKey, databaseId, dataGroupId) {
    return callApi(`/queries/${databaseId}/datagroups/${dataGroupId}/queries`, {
      'X-AE-USERKEY': userKey,
    });
  }

export function createDataGroup(userKey, databaseId, dataGroup){
  return callApi(
    `queries/${databaseId}/datagroup`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    dataGroup,
  );
}

export function updateDataGroup(userKey, databaseId, dataGroup){
  return callApi(
    `/queries/${databaseId}/datagroup`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    dataGroup,
  );
}

export function deleteDataGroup(userKey, databaseId, dataGroupId) {
  return callApi(
    `/queries/${databaseId}/datagroup/${dataGroupId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function retrieveQuery(userKey, databaseId, queryId) {
  return callApi(`/queries/${databaseId}/${queryId}`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveQueries(userKey, databaseId) {
     return callApi(`/queries/${databaseId}`, {
     'X-AE-USERKEY': userKey,
    });
}

export function retrieveQuerySQL(userKey, databaseId, query) {
  return callApi(`/queries/${databaseId}/sql`,
   {'X-AE-USERKEY': userKey,},
   'POST',
    query);
}

export function retrieveQuerySegments(userKey, databaseId, query) {
  return callApi(`/queries/${databaseId}/segments`, 
  {'X-AE-USERKEY': userKey,},
  'POST',
  query);
}

export function retrieveQueryWhereStatement(userKey, databaseId, query) {
  return callApi(`/queries/${databaseId}/where`, 
  {'X-AE-USERKEY': userKey,},
  'POST',
  query);
}