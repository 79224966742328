import {
  FETCHING_GROUPS_FROM_SERVER,
  FETCHING_GROUP_FROM_SERVER,
  RECEIVED_GROUP_USERS,
  RECEIVED_GROUPS,
  RECEIVED_GROUP,
  UPDATED_GROUP,
} from '../../actions/portalManagement/portalManagement.actionTypes';

const groupsReducer = (state = { items: [], isFetching: false, isUpdated: false, current: null, users: [] }, action) => {
  switch (action.type) {
    case FETCHING_GROUPS_FROM_SERVER:
    case FETCHING_GROUP_FROM_SERVER:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVED_GROUP:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        current: action.group,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_GROUPS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        items: action.groups,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_GROUP_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        users: action.users,
      });
    case UPDATED_GROUP:
      return Object.assign({}, state, {
        isUpdated: true,
      });
    default:
      return state;
  }
};

export default groupsReducer;
