import $ from 'jquery';

class QueryUtils {
    static clauseFromFactColumn = (operators, factColumn) => {
        let clauses = operators.filter((item, index) => item.type === 'clause');
        let clause = clauses ? clauses[0] : {};
        return {...factColumn, ...clause, subject: factColumn.name, sql: clause.sql, type: 'clause'};
    }
    static clauseFromOperator = (operator) => {
        return {...operator, subject: '', sql: operator.sql};
    }
    static createFilterItem = (operators, o) => {
        let item = {...o};
        if (o.type === 'factColumn'){
            item = QueryUtils.clauseFromFactColumn(operators, o);
        }
        else if (o.type === 'clause'){
            item = QueryUtils.clauseFromOperator(o);
        }

        return item;
    }
    static numberItems(o, parentSerialNumber, count){
        // traverse down a tree assigning serial numbers and parent serial numbers
        count += 1;
        o.serialNumber = count;
        o.parentSerialNumber = parentSerialNumber;
        for (let i = 0; i < (o.items ? o.items.length : -1); i++) { 
            count = this.numberItems(o.items[i], o.serialNumber, count);
            o.items[i].order = i;
        }
        return count;
    }
    static removeTreeItem = (branch, serialNumber) => {
        //console.error('removing: ', serialNumber);
        if (!branch.items || branch.items.length === 0){return branch;}

        branch.items = branch.items.filter(x=> x.serialNumber !== serialNumber);
        for (let i = 0; i < branch.items.length; i++) { 
            branch.items[i] = QueryUtils.removeTreeItem(branch.items[i], serialNumber);
        }
        return branch;
    }

    static insertTreeItem = (branch, item, parentSerialNumber, position) => {
        //('inserting item: ', item.serialNumber, ' into: ', parentSerialNumber, position);
        if (!branch.items) {branch.items = [];}

        if (branch.serialNumber === parentSerialNumber){
            var ndx = position + 1; // add after position
            branch.items = [
                ...branch.items.slice(0, ndx),
                item,
                ...branch.items.slice(ndx)
            ];
            //console.error('the inserted items: ', branch.items);
        }
        else {
            for (let i = 0; i < branch.items.length; i++) { 
               // console.error('entering child items', i);
                branch.items[i] = QueryUtils.insertTreeItem(branch.items[i], item, parentSerialNumber, position);
            }            
        }
        return branch;
    }

    static handleOnDragOver = (ev) => {
        ev.preventDefault();
        $(ev.target).addClass('over');
    }

    static handleOnDragLeave = (ev) => {
        ev.preventDefault();
        $(ev.target).removeClass('over');
    }

    static handleOnDragStart = (ev, item) => {
        ev.dataTransfer.setData("text", JSON.stringify(item));
    }

    static parseDragObject = (ev) => {
        try {
            ev.stopPropagation();
            return JSON.parse(ev.dataTransfer.getData("text"));
        }
        catch(err) {
            console.error(err);
            return {};
        }
    }
}

export default QueryUtils;
