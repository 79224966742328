import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import radium from 'radium';
import { Grid, Button } from '@arius';
import { WORKSPACE_MANAGEREVIEWLEVELS, MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import ReviewLevelEditModal from './modal';
import { showModal } from '@app/actions/modal.actions';
import { notifyError } from '@app/utilities/notifier';
import {fetchReviewLevels, receivedReviewLevel, deleteReviewLevel } from '@app/actions/analysis/workspace.actions';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import NoResults from '@app/shared/presentational/noResults';
import PurpleButton from '@app/shared/presentational/purpleButton';

// const 
//   listType = 'CollectionLibrary';

class ReviewLevelSummary extends Component {
    static propTypes = {
        reviewLevels: PropTypes.arrayOf(
            PropTypes.shape({
            levelId: PropTypes.number,
            level: PropTypes.number,
            description: PropTypes.string,
            })
        ),
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        currentWorkspace: PropTypes.object,
        workspaceId: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            sortedBy: 'listName',
            ascending: true,
            reviewLevel: [],
            databaseId:null,
            LevelCount:0,
            showEditModal: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.promptTodelete = this.promptTodelete.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refresh();
        }
    }

    refresh(){
        const { userKey, dispatch, currentWorkspace, verifyPermission } = this.props;
        const { canManage } = this.state;

        if(currentWorkspace && currentWorkspace.id !== this.state.workspaceId){
            let workspaceId = currentWorkspace.id;
            dispatch(fetchReviewLevels(userKey, workspaceId));
            this.setState({workspaceId});
        }

        if(currentWorkspace){
            let canManageNOW = verifyPermission(WORKSPACE_MANAGEREVIEWLEVELS) ||
                currentWorkspace.permissions.indexOf(WORKSPACE_MANAGEREVIEWLEVELS) > -1;
            
            if (canManage !== canManageNOW){
                this.setState({canManage: canManageNOW});
            }
        }
    }
    
    add(){
        const { canManage } = this.state;
        if (!canManage){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        var item = {reviewLevelId: 0, databaseId: this.state.workspaceId, description: 'new level', memberIds: []};
        this.props.receivedReviewLevelHandler(item);  // doesn't call the api, simply puts the record in state
        this.setState({showEditModal: true, selected: item});
    }

    edit(item){
        this.props.receivedReviewLevelHandler(null);  // will force modal to reload record
        this.setState({showEditModal: true, reviewLevelId: item.reviewLevelId});
    }

    promptTodelete(item){
        const { dispatch } = this.props;
        const { canManage } = this.state;
        if (!canManage){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({nameToDelete: 'Level ' + item.level + ' - ' + item.description, idToDelete: item.reviewLevelId});

        let deleteMessageItems = [
            'Are you sure you want to delete the following level?',
            (<li key={item.reviewLevelId} style={{ fontWeight: 800 }}>{'Level ' + item.level + ' - ' + item.description}</li>)
        ];

        const yesClickHandler = this.delete;
        const noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    delete(item){
        const { userKey } = this.props;
        const { workspaceId, idToDelete } = this.state;
        this.props.deleteHandler(userKey, workspaceId, idToDelete);
        this.setState({reviewLevelId: null});
    }

    handleClose(){
        this.setState({showEditModal: false});
        this.props.receivedReviewLevelHandler(null);
    }

    getActionItems(row) {
        return  <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button 
                iconName="edit" 
                onClick={() => this.edit(row)}
                toolTip='Edit'/>
            {row.canDelete ? <Button 
                iconName="delete" 
                onClick={() => this.promptTodelete(row)}
                toolTip='Delete'/> : <div style={{width: 30, height: 30}}></div>}
        </div>;
    }

    getGrid(data) {
        let columns = [
            { field: 'level', headerText: 'Level', width: 80},
            { field: 'description', headerText: 'Description'},
            { headerText: 'Actions', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
        />
    }

    render() {
        const { LevelCount, workspaceId, canManage } = this.state,
            { reviewLevels } = this.props;

        const  addLevelButton = ( 
            <PurpleButton 
                id="downloadReport" 
                clickHandler={() =>  { 
                        if (!workspaceId){
                            notifyError('Please select a database');
                        }
                        else if (!canManage){
                            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
                        }
                        else if (LevelCount > 4){
                            notifyError('You cannot add more than 5 review levels');
                        }
                        else {
                            this.add();
                        }
                    }
                } 
                message="Add Review Levels" 
                type="add"
                waiting={false}>
            </PurpleButton>
            );
        const titleRow = (
            <span
                style={{
                    borderBottom: '2px solid #BDBDBD',
                    margin: 'initial',
                    height: '75px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <div style={{ margin: 'initial', paddingLeft: 15, display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <h2>
                            Review levels
                        </h2>
                    </div>
                </div>
                {addLevelButton}
            </span>
            );

            let renderTable;
            const tableHeight = { maxHeight: 'calc(100vh - 181px)' };
            if (reviewLevels && reviewLevels.length) {
                renderTable = this.getGrid(reviewLevels);
            } 
            else if (!workspaceId) {
                renderTable = (
                    <div
                    style={{ width: '85vw', margin: '5vh auto' }}>
                    <PlaceHolderMessage message={'Please select a database'} />
                    </div>
                );
                }
            else {
                renderTable = <div style={{ width: '85vw', margin: '5vh auto' }}><NoResults /></div>;
            }

        return (
            <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                <div style={{ width: '85vw', maxHeight: 'calc(100vh - 106px)' }}>
                    {titleRow}
                    <div className="custom-table-overflow" style={tableHeight}>
                        {renderTable}
                    </div>
                    <ReviewLevelEditModal 
                        show={this.state.showEditModal} 
                        reviewLevelId={this.state.reviewLevelId} 
                        saveCallback={null} 
                        closeCallback={this.handleClose}
                        canManage={canManage}
                        workspaceId={workspaceId}  />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reviewLevels:state.analysis.workspaces.reviewLevels,
    isFetchingReviewLevels:state.analysis.workspaces.isFetchingReviewList,
    
    userKey: state.user.userKey,
  });
  
    const mapDispatchToProps = dispatch => ({

        receivedReviewLevelHandler:(reviewLevel)=>{
            dispatch(receivedReviewLevel(reviewLevel));
        },

        deleteHandler:(userKey, databaseId, reviewLevelId)=>{
            dispatch(deleteReviewLevel(userKey, databaseId, reviewLevelId));
        },

        dispatch,
    });

export default radium(connect(mapStateToProps, mapDispatchToProps)(ReviewLevelSummary));
