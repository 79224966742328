import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import * as reportServiceApi from '@app/api/powerbi.serviceApi';
import { Report } from '../presentational/powerbi/report';
import SideBar from '../presentational/powerbi/sidebar';
import { createPermissionChecker } from '@app/utilities/permissions';

class PowerBITab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            isFetching: true
        };
    }

    componentDidMount() {
        reportServiceApi.retrievePBIReports(this.props.userKey).then(
            (data) => {
                this.setState({...data, isFetching: false});
            }
        )
    }

    getPermissionChecker() {
        const { userPermissions, } = this.props;
        return createPermissionChecker(userPermissions);
    }

    onEmbedded(embed) {
        //console.error(`Report embedded: `, embed, this);
    }

    render() {
        const { reports, isFetching } = this.state;
        const { dispatch } = this.props;
        var list = reports ? reports : [];
        const verifyPermission = this.getPermissionChecker()
        return (
        <div style={{display: 'flex'}}>
            <SideBar reports={reports} isFetching={isFetching} verifyPermission={verifyPermission} dispatch={dispatch}/>
            <Switch>
            {
                list.map((r) => 
                <Route key={`rpt${r.id}`} path={`/arius/powerbi/${r.id}`}
                    render={props => (
                        <Report 
                            id={r.id}
                            embedUrl={`${r.embedUrl}/ReportSection5a0eec01c81be59dba37/`}
                            filterPaneEnabled={true}
                            navContentPaneEnabled={true}
                            onEmbedded={this.onEmbedded}
                        />
                    )}
                />)
            }
            </Switch>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    userPermissions: state.user.userPermissions,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerBITab);
