import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';

const style = {
  checkbox: {
    margin: '8.5px 2.5px 2.5px 2.5px',
  },
};

class ProjectStatusView extends Component {
  static propTypes ={
    updateParent: PropTypes.func,
    state: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      checked: ['All'],
    };
    this.checkBoxClick = this.checkBoxClick.bind(this);
    this.updateTheParent = this.updateTheParent.bind(this);
  }

  componentWillMount() {
    const { state } = this.props;
    this.setState(state);
  }

  componentWillUnmount() {
    if (!this.state.checked.length) {
      const { updateParent } = this.props;
      updateParent({ ...this.state, ...{ checked: ['All'] } });
    }
  }

  checkBoxClick(boxName) {
    const checked = this.state.checked.slice(),
      index = checked.indexOf(boxName);

    if (boxName === 'All') {
      if (index === -1) {
        this.setState({ checked: ['All'] }, () => this.updateTheParent());
      } else {
        this.setState({ checked: [] }, () => this.updateTheParent());
      }
      return;
    } else {
      if (checked.indexOf('All') !== -1) {
        checked.splice(checked.indexOf('All'), 1);
      }
      if (index === -1) {
        checked.push(boxName);
      } else {
        checked.splice(index, 1);
      }
    }
    this.setState({ checked }, () => this.updateTheParent());
  }

  updateTheParent() {
    const { updateParent } = this.props;
    updateParent(this.state);
  }

  render() {
    const { checked } = this.state,
      checkVisiStyle = {};
    
    // if (checked.indexOf('All') !== -1) {
    //   checkVisiStyle.visibility = 'hidden';
    //   checkVisiStyle.pointerEvents = 'none';
    // }
      return (
    <div className="container form" style={{width:'100%'}}>
    <Form.Group>
      <div className="row">
      <div className="col-sm-3">
          <Form.Check type="checkbox"
            id="ps01"
            checked={checked.indexOf('All') !== -1}
            style={style.checkbox}
            onChange={() => this.checkBoxClick('All')}
            label="All" />
        </div>
        <div className="col-sm-3">
          <Form.Check type="checkbox"
            id="ps1"
            checked={checked.indexOf('Ad Hoc') !== -1}
            style={style.checkbox}
            onChange={() => this.checkBoxClick('Ad Hoc')}
            label="Ad Hoc" />
          <Form.Check type="checkbox"
            id="ps2"
            checked={checked.indexOf('Not Reviewed') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Not Reviewed')}
            label="Not Reviewed" />
          <Form.Check type="checkbox"
            id="ps3"
            checked={checked.indexOf('Not Started') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Not Started')}
            label="Not Started" />
          <Form.Check type="checkbox"
            id="ps4"
            checked={checked.indexOf('In Progress') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('In Progress')}
            label="In Progress" />
          <Form.Check type="checkbox"
            id="ps5"
            checked={checked.indexOf('Complete') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Complete')}
            label="Complete" />
        </div>
        <div className="col-sm-3">
          <Form.Check type="checkbox"
             id="ps6"
             checked={checked.indexOf('Level 1 Review') !== -1}
             style={{ ...style.checkbox, ...checkVisiStyle }}
             onChange={() => this.checkBoxClick('Level 1 Review')}
             label="Level 1 Review" />
          <Form.Check type="checkbox"
            id="ps7"
            checked={checked.indexOf('Level 2 Review') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Level 2 Review')}
            label="Level 2 Review" />
          <Form.Check type="checkbox"
            id="ps8"
            checked={checked.indexOf('Level 3 Review') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Level 3 Review')}
            label="Level 3 Review" />
          <Form.Check type="checkbox"
            id="ps9"
            checked={checked.indexOf('Level 4 Review') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Level 4 Review')}
            label="Level 4 Review" />
          <Form.Check type="checkbox"
            id="ps10"
            checked={checked.indexOf('Level 5 Review') !== -1}
            style={{ ...style.checkbox, ...checkVisiStyle }}
            onChange={() => this.checkBoxClick('Level 5 Review')}
            label="Level 5 Review" />
        </div>
      </div> 
      </Form.Group>
    </div> 
    );
  }
}

export default radium(ProjectStatusView);
