import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Resize, Inject, DetailRow, Edit, Toolbar, ColumnChooser } from '@syncfusion/ej2-react-grids';
import { isArray } from 'util';
import $ from 'jquery';


class AriusGrid extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.object),
    };
    static defaultProps = {    };
    constructor(props) {
        super(props);
        this.state = {dm: null};
        this.expand = this.expand.bind(this);

        // NOTE:  for whatever reason, complex grid properties need to be set at the component level
        //          before they can be used in the grid...  (per syncfusion guidance)
        this.defaults = {
            sortSettings: {allowUnsort: false},
            pageSettings: { pageSize: 10, pageSizes: [10, 50, 100]},
            selectionSettings: { checkboxOnly: true },
            checkBoxChange: ((e) => {
                let selected = this.grid.getSelectedRecords();
                this.props.checkBoxHandler(selected);
                return null;
            })
        };
        this.resizeMonitor = this.resizeMonitor.bind(this);
        this.adjustWidthsAfterWindowResize = this.adjustWidthsAfterWindowResize.bind(this);
        this.waitingForResizeEnd = false;

        // again, for whatever reason, this has to live in the ctor.  otherwise strange things happen...
        this.sortSettings = null;
        this.columnVisiblity = null;
        const { persistenceKey } = this.props;
        if (persistenceKey){
            let persistenceData = JSON.parse(localStorage.getItem(persistenceKey));
            if (persistenceData && persistenceData.sortSettings){
                this.sortSettings = persistenceData.sortSettings;
            }
            if (persistenceData && persistenceData.columns){
                this.columnVisiblity = persistenceData.columns.map(x=> x.visible);
            }
        }
    }

    componentDidMount() {
        if (this.monitorResize){
            window.addEventListener('resize', this.resizeMonitor);
        }
    }

    componentWillUnmount() {
        if (this.monitorResize){
            window.removeEventListener('resize', this.resizeMonitor);
        }
    }

    resizeMonitor() {
        if(!this.waitingForResizeEnd){
            setTimeout(()=> this.adjustWidthsAfterWindowResize(), 1000);
            this.waitingForResizeEnd = true;
        }
    }

    adjustWidthsAfterWindowResize(){
        const { detailTemplate, columns, } = this.props;
        let needsModifications = false;

        this.waitingForResizeEnd = false;

        let el = this.grid.getHeaderTable();
        let children = $(el).find(".e-columnheader").children();
        let widths = columns.map((z, i)=> {
            let ndx = detailTemplate ? i+1 : i;
            return $(children[ndx]).outerWidth();
        });

        let newColumns = columns.map((c,i)=> {
            if (c.minColWidth && widths[i] < c.minColWidth){
                c.width = c.minColWidth;
                needsModifications = true;
            }
            if (c.minColWidth && widths[i] > c.minColWidth){
                c.width = null;
                needsModifications = true;
            }
            return this.getColumnConfig(c);
        })

        if (needsModifications){
            this.grid.columns = newColumns;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.grid && this.props.checkBoxHandler){
            // Clear out the checked rows if grid data is changed (based on set of primary keys)
            let pk = this.primaryKey;
            let prevIds = JSON.stringify(prevProps.data.map((r) => r[pk]));
            let newIds = JSON.stringify(this.props.data.map((r) => r[pk]));
            if (prevIds !== newIds) {
                this.grid.clearSelection();
                this.props.checkBoxHandler([]);
            }
        }
    }

    get allowPaging() {
        return this.props.allowPaging === true ? true : false;  // opt-in
    }

    get hasSelectableRows() {
        return this.columns ? this.props.columns[0].type === 'checkbox' : false;
    }

    get hasTemplate() {
        const { columns } = this.props;
        if (columns && columns.filter(x=> x.template).length) {
            return true;
        }
        if (columns && columns.filter(x=> x.headerTemplate).length) {
            return true;
        }
        return false;
    }

    get config() {
        const { data, height, detailTemplate, allowResizing, allowPaging, pageSettings, editOptions, 
            gridKey, queryCellInfo, toolbar, gridRef, showColumnChooser, persistenceKey, format } = this.props;
        let adjustedHeight = height ? height : 'calc(100vh - 240px)';
        let allowSorting = this.props.allowSorting === false ? false : true;
        let checkBoxHandler = this.props.checkBoxHandler ? this.props.checkBoxHandler : null;
        let config = {
            sortSettings: this.defaults.sortSettings,
            ref: (g) => {
                if (gridRef) {
                    gridRef(g);  // set the ref on the parent
                }
                this.grid = g
            },
            dataBound: this.dataBound.bind(this),
            dataSource: data,
            allowTextWrap: false, 
            textWrapSettings: {wrapMode: 'Content'},
            allowSorting, 
            allowPaging,
            queryCellInfo,
            toolbar,
            format
        };

        if (editOptions) { 
            config.editSettings = editOptions;
            config.actionComplete = (args) => {
                if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
                    this.grid.isEditing = true;
                    let dialog = args.dialog;
                    if (dialog){
                        dialog.width = '80%';
                        dialog.height = '250px';
                        dialog.header = args.requestType === 'beginEdit' ? this.props.getEditMessage(args.rowData) : 'New Record';
                    }
                     if (this.props.beginEdit){
                        this.props.beginEdit(args.rowData);
                    }
                }
                if ((args.requestType === 'save')) {
                    this.grid.isEditing = false;
                    if (this.props.onSave){
                        var data = this.grid.contentModule.rows.map(r => r.data);
                        this.props.onSave(data);
                    }
                }
            };
            config.load = () => {
                let instance = this.grid;
                // This is for making the grid go into edit mode with a single click...
                instance.element.addEventListener('mousedown', (e) => {
                    if ((e.target).classList.contains("e-rowcell")) {
                        if (instance.isEdit){
                            instance.endEdit();
                        } else {
                            let index = parseInt((e.target).getAttribute("Index"));
                            instance.selectRow(index);
                            instance.startEdit();
                        }
                    };
                });
            };
        }
        if (adjustedHeight !== 'auto') { config.height = adjustedHeight }

        if (checkBoxHandler && this.hasSelectableRows && this.primaryKey) {
            config.selectionSettings = this.defaults.selectionSettings;
            config.checkBoxChange = this.defaults.checkBoxChange.bind(this);
        }
        if (allowResizing!==false) { config.allowResizing = true;}
        if (detailTemplate) { config.detailTemplate = detailTemplate;}
        if ( this.allowPaging) {     
            // assume the parent component has created a secure datamanager and set it as the dataSource
            // (and is handling its updating when appropriate.  e.g. as api url params change)
            config.pageSettings = pageSettings ? pageSettings : this.defaults.pageSettings;
        }

        config.key = gridKey ? gridKey : 'NOGRIDKEYDEFINED';

        if (showColumnChooser === true){
            config.toolbar=['ColumnChooser'];
            config.showColumnChooser=true;
        }

        if (persistenceKey){
            config.actionComplete = (e) => {
                if (e.requestType === 'sorting' || e.requestType === 'columnstate'){
                    localStorage.setItem(persistenceKey, this.grid.getPersistData());
                }
            }
            config.sortSettings = this.sortSettings;
        }

        return config;
    }

    get services() {
        const { detailTemplate, allowResizing, allowPaging, editOptions, toolbar, showColumnChooser} = this.props;
        let allowSorting = this.props.allowSorting === false ? false : true;

        let services = [];
        if (allowSorting) { services.push(Sort)}
        if (allowPaging===true) { services.push(Page)}
        if (allowResizing!==false) { services.push(Resize) }
        if (detailTemplate) { services.push(DetailRow) }
        if (editOptions) { services.push(Edit)}
        if (toolbar) {services.push(Toolbar)}

        if (showColumnChooser === true){
            services.push(Toolbar);
            services.push(ColumnChooser);
        }

        return services;
    }

    get columns() {
        let { columns } = this.props;

        // handle persistence of column info - for now only which columns have been hidden/shown with column choose
        if (this.columnVisiblity){
            columns = columns.map((x,i) => {return {...x, visible: this.columnVisiblity[i]}});
        }
        // END COLUMN PERSISTENCE

        return Array.isArray(columns) ? columns.map((c) => this.getColumn(c)) : [];
    }

    get primaryKey() {
        const { columns } = this.props;
        let primaryColumn = isArray(columns) ? columns.find(x => x.isPrimaryKey) : null;
        return primaryColumn ? primaryColumn.field : '';
    }

    get monitorResize(){
        return this.props.columns.findIndex(x=> x.minColWidth > 0) > -1;
    }

    getColumn(o) {
        o = this.getColumnConfig(o);
        return <ColumnDirective key={`column ${JSON.stringify(o)}`} {...o}></ColumnDirective>;
    }
    
    getColumnConfig(o) {
        o.clipMode = o.enableTooltips === false ? '' : 'EllipsisWithTooltip';

        if (o.type === "datetime" || 
            o.headerText === "Last Modified" || 
            o.headerText === "Last Submitted" || 
            o.headerText === "Uploaded" ||
            o.headerText === "Created" ||
            o.headerText === "Last Updated"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).local().format('L LT') : ''}</span>
            )};
            o.width = o.width ? o.width : 125;
        }

        if (o.type === "date"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).local().format('L') : ''}</span>
            )};
            o.width = o.width ? o.width : 120;
        }

        if (o.type === "dateUTC"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).format('L') : ''}</span>
            )};
            o.width = o.width ? o.width : 100;
        }

        if (o.headerText){
            o.headerTextAlign = o.headerTextAlign ? o.headerTextAlign : 'left';
        }

        if (o.headerText === "Name"){
            o.width = o.width ? o.width : 300;
            o.clipMode = 'EllipsisWithTooltip';
        }

        if (o.headerText === "Description"){
            o.width = o.width ? o.width : 300;
            o.clipMode = 'EllipsisWithTooltip';
        }

        if (o.headerText === "Last Modified By" || 
            o.headerText === "Last Submitted By" || 
            o.headerText === "Uploaded By" ||
            o.headerText === "Created By" ||
            o.headerText === "Last Updated By"){
            o.width = o.width ? o.width : 150;
            o.clipMode = 'EllipsisWithTooltip';
        }

        if (o.headerText === "Actions"){
            o.width = o.width ? o.width : 100;
            o.textAlign = 'center';
            o.headerTextAlign = 'center';
            o.clipMode = o.enableTooltips === true ? 'EllipsisWithTooltip' : ''; // default to false
        }

        if (o.headerText === "Record Count"){
            o.width = o.width ? o.width : 100;
            o.textAlign = 'center';
            o.headerTextAlign = 'center';
        }

        if (o.headerText === "Job"){
            o.minWidth = 100;
        }

        return o;
    }

    getInjectedServices(o) {

    }

    expand(e) {
        let { expandedIndex } = this.props;
        if (this.Grid && expandedIndex) { 
            //console.error('fire EXPANDING......')
            this.Grid.detailRowModule.expand(expandedIndex);
        }
    }

    dataBound(){ 
        // Starting point for the code below is Grid.prototype.hideScroll
        let g = this.grid;
        var headerContent = g.headerModule.getPanel();
        var contentTable = g.contentModule.getPanel().querySelector('.e-content');
        if ((g.currentViewData.length * g.getRowHeight()) < g.element.scrollHeight){//this.height) {
            headerContent.style.paddingRight = '';
            contentTable.style.overflowY = 'auto';
        }
        else {
            headerContent.style.paddingRight = '16px';
            contentTable.style.overflowY = 'scroll';
        }
    } 

    render() {
        if (this.hasTemplate || this.props.detailTemplate){
           // Kludge to prevent improper rendering of the detail row icons or header template
            setTimeout(()=> {
                if (this.grid && !this.props.allowPaging && this.props.initialRefresh !== false) {
                    this.grid.refresh();
                }
                return null;
            }, 100)
        }

        return (
            <GridComponent {...this.config}>
                <ColumnsDirective>{this.columns}</ColumnsDirective>
                <Inject services={this.services} />
            </GridComponent>
        )
    }
}

export default AriusGrid;