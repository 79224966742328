import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import {
    fetchReviewLevel,
    receivedReviewLevel,
    createReviewLevel,
    updateReviewLevel
  } from '@app/actions/analysis/workspace.actions';
import {
    fetchSubscriptionUsers,
  } from '@app/actions/portalManagement/subscriptionUser.actions';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { notifyError } from '@app/utilities/notifier';
import * as GlobalStyles from '@app/utilities/globalStyles';
import UserPicker from './userPicker';

class ReviewLevelEditModal extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            available: null,
            assigned: null,
            reviewLevel: null,
        };
   
        this.save= this.save.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleMembersChange = this.handleMembersChange.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    
    componentDidUpdate(p) {
        const { userKey, users, selected, isFetchingReviewLevel, isFetchingUsers, reviewLevelId} = this.props;
        
        if ((!isFetchingUsers && !users) || users.length === 0){
            this.props.fetchSubscriptionUsersHandler(this.props.userKey);
        }

        if (!selected && !isFetchingReviewLevel && reviewLevelId){
            this.setState({ assigned:null, available:null, reviewLevel: null});
            this.props.fetchReviewLevelHandler(userKey, reviewLevelId);
        }

        if (selected && users && !this.state.assigned && !this.state.available){
            let reviewLevel = JSON.parse(JSON.stringify(selected));
            let assigned = [];
            let available = [];
            if (users.length === 0) {return;}
            for(let i = 0; i < users.length; i++){
                let id = users[i].userId;
                if (reviewLevel.memberIds.indexOf(id) < 0){
                    available.push(users[i]);
                } else {
                    assigned.push(users[i]);   
                }
            }
            this.setState({ assigned, available, reviewLevel, description: reviewLevel.description});
        }
    }

    handleDescriptionChange(e) {
        const { reviewLevel } = this.state;
        const description = e.target.value;
        reviewLevel.description = description;
        this.setState({ reviewLevel, description, isUpdated: true });
    }

    handleMembersChange(assigned, available) {
        //console.log('modal handleMembersChange');
        const { reviewLevel } = this.state;

        if (reviewLevel) {
            reviewLevel.memberIds = assigned.map(u => u.userId);
            this.setState({reviewLevel, assigned, available});
        }

        //const { selected } = this.props;
        // console.log(selected);
        // console.log(assigned);
        // if (selected){
        //     //selected.memberIds = assigned.map(u => u.userId);
        //     this.props.receivedReviewLevelHandler(selected);    
        // }
    }

    save() {
        const { canManage } = this.props;
        const { reviewLevel } = this.state;

        if (!canManage){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        const { userKey, workspaceId } = this.props;
        let record = JSON.parse(JSON.stringify(reviewLevel));

        if (reviewLevel.reviewLevelId > 0) {
            this.props.updateHandler(userKey, workspaceId, record);
        } else {
            this.props.createHandler(userKey, workspaceId, record);
        }
        // TODO wait indicator
        this.handleClose();
    }

    handleOpen(){
        this.setState({available: null, assigned: null, description: null});
    }

    handleClose(){
        this.props.closeCallback();
    }
  
    render() {

        const {show, canManage}=this.props;
        const description = this.state.description ? this.state.description : '';
        return (
            
            <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} 
                   dialogClassName="edit-modal">
            <Modal.Header closeButton>
            <Modal.Title>Edit Review Level</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row" disabled={!canManage}>
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group">
                            <label htmlFor="roleDescription">Description</label>
                            <textarea
                                id="roleDescription"
                                value={description}
                                onChange={this.handleDescriptionChange}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12" style={{border:'blue solid 0px'}}>
                        <UserPicker
                            style={GlobalStyles.card}
                            available={this.state.available}
                            assigned={this.state.assigned}
                            onChanges={this.handleMembersChange}
                        />
                    </div>
                </fieldset> 
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button key="confirmButton"
                    className="btn btn-info dialog-button"
                    onClick={this.save}>Save</button>
                <button key="nobutton"
                    className="btn btn-info dialog-button"
                    onClick={this.handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
        );
        }
    }

    const mapStateToProps = state => ({
        userKey: state.user.userKey,
        users: state.portalManagement.users.subscriptionUsers,
        selected: state.analysis.workspaces.selectedReviewLevel,
        isFetchingReviewLevel: state.analysis.workspaces.isFetchingReviewLevel,
        isFetchingUsers: state.portalManagement.users.isFetching
    });
    
    const mapDispatchToProps = dispatch => ({

        fetchSubscriptionUsersHandler:(userKey)=>{
            dispatch(fetchSubscriptionUsers(userKey));
        },

        fetchReviewLevelHandler:(userKey,reviewLevelId)=>{
            dispatch(fetchReviewLevel(userKey,reviewLevelId));
        },

        receivedReviewLevelHandler:(reviewLevel)=>{
            dispatch(receivedReviewLevel(reviewLevel));
        },

        createHandler:(userKey, workspaceId, reviewLevel)=>{
            dispatch(createReviewLevel(userKey, workspaceId, reviewLevel));
        },

        updateHandler:(userKey, workspaceId, reviewLevel)=>{
            dispatch(updateReviewLevel(userKey, workspaceId, reviewLevel));
        },

        dispatch,
    });
  
export default  radium(connect(mapStateToProps, mapDispatchToProps)(ReviewLevelEditModal));

