import React, { Component } from 'react';
import * as api from '@app/serviceApi/todDatabases.serviceApi';
import { Grid } from '@arius';

class MappingsListDetailRow extends Component {
    constructor(props) {
        super(props);
        this.state = {data: {}};
        this.getDelimiterTemplate = this.getDelimiterTemplate.bind(this);
    }

    componentDidMount() {
        const { row, userKey, databaseId } = this.props;
        this.fetchData(userKey, databaseId, row.mapperId);
    }

    fetchData(userKey, databaseId, mapperId){
        api.retrieveDatabaseMapping(userKey, databaseId, mapperId)
            .then(data => {
                this.setState({data})
            });
    }

    getDelimiterTemplate(row) {
        const { dateFormatDelimiters } = this.props;
        let found;
        if (row.dateFormat) {
            found = dateFormatDelimiters.find(dfd => dfd.value === row.dateFormatDelimiter);
        }
        return <span>{found ? found.label : ''}</span>;
    }

    getGrid(data) {
        let columns = [
            {headerText: 'CSV Column', field: 'externalColumnName' },
            {headerText: 'Column Type', field: 'factColumnDisplayName' },
            {headerText: 'Date Format', field: 'dateFormat'},
            {headerText: 'Date Format Delimiter', field: 'dateFormatDelimiter', template: this.getDelimiterTemplate },
        ];
        return <Grid columns={columns} data={data} allowSorting={false} height='auto'/>
    }

    render() {
        const { data } = this.state;
        let rowData = data.columns ? data.columns : [];
        return <div>
            <div style={{marginBottom: '5px'}}><b>Description:</b>&nbsp;&nbsp;{data.mapperDescription}</div>
            {this.getGrid(rowData)}
        </div>
    }
}

export default MappingsListDetailRow;