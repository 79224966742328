import {
    RECEIVED_UPLOADS,
    FETCHING_UPLOADS,
    SAVING_UPLOADS, 
    RECEIVED_COLUMNS_FOR_FILE
} from './analysis.actionTypes';
import * as uploadsServiceApi from '../../serviceApi/uploads.ae.serviceApi';

function fetchingFromServer() {
    return {
        type: FETCHING_UPLOADS,
    };
}

function savingToServer() {
    return {
        type: SAVING_UPLOADS,
    };
}

function receivedUploads(json) {
    return {
        type: RECEIVED_UPLOADS,
        items: json,
        receivedAt: Date.now(),
    };
}

function receivedFileColumns(json) {
    return {
      type: RECEIVED_COLUMNS_FOR_FILE,
      columns: json,
      receivedAt: Date.now(),
    };
  }

function fetchUploads(userKey) {
    return (dispatch) => {
        dispatch(fetchingFromServer());
        return uploadsServiceApi.retrieveUploads(userKey)
                .then(json => dispatch(receivedUploads(json)));
    };
}

function uploadFile(userKey, file) {
    return (dispatch) => {
        dispatch(savingToServer());
        return uploadsServiceApi.uploadFile(userKey, file)
                .then(() => dispatch(fetchUploads(userKey)));
    };
}

function deleteUploadedFile(userKey, uploadId) {
    return (dispatch) => {
        dispatch(savingToServer());
        return uploadsServiceApi.deleteUpload(userKey, uploadId)
                .then(() => dispatch(fetchUploads(userKey)));
    };
}

function fetchColumnsForFile(userKey, fileId) {
    return (dispatch) => {
        if (fileId) {
            dispatch(fetchingFromServer());
            return uploadsServiceApi.retrieveColumnsForFile(userKey, fileId)
                    .then((json) => dispatch(receivedFileColumns(json)));
        } else {
            return dispatch(receivedFileColumns([]));
        }
    };
}

  
export {
    fetchUploads,
    uploadFile,
    deleteUploadedFile,
    fetchColumnsForFile
};
