import { callApi, getBaseServiceUrl } from '../utilities/callApi';
import { getToken } from '../utilities/adalAdapter';

export function retrieveTotals(userKey, databaseId) {
  return callApi(`/factDatabases/${databaseId}/totals`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveTotalsTable({
  userKey,
  databaseId,
  valuationDate,
  columns,
  filters,
  measures,
  rows,
}) {
  return callApi(
    `/factDatabases/${databaseId}/totals/${valuationDate}`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    { columns, filters, measures, rows }
  );
}

export function exportToCsv({
  userKey,
  databaseId,
  valuationDate,
  columns,
  filters,
  measures,
  rows,
}) {
  return callApi(
    `/factDatabases/${databaseId}/totals/${valuationDate}/tocsv`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    { columns, filters, measures, rows },
    true
  );
}

export function exportToExcel({
  userKey,
  databaseId,
  valuationDate,
  columns,
  filters,
  measures,
  rows,
}) {
  return getBaseServiceUrl().then(baseUrl =>
    getToken().then(
      token =>
        new Promise((resolve, reject) => {
          const http = new XMLHttpRequest();
          http.open(
            'POST',
            `${baseUrl}/factDatabases/${databaseId}/totals/${valuationDate}/toexcel`,
            true
          );
          http.setRequestHeader(
            'Content-Type',
            'application/json;charset=UTF-8'
          );
          http.setRequestHeader('Authorization', `Bearer ${token}`);
          http.setRequestHeader('X-AE-USERKEY', userKey);
          http.responseType = 'arraybuffer';
          http.onreadystatechange = () => {
            if (http.readyState === 4) {
              if (http.status === 200) {
                const blob = new Blob([http.response], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
                });
                resolve(blob);
              } else {
                reject(http.response);
              }
            }
          };
          http.send(JSON.stringify({ columns, filters, measures, rows }));
        })
    )
  );
}
