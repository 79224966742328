import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Colors from '../../../utilities/colors';
import * as GlobalStyles from '@app/utilities/globalStyles';
import { notifyError } from '../../../utilities/notifier';
import {
    OverlayTrigger,
    Popover,
    Form,
} from 'react-bootstrap';
import { validateName } from '../../../utilities/validators';
import AnalysisToolbar from './analysisToolbar';
import DraggableCommand from './draggableCommand';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { COMMAND_IDS } from '../../../utilities/constants';
import bowser from 'bowser';
import { retrieveExportsByListType } from '../../../actions/analysis/project.actions';
import { Button } from '@arius';

const style = {
  mainContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  activeItem: {
    backgroundColor: Colors.lightGrey,
    cursor: 'pointer',
  },
  assignedListItem: {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    cursor: 'move',
  },
  inactiveItem: {
    cursor: 'pointer',
  },
  booleanRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    borderRadius: '4px',
    backgroundColor: Colors.blue,
    color: 'white',
    margin: '10px',
    fontSize: '40px',
    cursor: 'pointer',
    textAlign: 'center',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      boxShadow: '0px 14px 28px rgba(0,0,0,0.25), 0px 10px 10px rgba(0,0,0,0.22)',
      backgroundColor: Colors.purple,
    },
  },
  paramTiles: {
    borderRadius: '4px',
    backgroundColor: 'white',
    color: Colors.purple,
    margin: '10px',
    fontSize: '15px',
    cursor: 'pointer',
    textAlign: 'center',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      boxShadow: '0px 14px 28px rgba(0,0,0,0.25), 0px 10px 10px rgba(0,0,0,0.22)',
      backgroundColor: Colors.purple,
      color: 'white',
    },
  },
  commandBox: {
    backgroundColor: 'white',
    // flex: '2 1 auto',
    // display: 'flex',
    // flexFlow: 'column nowrap',
    borderRadius: '5px',
    border: '1px solid rgb(204, 204, 204)',
    overflowY: 'auto',
    padding: '0px 5px',
    overflowX: 'auto',
    height: '100%'
  },
    commandColumn: {
        minHeight: '190px',
        height: 'calc(100vh - 600px)',
        flex: '1 1',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    commandContainer: {
        display: 'flex',
    },
    paramsColumn: {
        height: '100%',
        flex: '1 1',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
  paramsContainer: {
    display: 'flex',
    flex: '2 1'
  },
  inputContainer: {
    margin: '0px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  labelRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    listStyle: 'none',
    backgroundColor: 'white',
    padding: '20px',
  },
  // paramBox: {
  //   height: '0vh',
  // },
  saveCancelButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnStyle: {...GlobalStyles.button, marginRight: '10px'}
};
  
class CreateScripts extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        scriptCommands: PropTypes.array,
        selectedScript: PropTypes.object,
        createScriptHandler: PropTypes.func,
        updateScriptHandler: PropTypes.func,
        fetchScriptCommandsHandler: PropTypes.func,
        fetchScriptsHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        userId: PropTypes.string,
        scripts: PropTypes.array,
        params: PropTypes.object,
        editScriptHandler: PropTypes.func,
        dbChangeAllowed: PropTypes.bool,
        mappers: PropTypes.array,
        fetchMappersHandler: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            scriptName: '',
            scriptDescription: '',
            selectedItem: null,
            selectedAssignedItem: null,
            availableItems: [],
            assignedItems: [],
            paramError: null,
            availableParams: [],
            assignedParams: [],
            selectedParams: [],
            assignedItemsTrackerArray: [],
            keyableIndexIncrementor: 0,
            selectedParamDesc: '',
            script: null,
            scriptId: null,
            commands: [],
            softErrorMode: true,
            inputValues: [],
            mappers: [
                {
                value: '',
                label: 'Project default',
                },
            ],
            availableInputValues: [{
                value: 1,
                error: null,
                name: '',
            }],
            todInput: [{ value: '' }, { value: '' }],
            assignedTodInput: [{ value: '' }, { value: '' }, { value: '' }],
            assignedInputValues: [],
            monthSelectorArray: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
            ie: false,
            saveCopyInput: [{ value: ' - Copy1' }],
            assignedSaveCopyInput: [{ value: '' }],
        };
        if (props.params && props.params.scriptId && props.params.scriptId !== 'new') {
            
            this.state = {
                ...this.state,
                editingMode: true,
                workspaceId: props.params.databaseId,
            };
            const scriptId = props.params.scriptId, inputValues = [];
            this.state.scriptId = scriptId;
            if (props.scripts && props.scripts.length) {
                let KII = this.state.keyableIndexIncrementor;
                const script = props.scripts.find(s => s.batchScriptId === Number(scriptId)),
                assignedItems = script && script.commands ? script.commands.map((s) => {
                    const keyableIndex = KII++,
                    assignInput = {
                        keyableIndex,
                        inputValues: [],
                    },
                    name = s.name,
                    id = s.id;
                if (s.parameters.length && s.id !== 4 && s.id !== 5) {
                    const params = [], paramNames = [];
                    s.parameters.forEach((p) => {
                        params.push(p.value);
                        paramNames.push(p.name);
                        if (name === 'Remove Oldest Exposure Period') {
                        assignInput.inputValues.push({
                            error: null,
                            name: p.name,
                            value: p.value,
                        });
                        }
                    });
                    inputValues.push(assignInput);
                    return {
                        id,
                        keyableIndex,
                        value: name,
                        params,
                        paramNames,
                    };
                    } else {
                    return {
                        id,
                        keyableIndex,
                        value: name,
                    };
                    }
                }) : null;
                this.state = {
                    ...this.state,
                    script,
                    scriptDescription: script.scriptDescription,
                    scriptName: script.scriptName,
                    assignedItems,
                    keyableIndexIncrementor: KII
                }; 
            }
        }
        this.handleScriptNameChange = this.handleScriptNameChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addParam = this.addParam.bind(this);
        this.removeParam = this.removeParam.bind(this);
        this.moveCard = this.moveCard.bind(this);
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.backToScripts = this.backToScripts.bind(this);
        this.saveScript = this.saveScript.bind(this);
        this.getGenericLists = this.getGenericLists.bind(this);
    }

    getGenericLists(commandId){
        const { collectionSets, exhibitOptions, dataDefaults, exhibitDefaults, } = this.props;
        switch (commandId) {
            case COMMAND_IDS.LOAD_COLLECTION_SET:
                return collectionSets;
            case COMMAND_IDS.LOAD_EXHIBIT_OPTIONS:
                return exhibitOptions;
            case COMMAND_IDS.LOAD_DATA_DEFAULTS:
                return dataDefaults;
            case COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS:
                return exhibitDefaults;
           default:
              break;
        }
    }

    componentWillMount() {
        const { fetchScriptsHandler,
        fetchScriptCommandsHandler,
        currentWorkspace,
        userKey,
        scripts,
        params,
        dispatch,
        dbChangeAllowed,
        fetchMappersHandler } = this.props;
        if (scripts && currentWorkspace && !scripts.length && dbChangeAllowed) {
        fetchScriptsHandler({ userKey, databaseId: currentWorkspace.id });
        }
        if (params.databaseId && !currentWorkspace) {
        dispatch(fetchWorkspace(userKey, params.databaseId));
        }
        if (currentWorkspace) {
            fetchMappersHandler({ userKey, databaseId: currentWorkspace.id });
            this.loadGenericLists();
        }
        if (bowser && bowser.name === 'Internet Explorer') {
        this.setState({ ie: true });
        window.setTimeout(fetchScriptCommandsHandler, 500);
        } else {
        fetchScriptCommandsHandler();
        }
    }

    componentDidMount() {
        if (bowser && bowser.name === 'Internet Explorer') {
        this.setState({ ie: true });
        }
        const { scriptCommands } = this.props;
        if (scriptCommands) {
        this.setState({
            availableItems: this.alphabetizeItems(scriptCommands),
            commands: this.alphabetizeItems(scriptCommands),
        });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { scriptCommands, workspaces, fetchMappersHandler, userKey, mappers} = this.props,
        { scriptId, editingMode } = this.state,
        newState = {},
        inputValues = [];
        let KII = this.state.keyableIndexIncrementor;
        if (scriptCommands && scriptCommands !== nextProps.scriptCommands) {
            newState.availableItems = this.alphabetizeItems(nextProps.scriptCommands);
            newState.commands = this.alphabetizeItems(nextProps.scriptCommands);
        }
        if (nextProps.mappers && mappers !== nextProps.mappers) {
            const newMappersMap = nextProps.mappers.map((mapper) => { // eslint-disable-line
                return {
                    label: mapper.name,
                    value: mapper.mapperId,
                };
            });
            newMappersMap.unshift({
                value: '',
                label: 'Project default',
            });
            this.setState({ mappers: newMappersMap });
        }
        if (workspaces && nextProps.workspaces && workspaces !== nextProps.workspaces) {
            const currentWorkspace = nextProps.workspaces.find(ws => ws.workspaceId === Number(this.state.workspaceId));
            newState.currentWorkspace = currentWorkspace;
            if (currentWorkspace) {
                this.changeWorkspaceHandler(currentWorkspace.workspaceId);
            }
        }
        if (nextProps.currentWorkspace && this.props.currentWorkspace !== nextProps.currentWorkspace) {
            fetchMappersHandler({ userKey, databaseId: nextProps.currentWorkspace.id });
            this.loadGenericLists();
        }
        if (this.props.scripts && nextProps.scripts && this.props.scripts !== nextProps.scripts && editingMode) {
        
        const script = nextProps.scripts.find(s => s.batchScriptId === Number(scriptId));
        const assignedItems = script && script.commands ? script.commands.map((s) => {
                const keyableIndex = KII++,
                    assignInput = {
                    keyableIndex,
                    inputValues: [],
                    },
                    name = s.name,
                    id = s.id;
                if (s.parameters.length && s.id !== 4 && s.id !== 5) {
                    const params = [], paramNames = [];
                    s.parameters.forEach((p) => {
                    params.push(p.value);
                    paramNames.push(p.name);
                    if (name === 'Remove Oldest Exposure Period') {
                        assignInput.inputValues.push({
                        error: null,
                        name: p.name,
                        value: p.value,
                        });
                    }
                    });
                    inputValues.push(assignInput);
                    return {
                    id,
                    keyableIndex,
                    value: name,
                    params,
                    paramNames,
                    };
                } else {
                    return {
                    id,
                    keyableIndex,
                    value: name,
                    };
                }
            }) : null;
            newState.assignedInputValues = inputValues;
            newState.script = script;
            newState.scriptDescription = script.scriptDescription;
            newState.scriptName = script.scriptName;
            newState.assignedItems = assignedItems;
            newState.workspaceId = script.workspaceId; 
            newState.keyableIndexIncrementor = KII;
        }
        this.setState(newState);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const { assignedItemsTrackerArray } = this.state;
        if (assignedItemsTrackerArray !== prevState.assignedItemsTrackerArray) {
        return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        const { assignedItems } = this.state;

        if (assignedItems &&
        assignedItems.length &&
        prevState.assignedItems &&
        prevState.assignedItems.length !==
        assignedItems.length) {
        const commandContainer = document.getElementById('draggableListContainer'),
            command = document.getElementById(`assigned-commands-${assignedItems.length - 1}`),
            containerBottom = commandContainer.getBoundingClientRect().bottom,
            commandBottom = command.getBoundingClientRect().bottom;
        if (commandBottom > containerBottom) {
            command.scrollIntoView();
        }
        }
    }

    loadGenericLists(){
        const { currentWorkspace, userKey, dispatch } = this.props;
        if (!currentWorkspace) { return;}
        const databaseId = currentWorkspace.id || currentWorkspace.workspaceId;
        let getLists = (listType) => dispatch(retrieveExportsByListType({ userKey, databaseId, listType})); 
        getLists('collectionSets');
        getLists('exhibitOptions');
        getLists('dataDefaults');
        getLists('exhibitDefaults');
    }

    getValidationState(softErrorMode) {
        const { scriptName, script } = this.state, 
        { scripts } = this.props,
        comparingName = scriptName ? scriptName.toLowerCase().trim() : '';
        let error = validateName(scriptName, softErrorMode, true);
        if (!error) {
        const found = scripts.find(s => s.scriptName.toLowerCase() === comparingName);
        if (!script) {
            error = found ? 'Name must be unique' : '';
        } else {
            error = found && script && found.batchScriptId !== script.batchScriptId ? 'Name must be unique' : '';
        }
        }
        return error;
    }

    handleScriptNameChange(e) {
        this.setState({ scriptName: e.target.value });
    }

    selectItem(item, e) {
        e.stopPropagation();
        const { selectedItem, selectedParams } = this.state, newState = {};
        newState.selectedItem = selectedItem === item ? null : item;
        newState.selectedAssignedItem = null;
        newState.selectedParamDesc = '';
        let hasParams = newState.selectedItem &&
            newState.selectedItem.params &&
            newState.selectedItem.params.length;

        if (hasParams && item && (item.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD
            || item.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD)) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                params[0] = 1;
                found = { id, params,};
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (item.id === COMMAND_IDS.LOAD_DATA_FROM_TOD)) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                params[1] = '';
                params[2] = false;
                found = {
                    id,
                    params,
                };
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (
            item.id === COMMAND_IDS.LOAD_COLLECTION_SET
            || item.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
            || item.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
            || item.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
        )) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                let lists = this.getGenericLists(item.id);
                params[0] = lists && lists.length ? lists[0].exportListId.toString() : null;
                found = {id,params,};
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (item.id === COMMAND_IDS.SAVE_AS_COPY)) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => ' - Copy1');
                found = {
                id,
                params,
                };
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (item.id === COMMAND_IDS.COMPRESS_COLUMNS 
            || item.id === COMMAND_IDS.COMPRESS_ROWS)) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                params[0] = 'Year';
                params[1] = true;
                found = {
                id,
                params,
                };
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (item.id === COMMAND_IDS.APPEND_NEW_EVALUATION_PERIOD)) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                params[0] = true;
                params[1] = false;
                found = {
                id,
                params,
                };
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (hasParams && item && (item.id === COMMAND_IDS.APPLY_DATA_DEFAULTS 
            || item.id === COMMAND_IDS.APPLY_SDF_DEFAULTS
            || item.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS)
        ) {
            const id = item.id;
            let found = selectedParams.find(sp => sp.id === id);
            if (!found) {
                const params = item.params.map(() => null);
                if (item.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS) {
                    params[0] = item.params[0].options[0].value;
                }
                else {
                    params[0] = item.params[0].options[1].value;
                }
                found = {id,params,};
                selectedParams.push(found);
                newState.selectedParams = selectedParams;
            } 
        } else if (
            newState.selectedItem &&
            newState.selectedItem.params &&
            newState.selectedItem.params.length
        ) {
            newState.availableParams = newState.selectedItem.params;
            newState.assignedParams = [];
        } else {
            newState.availableParams = [];
            newState.assignedParams = [];
        }
        this.setState(newState);
    }

    selectAssignedItem(item, e) {
        if (e) {
        e.stopPropagation();
        }
        const { selectedAssignedItem, commands, assignedTodInput, assignedSaveCopyInput } = this.state, newState = {};
        newState.selectedAssignedItem = selectedAssignedItem === item ? null : item;
        newState.selectedItem = null;
        if (
        newState.selectedAssignedItem &&
        newState.selectedAssignedItem.params &&
        newState.selectedAssignedItem.params.length
        ) {
        let paramNames = [];
        const command = commands.find(c => c.id === newState.selectedAssignedItem.id) ||
            commands.find(c => c.value === newState.selectedAssignedItem.value);
        if (command) {
            paramNames = command.params.map(p => p.name);
        }
        newState.selectedAssignedItem.paramNames = paramNames;
        newState.availableParams = newState.selectedAssignedItem.params;
        newState.assignedParams = [];
        } else {
        newState.availableParams = [];
        newState.assignedParams = [];
        }
        if (newState.selectedAssignedItem
        && (newState.selectedAssignedItem.id === COMMAND_IDS.LOAD_DATA_FROM_TOD
            || newState.selectedAssignedItem.id === COMMAND_IDS.LOAD_COLLECTION_SET
            || newState.selectedAssignedItem.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
            || newState.selectedAssignedItem.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
            || newState.selectedAssignedItem.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
        )) {
        newState.selectedAssignedItem.params.forEach((par, idx) => {
            assignedTodInput[idx].value = par;
        });
        newState.assignedTodInput = assignedTodInput;
        }
        if (newState.selectedAssignedItem && newState.selectedAssignedItem.id === COMMAND_IDS.SAVE_AS_COPY) {
        newState.selectedAssignedItem.params.forEach((par, idx) => {
            assignedSaveCopyInput[idx].value = par;
        });
        newState.assignedSaveCopyInput = assignedSaveCopyInput;
        }
        this.setState(newState);
    }

    selectParamDesc(value) {
        this.setState({ selectedParamDesc: value });
    }
    
    addItem() {
        
        let { selectedItem, keyableIndexIncrementor } = this.state;
        const { selectedParams, availableInputValues, assignedInputValues } = this.state,
        assignedItems = this.state.assignedItems.slice();
        if (selectedItem) {
            const
                param = selectedParams.find(p => p.id === selectedItem.id),
                params = param ? param.params : null,
                newCommand = {
                value: selectedItem.value,
                id: selectedItem.id,
                keyableIndex: keyableIndexIncrementor,
                },
                modInputValues = {
                inputValues: availableInputValues,
                keyableIndex: keyableIndexIncrementor,
                },
                newState = {};
            assignedInputValues.push(modInputValues);
            keyableIndexIncrementor++;
            if (params) {
                newCommand.params = params;
            }
            if (selectedItem.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD || selectedItem.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD) {
                newState.availableInputValues = [{
                value: 1,
                error: null,
                name: '',
                }];
            } else if (selectedItem.id === COMMAND_IDS.LOAD_DATA_FROM_TOD 
                || selectedItem.id === COMMAND_IDS.LOAD_COLLECTION_SET
                || selectedItem.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
                || selectedItem.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
                || selectedItem.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
            ) {
                newState.todInput = [{ value: '' }, { value: '' }];
            } else if (selectedItem.id === COMMAND_IDS.SAVE_AS_COPY) {
                newState.saveCopyInput = [{ value: ' - Copy1' }];
            } 
            assignedItems.push(newCommand);
            selectedParams.splice(selectedParams.indexOf(param), 1);
            selectedItem = null;
            newState.assignedItems = assignedItems;
            newState.selectedItem = selectedItem;
            newState.keyableIndexIncrementor = keyableIndexIncrementor;
            newState.assignedInputValues = assignedInputValues;
            this.setState(newState);
            this.selectAssignedItem(newCommand);
        }
    }

    removeItem() {
        const { selectedAssignedItem } = this.state,
        assignedItems = this.state.assignedItems.slice();
        if (selectedAssignedItem) {  
        assignedItems.splice(assignedItems.indexOf(selectedAssignedItem), 1);
        this.setState({
            assignedItems,
            selectedAssignedItem: null,
        });
        }
    }

    addParam(param) {
        const availableParams = this.state.availableParams.slice(),
        assignedParams = this.state.assignedParams.slice();
        if (param) {
        if (!assignedParams.includes(param) && availableParams.includes(param)) {
            assignedParams.push(param);
            availableParams.splice(availableParams.indexOf(param), 1);
            this.setState({
            assignedParams,
            availableParams,
            });
        }
        }
    }

    removeParam(param) {
        const availableParams = this.state.availableParams.slice(),
        assignedParams = this.state.assignedParams.slice();
        if (param) {
        if (!availableParams.includes(param) && assignedParams.includes(param)) {
            availableParams.push(param);
            assignedParams.splice(assignedParams.indexOf(param), 1);
            this.setState({
            assignedParams,
            availableParams,
            });
        }
        }
    }

    validateScript() {
        const { scriptName, assignedItems, softErrorMode, script } = this.state,
        nameError = this.getValidationState(softErrorMode),
        { scripts, currentWorkspace } = this.props,
        comparingName = scriptName.toLowerCase() || '';

        if (nameError) {
        return nameError;
        }

        if (!scriptName) {
        return 'Name cannot be blank';
        }

        if (!currentWorkspace) {
        return 'You must select a valid database in order to create a script';
        }

        if (!assignedItems.length) {
        return 'You cannot create a script with no commands';
        }
        const found = scripts.find(s => s.scriptName.toLowerCase() === comparingName),
        error = found && script && found.batchScriptId !== script.batchScriptId ? 'Name must be unique' : '';
        return error;
    }

    saveScript() {
        //if (this.props.dbChangeAllowed) {
        //if (true) { 
        this.setState({ softErrorMode: false });
        const { 
            createScriptHandler, 
            userKey, 
            currentWorkspace, 
            userId, 
            scriptCommands, 
            editScriptHandler,
            browserHistory } = this.props,
            { assignedItems, scriptDescription, editingMode, scriptId } = this.state;

            const scriptName = this.state.scriptName.trim(),
            databaseId = currentWorkspace ? currentWorkspace.id : null, 
            commands = assignedItems.map((ai) => {
            let parameters;
            if (ai.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD || ai.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD) {
                parameters = ai.id === 4
                ? [{ name: 'Remove Last Evaluation Period', value: 1 }]
                : [{ name: 'Number of evaluation periods', value: 1 }];
                return {
                id: ai.id,
                name: ai.value,
                parameters,
                };
            }
            if (ai.id === COMMAND_IDS.COMPRESS_COLUMNS || ai.id === COMMAND_IDS.COMPRESS_ROWS) {
                parameters = ai.paramNames.map((pn, i) => {
                const foundCommand = scriptCommands.find(sc => sc.id === ai.id);
                let name = pn;
                if (foundCommand) {
                    const foundParam = foundCommand.params.find(fc => fc.name === ai.paramNames[i]);
                    if (foundParam && foundParam.value) {
                    name = foundParam.value;
                    }
                }
                return {
                    name,
                    value: ai.params[i],
                };
                });
                return {
                id: ai.id,
                name: ai.value,
                parameters,
                };
            }
            if (ai.id === COMMAND_IDS.APPLY_DATA_DEFAULTS || ai.id === COMMAND_IDS.APPLY_SDF_DEFAULTS
                || ai.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS) {
                parameters = ai.paramNames.map((pn, i) => ({ name: pn, value: ai.params[i] }));
                return {
                id: ai.id,
                name: ai.value,
                parameters,
                };
            }
            if (ai.params && ai.params.length) {
                const command = scriptCommands.find(sc => sc.value === ai.value);
                if (command && command.params.length) {
                parameters = ai.params.map((p, i) => {
                    if (command.params[i].options) {
                    return {
                        name: command.params[i].options[0],
                        value: ai.params[i],
                    };
                    } else {
                    return {
                        name: command.params[i].name,
                        value: ai.params[i],
                    };
                    } 
                });
                } else {
                parameters = [];
                }
            }
            return {
                id: ai.id,
                name: ai.value,
                parameters,
            };
            });
        const error = this.validateScript();
        if (!error) {
            if (editingMode) {
                editScriptHandler({
                    batchScriptId: scriptId,
                    userKey,
                    scriptName,
                    scriptDescription,
                    commands,
                    databaseId,
                    updateBy: userId,
                });
                browserHistory.push(`/arius/automation/${databaseId}/scripts`);
            } else {
                createScriptHandler({ userKey, createBy: userId, scriptDescription, scriptName, commands, databaseId });
                browserHistory.push(`/arius/automation/${databaseId}/scripts`);
            }
        } else {
            notifyError(error);
        }
        //} else {
        //  notifyError('You are not authorized to access this functionality');
        //}
    }

    alphabetizeItems(items) {
        return items.sort((a, b) => {
        if (a.value.toString().toLowerCase() < b.value.toString().toLowerCase()) {
            return -1;
        } else {
            return 1;
        }
        });
    }

    selectBool(bool, idx) {
        const { selectedItem, selectedParams, selectedAssignedItem } = this.state;
        if (selectedItem) {
        const id = selectedItem.id;
        let found = selectedParams.find(sp => sp.id === id);
        if (found) {
            found.params[idx] = bool;
        } else {
            const params = selectedItem.params.map(() => null);
            params[idx] = bool;
            found = {
            id,
            params,
            };
            selectedParams.push(found);
        }
        this.setState({ selectedParams });
        } else {
        selectedAssignedItem.params[idx] = bool;
        this.setState({ selectedAssignedItem });
        }
    }
    
    inputParam(e, idx, name) {
        const value = e.target.value, { availableInputValues, assignedInputValues, selectedItem,
        selectedParams, selectedAssignedItem, todInput, assignedTodInput, saveCopyInput, assignedSaveCopyInput } = this.state,
        newState = {},
        inputValues = selectedItem
            ? availableInputValues
            : assignedInputValues.find(iv => iv.keyableIndex === selectedAssignedItem.keyableIndex);
        if (selectedItem) {
        if (selectedItem.id === COMMAND_IDS.LOAD_DATA_FROM_TOD 
            || selectedItem.id === COMMAND_IDS.LOAD_COLLECTION_SET
            || selectedItem.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
            || selectedItem.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
            || selectedItem.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
        ) {
            todInput[idx].value = value;
            newState.todInput = todInput;
        } else if (selectedItem.id === COMMAND_IDS.SAVE_AS_COPY) {
            saveCopyInput[idx].value = value;
            newState.saveCopyInput = saveCopyInput;
        } else if (selectedItem.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD || selectedItem.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD) {
            if (isNaN(value) || (value && value.length > 2) || (value % 1 !== 0)) {
            inputValues[idx] = { 
                value: inputValues[idx] ? inputValues[idx].value : '',
                error: 'Must be an integer from 1-99',
                name,
            };
            this.setState({ inputValues });
            return;
            }
            inputValues[idx] = {
            value,
            error: null,
            name,
            };
            newState.availableInputValues = inputValues;
        }
        const id = selectedItem.id;
        let found = selectedParams.find(sp => sp.id === id);
        if (found) {
            found.params[idx] = value;
        } else {
            const params = selectedItem.params.map(() => null);
            params[idx] = value;
            found = {
            id,
            params,
            };
            selectedParams.push(found);
        }
        newState.selectedParams = selectedParams;
        this.setState(newState);
        } else {
        let referenceNumber, index;
        if (selectedAssignedItem.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD
            || selectedAssignedItem.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD) {
            referenceNumber = inputValues.keyableIndex;
            index = assignedInputValues.indexOf(inputValues);
            if (isNaN(value) || (value && value.length > 2) || (value % 1 !== 0)) {
            if (inputValues) {
                inputValues.inputValues[idx] = { 
                value: inputValues[idx] ? inputValues[idx].value : '',
                error: 'Must be an integer from 1-99',
                name,
                };
            }
            assignedInputValues.splice(index, 1, inputValues);
            newState.assignedInputValues = assignedInputValues;
            return;
            }
            inputValues.inputValues[idx] = {
            value,
            error: null,
            referenceNumber,
            name,
            };
        } else if (selectedAssignedItem.id === COMMAND_IDS.LOAD_DATA_FROM_TOD 
            || selectedAssignedItem.id === COMMAND_IDS.LOAD_COLLECTION_SET
            || selectedAssignedItem.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
            || selectedAssignedItem.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
            || selectedAssignedItem.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
        ) {
            assignedTodInput[idx].value = value;
            newState.assignedTodInput = assignedTodInput;
        } else if (selectedAssignedItem.id === COMMAND_IDS.SAVE_AS_COPY) {
            assignedSaveCopyInput[idx].value = value;
            newState.assignedSaveCopyInput = assignedSaveCopyInput;
        }
        
        selectedAssignedItem.params[idx] = value;
        if (inputValues) {
            assignedInputValues.splice(assignedInputValues.indexOf(inputValues), 1, inputValues);
        }
        this.setState({ selectedAssignedItem, assignedInputValues });
        }
    }

    selectModifyDateParams(e, idx) {
        const { selectedItem, selectedParams, selectedAssignedItem } = this.state,
        id = selectedItem ? selectedItem.id : null,
        value = e.target.value !== 'select' ? e.target.value : null;
        
        if (selectedItem) {
        let found = selectedParams.find(sp => sp.id === id);
        if (found) {
            found.params[idx] = value;
        } else {
            const params = selectedItem.params.map(() => null);
            params[idx] = value;
            found = {
            id,
            params,
            };
            selectedParams.push(found);
        }
        this.setState({ selectedParams });
        } else {
        if (value) {
            selectedAssignedItem.params[idx] = value;
            this.setState({ selectedParams });
        } else {
            notifyError('Cannot remove parameter from an assigned command');
        } 
        }
    }

    moveCard(dragIndex, hoverIndex) {
        const 
        { assignedItems } = this.state,
        dragCard = assignedItems[dragIndex];
        assignedItems.splice(dragIndex, 1);
        assignedItems.splice(hoverIndex, 0, dragCard);

        this.setState({ assignedItems });
    }

    changeWorkspaceHandler(workspaceId) {
        const { dispatch, userKey, fetchScriptsHandler, browserHistory, params } = this.props;
        // this.setState({ selectedWorkspaceId: workspaceId });
        dispatch(fetchWorkspace(userKey, workspaceId));
        fetchScriptsHandler({ userKey, databaseId: workspaceId });
        browserHistory.push(`/arius/automation/${workspaceId}/scripts/${params.scriptId}`);
    }
    
    backToScripts() {
        const { browserHistory, params } = this.props;
        browserHistory.push(`/arius/automation/${params.databaseId}/scripts`);
    }

    selectMapper(e, idx) {
        const value = e.target.value,
        { selectedItem, selectedAssignedItem, selectedParams } = this.state,
        id = selectedItem ? selectedItem.id : selectedAssignedItem.id,
        newState = {};
        if (selectedItem) {
        let found = selectedParams.find(sp => sp.id === id);
        if (found) {
            found.params[idx] = value;
        } else {
            const params = selectedItem.params.map(() => null);
            params[idx] = value;
            found = {
            id,
            params,
            };
            selectedParams.push(found);
        }
        newState.selectedParams = selectedParams;
        } else {
        selectedAssignedItem.params[idx] = value;
        newState.selectAssignedItem = selectedAssignedItem;
        }
        this.setState(newState);
    }

    getGenericListParam(name, selectedItem, selectedParams, commandId, exportListId, idx){
        let lists = this.getGenericLists(commandId);

        let val = '';
        if (selectedItem) {
            let sp = selectedParams.find(sp => sp.id === commandId);
            const found = lists.find(cs => cs.exportListId == sp.params[0]); // eslint-disable-line eqeqeq
            if (found) val = found.exportListId;
        } else {
            const list = lists.find(m => m.exportListId == exportListId); // eslint-disable-line eqeqeq
            if (list) {
                val = list.exportListId;
            }
        }
        
        return <div 
            style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }} 
            key={`tod-params-available-${idx}`}
        >
            <Form.Label style={{ display: 'flex', flex: '3 1', alignItems: 'center' }}>{name}</Form.Label>
            <div style={{ display: 'flex', flex: '3 1', marginRight: '30px', paddingTop: '15px' }}>
                    <Form.Group controlId="formValidationError1" style={{ marginBottom: '5px', width: '100%' }}>
                    <Form.Control
                        as="select"
                        placeholder="Select Workspace"
                        value={val}
                        onChange={(e) => this.selectMapper(e, idx)}
                    >
                        {lists ? lists.map(cs =>
                            (
                            <option
                                key={`csValue-${cs.exportListId}`}
                                value={cs.exportListId}
                            >
                                {cs.listName}
                            </option>
                            )
                        ) 
                        : <option>{`You have no ${name}s`}</option>}
                    </Form.Control>
                </Form.Group>
            </div>
        </div>
    }

    render() {
        const { scriptName, scriptDescription, softErrorMode } = this.state;
        const { currentWorkspace,
            workspaces,
            dbChangeAllowed,
            dispatch
        } = this.props,
        nameError = this.getValidationState(softErrorMode),
        {
            commands,
            availableItems,
            assignedItems,
            selectedItem,
            selectedParams,
            selectedAssignedItem,
            editingMode,
            availableInputValues,
            assignedInputValues,
            todInput,
            assignedTodInput,
            ie,
            mappers,
            saveCopyInput,
            assignedSaveCopyInput,
        } = this.state,
        parametersRequired =
            selectedItem && selectedItem.params && selectedItem.params.length,
        parametersAssigned = selectedAssignedItem && selectedAssignedItem.params && selectedAssignedItem.params.length,
        ieSpecificCSS = ie ? { whiteSpace: 'nowrap' } : {},
        mainNav = document.getElementById('mainNavbarContainer');
        let parameterInputError = false,
        mainContainerHeight,
        navHeight,
        inputValues;
        if (selectedItem || selectedAssignedItem) {
        inputValues = selectedItem
            ? availableInputValues
            : (assignedInputValues.find(iv => iv.keyableIndex === selectedAssignedItem.keyableIndex) || {}).inputValues;
        }

        if (mainNav) {
        navHeight = mainNav.getBoundingClientRect().height;
        mainContainerHeight = `calc(100vh - ${navHeight}px)`;
        }

        let leftParamsContainerJSX = (
        <div style={style.paramBox}>
            <div style={{ fontWeight: 'bold' }}>none</div>
        </div>
        ),
        rightParamsContainerJSX = (
            <div></div>
            // TODO - WDD - for now this is being replaced by popover showing parameter descriptions
            // <Form.Group
            // style={{
            //     display: 'flex',
            //     flex: '1 1 auto',
            //     flexFlow: 'column nowrap',
            //     marginLeft: '25px',
            // }}
            // //validationState={paramError}
            // >
            // <Form.Label>
            //     DescriptionXXx
            // </Form.Label>
            // <div style={[style.paramBox, { marginLeft: '10px' }]}>
            //     {descJSX}
            // </div>
            // </Form.Group>
        );

        if (parametersRequired || parametersAssigned) {
        let params,
            selectedParam,
            commandItem;
        if (parametersRequired) {
            params = selectedItem.params;
            commandItem = selectedItem;
            selectedParam = selectedParams.find(sp => sp.id === selectedItem.id);
            if (selectedParam && selectedParam.params) {
                if (selectedParam.params.indexOf(null) !== -1) {
                    parameterInputError = true;
                }
            } else {
                parameterInputError = true;
            }
        } else {
            commandItem = selectedAssignedItem;
            params = (availableItems.find(item => item.value === selectedAssignedItem.value) || {}).params;
        }
        if (commandItem.id === COMMAND_IDS.APPEND_NEW_EVALUATION_PERIOD) {
            leftParamsContainerJSX = (
            <div style={style.paramBox}>
                {params.map((par, idx) => {
                let trueChecked = false, falseChecked = false;
                if (selectedItem) {
                    const selectedPar = selectedParams.find(
                    sp => sp.id === selectedItem.id
                    );
                    if (selectedPar && selectedPar.params[idx] !== null) {
                    trueChecked = selectedPar.params[idx] === true;
                    falseChecked = selectedPar.params[idx] === false;
                    }
                } else {
                    trueChecked = selectedAssignedItem.params[idx] === true;
                    falseChecked = selectedAssignedItem.params[idx] === false; 
                }
                return (
                    <div key={`selectedItem-params-${idx}`}>
                    {par.options.length === 1
                        ? <div style={{ margin: '10px 0' }}>
                            <Form.Label>{par.options[0]}</Form.Label>
                            {par.description
                            ?   <OverlayTrigger trigger="click" placement="right" overlay={
                                    <Popover 
                                        title="Parameter Description"
                                    >{par.description}</Popover>
                            }>
                                    <span 
                                        style={{ textDecoration: 'underline',
                                        marginLeft: '5px', cursor: 'pointer' }}>
                                        see description
                                    </span>
                                </OverlayTrigger>
                            
                            
                            // <span 
                            //     style={{ textDecoration: 'underline',
                            //     marginLeft: '5px', cursor: 'pointer' }}
                            //     onClick={() => this.selectParamDesc(par.options[0])}>
                            //     see description
                            // </span>
                            : ''}
                            {par.type === 'boolean'
                            ? <div style={style.booleanRow}>
                                <span style={style.inputContainer}>
                                    <input 
                                        type="radio" 
                                        checked={trueChecked} 
                                        onChange={() => this.selectBool(true, idx)}/>
                                    <span style={{ marginLeft: '2px', marginTop: '4px' }}>true</span>
                                </span>
                                <span style={style.inputContainer}>
                                    <input 
                                        type="radio" 
                                        checked={falseChecked} 
                                        onChange={() => this.selectBool(false, idx)}/>
                                    <span style={{ marginLeft: '2px', marginTop: '4px' }}>false</span>
                                </span>
                                </div>
                            : <div>other</div>}
                        </div>
                        : <div>other</div>}
                    </div>
                );
                })}
            </div>
            );
        } else if (
            (selectedItem || selectedAssignedItem).id === COMMAND_IDS.MODIFY_DATE_PARAMETERS
            ) {
            let selectedModParams;
            if (selectedItem) {
            selectedModParams = (selectedParams.find(sp => sp.id === selectedItem.id) || {}).params;
            }
            let s = {textAlign: 'left', paddingLeft: 0};
            leftParamsContainerJSX = (
            <div className='container' style={[style.paramBox, { marginLeft: '0px', paddingLeft:0}]}>
                <div className=" row" style={{marginBottom: 5}}>
                    <label className="col-sm-6 form-label" style={s}>{params[0].options[0]}</label>
                    <div className="col-sm-6">
                        <Form.Control
                            as='select'
                            placeholder="select"
                            onChange={e => this.selectModifyDateParams(e, 0)}
                            value={(selectedModParams && selectedModParams[0]) || (selectedAssignedItem && selectedAssignedItem.params[0]) || 'select'}
                        >
                            <option value="select">select modification parameter</option>
                            {params[0].values.map((option, i) => (
                            <option 
                                key={`options-${option}-${i}`} 
                                value={option}>
                                {option}
                            </option>
                            ))}
                        </Form.Control>   
                    </div>
                </div>
                { params[1] ?
                <div className="row">
                    <label className="col-sm-6 form-label" style={s}>{params[1].options[0]}</label>
                    <div className="col-sm-6">
                        <Form.Control
                            as="select"
                            placeholder="select"
                            onChange={e => this.selectModifyDateParams(e, 1)}
                            value={(selectedModParams && selectedModParams[1]) || (selectedAssignedItem && selectedAssignedItem.params[1]) || 'select'}
                            >
                            <option value="select">select period</option>
                            {params[1].values.map((value, i) => (
                                <option
                                key={`values-${value}-${i}`}
                                value={value}>
                                {value}
                                </option>
                            ))}
                        </Form.Control> 
                    </div>
                </div> : <span />
                }
   
                                {/* <Form.Label as={Col} xs={5}>{params[0].options[0]}</Form.Label>
                <Form.Control
                    as={Col} xs={5}
                    placeholder="select"
                    onChange={e => this.selectModifyDateParams(e, 0)}
                    value={(selectedModParams && selectedModParams[0]) || (selectedAssignedItem && selectedAssignedItem.params[0]) || 'select'}
                >
                    <option value="select">select modification parameter</option>
                    {params[0].values.map((option, i) => (
                    <option 
                        key={`options-${option}-${i}`} 
                        value={option}>
                        {option}
                    </option>
                    ))}
                </Form.Control> */}
                {/* <Form.Group controlId="formOptionControlsSelect">
                <Form.Label>{params[0].options[0]}</Form.Label>
                <Form.Control
                    as="select"
                    placeholder="select"
                    onChange={e => this.selectModifyDateParams(e, 0)}
                    value={(selectedModParams && selectedModParams[0]) || (selectedAssignedItem && selectedAssignedItem.params[0]) || 'select'}
                >
                    <option value="select">select modification parameter</option>
                    {params[0].values.map((option, i) => (
                    <option 
                        key={`options-${option}-${i}`} 
                        value={option}>
                        {option}
                    </option>
                    ))}
                </Form.Control>
                </Form.Group> */}

            </div>
            );
        } else if (commandItem.id === COMMAND_IDS.REMOVE_OLDEST_EXPOSURE_PERIOD || commandItem.id === COMMAND_IDS.REMOVE_LAST_EVALUATION_PERIOD) {
            const paramNames = commandItem.paramNames;
            const localParams = commandItem.params.map((par, idx) => {
            let name, inputValue;
            if (commandItem.paramNames && commandItem.paramNames.length) {
                name = paramNames[idx];
                inputValue = par;
            } else if (typeof par !== 'string' || (inputValues && inputValues.length)) {
                name = par.name || inputValues[idx].name;
                inputValue = inputValues && inputValues[idx]
                        ? inputValues[idx].value
                        : '';
            } 
            const parError = inputValues && inputValues.length ? inputValues[idx].error : null;
            return (
                <div key={`${idx}-avail-side`}>
                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flex: '3 1', alignItems: 'center' }}>
                    {name}
                    &nbsp;
                    </div>
                    <div style={{ display: 'flex', flex: '1 1', marginRight: '30px', paddingTop: '15px' }}>
                    <Form.Group controlId="formValidationError1" style={{ marginBottom: '5px' }}>
                        <Form.Control
                        type="text"
                        value={inputValue}
                        style={{ width: '42px' }}
                        onChange={(e) => this.inputParam(e, idx, name)} />
                    </Form.Group>
                    </div>
                </div>
                <Form.Control.Feedback type='invalid' style={{ marginRight: '30px', color: '#a94442' }}>{parError}</Form.Control.Feedback>
                </div>
            );
            });
            leftParamsContainerJSX = (
            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                {localParams}
            </div>
            );
        } else if (commandItem.id === COMMAND_IDS.LOAD_DATA_FROM_TOD 
            || commandItem.id === COMMAND_IDS.LOAD_COLLECTION_SET 
            || commandItem.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
            || commandItem.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
            || commandItem.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
            || commandItem.id === COMMAND_IDS.SAVE_AS_COPY
        ) {
            const paramNames = commandItem.paramNames,
            localParams = commandItem.params.map((cip, idx) => {
                let name, format = '';
                if (paramNames && paramNames.length) {
                    name = paramNames[idx];
                } else if (cip.name) {
                    name = cip.name;
                }

                if (name === 'TOD date') {
                    format = <span>(MM/dd/yyyy)</span>;
                }

                let input;
                if (name === 'Project Name Suffix') {
                    input = selectedItem ? saveCopyInput : assignedSaveCopyInput;
                } else {
                    input = selectedItem ? todInput : assignedTodInput;
                }

                let paramJSX = '';
                if (name === 'Load last diagonal only') {
                    let trueChecked = false, falseChecked = false;
                    if (selectedItem) {
                        const selectedPar = selectedParams.find(
                        sp => sp.id === selectedItem.id
                        );
                        if (selectedPar && selectedPar.params[idx] !== null) {
                        trueChecked = selectedPar.params[idx] === true;
                        falseChecked = selectedPar.params[idx] === false;
                        }
                    } else {
                        trueChecked = selectedAssignedItem.params[idx] === true;
                        falseChecked = selectedAssignedItem.params[idx] === false; 
                    }
                    paramJSX = (
                        <div 
                            key={`DIV-${idx}`}
                            style={{ 
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '5px',
                            }}
                        >
                        <Form.Label style={{ display: 'flex', flex: '1 1' }}>{name}</Form.Label>
                        <div style={[style.booleanRow, { marginRight: '30px', display: 'flex', flex: '1 1' }]}>
                            <span style={style.inputContainer}>
                                <input type="radio" checked={trueChecked} onChange={() => this.selectBool(true, idx)} />
                                <span style={{ marginLeft: '2px', marginTop: '4px' }}>true</span>
                            </span>
                            <span style={style.inputContainer}>
                                <input type="radio" checked={falseChecked} onChange={() => this.selectBool(false, idx)} />
                                <span style={{ marginLeft: '2px', marginTop: '4px' }}>false</span>
                            </span>
                        </div>
                        </div>
                    );
                } else if (name === 'Measure Mapper name') {
                    const found = selectedParams.find(
                        sp => sp.id === commandItem.id
                    );
                    let val = '';
                    if (found && selectedItem) {
                        val = found.params[idx];
                    } else if (name === 'Measure Mapper name') {
                        const mapper = mappers.find(m => m.value == selectedAssignedItem.params[idx]); // eslint-disable-line eqeqeq
                        if (mapper) {
                        val = mapper.value;
                        }
                    }
                    paramJSX = (
                        <div 
                        style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }} 
                        key={`tod-params-available-${idx}`}>
                        <Form.Label style={{ display: 'flex', flex: '3 1', alignItems: 'center' }}>{name}&nbsp;{format}</Form.Label>
                        <div style={{ display: 'flex', flex: '3 1', marginRight: '30px', paddingTop: '15px' }}>
                            <Form.Group controlId="formValidationError1" style={{ marginBottom: '5px', width: '100%' }}>
                            <Form.Control
                                as="select"
                                placeholder="Select Workspace"
                                value={val}
                                onChange={(e) => this.selectMapper(e, idx)}
                            >
                                {mappers
                                ? mappers.map(mapper =>
                                    (
                                    <option
                                        key={`mapperValue-${mapper.value}`}
                                        value={mapper.value}
                                    >
                                        {mapper.label}
                                    </option>
                                    )
                                ) 
                                : <option>You have no databases</option>}
                            </Form.Control>
                            </Form.Group>
                        </div>
                        </div>
                    );
                } else if (name === 'Collection Set Name'
                    || name === 'Exhibit Options Name'
                    || name === 'Data Defaults Name'
                    || name === 'Exhibit Defaults Name'
                ) {
                    paramJSX = this.getGenericListParam(name, selectedItem, selectedParams, commandItem.id, 
                        selectedItem ? '' : selectedAssignedItem.params[idx], idx);
                } else {
                paramJSX = (
                    <div 
                        style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }} 
                        key={`tod-params-available-${idx}`}>
                    <Form.Label style={{ display: 'flex', flex: '3 1', alignItems: 'center' }}>{name}&nbsp;{format}</Form.Label>
                    <div style={{ display: 'flex', flex: '3 1', marginRight: '30px', paddingTop: '15px' }}>
                        <Form.Group controlId="formValidationError1" style={{ marginBottom: '5px', width: '100%' }}>
                        <Form.Control
                            type="text"
                            value={input[idx].value}
                            onChange={(e) => this.inputParam(e, idx, name)} />
                        </Form.Group>
                    </div>
                    </div>
                );
                }
                return paramJSX;
            });
            leftParamsContainerJSX = (
            <div>
                {localParams}
            </div>
            );
        } else if (commandItem.id === COMMAND_IDS.COMPRESS_COLUMNS || commandItem.id === COMMAND_IDS.COMPRESS_ROWS) {
            const paramNames = commandItem.paramNames,
            localParams = commandItem.params.map((param, idx) => {
                let paramJSX, val;
                let options;
                if (param.options) {
                options = param.options;
                const foundItem = selectedParams.find(sp => sp.id === commandItem.id);
                if (foundItem) {
                    val = foundItem.params[idx];
                }
                } else {
                const foundCommand = commands.find(c => c.id === commandItem.id);
                if (foundCommand) {
                    options = foundCommand.params[idx].options;
                }
                val = param;
                }
                const name = param.name || paramNames[idx];
                if (idx === 0) {
                paramJSX = (
                    <div 
                        style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }} 
                        key={`tod-params-available-${idx}`}>
                    <Form.Label style={{ display: 'flex', flex: '3 1', alignItems: 'center' }}>{name}</Form.Label>
                    <div style={{ display: 'flex', flex: '3 1', marginRight: '30px', paddingTop: '15px' }}>
                        <Form.Group controlId="formValidationError1" style={{ marginBottom: '5px', width: '100%' }}>
                        <Form.Control
                            as="select"
                            placeholder="Select Workspace"
                            value={val}
                            onChange={(e) => this.selectMapper(e, idx)}
                        >
                            {options.map(option =>
                            (
                                <option
                                key={`optionValue-${option}`}
                                value={option}
                                >
                                {option}
                                </option>
                            )
                            )}
                        </Form.Control>
                        </Form.Group>
                    </div>
                    </div>
                );
                } else if (idx === 1) {
                let trueChecked = false, falseChecked = false;
                if (selectedItem) {
                    const selectedPar = selectedParams.find(
                    sp => sp.id === selectedItem.id
                    );
                    if (selectedPar && selectedPar.params[idx] !== null) {
                    trueChecked = selectedPar.params[idx] === true;
                    falseChecked = selectedPar.params[idx] === false;
                    }
                } else {
                    trueChecked = selectedAssignedItem.params[idx] === true;
                    falseChecked = selectedAssignedItem.params[idx] === false; 
                }
                paramJSX = (
                    <div
                        key={`tod-params-available-${idx}`}
                    style={{ 
                        alignItems: 'center',
                        marginTop: '5px',
                    }}>
                    <Form.Label style={{ display: 'flex', flex: '1 1' }}>{name}</Form.Label>
                    <div style={style.booleanRow}>
                        <span style={style.inputContainer}>
                            <input type="radio" checked={trueChecked} onChange={() => this.selectBool(true, idx)} />
                            <span style={{ marginLeft: '2px', marginTop: '4px' }}>true</span>
                        </span>
                        <span style={style.inputContainer}>
                            <input type="radio" checked={falseChecked} onChange={() => this.selectBool(false, idx)} />
                            <span style={{ marginLeft: '2px', marginTop: '4px' }}>false</span>
                        </span>
                    </div>
                    </div>
                );
                }
                return paramJSX;
            }); 
            leftParamsContainerJSX = (
            <div>
                {localParams}
            </div>
            );
        } else if (commandItem.id === COMMAND_IDS.APPLY_DATA_DEFAULTS || commandItem.id === COMMAND_IDS.APPLY_SDF_DEFAULTS
            || commandItem.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS) {
            const foundParams = selectedParams.find(sp => sp.id === commandItem.id),
            paramNames = commandItem.paramNames;
            let selectedLocalParam; 
            leftParamsContainerJSX = commandItem.params.map((cip, index) => {
            if (foundParams && selectedItem) {
                selectedLocalParam = foundParams.params[index];
            } else {
                // const foundCommand = commands.find(c => c.id === commandItem.id);
                // let foundOption;
                // if (foundCommand) {
                //   foundOption = foundCommand.params[index].options.find(o => o.value === commandItem.params[index]);
                // }
                // if (foundOption) {
                // }
                selectedLocalParam = commandItem.params[index];
            }
            const name = cip.name || paramNames[index],
                options = cip.options || (commands.find(c => c.id === commandItem.id) || {}).params[index].options;
            return (
                <div key={`DIV${cip.name}-${index}`}>
                    <Form.Label>{name}</Form.Label>
                    <Form.Group>
                        { options.map((option, idx) => (
                            <Form.Check type="radio"
                                key={`${cip.name}-${idx}`}
                                name="radioGroup"
                                checked={option.value === selectedLocalParam}
                                onChange={() => this.selectBool(option.value, index)}
                                label={option.label}
                            />
                        ))}
                    </Form.Group>
                </div>
            );
            });        
        }
        }

        const paramsSectionJSX = (
        <div className='row' style={style.paramsContainer}>
            <div style={{...style.paramsColumn, marginRight: '5px' }}>
            <Form.Group
                style={{
                display: 'flex',
                flex: '1 1 auto',
                flexFlow: 'column nowrap',
                }}
                //validationState={paramError}
            >
                <Form.Label>
                Parameters
                </Form.Label>
                {leftParamsContainerJSX}
            </Form.Group>
            </div>
            <div style={[style.paramsColumn, { marginRight: '5px' }]}>
            {rightParamsContainerJSX}
            </div>
        </div>
        );
        
        const scriptList = (
        assignedItems.map((command, idx) => {
            let optionalParams, optionalParamsJSX;
            if (command && command.params) {
            optionalParams = JSON.parse(JSON.stringify(command.params));
            optionalParamsJSX = (
                <span>
                (
                {optionalParams.map((param, index) => {
                    let paramJSX;
                    if (command.id === COMMAND_IDS.LOAD_DATA_FROM_TOD && index == 1) { // eslint-disable-line eqeqeq
                    const mapper = mappers.find(m => m.value == param); // eslint-disable-line eqeqeq
                    if (mapper) {
                        paramJSX = mapper.label;
                    } else {
                        paramJSX = '';
                    }
                    } else if (command.id === COMMAND_IDS.LOAD_COLLECTION_SET
                        || command.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
                        || command.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
                        || command.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
                    ) { // eslint-disable-line eqeqeq
                        const lists = this.getGenericLists(command.id);
                        const list= lists.find(cs => cs.exportListId == param); // eslint-disable-line eqeqeq
                        if (list) {
                            paramJSX = list.listName;
                        } else {
                            paramJSX = '';
                        }
                    } else if (command.id === COMMAND_IDS.APPLY_DATA_DEFAULTS || command.id === COMMAND_IDS.APPLY_SDF_DEFAULTS
                    || command.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS) {
                    const foundCommand = commands.find(c => c.id === command.id);
                    let foundOption;
                    if (foundCommand) {
                        foundOption = foundCommand.params[index].options.find(o => o.value === command.params[index]);
                    }
                    if (foundOption) {
                        optionalParams[index] = foundOption.label;
                    }
                    return optionalParams[index];
                    } else { 
                    paramJSX = typeof param === 'string'
                        ? param
                        : param.toString();
                    }
                    return (
                    <span key={`optional-param-${index}`}>
                        {paramJSX}
                        {index !== optionalParams.length - 1
                        ? <span>, </span>
                        : ''}
                    </span>
                    );
                })}
                )
                </span>
            );
            }
            return (
            <DraggableCommand
                key={`assigned-commands-${command.id}-${command.keyableIndex}`}
                id={`assigned-commands-${idx}`}
                index={idx}
                text={command.value}
                optionalParams={optionalParamsJSX}
                selected={{
                ...(selectedAssignedItem === command
                    ? style.activeItem
                    : style.inactiveItem),
                ...style.assignedListItem,
                }}
                ieSpecificCSS={ieSpecificCSS}
                onClick={e => this.selectAssignedItem(command, e)}
                moveCard={this.moveCard}
            />
            );
        })
        );
        return (
        <div style={{...style.mainContainer, height: mainContainerHeight, fontSize: 'smaller' }}>
            <AnalysisToolbar
                disabled={editingMode || !dbChangeAllowed}
                currentWorkspace={currentWorkspace}
                workspaces={workspaces}
                dispatch={dispatch}
                changeWorkspaceHandler={this.changeWorkspaceHandler}
            />
            <div className='list-header-arius'>
                {editingMode ? <h4>Edit Script</h4> : <h4>Create Script</h4>}
                <Button mode='back' onClick={this.backToScripts} toolTip='Back to Scripts'/>
            </div>
            <div className='create-script-box container'>
                <div className='row' style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                    <Form.Group
                        style={{ width: '100%', paddingRight: 35 }}
                        controlId="createScriptFormName">
                        <Form.Label>Script Name</Form.Label>
                        <Form.Control
                            type="text"
                            style={{  }}
                            value={scriptName}
                            placeholder="Enter script name"
                            onChange={e => this.handleScriptNameChange(e)}
                            isInvalid={nameError ? 'error' : null}
                        />
                        <Form.Control.Feedback type='invalid'>{nameError}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group 
                        controlId="createScriptFormDesc"
                        style={{ width: '100%', paddingLeft: 35 }}>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            style={{  }}
                            placeholder="Enter script description"
                            value={scriptDescription}
                            onChange={e => this.setState({ scriptDescription: e.target.value })}
                        />
                    </Form.Group>
                </div>
                <div className='row' style={style.commandContainer}>
                    <div className="form-group" style={[style.commandColumn, { marginRight: '5px' }]}>
                        <Form.Label>Commands</Form.Label>
                        <div style={style.commandBox}>
                            {availableItems && availableItems.length ? availableItems.map((command, idx) => {
                                let optionalParams, optionalParamsJSX;
                                const found = selectedParams.find(sp => sp.id === command.id);
                                if (command === selectedItem && command.params) {
                                optionalParams = command.params.map(() => 'required');
                                if (found) {
                                    optionalParams = found.params.map((sp, index) => {
                                    if (sp === false || sp === true) {
                                        return sp;
                                    }
                                    if (command.id === COMMAND_IDS.APPLY_DATA_DEFAULTS || command.id === COMMAND_IDS.APPLY_SDF_DEFAULTS
                                    || command.id === COMMAND_IDS.CLEAR_MANUAL_SELECTIONS) {
                                        const foundOption = command.params[index].options.find(o => o.value === sp);
                                        if (foundOption) {
                                        optionalParams[index] = foundOption.label;
                                        }
                                        return optionalParams[index];
                                    }
                                    if (command.id === COMMAND_IDS.LOAD_DATA_FROM_TOD) {
                                        if ((sp === '' || sp) && index === 1) {
                                            if (sp !== 'required') {
                                                const mapper = mappers.find(m => m.value == sp); // eslint-disable-line eqeqeq
                                                if (mapper) {
                                                    return mapper.label;
                                                } else {
                                                    return sp;
                                                }
                                            }
                                        }
                                        return sp || optionalParams[index];
                                    } else if (command.id === COMMAND_IDS.LOAD_COLLECTION_SET
                                        || command.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
                                        || command.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
                                        || command.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
                                    ) {
                                        if (sp !== 'required') {
                                            const lists = this.getGenericLists(command.id);
                                            const list = lists.find(m => m.exportListId == sp); // eslint-disable-line eqeqeq
                                            if (list) {
                                                return list.listName;
                                            }
                                        }
                                    }
                                    return sp || optionalParams[index];
                                    });
                                }
                                optionalParamsJSX = (
                                    <span>
                                    (
                                    {optionalParams.map((param, index) => {
                                        const paramJSX = typeof param === 'string'
                                        ? param
                                        : param.toString();
                                        let color = param === 'required'
                                        ? { color: 'red' }
                                        : { color: 'green' };
                                        return (
                                        <span
                                            style={color}
                                            key={`optional-param-${index}`}
                                        >
                                            {paramJSX}
                                            {index !== optionalParams.length - 1
                                            ? <span>, </span>
                                            : ''}
                                        </span>
                                        );
                                    })}
                                    )
                                    </span>
                                );
                                }
                                return (
                                <div
                                    className='available-commands'
                                    key={`available-commands-${command.id}-${idx}`}
                                    style={[
                                    selectedItem === command
                                        ? style.activeItem
                                        : style.inactiveItem,
                                    ieSpecificCSS,
                                    { minHeight: '14px' },
                                    ]}
                                    onClick={e => this.selectItem(command, e)}
                                >
                                    {command.value}{optionalParamsJSX}
                                </div>
                                );
                            }) : <div />}
                        </div>
                    </div>
                    <div
                        style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: '60px',
                        }}
                    >
                        <i
                        key="add-command-button"
                        style={style.button}
                        className="material-icons"
                        onClick={
                            parameterInputError
                            ? () => notifyError('parameter input required')
                            : () => this.addItem()
                        }
                        >
                        keyboard_arrow_right
                        </i>
                        <i
                        key="remove-command-button"
                        style={style.button}
                        className="material-icons"
                        onClick={() => this.removeItem()}
                        >
                        keyboard_arrow_left
                        </i>
                    </div>
                    <div className="form-group" style={[style.commandColumn, { marginLeft: '5px' }]}>
                        <Form.Label>Added to Script</Form.Label>
                        <div
                        key="added-to-script-container"
                        id="draggableListContainer"
                        style={style.commandBox}>
                        {scriptList}
                        </div>
                    </div>
                </div>
                {paramsSectionJSX}
                <div style={{ width: '100%', position: 'fixed',
                        bottom: 0,left: 0,display:'flex', alignItems:'center',justifyContent: 'flex-end',
                        backgroundColor: Colors.grey, padding: 10, height: '54px', paddingBottom: '15px'}}>
                    <button
                        key="save"
                        type="button"
                        style={style.btnStyle}
                        onClick={this.saveScript}
                        className="btn btn-default"
                        >
                            Save
                        </button>
                    <button
                        key="cancel"
                        type="button"
                        style={style.btnStyle}
                        className="btn btn-default"
                        onClick={this.backToScripts}
                    >
                        Back to Scripts
                    </button>
                </div>
            </div>
        </div>
        );
    }
}

const withDragDropContext = (Component) => (props) => (
    <DndProvider backend={HTML5Backend}>
        <Component {...props} />
   </DndProvider>
);

export default withDragDropContext(radium(CreateScripts));
