import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import ClauseObjectEditor from './editors/clauseObjectEditor';
import { notifyError } from '../../../../utilities/notifier';
import Utils from './queryUtils';

class Clause extends Component {
    static propTypes = {
        column: PropTypes.string,
        group: PropTypes.string,
    };
    constructor(props) {
        super(props);
        this.state = {   
            deleteMode: null, 
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleGroupEdit = this.handleGroupEdit.bind(this);
        this.handleValuesEdit = this.handleValuesEdit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleVerbClick = this.handleVerbClick.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.remove = this.remove.bind(this);
    }

    onDrop = (ev, target) => {
        let o = Utils.parseDragObject(ev);
        Utils.handleOnDragLeave.call(this, ev);
        
        let info = this.myInfo;
        if (o.type === 'factColumn' && target === 'subject' && info.factColumnGuid === null) {
            // NOTE:  do not allow the subject to change if one already exists (user should 
            // remove the subject and drag another in).  if they drag a factcolumn in when there's 
            // already one defined just use it to create a new clause to follow this one
            this.props.handleChange(this.updateSubject(o));
            return;
        }

        this.props.insert(o, info.parentSerialNumber, info.order);
    }

    handleClick = (e) => {
        let o = this.myInfo;
        if (o.columnType){
            this.setState({showModal: true});
        } else {
            notifyError('Requires a column be defined');
        }
    }

    handleGroupEdit = (group) => {
        this.props.handleChange(this.updateGroup(group));
        this.handleClose();
    }

    handleValuesEdit = (values) => {
        this.props.handleChange(this.updateValues(values));
        this.handleClose();
    }

    handleClose = (e) => {
        this.setState({showModal: false});
    }

    remove = (target) => {
        if (target === 'subject') {
            let info  = this.updateSubject(null);
            info.dataGroupId = null;
            info.values = null;
            info.rollupId = null;
            info.objectOfClause = null;
            this.props.handleChange(info);
        } else if (target === 'object') {
            this.props.handleChange(this.updateValues(null));
        } else if (target === 'clause') {
            this.props.remove(this.props.data.serialNumber)
        }
        this.setState({deleteMode: null});
    }

    handleVerbClick = (e) => {
        const {operators, data} = this.props;
        let clauses = operators.filter((item, index) => item.type === 'clause');
        let ndx = clauses.findIndex((item)=> item.queryFragmentOperatorId === data.queryFragmentOperatorId) +1;
        ndx = ndx === clauses.length ? 0 : ndx;
        this.props.handleChange(this.updateVerb(clauses[ndx]));
    }

    handleExpandClick = () => {
        let info = this.myInfo;
        info.collapsed = !info.collapsed;
        this.props.handleChange(info);
    }
    
    updateSubject(o){
        let info = this.myInfo;
        info.factColumnGuid = o ? o.factColumnGuid : null;
        info.columnType = o ? o.columnType : null;
        info.subject = o ? o.name : null;
        return info;
    }

    updateGroup(o){
        let info = this.myInfo;
        info.dataGroupId = o ? o.id : null;
        info.values = null;
        info.rollupId = null;
        info.objectOfClause = o ? o.name : null;
        return info;
    }

    updateValues(values){
        let info = this.myInfo;
        let array = JSON.parse(values);
        let str = array ? array.join(',') : '';
        str = str.length > 10 ? str.substring(0, 10) + "..." : str;

        info.dataGroupId = null;
        info.values = values;
        info.rollupId = null;
        info.objectOfClause = str;
        return info;
    }

    updateVerb(o){
        let info = this.myInfo;
        info.queryFragmentOperatorId = o.queryFragmentOperatorId;
        info.sql = o.sql;
        return info;
    }

    get myInfo() {
        const {data} = this.props;
        return {
            factColumnGuid: data.factColumnGuid ? data.factColumnGuid : null,
            queryFragmentOperatorId: data.queryFragmentOperatorId,
            serialNumber: data.serialNumber,
            parentSerialNumber: data.parentSerialNumber,
            type: data.type,
            sql: data.sql,
            subject: data.subject,
            objectOfClause: data.objectOfClause,
            dataGroupId: data.dataGroupId,
            rollupId: data.rollupId,
            values: data.values,
            order: data.order,
            collapsed: data.collapsed,
            columnType: data.columnType
        };
    }
    
    get subjectContent() {
        const { subject } = this.props.data;
        const { deleteMode } = this.state;
        let cm  = deleteMode === 'subject' || deleteMode === 'clause' ? 'subject delete' : 'subject';

        let subjectContent = subject ? (
            <span className={cm}
                onDrop={(e)=>{this.onDrop(e, "subject")}}
                onDragOver={Utils.handleOnDragOver}
                onDragLeave={Utils.handleOnDragLeave}
            >
                {subject}
                {this.removerContent('subject')}
            </span>
        ) : (
            <span className='empty' 
                onDrop={(e)=>{this.onDrop(e, "subject")}}>
                drag column here
            </span>
        );
        return subjectContent;
    }

    get verbContent() {
        const { sql } = this.props.data;
        const { deleteMode } = this.state;
        let cn  = deleteMode === 'clause' ? 'delete' : '';
        return <span className={`verb ${cn}`} onClick={this.handleVerbClick}>{sql}</span>
    }

    get objectPreview() {
        const { objectOfClause, dataGroupId, values:json, collapsed} = this.props.data;
        const { deleteMode } = this.state;
        let cn  = deleteMode === 'object' || deleteMode === 'clause' ? 'delete' : '';

        let preview = 'none';
        let values = json ? JSON.parse(json) : [];
        if (dataGroupId) {
            return <span className={`clause-object-group ${cn}`}>{objectOfClause}</span>;
        }

        if (values && !collapsed) {
            preview = values.map((x,i)=> (
                <span key={`oi${i}`}><span className={`clause-object-item ${cn}`}>'{x}'</span>{i+1<values.length ? ',': ''}</span>
            ));
        } else if (values && collapsed){
            preview = values.length > 0 ? "'" + values.toString().substring(0, 10) + '...' : `'${values[0]}'`;
            preview = <span className={`clause-object-item ${cn}`}>{preview}</span>;
        }
        return preview;
    }

    removerContent(type) {
        const { deleteMode } = this.state;
        let blank = type === 'clause' ? <span>&nbsp;&nbsp;&nbsp;</span> : <span>&nbsp;</span>;
        return <span
            onMouseEnter={() => this.setState({deleteMode: type})}
            onMouseLeave={() => this.setState({deleteMode: null})}
        >
            {
                deleteMode === type ? <span 
                    onClick={(ev)=> {
                    ev.stopPropagation();
                    this.remove(type)}}>&nbsp;
                        <i className="fa fa-times delete" aria-hidden="true" title={`remove ${type}`}></i>
                </span> : blank
            }
        </span>;
    }

    get objectContent() {
        const { objectOfClause, collapsed} = this.props.data;
        const { deleteMode } = this.state;
        let cn  = deleteMode === 'object' || deleteMode === 'clause' ? 'clause-object delete' : 'clause-object';

        let objectContent = objectOfClause ? (
                <span className={cn} onDrop={(e)=>{this.onDrop(e, "object")}}>
                    (
                        <span className={collapsed ? 'object-expand' : 'object-collapse'} onClick={this.handleExpandClick}></span>
                        <span onClick={this.handleClick}>{this.objectPreview}</span>
                        {this.removerContent('object')}
                    )
                </span>
            ) : (
                <span className='empty' 
                    onDrop={(e)=>{this.onDrop(e, "object")}}
                    onClick={this.handleClick}>
                    click to edit
                </span>
            ) 

        return objectContent;
    }

    render() {
        const {subject, sql, values} = this.props.data;
        let o = this.myInfo;

        return (
        
            <span className='clause'
                onDragStart={(e)=> Utils.handleOnDragStart(e, o)} 
                onDragOver={Utils.handleOnDragOver}
                onDragLeave={Utils.handleOnDragLeave} 
                onDrop={this.onDrop}
                key={'clae'+66}       
                draggable
            >
                {this.subjectContent}
                {this.verbContent}
                {this.objectContent}
                {this.removerContent('clause')}
                <ClauseObjectEditor 
                    includeOtherColumns={this.props.includeOtherColumns}
                    show={this.state.showModal}
                    closeCallback={this.handleClose}
                    handleGroupEdit={this.handleGroupEdit}
                    handleValuesEdit={this.handleValuesEdit}
                    values={values}
                    subject={subject}
                    sql={sql}
                    columnType={o.columnType}
                    dataGroupId={o.dataGroupId}
                    factColumnGuid={o.factColumnGuid}
                ></ClauseObjectEditor>
            </span>
            );
    } 
}

const mapStateToProps = state => ({
    operators: state.tod.queries.operators,
  });

  const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Clause);

