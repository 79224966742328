import React, { Component } from 'react';
import radium from 'radium';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../../../shared/presentational/loader';
import * as Colors from '../../../../utilities/colors';
import * as segmentServiceApi from '@app/api/segments.serviceApi';
import { Grid, Button as AButton } from '@arius'

class ProjectInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentSegments: {},
            isFetching: false
        };
    }

    showModal = (project) => {
        const { userKey, currentWorkspace } = this.props;
        
        segmentServiceApi.retrieveSegments(userKey, currentWorkspace.id, project.projectId)
            .then(data => {
                this.setState({currentSegments:this.getSegmentDimensions(data), isFetching: false})
            });
        this.setState({showModal: true, isFetching: true});   
    }

    getSegmentDimensions(segments) {
        return segments.map(segment => {
            const dimensionValues = JSON.parse(segment.segmentSettings).Dimensions;
            const dimensionValuesParsed = dimensionValues.replace(/\\/g, '');
            const parsedUserDimValues = JSON.parse(dimensionValuesParsed).UserDefined;
            const parsedSystemDimValues = JSON.parse(dimensionValuesParsed).System;
            return { ...segment, ...parsedSystemDimValues, ...parsedUserDimValues };
        });
    }
    

    closeModal = () => {
        this.setState({ showModal: false })
    }

    getHeaders = (userDefinedDimensions, systemDimensions) => {
        let columns = [];

        systemDimensions.forEach((d, i) => {
            if (d) {
                columns.push(
                    { field: d, headerText: d, width: '200px'}
                )
            }
        });

        userDefinedDimensions.forEach((d, i) => {
            if (d) {
                columns.push(
                    { field: d, headerText: d, width: '200px'}
                )
            }
        });

        return columns;
    }

    getRow = (userDefinedDimensions, systemDimensions, segment, idx) => {

        const segmentSettings = JSON.parse(segment.segmentSettings);
        const dimensions = segmentSettings.Dimensions;
        const dimensionsParsed = dimensions.replace(/\\/g, '');
        const dimensionValues = JSON.parse(dimensionsParsed);
        const parsedUserDimValues = dimensionValues.UserDefined;
        const parsedSystemDimValues = dimensionValues.System;

        let colums = {};

        systemDimensions.forEach((d, i) => {
            if (d) {
                colums[d] = parsedSystemDimValues[d];
            }
        });

        userDefinedDimensions.forEach((d, i) => {
            if (d) {
                colums[d] = parsedUserDimValues[d];
            }
        });

        return colums;
    }

    getGrid = (segments) => {
        const {currentWorkspace, } = this.props;

        if (segments && segments.length && currentWorkspace) {
            const dimensions = JSON.parse(currentWorkspace.dimensions);
            const userDefinedDimensions = dimensions.userDefined;
            const systemDimensions = dimensions.system;

            const columns = this.getHeaders(userDefinedDimensions, systemDimensions);
            const data = segments.map((segment, idx) => this.getRow(userDefinedDimensions, systemDimensions, segment, idx));
            return <Grid data={data} columns={columns} height='calc(100vh - 265px)'/>;
        }

        return <div></div>;
    }

    render() {
        const { idx, project } = this.props;
        const { showModal, currentSegments, isFetching } = this.state;
        let bodyMarkup = (
            <div style={{ margin: '5vh auto' }}><Loader loadingItem="Segments" /></div>
        );

        if (showModal && !isFetching && currentSegments) {
            bodyMarkup = this.getGrid(currentSegments);
        }

        return (
            <div>
                <AButton key={'btn-info-' + idx} 
                    toolTip={'Segments'} 
                    placement="left" 
                    iconName="info" 
                    style={{paddingLeft:'0px'}}
                    onClick={(e) => this.showModal(project)} 
                />
            {showModal?      
                <Modal
                    id={this.props.modalId}
                    show={showModal}
                    onHide={this.closeModal}
                    dialogClassName="projectInfoModal"
                    enforceFocus
                >
                    <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title>Segments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {bodyMarkup}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-dismiss="modal"
                            onClick={this.closeModal}
                            variant='outline-secondary'
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                :('')
            }
            </div>
        );
    }
}


export default radium(ProjectInfo);