import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form, Card, Col } from 'react-bootstrap';

class QueryTopLevelForm extends Component {
  static propTypes = {
    isWritable: PropTypes.bool,
    databaseExposureDates: PropTypes.array,
    queryName: PropTypes.string,
    queryDesc: PropTypes.string,
    queryExpDate: PropTypes.string,
    getNameValidationError: PropTypes.func,
    updateQueryName: PropTypes.func,
    updateQueryDescription: PropTypes.func,
    updateExpDate: PropTypes.func,
    softErrorMode: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      queryName: '',
      queryDesc: '',
      queryExpDate: '',
      softErrorMode: true,
    };
    this.updateQueryName = this.updateQueryName.bind(this);
    this.updateQueryDescription = this.updateQueryDescription.bind(this);
    this.updateExpDate = this.updateExpDate.bind(this);
  }

  componentDidMount() {
    const { queryName, queryDesc, queryExpDate } = this.props, newState = {};
    if (queryExpDate) {
      newState.queryExpDate = queryExpDate;
    }

    if (queryName) {
      newState.queryName = queryName;
    }

    if (queryDesc) {
      newState.queryDesc = queryDesc;
    }

    if (queryExpDate || queryName || queryDesc) {
      this.setState(newState);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { queryName, queryDesc, queryExpDate, softErrorMode } = this.props,
      newState = {};
    let shouldUpdate = false;
    if (queryExpDate !== nextProps.queryExpDate) {
      newState.queryExpDate = nextProps.queryExpDate;
      shouldUpdate = true;
    }

    if (nextProps.queryName !== queryName) {
      newState.queryName = nextProps.queryName;
      shouldUpdate = true;
    }

    if (nextProps.queryDesc !== queryDesc) {
      newState.queryDesc = nextProps.queryDesc;
      shouldUpdate = true;
    }

    if (softErrorMode !== nextProps.softErrorMode) {
      newState.softErrorMode = nextProps.softErrorMode;
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      this.setState(newState);
    }
  }

  updateQueryName(queryName) {
    this.setState({ queryName });
    this.props.updateQueryName(queryName);
  }

  updateQueryDescription(queryDesc) {
    this.setState({ queryDesc });
    this.props.updateQueryDescription(queryDesc);
  }

  updateExpDate(queryExpDate) {
    this.setState({ queryExpDate });
    this.props.updateExpDate(queryExpDate);
  }

  render() {
    const {
        isWritable,
        databaseExposureDates,
        getNameValidationError,
    } = this.props;
    const { 
        softErrorMode,
        queryName,
        queryDesc,
    } = this.state;

    const nameError = getNameValidationError(softErrorMode);
    const expDateError = !this.state.queryExpDate && !softErrorMode;

    
    return (
        <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
            <Card.Body> 
                <Form.Row>
                <Form.Group as={Col} md={6}>
                        <Form.Label>Query Name:</Form.Label>
                        <Form.Control
                            type="text"
                            value={queryName}
                            id="mappingDesc"
                            placeholder="Enter Query Name"
                            onChange={e => this.updateQueryName(e.target.value)}
                            isInvalid={nameError}
                            disabled={!isWritable}
                        />
                        <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="exposureDateSelect">
                        <Form.Label>Exposure Date:</Form.Label>
                        <Form.Control
                            disabled={!isWritable}
                                as="select"
                                value={this.state.queryExpDate || 'select'}
                                placeholder="Select an Exposure Date"
                                onChange={e => {
                                    let queryExpDate = e.target.value;
                                    if (queryExpDate === 'default') {
                                    queryExpDate = null;
                                    }
                                    this.updateExpDate(queryExpDate);
                                }}
                                isInvalid={expDateError}
                            >
                                <option value="default">
                                -- Select an Exposure Date --
                                </option>
                                {databaseExposureDates.map(col => (
                                    <option
                                    value={col.factColumnDisplayName}
                                    key={`${col.factColumnGuid}`}
                                    >
                                        {col.factColumnDisplayName}
                                    </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please Select Exposure Date</Form.Control.Feedback>
                    </Form.Group>
                        <Form.Group as={Col} md={12}>
                            <Form.Label>Query Description:</Form.Label>
                            <Form.Control
                                disabled={!isWritable}
                                value={queryDesc}
                                id="mappingDesc"
                                placeholder="Enter Query Description"
                                onChange={e => this.updateQueryDescription(e.target.value)}
                                />
                        </Form.Group>
                </Form.Row>
            </Card.Body>
        </Card>
    )
  }
}

export default radium(QueryTopLevelForm);
