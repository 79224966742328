
import { SHOW_MODAL, HIDE_MODAL } from './actionTypes';


function showModal(modalType, message, yesClickHandler, noClickHandler) {
  return {
    type: SHOW_MODAL,
    modalType,
    message,
    yesClickHandler,
    noClickHandler,
  };
}

function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export {
  showModal,
  hideModal,
};
