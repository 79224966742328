import React from 'react';
import * as Colors from '../../../utilities/colors';

const AdxQueryWarning = () => (
    <div style={{ color: Colors.purple, textAlign: 'center', fontSize: 20, margin: 10 }}>
        Use Advanced Queries page to create queries for ADX databases.
    </div>
);

export default AdxQueryWarning;
