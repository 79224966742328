import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Link } from 'react-router-dom';
import {  NavTab, NavSideTabs } from '@app/utilities/routing';
import * as GlobalStyles from '@app/utilities/globalStyles';
import Feature from '@app/utilities/routing/routeSafeFeature';

class Sidebar extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { baseUrl } = this.props;
        return (
        <div className='sidebar' style={GlobalStyles.sidebar}>
            <div className="analysis-home-link" style={{marginTop: 20, padding: '0px 20px' }}>
                <Link to={"/arius"}>
                    <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ paddingRight: 2 }}
                    aria-hidden="true"
                    />
                    Analysis Home
                </Link>
            </div>
            <NavSideTabs>
                <NavTab label='Extract Tables' to={`${baseUrl}/extracts`}/>
                <NavTab label='Extract Table Lists' to={`${baseUrl}/exportlists`}/>
                <NavTab label='Support Tables' to={`${baseUrl}/support`}/>
                <NavTab label='Reporting Tables' to={`${baseUrl}/reports`}/>
                <Feature flag="powerBiReports">
                    <NavTab label='Power BI Data Models' to={`${baseUrl}/powerbi`}/>
                </Feature>
            </NavSideTabs> 
        </div>
        );
    }
}

export default radium(Sidebar);
