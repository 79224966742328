import React, { Component } from 'react';
import radium from 'radium';
import { getBaseServiceUrl } from '@app/utilities/callApi';
import * as Colors from '@app/utilities/colors';
import {
  getAvailableDownloads,
  deleteExportFile,
} from '@app/actions/analysis/download.actions';
import Loader from '@app/shared/presentational/loader';
import { RECEIVED_DOWNLOADS } from '@app/actions/analysis/analysis.actionTypes';
import { Button, Modal} from 'react-bootstrap';

import { Grid, Button as AButton } from '@arius';
import { showModal } from '@app/actions/modal.actions';
import { notifyError } from '@app/utilities/notifier';
import { WORKSPACE_DELETEPROJECT, WORKSPACE_READPROJECT, MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';

class DownloadsList extends Component {
    static propTypes = {};
    static defaultProps = {
        isFetching: false,
        downloadss: [],
        userKey: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            selectedDownloadType: 0,
            selectedDatabaseId: 0,
            displayWaitingIcon: false,
            columnSelected: '',
            isDescending: false,
        };
        this.getGrid = this.getGrid.bind(this);
        this.download = this.download.bind(this);
        this.launchModal = this.launchModal.bind(this);
        this.getNumberOfFilesTemplate = this.getNumberOfFilesTemplate.bind(this);
    }

    get fileTypeId(){
        const { selectedDownloadType, availableDownloadTypes} = this.props;
        const o = availableDownloadTypes ? availableDownloadTypes.find(x=> x.fileType === selectedDownloadType) : null;
        return o ? o.id : -1;
    }

    getNumberOfFilesTemplate(r) {
        //const { selectedDownloadType } = this.props;
        //return selectedDownloadType === 'pdf' ?
        //            <span>{r.numberOfProjectsPresent}/{r.numberOfFiles}</span> : 
        //            <span>{r.numberOfFiles}</span>;

        return <span>{r.numberOfProjectsPresent}/{r.numberOfFiles}</span>;
    }

    componentDidMount() {
        let { selectedDownloadType, changeDownloadTypeHandler, params} = this.props;

        if (params.downloadType || !selectedDownloadType){
            // this handles two cases:
            //      - the url contains params - use them rather than whatever is in state
            //      - the url does not contain params and there are none in state - use 'apj'
            changeDownloadTypeHandler(params.downloadType ? params.downloadType : 'apj');
        } else {
            changeDownloadTypeHandler(selectedDownloadType);
        }
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { currentDb, selectedDownloadType, availableDownloadTypes} = this.props;
        if (currentDb && prevProps.currentDb !== currentDb) {
            this.refresh();
            return;
        }
        if (selectedDownloadType !== prevProps.selectedDownloadType){
            this.refresh();
            return;
        }

        if (availableDownloadTypes !== prevProps.availableDownloadTypes){
            this.refresh();
            return;
        }
    }

    refresh() {
        const { dispatch, userKey, workspaceId } = this.props;
        if (workspaceId > 0 && this.fileTypeId > 0) {
            dispatch(getAvailableDownloads(userKey, this.fileTypeId, workspaceId));
        }
        else {
            dispatch({ type: RECEIVED_DOWNLOADS, data : [] });
        }
    }

    ///////////////////////////////////////////////////////////
    // from downloadItem
    
    launchModal(item) {
        const { apiKey, verifyPermission } = this.props;

        if (!verifyPermission(WORKSPACE_READPROJECT)){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }

        if (apiKey === null || apiKey === ""){
            notifyError("You must have API key assigned");
            return;
        }
        this.setState({showModal: true, item});
    }

    handleOpen = () => {
        this.setState({ displayWaitingIcon: false });
    }

    handleClose = () => {
        this.setState({ displayWaitingIcon: false, showModal: false });
    }

    download(){
        const { apiKey } = this.props;
        const { item } = this.state;
        const { downloadType, databaseID, id } = item;

        getBaseServiceUrl().then(baseUrl => {
            let url = `${baseUrl}/downloads/${downloadType}/${databaseID}/${id}?apikey=${apiKey}`;
            window.open(url,"_self");
            this.setState({showModal: false});
        });
    }

    promptToDelete = (item) => {
        const { dispatch, userKey, verifyPermission } = this.props;

        if (!verifyPermission(WORKSPACE_DELETEPROJECT)){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }

        let deleteMessageItems = [
            <li key={item.id} style={{ fontWeight: 800, listStyle: 'none' }}>
                {item.fileName}
            </li>,
        ];
    
        deleteMessageItems.unshift(
            'Are you sure you want to delete the following exported file: '
        );
        deleteMessageItems.push(
            'This action cannot be undone.'
        );

        const yesClickHandler = () => {
            dispatch(deleteExportFile(userKey, item.downloadType, item.databaseID, item.id)); 
        };
        const noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);       
    }

    // end from downloadItem
    //////////////////////////////////////////////////////////////

    getActionItems = (row) => {

        const actionButtons = (
            <div>
                <AButton key={'btn-download-' + row.id} 
                        toolTip={'Download'} 
                        placement="left" 
                        iconName="arrow_downward" 
                        onClick={(e) => this.launchModal(row)} 
                        />

                <AButton key={'btn-delete-' + row.id} 
                        toolTip={'Delete'} 
                        placement="left" 
                        iconName="delete" 
                        onClick={(e) => this.promptToDelete(row)} />
            </div>
        );

        return  (actionButtons);
    }

    getGrid(data) {
        const columns = [
            { field: 'fileName', headerText: 'File (created on YYYYMMDD-hhmmss)'},
            { field: 'createdBy', headerText: 'Created By'},
            { field: 'createdDate', headerText: 'Created'},
            { field: 'numberOfFiles', headerText: '# Projects', width: 80, 
                template: this.getNumberOfFilesTemplate},
            { field: 'fileSizeFormatted', headerText: 'Approx size', width: 100},
            { headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    render() {
        const { 
            isFetching,
            workspaces,
            workspaceId,
            availableDownloads,
            availableDownloadTypes, 
            selectedDownloadType,
            changeDownloadTypeHandler,
            changeWorkspaceHandler,
        } = this.props;
        const { displayWaitingIcon, showModal, item } = this.state;

        let alreadyFetched = true;
        let data = availableDownloads ? availableDownloads : [];
        let dbs = workspaces ? workspaces : [];
        let dts = availableDownloadTypes ? availableDownloadTypes : [];
        let toolbar = (
            <div className='row form form-inline' style={{
                paddingTop: '15px', paddingBottom: '15px',
                backgroundColor: '#EAEAEA'}}>
                <div className="form-group col-sm-6">
                    <label style={{marginRight: '15px'}} htmlFor="selectedDatabaseId">Database:  </label>
                    <select className="form-control" style={{width: '60%'}} 
                            id="selectedDatabaseId" 
                            value={workspaceId} 
                            onChange={changeWorkspaceHandler}>
                        <option value="">
                        -- Select a Database --
                        </option>
                        {dbs.map(col => (
                        <option
                            value={col.workspaceId}
                            key={`${col.workspaceName}`}
                            >
                            {col.workspaceName}
                        </option>
                        ))
                        }
                    </select>
                </div>
                <div className="form-group  col-sm-6">
                    <label style={{marginRight: '15px'}} htmlFor="selectedDatabaseId">Download Type:  </label>
                    <select style={{width: '60%'}} className="form-control"
                            id="selectedDownloadType"
                            value={selectedDownloadType}
                            onChange={changeDownloadTypeHandler}>
                        <option value="">
                        -- Select a Download Type --
                        </option>
                        {dts.map(col => (
                        <option
                            value={col.fileType}
                            key={`${col.fileType}`}
                            >
                            {col.displayName}
                        </option>
                        ))
                        }
                    </select>
                </div> 
            </div>
        );

        let headerMarkup = (
            <div className="list-header-arius">
                <h4>Downloads</h4> 
            </div>
        );

        let contentMarkup = (
            <div style={{ width: '75vw', marginTop: '5vh auto' }}>
            <Loader loadingItem="Downloads" />
            </div>
        );

        if (!isFetching && alreadyFetched) {
            contentMarkup = this.getGrid(data);
        }

        let waitControl = displayWaitingIcon? (
                    <i style={ { color: Colors.purple, marginLeft: 10 } } className="fa fa-spinner fa-spin"></i>
                    ) : (<div></div>);

        let modalButtons = item? (              
            <span>
                <Button key="confirmButton"
                    variant="arius"
                    style={{marginRight:'10px'}}
                    onClick={this.download}>Yes</Button>
                <Button key="nobutton"
                    variant="arius"
                    onClick={this.handleClose}>No</Button>
            </span>):('');

        let modalBody = item? (
            <span style={{ textAlign: 'center', fontSize: 20, margin: 10 }}>
                <div>
                    Are you sure you want to download the following exported file:
                    <li
                        key={item.id}
                        style={{ fontWeight: 800, listStyle: 'none' }}
                    >
                        {item.fileName}
                        {waitControl}
                    </li>
                </div>
            </span>):('');

        return (
            <div className="list-container-arius">
                {toolbar}
                {headerMarkup}
                {contentMarkup}
                {item && showModal ?
                    <div>
                        <Modal show={showModal} 
                            onHide={this.handleClose} 
                            onEnter={this.handleOpen} 
                            dialogClassName="download-confirmation-modal">
                            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                                <Modal.Title>Are you sure?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modalBody}</Modal.Body>
                            <Modal.Footer>{modalButtons}</Modal.Footer>
                        </Modal>
                    </div>:('')
                }
            </div>
        );
    }
}

export default radium(DownloadsList);
