export const MEMBER_TYPE_ID = {
    user: 1,
    group: 2,
  },
  SERVER_NOTIFICATION_MESSAGES = {
    CREATING_DB: 'Creating AE database',
    DB_SHELL_CREATED: 'Database shell created...',
    CREATING_DB_INTERNAL: 'Creating database internal structure...',
    DB_COMPLETE: 'Database creation complete.',
    AE_DB_BACKUP_IN_PROGRESS: 'Copy in progress...',
    AE_DB_BACKUP_FINALIZING: 'Finalizing backup...',
    AE_DB_BACKUP_COMPLETE: 'Backup complete...',
    AE_DB_ARCHIVE_IN_PROGRESS: 'Archive in progress...',
    AE_DB_ARCHIVE_FINALIZING: 'Finalizing archive...',
    AE_DB_ARCHIVE_COMPLETE: 'Archive complete...',
    AE_DB_ARCHIVE_RESTORE_PACKAGE_RETRIEVED: 'Archive package retrieved...',
    AE_DB_ARCHIVE_RESTORE_IN_PROGRESS: 'Restore of archive in progress...',
    TOD_DB_BACKUP_COMPLETE: 'Backup complete and has been added to the Restore tab.',
    TOD_DB_LOAD_IN_PROGRESS: 'Data load in progress...',
    TOD_DB_LOAD_FINALIZING: 'Finalizing data load...',
    TOD_DB_LOAD_COMPLETE: 'Data load complete.',
    TOD_DB_LOAD_CANCEL_COMPLETE: 'Cleanup after cancellation complete.',
    TOD_DB_LOAD_CANCELLED: 'Data load cancelled.',
    TOD_DB_LOAD_IMPORT_COMPLETE: 'Import complete',
    TOD_DB_LOAD_CREATING_DB_COPY: 'Creating database copy',
    TOD_DB_LOAD_RECORDS_PROCESSING: 'Records processing',
  },
  SERVER_NOTIFICATION_SUBJECTS = {
    CREATING_DB: 'AEDatabaseCreate',
    TOD_DATABASE_CREATE: 'TodDatabaseCreate',
    AE_DB_BACKUP: 'AEDatabaseBackup',
    AE_DB_RESTORE: 'AEDatabaseBackupRestore',
    AE_DB_PIT_RESTORE: 'AEDatabasePointInTimeRestore',
    AE_DB_ARCHIVE: 'AEBackupArchive',
    AE_DB_ARCHIVE_RESTORE: 'AEBackupArchiveRestore',
    TOD_DB_RESTORE: 'TODDatabaseRestoreBackup',
    TOD_DB_PIT_RESTORE: 'TODDatabasePointInTimeRestore',
    TOD_DB_ARCHIVE: 'TODBackupArchive',
    TOD_DB_ARCHIVE_RESTORE: 'TODBackupArchiveRestore',
    TOD_DATABASE_LOAD: 'TODDatabaseLoad',
    TOD_DB_BACKUP: 'TODDatabaseBackup',
    DB_PITR_UNDO: 'UndoDatabasePointInTimeRestore',
  },
  TOD_DB_COLUMN_TYPES = [
    {
      id: 1,
      label: 'Dimension',
      value: 'dimension',
      isDate: false,
    },
    {
      id: 2,
      label: 'Measure',
      value: 'measure',
      isDate: false,
    },
    {
      id: 3,
      label: 'Exposure Date',
      value: 'dateY',
      isDate: true,
    },
    {
      id: 4,
      label: 'Development Date',
      value: 'dateX',
      isDate: true,
    },
    {
      id: 5,
      label: 'Calculated Measure',
      value: 'calc_measure',
      isDate: false,
    },
    {
      id: 6,
      label: 'Other',
      value: 'other',
      isDate: false,
    },
    {
      id: 7,
      label: 'Valuation Date',
      value: 'dateVal',
      isDate: false,
    },
  ],
  COMMAND_IDS = {
    APPEND_NEW_EVALUATION_PERIOD: 1,
    LOAD_DATA_FROM_TOD: 2,
    APPEND_OLDEST_EXPOSURE_PERIOD: 3,
    REMOVE_LAST_EVALUATION_PERIOD: 4,
    REMOVE_OLDEST_EXPOSURE_PERIOD: 5,
    MODIFY_DATE_PARAMETERS: 6,
    SAVE: 7,
    COMPRESS_COLUMNS: 9,
    COMPRESS_ROWS: 10,
    APPEND_ALL_PRIOR_ROW: 11,
    REMOVE_ALL_PRIOR_ROW: 12,
    APPLY_SDF_DEFAULTS: 13,
    APPLY_DATA_DEFAULTS: 14,
    SAVE_AS_COPY: 15,
    CLEAR_MANUAL_SELECTIONS: 16,
    CSV_IMPORT: 17,
    SAVE_CURRENT_SDFS: 18,
    MODIFY_PROJECT_STATUS: 19,
    LOAD_COLLECTION_SET: 20,
    LOAD_EXHIBIT_OPTIONS: 23,
    LOAD_DATA_DEFAULTS: 24,
    LOAD_EXHIBIT_DEFAULTS: 25
  },
  COMMAND_STATUSES = {
    NOT_EXECUTED: 'NotExecuted',
    FAILED: 'Failed',
    EXECUTING: 'Executing',
    COMPLETED: 'CompletedSuccessfully',
    CANCELLED: 'Cancelled',
  },
  APPLICATION_NAMES = {
    PORTAL_MANAGEMENT: 'Portal Management',
    ARIUS_ENTERPRISE: 'Arius Analysis Tool',
    TOD: 'Triangles on Demand',
    ADVANCED_ANALYTICS: 'Advanced Analytics'
  },
  FORBIDDEN_COLUMN_NAMES = [      
    "Id",
    "DatabaseName",
    "ExtractTableName",
    "ProjectID",
    "ProjectVersion",
    "ProjectName",
    "ProjectLastModifiedByDate",
    "ProjectLastModifiedBy",
    "ProjectStatus",
    "ValuationDate",
    "DataStructure",
    "Mode",
    "ExposureDate",
    "CalendarDate",
    "DevelopmentAge",
    "ExtractedDate",
    "ExtractedBy",
    "Segment",
    "TableID",
    "ObjectName",
    "Source",
    "Shape",
    "Value"
].map(x=> x.toLocaleLowerCase()),
TAG_TOD_CREATE_DB='TOD CREATE DB',
TAG_AE_CREATE_DB='AE CREATE DB';