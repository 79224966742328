import {
    FETCHING_PERFORMANCE_INFO,
    RECEIVED_PERFORMANCE_INFO,
    FETCHING_PERFORMANCE_OPTIONS,
    RECEIVED_PERFORMANCE_DATABASE_OPTIONS,
    RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS,
    RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS,
    FETCHING_PERFORMANCE_JOB_STATUS,
    RECEIVED_PERFORMANCE_JOB_STATUS,
    SENT_PERFORMANCE_COMMAND,
    RECEIVED_PERFORMANCE_POWERBI_OPTIONS,
} from '../actions/utils/performance.actionTypes';
import performanceReducer from './utils/performance.reducer';

const utils = (
    state = {
        performance:{
            performanceInfo: null,
            fetchingPerformanceInfo: false,
        },
    },
    action,
    ) => {
    switch (action.type) {
        case FETCHING_PERFORMANCE_INFO:
        case RECEIVED_PERFORMANCE_INFO:
        case FETCHING_PERFORMANCE_OPTIONS:
        case RECEIVED_PERFORMANCE_DATABASE_OPTIONS:
        case RECEIVED_PERFORMANCE_ELASTICPOOL_OPTIONS:
        case RECEIVED_PERFORMANCE_APPSERVICEPLAN_OPTIONS:
        case FETCHING_PERFORMANCE_JOB_STATUS:
        case RECEIVED_PERFORMANCE_JOB_STATUS:
        case SENT_PERFORMANCE_COMMAND:
        case RECEIVED_PERFORMANCE_POWERBI_OPTIONS:
        return Object.assign({}, state, {
            performance: performanceReducer(state.performance, action),
        });
        default:
        return state;
    }
};

export default utils;
