import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ProgressBar } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import { SERVER_NOTIFICATION_MESSAGES, SERVER_NOTIFICATION_SUBJECTS } from '../../../utilities/constants';

class WorkspaceCreateModal extends Component {
  static propTypes = {
    systemDimensions: PropTypes.arrayOf(PropTypes.string),
    saveHandler: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    clearNotificationHandler: PropTypes.func.isRequired,
    serverNotification: PropTypes.object,
    showModal: PropTypes.bool,
  };
  static defaultProps = { systemDimensions: [], saveHandler: () => {} };
  constructor(props) {
    super(props);
    this.state = {
      workspaceName: '',
      workspaceDesc: '',
      userDefinedDims: '',
      isCreating: false,
    };
    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.descChangeHandler = this.descChangeHandler.bind(this);
    this.dimsChangeHandler = this.dimsChangeHandler.bind(this);
    this.clearNotificationsAndClose = this.clearNotificationsAndClose.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const { serverNotification } = this.props;
    if (serverNotification && serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.CREATING_DB && serverNotification.message
      !== SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE) {
      this.setState({ isCreating: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { serverNotification } = this.props;
    if (prevProps.serverNotification && prevProps.serverNotification.message !== SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE &&
        serverNotification && serverNotification.message === SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE) {
      this.setState({
        workspaceName: '',
        workspaceDesc: '',
        userDefinedDims: '',
        isCreating: false,
      });
    }
  }

  nameChangeHandler(e) {
    this.setState({ workspaceName: e.target.value });
  }

  descChangeHandler(e) {
    this.setState({ workspaceDesc: e.target.value });
  }

  dimsChangeHandler(e) {
    this.setState({ userDefinedDims: e.target.value });
  }

  saveHandler() {
    this.setState({ isCreating: true });
    this.props.saveHandler({
      workspaceName: this.state.workspaceName,
      workspaceDesc: this.state.workspaceDesc,
      userDefinedDims: this.state.userDefinedDims,
      userKey: this.props.userKey,
    });
  }

  close() {
    const { serverNotification } = this.props;
    if (!this.state.isCreating && serverNotification.message) {
      this.props.clearNotificationHandler();
    }
    this.props.closeHandler();
  }

  clearNotificationsAndClose() {
    this.props.clearNotificationHandler();
    this.props.closeHandler();
  }

  open() {
    this.setState({ showModal: true });
  }

  render() {
    const { serverNotification } = this.props;
    const { isCreating } = this.state;

    let bodyMarkup = (
      <div>
        <div className="row form-group">
            <div className="col-md-3" style={{ textAlign: 'right', fontWeight: 800 }}>Name:</div>
            <div className="col-md-9">
                <input
                  className="form-control"
                  type="text"
                  id="workspaceName"
                  placeholder="Name"
                  value={this.state.workspaceName}
                  onChange={this.nameChangeHandler}
                />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-md-3" style={{ textAlign: 'right', fontWeight: 800 }}>Description:</div>
            <div className="col-md-9">
                <input
                  className="form-control"
                  type="text"
                  id="workspaceDesc"
                  placeholder="Description"
                  value={this.state.workspaceDesc}
                  onChange={this.descChangeHandler}
                />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-md-3" style={{ textAlign: 'right', fontWeight: 800 }}>Dimensions (System):</div>
            <div className="col-md-9">
                Segment
            </div>
        </div>
        <div className="row form-group">
            <div className="col-md-3" style={{ textAlign: 'right', fontWeight: 800 }}>Dimensions (User):</div>
            <div className="col-md-9">
                <input
                  className="form-control"
                  type="text"
                  id="userDefinedDims"
                  placeholder="Dimensions"
                  value={this.state.userDefinedDims}
                  onChange={this.dimsChangeHandler}
                />
              <h6>(Can be blank, but names entered must be separated by commas)</h6>
            </div>
        </div>
        <Modal.Footer>
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.close}>Close</button>
          <button type="button" className="btn btn-primary" onClick={this.saveHandler}>Save</button>
        </Modal.Footer>
      </div>
    );

    if (isCreating) {
      bodyMarkup = (
        <div>
          Please wait a moment while we prepare to create your database.
        </div>
      );
    }

    if (serverNotification.subject && serverNotification.subject === SERVER_NOTIFICATION_SUBJECTS.CREATING_DB) {
      let progress = (
        <div>
          <ProgressBar active now={100} />
        </div>
      );

      if (serverNotification.message && serverNotification.message === SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE) {
        progress = (
          <div>
            <div><i className="fa fa-check" style={{ display: 'flex', justifyContent: 'center', fontSize: 50, color: 'green' }}></i></div>
            <Modal.Footer>
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.close}>Dismiss</button>
            </Modal.Footer>
          </div>
        );
      }

      bodyMarkup = (
        <div style={{ margin: 10 }}>
          <div className="row" style={{ textAlign: 'center' }}>
            {serverNotification.message}
          </div>
          <div className="row">
            {progress}
          </div>
        </div>
      );
    }

    return (
      <Modal id={this.props.modalId} show={this.props.showModal} onHide={this.close} enforceFocus>
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
          <button type="button"
            onClick={this.close}
            className="close"
            data-dismiss="modal" aria-label="Close"
            style={{ color: '#FFFFFF' }}>
            <span aria-hidden="true">&times;</span>
          </button>
          <Modal.Title>New Database</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyMarkup}
        </Modal.Body>
      </Modal>
    );
  }
}

export default WorkspaceCreateModal;
