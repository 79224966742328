import { callApi } from '../utilities/callApi';

export function getAvailableDownloadTypes(userKey) {
  return callApi(
    `downloads/fileTypes`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function getDatabasesWithAvailableDownloads(userKey) {
  return callApi(
    `downloads/databases`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function getAvailableDownloads(userKey, downloadType, databaseId) {
  return callApi(
    `downloads/${downloadType}/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function deleteExportFile(userKey, downloadType, databaseId, downloadId) {
  return callApi(
    `downloads/${downloadType}/${databaseId}/${downloadId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}
