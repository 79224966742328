function translateStepName(step, stepName) {
  return stepName;
}

const ErrorCodes = {
  unavailable: () => 'Command is not allowed for the current state of the project',
  literal: (p) => p.errorMessage,
};

function translateError(params) {
  if (!params) {
    return '';
  }
  if (typeof params === 'string') {
    return params;
  }
  if (params.error) {
    return params.error;
  }
  if (params.errorCode) {
    try {
      return ErrorCodes[params.errorCode.toLowerCase()](params);
    } catch (e) {
      return params.errorCode;
    }
  }
  return '';
}

function formatFailedMessage(params) {
  let msg = 'Failed';
  if (params.stepName) {
    msg += ` at ${translateStepName(params.step, params.stepName)}`;
  }
  const errMsg = translateError(params);
  if (errMsg) {
    msg += `: ${errMsg}`;
  }
  return msg;
}

const LongRunningOperationsCodeDictionary = {
  jobs: {
    AddEvaluation: 'Add New Evaluation',
    UpdateProject: 'Project Recalculation',
  },
  operations: {
    AddEvaluation: 'Add New Evaluation for Project ID',
    UpdateProject: 'Project Recalc for Project ID',
  },
  statuses: {
    1: 'Pending',
    2: 'Executing',
    3: 'Completed',
    4: 'Failed',
    5: 'Cancelled',
    6: 'Cancelling',
  },
  info: {
    Pending: () => 'Pending',
    Exception: params => (params ? params.message : 'Exception'),
    Failed: params => (params ? formatFailedMessage(params) : 'Failed'),
  },
};

export default LongRunningOperationsCodeDictionary;
