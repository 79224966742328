import LongRunningOperationsCodeDictionary
  from '../longRunningOperationsCodeDictionary';

function getOperationDescription(code, params = {}, isHeader = false) {
  let description;

  if (!isHeader) {
    const operationParamsValue = params ? params.projectId : '';
    const foundOperation = LongRunningOperationsCodeDictionary.operations[code];
    description = foundOperation
      ? `${foundOperation} ${operationParamsValue || ''}`
      : code;
  } else {
    description = LongRunningOperationsCodeDictionary.jobs[code] || code;
  }
  return description;
}

function getStatusDescription(code) {
  const foundStatus = LongRunningOperationsCodeDictionary.statuses[code];
  return foundStatus || code;
}

function getInfoDescription(code, params) {
  const foundInfo = LongRunningOperationsCodeDictionary.info[code];
  if (foundInfo) {
    return foundInfo(params);
  }
  return code;
}

export { getOperationDescription, getStatusDescription, getInfoDescription };
