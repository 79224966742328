import React, { Component } from 'react';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { connect } from '@app/utilities/routing';
import { Route, Switch } from 'react-router-dom';
import { fetchApiKey } from '@app/actions/settings.actions';
import { createPermissionChecker } from '@app/utilities/permissions';

import { fetchWorkspaces, fetchWorkspace } from '@app/actions/analysis/workspace.actions';
import { getAvailableDownloadTypes } from '@app/actions/analysis/download.actions';
import { DOWNLOAD_CHANGE_TYPE } from '@app/actions/analysis/analysis.actionTypes';
import DownloadsList from '@app/analysis/downloads/downloadsList';

class DownloadsTab extends Component {
    static propTypes = {  };
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.changeDownloadTypeHandler = this.changeDownloadTypeHandler.bind(this);
    }
    get baseUrl() {
        const { match } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        return `/arius/downloads${databaseId ? `/${databaseId}` : ''}`;
    }

    get newUrlFromCurrentDb() {
        const { currentDb, selectedDownloadType } = this.props;
        return `/arius/downloads/${currentDb.id}/${selectedDownloadType ? selectedDownloadType : "apj"}`;  // default to 'projects' in LHM
    }

    componentDidMount() {
        const { 
            currentDb,
            getDatabases,
            getFileTypes,
            userKey,
            match,
            history,
            dispatch
        } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
        }
        getDatabases(userKey);
        getFileTypes(userKey);
        dispatch(fetchApiKey(userKey));
    }

    componentDidUpdate(prevProps) {
        const {
            currentDb,
            userKey,
            getDatabase,
            match,
            isFetchingDatabases,
            isFetchingDatabase,
            history
        } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        
        if (isFetchingDatabases) {
            return;
        }

        if (currentDb && !databaseId){
            history.push(this.newUrlFromCurrentDb);
            return;
        }

        if (databaseId && !isFetchingDatabase) {
            if ((currentDb && currentDb.id !== databaseId) || !currentDb) {
                getDatabase(userKey, databaseId)
            }
        }
    }

    getPermissionChecker() {
        const { currentDb, userPermissions, } = this.props;
        return createPermissionChecker(
            currentDb ? [...userPermissions, ...currentDb.permissions] : userPermissions);
    }

    changeWorkspaceHandler(e) {
        const { history, location, currentDb, selectedDownloadType, match} = this.props;
        let id = e && e.target && e.target.value ? e.target.value : e;
        const workspaceId = parseInt(id,10);
        if (currentDb && currentDb.id === workspaceId) { return; }
        let newUrl = location.pathname.replace(this.baseUrl, `/arius/downloads/${workspaceId}`);
        if (!match.params.downloadType){
            newUrl += `/${selectedDownloadType}`;
        }
        history.push(newUrl);
    }

    changeDownloadTypeHandler(e) {
        const { dispatch, currentDb, history } = this.props,
            fileType = e && e.target && e.target.value ? e.target.value : e;
        
        dispatch({ type: DOWNLOAD_CHANGE_TYPE, downloadType : fileType });
        if (currentDb){
            history.push(`/arius/downloads/${currentDb.id}/${fileType}`);
        }
    }

    render() {
        const {
            apiKey,
            currentDb,
            workspaces,
            match,
            isFetching,
            userKey,
            dispatch,
            availableDownloadTypes,
            availableDownloads,
            selectedDownloadType,
        } = this.props;
        let { params } = match;
        const workspaceId = parseInt(params.databaseId,10);

        let childProps = {
            apiKey,
            workspaces,
            workspaceId: workspaceId ? workspaceId: -1,
            currentDb,
            isFetching,
            changeDownloadTypeHandler: this.changeDownloadTypeHandler,
            changeWorkspaceHandler: this.changeWorkspaceHandler,
            userKey,
            params,
            dispatch,
            availableDownloadTypes,
            availableDownloads,
            selectedDownloadType,
            verifyPermission: this.getPermissionChecker(),
        };

        return (
        <Feature flag="ariusAnalysis">
            <Switch>
                <Route path="/arius/downloads/:databaseId/:downloadType" render={props => (<DownloadsList {...childProps}/>)}/>
                <Route path="/arius/downloads" render={props => (<DownloadsList {...childProps}/>)}/>
            </Switch>  
        </Feature>
        );
    }
}


const mapStateToProps = state => ({
    currentDb: state.analysis.workspaces.current,
    workspaces: state.analysis.workspaces.items,
    isFetching: state.analysis.projects.isFetching,
    isFetchingDatabases: state.analysis.workspaces.isFetching,
    isFetchingDatabase: state.analysis.workspaces.isFetchingWorkspace,
    userKey: state.user.userKey,
    availableDownloadTypes: state.analysis.downloads.availableDownloadTypes,
    availableDownloads: state.analysis.downloads.availableDownloads,
    selectedDownloadType: state.analysis.downloads.selectedDownloadType,
    apiKey: state.settingsMenu.apiKey,
    userPermissions: state.user.userPermissions,
});

const mapDispatchToProps = dispatch => ({
    getDatabase: (userKey, databaseId) => {
        dispatch(fetchWorkspace(userKey, databaseId));
    },
    getDatabases: userKey => {
        dispatch(fetchWorkspaces(userKey));
    },
    getFileTypes: userKey => {
        dispatch(getAvailableDownloadTypes(userKey));
    },
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsTab);
