import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import {
    fetchRollups
} from '@app/actions/tod/advancedQueries.actions';
import * as Colors from '@app/utilities/colors';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { notifyError } from '../../../../../utilities/notifier';

import * as GlobalStyles from '@app/utilities/globalStyles';

class RollupPickerModal extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);
        this.state = { 
            rollupId: null
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.launchModal = this.launchModal.bind(this);
        this.handleRollupChange = this.handleRollupChange.bind(this);
        this.importRollup = this.importRollup.bind(this);
    }

    handleOpen(){
        const {currentDb, userKey, dispatch}=this.props;
        this.setState({rollupId: null});
        dispatch(fetchRollups(userKey,  currentDb.id));
    }

    handleClose(){
        this.setState({show: false, rollupId: null});
    }

    handleRollupChange(e){
        this.setState({rollupId: e.target.value});
    }

    importRollup(){
        const {rollupId} = this.state;
        const {handleChange, rollups} = this.props;
        let data = rollups ? rollups : [];
        let rollup = data.find(x => x.rollupId.toString() === rollupId);

        handleChange(rollup.values.join());
        this.handleClose();
    }

    launchModal(){
        const {columnName } = this.props;
        let disabled = !columnName;
        if (disabled) {notifyError('please select a dimension');return;}
        this.setState({show: true});
    }

    componentDidUpdate() {
    }
  
    render() {

        const {rollups, columnName } = this.props;
        const {show, rollupId:ri} = this.state;
        let rollupId = ri ? ri : '';
        let disabled = !columnName;
        let data = rollups ? rollups.filter(x => x.columnName === columnName) : [];  // TODO filter to specific columnName

        let body = (
            <div className="row" style={{}}>
                <div className="col-sm-12" style={{}}>
                    <div className="form-group">
                        <label htmlFor="group">Dimension:</label>
                        <select className="form-control" id="rollupId" key="rollupId" 
                            value={rollupId} onChange={this.handleRollupChange} >
                            <option value="">-- choose a rollup --</option>
                            {data.map((o, idx) => (
                                <option key={o.rollupId} value={o.rollupId}>{o.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
            </div>
        );

        let buttons = 
            <span>
                <button key="importbutton"
                    className="btn btn-default dialog-button"
                    disabled = {!this.state.rollupId}
                    onClick={this.importRollup}>
                    Import
                </button>
                <button key="nobutton"
                    className="btn btn-default dialog-button"
                    style={{marginLeft:'5px'}}
                    onClick={this.handleClose}>
                    Cancel
                </button>
            </span>;

        let tooltip = disabled ? 'Please select a dimension' : 'Import values from an existing rollup definition';
        return (
            <span className="">
                 <OverlayTrigger
                    placement="left"
                    focus
                    overlay={<Tooltip id='tootipId_rolluppicker' style={GlobalStyles.toolTipFix}>{tooltip}</Tooltip>}
                    >
                    <label className="btn btn-outline-secondary btn-file" 
                        onClick={this.launchModal}
                        style={{marginBottom: 5, marginRight: 10, cursor: disabled ? 'not-allowed' : 'pointer'}}>
                         Import From Rollup
                    </label>
                </OverlayTrigger> 
                <Modal show={show} 
                    onHide={this.handleClose} onEnter={this.handleOpen} 
                    dialogClassName="wait-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title>Please choose a rollup for {columnName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{buttons}</Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }

    const mapStateToProps = state => ({
        userKey: state.user.userKey,
        rollups: state.tod.queries.rollups,
        currentDb: state.tod.databases.current,
      });
      
    const mapDispatchToProps = dispatch => ({
        dispatch,
    });
    
    export default radium(connect(mapStateToProps, mapDispatchToProps)(RollupPickerModal));


