import React from 'react';
import PropTypes from 'prop-types';
import * as Colors from '../../../utilities/colors';

const PlaceHolderMessage = ({ size, message }) => (
  <div style={{ color: Colors.purple, textAlign: 'center', fontSize: size || 20, margin: 10 }}>
    {message}
  </div>
);

PlaceHolderMessage.propTypes = {
  size: PropTypes.number,
  message: PropTypes.string,
};

export default PlaceHolderMessage;
