import React, { Component } from 'react';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { Modal, Button, Form } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

class ModalRunModel extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            selections: []
        };
        this.selectParam = this.selectParam.bind(this);
        this.open = this.open.bind(this);
        this.run = this.run.bind(this);
        this.close = this.close.bind(this);
        this.getInputControls = this.getInputControls.bind(this);
    }

    get hasErrors(){
        const { params } = this.props;
        const { selections } = this.state;
        let valid = true;
        params.map((p,i) => {
            if (selections.length <= i || !selections[i]){
                valid = false;
            }
            return null;
        })
        return !valid;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    selectParam(e, i) {
        let { selections } = this.state;
        selections[i] = e.target.value;
        this.setState({selections});
    }

    close() {
        this.setState({selectedFile: {}, errors: {}});
        this.props.closeHandler();
    }

    open() {
        const { userKey, getParams, selectedModel } = this.props;
        getParams(userKey, selectedModel.modelId);
        this.setState({ showModal: true, record: this.props.record });
    }

    run() {
        const { requestModelRun, userKey, params, selectedModel } = this.props;
        const { selections } = this.state;
        if (this.hasErrors){
            return;
        }
        requestModelRun(userKey, {
            modelId: selectedModel.modelId, 
            inputParams: params.map((p, i)=> {return {...p, value: selections[i]}})
        });
        this.close();
    }

    getInputControls(){
        const { params } = this.props;
        const { selections } = this.state;
        return params.map((p,i) => {
            return <Form.Group controlId={`select${p.name}`} key={`select${p.name}`}>
                <Form.Label>{p.name}</Form.Label>
                <Form.Control as="select"
                    value = {selections[i]}
                    onChange={(e) => this.selectParam(e,i)}
                >
                    <option value="select">-- Select --</option>
                    {p.values.map(u => (
                        <option key={`${p.name}${u}`} value={u}>{u}</option>
                    ))}
                </Form.Control>
            </Form.Group>
        })
    }

    render() {
        const { show, selectedModel } = this.props;

    return (
        <Modal id={'modalLoadFile'} show={show} dialogClassName="wait-modal" size="sm"
                onHide={this.close} onShow={this.open} backdrop='static'>
            <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
                Run {selectedModel ? selectedModel.name : ''}
            </Modal.Header>
            <Modal.Body className="modal-container">
                {this.getInputControls()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="arius" data-dismiss="modal" onClick={this.run} disabled={this.hasErrors}>Run Model</Button>
                <Button variant="arius" data-dismiss="modal" onClick={this.close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    columns: state.analysis.uploads.columns,
    uploads: state.analysis.uploads.items,
    formats: state.tod.databases.dateFormats,
    delimiters: state.tod.databases.dateFormatDelimiters,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(radium(ModalRunModel));
