import * as Colors from './colors';
export const boxShadow = {
	boxShadow: 'rgba(0, 0, 0, 0.2) 5px 7px 10px -5px, rgba(0, 0, 0, 0.137255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.117647) 0px -1px 0px -2px',
	},
	card = {
		boxShadow,
		// boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24)',
		// transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
		backgroundColor: Colors.formGrey,
		margin: 20,
		padding: 10,
	},
	button = {
		color: Colors.darkGrey,
		borderRadius: '5px',
		marginRight: '5vw',
    cursor: 'pointer',
    backgroundColor: '#DDDDDD',
		':hover': {
			backgroundColor: Colors.purple,
			color: 'white',
			transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
	},
	sidebar = {
        borderRight: '1px solid #ddd',
        width: '170px',
	},
	cellPositioning = {
		margin: 0,
		verticalAlign: 'bottom',
		height: 'initial',
		fontSize: 12,
		paddingTop: 2,
		paddingRight: 2,
		paddingBottom: 2,
		paddingLeft: 8,
	},
	fontSizes = {
		tableHeader: '12px',
		tableCells: '11px',
	},
	menuIconHover = {  // TODO REMOVE THIS (use the css class)
		cursor: 'pointer',
		':hover': {
			color: Colors.purple,
			transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
		},
	},
	menuIcon = {  // TODO REMOVE THIS (use the css class)
		fontSize: '20px',
		padding: '5px',
		color: Colors.darkGrey,
		cursor: 'pointer',
	},
	menuIconDisabled = {// TODO REMOVE THIS (use the css class)
		cursor: 'not-allowed',
	},
	tableCellContent = {
        height: 'initial',
        fontSize: 11,
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingLeft: 8,
    },
    panelCell = {
        padding: '0px',
        border: '0px',
    },
    panelCustom = {
        borderRadius: '0px',
        backgroundColor: 'white',
        margin: '0px',
        border: '0px',
        // width: '60vw',
        padding: '0px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    },
    dragHover = {
        // border: '2px purple dashed'
    },
    toolTipFix = { // TODO REMOVE THIS (use the css class)
        pointerEvents: 'none' // aims to fix blinking tooltip overlay in latest bootstrap 
    },
    addIcon = { // TODO REMOVE THIS (use the css class)
        color: Colors.darkGrey,
        borderRadius: '5px',
        marginRight: '5vw',
        cursor: 'pointer',
        fontSize: '4.5vmin',
        ':hover': {
          backgroundColor: Colors.purple,
          color: 'white',
          transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
        },
      },
      tableRow = {
        display: 'table',
        width: '75vw',
        tableLayout: 'fixed',
      },
      tableBody = {
        display: 'block',
        maxHeight: '70vh',
        overflow: 'auto',
      },
      headerCell = {
        cursor: 'pointer',
      },
      deleteIcon= {
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: 14,
        padding: 5,
        ':hover': {
          backgroundColor: Colors.purple,
          color: 'white',
          transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
        },
      },
buttonContainer={
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};
