export const 
  SITE_CREATETODDATABASE = 'Site.CreateTodDatabase',
  SITE_CREATEDATABASE = 'Site.CreateDatabase',
  SITE_MANAGESECURITY = 'Site.ManageSecurity',
  SITE_MANAGEROLES = 'Site.ManageRoles',
  SITE_MANAGEPERFORMANCE = 'Site.ManagePerformance',
  SITE_MANAGEUSERS = 'Site.ManageUsers',
  DATA_READ = 'Data.Read',
  DATA_MODIFY = 'Data.Modify',
  DATA_BACKUP = 'Data.Backup',
  DATA_MANAGESECURITY = 'Data.ManageSecurity',
  DATA_MANAGEQUERIES = 'Data.ManageQueries',
  DATA_DELETETODDATABASE = 'Data.DeleteTodDatabase',
  WORKSPACE_DELETEDATABASE = 'Workspace.DeleteDatabase',
  WORKSPACE_READPROJECT = 'Workspace.ReadProject',
  WORKSPACE_MANAGESECURITY = 'Workspace.ManageSecurity',
  WORKSPACE_MODIFYTABLELIBRARY = 'Workspace.ModifyTableLibrary',
  WORKSPACE_BACKUP = 'Workspace.Backup',
  WORKSPACE_CREATEPROJECT = 'Workspace.CreateProject',
  WORKSPACE_DELETEPROJECT = 'Workspace.DeleteProject',
  WORKSPACE_MANAGEJOBS = 'Workspace.ManageJobs',
  WORKSPACE_MODIFYPROJECT = 'Workspace.ModifyProject',
  WORKSPACE_LOCKUDO = 'Workspace.LockTableLibrary',
  WORKSPACE_UNLOCKUDO = 'Workspace.UnlockTableLibrary',
  WORKSPACE_MANAGEREVIEWLEVELS = 'Workspace.ManageReviewLevels',
  WORKSPACE_UNLOCKPROJECT = 'Workspace.UnlockProject',
  WORKSPACE_CREATEEDITEXTRACTTABLES = 'Workspace.CreateEditExtractTables',
  WORKSPACE_UPDATEEXTRACTTABLES = 'Workspace.UpdateExtractTables',
  WORKSPACE_DELETEEXTRACTTABLES = 'Workspace.DeleteExtractTables',
  WORKSPACE_DOWNLOADEXTRACTTABLES = 'Workspace.DownloadExtractTables',
  WORKSPACE_DIRECTIMPORT = 'Workspace.DirectImport',
  WORKSPACE_MANAGEMAPPERS = 'Workspace.ManageMappers',
  ADVANCED_ANALYTICS_UTILIZE = 'AdvancedAnalytics.Utilize',

  // ERROR MESSAGES
  MESSAGE_NOT_AUTHORIZED_TO_ACCESS = 'You are not authorized to access this functionality';
  

export function createPermissionChecker(available) {
  const cloned = available ? available.slice() : [];
  return (requested) => {
      //console.error('Checking ', requested, ': ', cloned);
      return cloned.indexOf(requested) !== -1;
  }
}

export function createPermissionChecker2(...arrays) {
  const permissions = Array.from(new Set([].concat(...arrays)));
  return requested => permissions.indexOf(requested) !== -1;
}
