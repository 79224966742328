import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import FeatureRoute from '@app/utilities/routing/featureRoute';
import App from '@app/components/app';
import Home from '@app/shared/containers/home';
import ProcessLogin from '@app/components/processLogin';
import ProcessLogoff from '@app/components/processLogoff';
import Unauthorized from '@app/components/unauthorized';
import PrivateRoute from '@app/utilities/routing/privateRoute';
import AriusAnalysisNav from '@app/analysis/ariusAnalysisNav';
import AdvancedAnalyticsNav from '@app/analytics/advancedAnalyticsNav';
import TodNav from '@app/tod/todNav';
import PortalManagementNav from '@app/portalManagement/portalManagementNav';

import AriusAnalysisDashboard from '@app/components/analysis/containers/ariusAnalysisDashboard';
import Workspaces from '@app/components/analysis/containers/workspaces';
import AEUploadsTab from '@app/components/analysis/containers/uploadsTab';
import AnalysisTab from '@app/components/analysis/containers/analysisTab';
import AutomationTab from '@app/components/analysis/containers/automationTab';
import ReportingTab from '@app/components/analysis/containers/reportingTab';
import DownloadsTab from '@app/components/analysis/containers/downloadsTab';

import UtilitiesNav from '@app/components/utils/presentational/utilitiesNav';
import UtilitiesDashboard from '@app/components/utils/containers/utilitiesDashboard';
import PerformanceLevelsTab from '@app/components/utils/containers/performanceLevelsTab';
import PowerBITab from '@app/components/analysis/containers/powerBITab';
import AuditLogsTab from '@app/components/utils/containers/auditlogsTab';

import TodDatabasesTab from '@app/components/tod/containers/todDatabasesTab';
import DbMapper from '@app/components/tod/containers/dbMapper';
import UploadsTab from '@app/components/tod/containers/uploadsTab';

import DbLoaderContainer from '@app/components/tod/containers/dbLoaderContainer';
import TotalsTab from '@app/components/tod/containers/totalsTab';

import QueriesTab from '@app/components/tod/containers/queriesTab';
import AdvancedQueriesTab from '@app/tod/advancedQueries/advancedQueriesTab';

import Users from '@app/components/portalManagement/containers/users';


import PortalManagementDashboard from '@app/components/portalManagement/containers/portalManagementDashboard';
import Roles from '@app/components/portalManagement/containers/roles';

import TodDashboard from '@app/components/tod/containers/todDashboard';

import AnalyticsTab from '@app/components/analytics/containers/analyticsTab';

export class NavRoutes extends Component {
    render() {
        return (
        <Switch>
            <Route path="/analytics" component={AdvancedAnalyticsNav} />
            <Route path="/arius" component={AriusAnalysisNav} />
            <Route path="/tod" component={TodNav} />
            <Route path="/portalManagement" component={PortalManagementNav} />
            <Route path="/utilities" component={UtilitiesNav} />
        </Switch>
        );
    }
}

export class ContentRoutes extends Component {
    render() {
        return (
        <Switch>
            <FeatureRoute flag="advancedAnalytics" path="/analytics/uploads" component={AnalyticsTab} />
            <FeatureRoute flag="advancedAnalytics" path="/analytics/models/:modelId" component={AnalyticsTab} />
            <FeatureRoute flag="advancedAnalytics" path="/analytics/models" component={AnalyticsTab} />
            <FeatureRoute flag="advancedAnalytics" path="/analytics" component={AnalyticsTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/workspaces/:workspaceId/security" component={Roles} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/workspaces/:workspaceId" component={Workspaces} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/workspaces" component={Workspaces} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/uploads" component={AEUploadsTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis/lists/:listType" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis/:databaseId/lists/:listType" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis/:databaseId/projects/:projectId/review" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis/:databaseId/projects/:date/:shape" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis/:databaseId" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/analysis" component={AnalysisTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/automation/:databaseId" component={AutomationTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/automation" component={AutomationTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/reporting/:databaseId" component={ReportingTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/reporting" component={ReportingTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/downloads/:databaseId/:downloadType" component={DownloadsTab} />
            <FeatureRoute flag="ariusAnalysis" path="/arius/downloads" component={DownloadsTab} />
            <FeatureRoute flag="powerBiReports" path="/arius/powerbi" component={PowerBITab} />
            <FeatureRoute flag="ariusAnalysis"te path="/arius" component={AriusAnalysisDashboard} />
            <Route path="/tod/queries/:databaseId" component={QueriesTab} />
            <Route path="/tod/queries" component={QueriesTab} />
            <Route path="/tod/advancedQueries/:databaseId" component={AdvancedQueriesTab} />
            <Route path="/tod/advancedQueries" component={AdvancedQueriesTab} />
            <Route path="/tod/databases/:databaseId/loader" component={DbLoaderContainer} />
            <Route path="/tod/databases/:databaseId/mappings" component={DbMapper} />
            <Route path="/tod/databases/:databaseId/security" component={Roles} />
            <Route path="/tod/databases/:databaseId" component={TodDatabasesTab} />
            <Route path="/tod/databases" component={TodDatabasesTab} />
            <Route path="/tod/uploads" component={UploadsTab} />
            <Route path="/tod/totals/:databaseId" component={TotalsTab} />
            <Route path="/tod/totals" component={TotalsTab} />
            <Route path="/tod" component={TodDashboard} />
            <Route path="/portalManagement/users" component={Users} />
            <Route path="/portalManagement/roles" component={Roles} />
            <Route path="/portalManagement/assignments" component={Roles} />
            <Route path="/portalManagement" component={PortalManagementDashboard} />
            <Route path="/utilities/auditLogSearch" component={AuditLogsTab} />
            <Route path="/utilities/performance" component={PerformanceLevelsTab} />
            <Route path="/utilities" component={UtilitiesDashboard} />
            <Route path="/" component={Home}/>
        </Switch>
        );
    }
}


export const getRouter = function(store) {
    return (
      <BrowserRouter>
          <Switch>
              <Route path="/login" component={ProcessLogin}/>
              <Route path="/logoff" component={ProcessLogoff}/>
              <Route path="/unauthorized" component={Unauthorized}/>
              <Redirect exact from="/" to="/home" /> 
              <PrivateRoute path="/" component={App} /> 
              <Redirect from="*" to="/home" />
          </Switch>
      </BrowserRouter>
    );
}
