import {
    FETCHING_EXTRACT_DEFINITIONS,
    RECEIVED_EXTRACT_DEFINITION_NAMES,
    FETCHING_REPORTING_DEFINITIONS,
    RECEIVED_REPORTING_DEFINITIONS,
    INITIATING_EXTRACT,
    EXTRACT_INITIATED,
    RECEIVED_REPORTING_CSV_FILE,
    FETCHING_REPORTING_WIDE_CSV,
    FETCHING_REPORTING_NARROW_CSV,
    FETCHING_CSV,
    RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
    FETCHING_TABLE_DEFINITIONS,
    RECEIVED_TABLE_DEFINITIONS,
    FETCHING_TABLE_DEFINITION,
    RECEIVED_TABLE_DEFINITION,
    FETCHING_REPORTING_TABLES,
    RECEIVED_REPORTING_TABLES,
    FETCHING_REPORTING_TABLE,
    RECEIVED_REPORTING_TABLE,
    RECEIVED_AVAILABLE_TABLES,
    RECEIVED_RIGHT_COLUMNS,
    RECEIVED_LEFT_COLUMNS,
} from '../../actions/analysis/analysis.actionTypes';

const reportingReducer = (
  state = {
    isFetching: false,
    extractKickingOff: false,
    extractDefinitions: [],
    csv: null,
  },
  action
) => {
  switch (action.type) {
    case INITIATING_EXTRACT:
      return Object.assign({}, state, {
        extractKickingOff: true,
      });
    case EXTRACT_INITIATED:
      return Object.assign({}, state, {
        extractKickingOff: false,
      });
    case FETCHING_EXTRACT_DEFINITIONS:
    case FETCHING_REPORTING_DEFINITIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVED_EXTRACT_DEFINITION_NAMES:
      return Object.assign({}, state, {
        isFetching: false,
        extractDefinitions: action.extractDefinitions,
      });
    case RECEIVED_REPORTING_DEFINITIONS:
        return Object.assign({}, state, {
          isFetching: false,
          reportingDefinitions: action.reportingDefinitions,
        });
    case FETCHING_REPORTING_WIDE_CSV:
      return Object.assign({}, state, {
        isFetchingWideCsv: true,
        isFetchingNarrowCsv: false,
        csv: null
      });
    case FETCHING_REPORTING_NARROW_CSV:
      return Object.assign({}, state, {
        isFetchingWideCsv: false,
        isFetchingNarrowCsv: true,
        csv: null
      });
    case FETCHING_CSV:
      return Object.assign({}, state, {
        isFetchingCsv: true,
        csv: null
      });
    case RECEIVED_REPORTING_CSV_FILE:
      return Object.assign({}, state, {
        isFetchingWideCsv: false,
        isFetchingNarrowCsv: false,
        isFetchingCsv: false,
        csv: action.csv
      });
    case FETCHING_TABLE_DEFINITIONS:
        return Object.assign({}, state, {
            isFetchingTableDefinitions: true,
            tableDefinitions: null
        });
    case RECEIVED_TABLE_DEFINITIONS:
        return Object.assign({}, state, {
            isFetchingTableDefinitions: false,
            tableDefinitions: action.data
        });
    case FETCHING_TABLE_DEFINITION:
        return Object.assign({}, state, {
            isFetchingTableDefinitions: true,
            tableDefinition: null
        });
    case RECEIVED_TABLE_DEFINITION:
        return Object.assign({}, state, {
            isFetchingTableDefinitions: false,
            tableDefinition: action.data
        });   
    case FETCHING_REPORTING_TABLES:
        return Object.assign({}, state, {
            isFetchingReportingTables: true,
            reportingTables: null
        });
    case RECEIVED_REPORTING_TABLES:
        return Object.assign({}, state, {
            isFetchingReportingTables: false,
            reportingTables: action.data
        });
    case FETCHING_REPORTING_TABLE:
        return Object.assign({}, state, {
            isFetchingReportingTable: true,
            reportingTable: null
        });
    case RECEIVED_REPORTING_TABLE:
        return Object.assign({}, state, {
            isFetchingReportingTable: false,
            reportingTable: action.data
        });   
    case RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION:
      return Object.assign({}, state, {
        userNamesForExtractDefinition: action.userNamesForExtractDefinition,
      });
    case RECEIVED_AVAILABLE_TABLES: 
        return Object.assign({}, state, {
            availableTables: action.data,
        });
    case RECEIVED_LEFT_COLUMNS: 
        return Object.assign({}, state, {
            leftColumns: action.columns,
            leftFiles: action.files
        });
    case RECEIVED_RIGHT_COLUMNS: 
        return Object.assign({}, state, {
            rightColumns: action.columns,
            rightFiles: action.files
        });
  default:
      return state;
  }
};

export default reportingReducer;
