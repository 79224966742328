import { callApi } from '../utilities/callApi';

export function retrieveUploads(userKey) {
  return callApi(
    '/uploads/tod',
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveGroupedUploads(userKey) {
    return callApi(
      '/uploads/tod/grouped',
      { 'X-AE-USERKEY': userKey }
    );
  }

export function retrieveColumnsForFile(userKey, fileId) {
  return callApi(
    `/uploads/tod/${fileId}/columns`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function deleteUpload(userKey, uploadId) {
  return callApi(
    `/uploads/tod/${uploadId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function deleteMultipleUploadFiles(userKey, bulkDeleteRequest) {
    return callApi(
        `/uploads/tod/bulkdelete`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        bulkDeleteRequest
    );
}

export function uploadFile(userKey, file) {
  return callApi(
    '/uploads/tod',
    { 'Content-Type': 'multipart/form-data', 'X-AE-USERKEY': userKey },
    'POST',
    file
  );
}

