import {
  RECEIVED_TOTALS_FOR_TOD_DB,
  FETCHING_TOD_TOTALS,
  FETCHING_TOD_TOTALS_TABLE,
  RECEIVED_TOTALS_TABLE,
  RECEIVED_TOD_CSV_FILE,
  RECEIVED_TOD_EXCEL_FILE } from './tod.actionTypes';
import * as totalsServiceApi from '../../serviceApi/totals.serviceApi';

function fetchingFromServer() {
  return {
    type: FETCHING_TOD_TOTALS,
  };
}

function fetchingTotalsTable() {
  return {
    type: FETCHING_TOD_TOTALS_TABLE,
  };
}


function receivedTotals(json) {
  return {
    type: RECEIVED_TOTALS_FOR_TOD_DB,
    dataSource: json,
  };
}

function receivedTotalsTable(json) {
  return {
    type: RECEIVED_TOTALS_TABLE,
    table: json,
  };
}

function receivedCsvFile(file) {
  const csvFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
  return {
    type: RECEIVED_TOD_CSV_FILE,
    csvFile,
  };
}

function receivedExcelFile(excelFile) {
  return {
    type: RECEIVED_TOD_EXCEL_FILE,
    excelFile,
  };
}

function clearTotalsTable() {
  return (dispatch) => {
    dispatch(receivedTotalsTable(null));
  };
}

function clearDataSource() {
  return (dispatch) => {
    dispatch(receivedTotals(null));
  };
}

function fetchTotals(userKey, databaseId) {
  return (dispatch) => {
    dispatch(fetchingFromServer());
    return totalsServiceApi.retrieveTotals(userKey, databaseId)
            .then(json => dispatch(receivedTotals(json)));
  };
}

function fetchTotalsTable({
  userKey,
  databaseId,
  valuationDate,
  columns,
  filters,
  measures,
  rows }) {
  return (dispatch) => {
    dispatch(fetchingTotalsTable());
    return totalsServiceApi.retrieveTotalsTable({
      userKey,
      databaseId,
      valuationDate,
      columns,
      filters,
      measures,
      rows }).then(json => dispatch(receivedTotalsTable(json)));
  };
}

function exportToCsv({ userKey, databaseId, valuationDate, columns, filters, measures, rows }) {
  return (dispatch) =>
    totalsServiceApi.exportToCsv({
      userKey,
      databaseId,
      valuationDate,
      columns,
      filters,
      measures,
      rows }).then(csv => dispatch(receivedCsvFile(csv)));
}

function exportToExcel({ userKey, databaseId, valuationDate, columns, filters, measures, rows }) {
  return (dispatch) =>
    totalsServiceApi.exportToExcel({
      userKey,
      databaseId,
      valuationDate,
      columns,
      filters,
      measures,
      rows }).then(excelFile => dispatch(receivedExcelFile(excelFile)));
}

export {
  fetchTotals,
  fetchTotalsTable,
  exportToCsv,
  exportToExcel,
  clearTotalsTable,
  clearDataSource,
};
