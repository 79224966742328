import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
    OverlayTrigger,
    Popover
} from 'react-bootstrap';
import {
    WORKSPACE_MANAGESECURITY,
    WORKSPACE_BACKUP,
    WORKSPACE_DELETEDATABASE,
    SITE_MANAGESECURITY,
} from '@app/utilities/permissions';
import { Button } from '@arius';
import * as style from '@app/utilities/globalStyles';
import { notifyError } from '@app/utilities/notifier';
import $ from 'jquery';

class WorkspaceActions extends Component {
    static propTypes = {
        database: PropTypes.object,
        isSelected: PropTypes.bool,
        onDatabaseClick: PropTypes.func,
        deleteClickHandler: PropTypes.func,
        openBackupModalHandler: PropTypes.func,
        viewInfoHandler: PropTypes.func,
        verifyPermission: PropTypes.func,
        index: PropTypes.number,
        description: PropTypes.string,
        columns: PropTypes.array,
        isFetching: PropTypes.bool,
        columnTypes: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.handleActionBackups = this.handleActionBackups.bind(this);
        this.handleActionDelete = this.handleActionDelete.bind(this);
        this.handleActionSecurity = this.handleActionSecurity.bind(this);
        this.handleActionUDO = this.handleActionUDO.bind(this);
        this.handleActionViewProjects  = this.handleActionViewProjects.bind(this);
    }

    handleActionUDO(e) {
        const { id, isOnline, openUdoLibraryModalHandler } = this.props;
        this.handleActionMenu(e);
        if (isOnline) {
            openUdoLibraryModalHandler(id, e);
        } else {
            notifyError('Unable to view UDO library, .........Placeholder');
        }
    }

    handleActionViewProjects(e) {
        const { id, isOnline, viewWorkSpaceHandler } = this.props;
        this.handleActionMenu(e);
        if (isOnline) {
            viewWorkSpaceHandler(id, e);
        } else {
            notifyError('Unable to view projects, database is currently offline or being created.');
        }
    }

    handleActionSecurity(e) {
        const { id, isOnline, verifyPermission, browserHistory} = this.props;
        this.handleActionMenu(e);
        if (isOnline) {
            if (verifyPermission(WORKSPACE_MANAGESECURITY) || verifyPermission(SITE_MANAGESECURITY)) {
                browserHistory.push(`/arius/workspaces/${id}/security`);
            } else {
                notifyError('You are not authorized to manage security on this workspace');
            } 
        } else {
            notifyError('Unable to change security settings, database is currently offline or being created.');          
        }
    }

    handleActionBackups(e) {
        const { id, isOnline, openBackupModalHandler, verifyPermission} = this.props;
        this.handleActionMenu(e);
        if (isOnline) {
            if (verifyPermission(WORKSPACE_BACKUP)){
                openBackupModalHandler(id, e)
            } else {
                notifyError('You are not authorized to manage database backups');
            }
        } else {
            notifyError('Unable to backup database, database is currently offline or being created.')
        }
    }

    handleActionDelete(e) {
        const { id, deleteClickHandler, verifyPermission } = this.props;
        this.handleActionMenu(e);
        if (verifyPermission(WORKSPACE_DELETEDATABASE)) {
            deleteClickHandler(id, e)
        } else {
            notifyError('You are not authorized to access this functionality');
        }
    }

    handleActionMenu(e) {
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${this.props.id}`).click();  // make action menu close.  TODO find better solution...
    }

    render() {
        const { id:databaseId } = this.props;

        const popover = (
            <Popover id="todListItemActions">
                <Button toolTip="Udo Library" iconName="library_books" onClick={this.handleActionUDO}/>
                <Button toolTip="View Projects" iconName="view_list" onClick={this.handleActionViewProjects}/>
                <Button toolTip="Security" iconName="lock" onClick={this.handleActionSecurity}/>
                <Button toolTip="Database Backup" iconName="backup" onClick={this.handleActionBackups}/>
                <Button toolTip="Delete" iconName="delete" onClick={this.handleActionDelete}/>
            </Popover>
        );

        return (
        <OverlayTrigger  trigger="click" placement="auto" overlay={popover} rootClose={true} >
            <i
                id={`action-overlay-${databaseId}`}
                key={`action-items-${databaseId}`}
                onClick={(e) => e.stopPropagation()}
                style={{...style.menuIcon, ...style.menuIconHover}}
                className="material-icons"
            >
                more_horiz
            </i>
        </OverlayTrigger>
        )
    }
}

export default radium(WorkspaceActions);
