import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import moment from 'moment';
import { Switch, Route } from 'react-router-dom';
import AnalysisProjectList from './analysisProjectList';
import { changeReviewLevelStatus } from '../../../actions/analysis/workspace.actions';
import { retrieveExportsByListType } from '@app/actions/analysis/project.actions';
import { 
  changeValuationDate, 
  tryFetchProjects, 
  receiveProjects,
} from '../../../actions/analysis/project.actions';
import { getProjectDataShapes, getDataStructureParam, getProjectListUrl, getDateParam } from '@app/utilities/projectDataHelper';
import { makeClone } from '../../../utilities/helpers';
import { createPermissionChecker } from '../../../utilities/permissions';

class AnalysisTabMain extends Component {
    static propTypes = {
        workspaces: PropTypes.array,
        currentWorkspace: PropTypes.object,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        isFetching: PropTypes.bool,
        projects: PropTypes.array,
        workspaceId: PropTypes.number,
        params: PropTypes.object,
        projectsHaveDataShape: PropTypes.bool,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        initiateBatchProcessHandler: PropTypes.func,
        apiUrl: PropTypes.object,
        getCurrentUdoVersion: PropTypes.func,
        cloneProject: PropTypes.func,
        currentUdoVersionNbr: PropTypes.number,
        runRollForward: PropTypes.func,
        fetchScriptsHandler: PropTypes.func,
        getProjectVersionsHandler: PropTypes.func,
        updateProjectVersionHandler: PropTypes.func,
        getCheckoutStatusHandler: PropTypes.func,
        scripts: PropTypes.array,
        segments: PropTypes.array,
        isFetchingSegments: PropTypes.bool,
        isFetchingWorkspaces: PropTypes.bool,
        checkingUniqueness: PropTypes.bool,
        userPermissions: PropTypes.array,
        projectVersions: PropTypes.array,
        valuationDate: PropTypes.string,
        copyingProject: PropTypes.object,
        copiedProjectId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        ]),
        getProjectsByValuationDateHandler: PropTypes.func,
        getValuationDatesHandler: PropTypes.func,
        valuationDates: PropTypes.array,
    };

    constructor(props) {
        
        super(props);
        this.state = {
            dataStructures: [],
            valuationDates: [],
            alreadyFetched: false,
            render: 0,
            isFetching: false,
        };
        if (props.currentWorkspace && !props.params.date) {
            //history.push(`/arius/analysis/workspaces/${props.currentWorkspace.id}/projects`);
            this.state.alreadyFetched = true;
        } else if (props.params && props.params.workspaceId) {
            this.state.alreadyFetched = true;
        }

        this.filterByValuationDate = this.filterByValuationDate.bind(this);
        this.refreshProjects = this.refreshProjects.bind(this);
        this.reRender = this.reRender.bind(this);
        this.getPermissionChecker = this.getPermissionChecker.bind(this);
        this.refreshPrintLists = this.refreshPrintLists.bind(this);
    }

    get baseUrl() {
        const { match } = this.props;
        const databaseId = match.params.databaseId ? parseInt(match.params.databaseId, 10) : null;
        const valDate = match.params.date;
        const shape = match.params.shape;
        var url = '/arius/analysis/projects';

        if (databaseId) {
            url = `/arius/analysis${databaseId}`;
            if (valDate && shape){
                url+= `/${valDate}/${shape}`;
            } else {
                url += '/all/all';
            }
        }
        return url;
    }

    get newUrl() {
        // get the url with databaseId injected (if needed) and child route preserved
        const { location } = this.props;
        return location.pathname.replace(this.baseUrl, `/arius/analysis/`);
    }

    get newUrlFromCurrentDb() {
        const { currentDb } = this.props;
        return `/arius/analysis/${currentDb.id}/projects/all/all`;  // default to 'projects' in LHM
    }

    componentDidMount() {
        let { 
            valuationDate,
            shape:dataStructure,
            dispatch,
            params} = this.props;

        if (params.date || !valuationDate){
            // this handles two cases:
            //      - the url contains params - use them rather than whatever is in state
            //      - the url does not contain params and there are none in state - use 'all'
            dispatch(changeValuationDate(getDateParam(params), getDataStructureParam(params)));
        } else {
            dispatch(changeValuationDate(valuationDate, dataStructure));
        }

        this.refreshPrintLists();
    }

    componentWillReceiveProps(nextProps) {
        const { userKey, currentWorkspace, getValuationDatesHandler, valuationDates, isFetching,
            projects: currentProjects, valuationDate, shape:dataStructure, dispatch, history, isFetchingValuationDates } = this.props,
        { isFetching: nextIsFetching, isFetchingProjects, projects: nextProjects } = nextProps,
        newState = {};

        // console.error('will receive =====================================================');
        // console.error('   currentWorkspace: ', currentWorkspace ? currentWorkspace.id : 'NONE', " > ",
        //   nextProps.currentWorkspace ? nextProps.currentWorkspace.id : 'NONE');
        // console.error('   valuationDates: ', valuationDates ? valuationDates : 'NONE', " > ",
        //   nextProps.valuationDates ? nextProps.valuationDates : 'NONE');
        //   console.error('   valuationDate: ', valuationDate ? valuationDate : 'NONE', " > ",
        //   nextProps.valuationDate ? nextProps.valuationDate : 'NONE');
        //   console.error('   isFetchingValuationDates: ', isFetchingValuationDates ? "YES" : 'NO', " > ",
        //   nextProps.isFetchingValuationDates ? 'YES' : 'NO');
          
          // PROJECTS HAVE CHANGED -> add some calculated props, put in state
        if (nextProjects !== currentProjects) {
            const projects = this.getProjectData(makeClone(nextProps.projects));
            newState.currentProjects = projects;
            newState.allProjects = projects.slice();
            newState.isFetching = false;
            if (nextProps.valuationDate) {
                history.push(getProjectListUrl(currentWorkspace.id, nextProps.valuationDate, dataStructure));
                if (nextProps.valuationDate !== 'all') {
                    this.filterByValuationDate(nextProps.valuationDate, projects);
                    delete newState.currentProjects;
                }
            }
        }
        
        if (isFetching !== nextIsFetching && nextIsFetching === true) {
            newState.isFetching = true;
        }

        // DATABASE HAS CHANGED -> load the valuation dates
        if (currentWorkspace !== nextProps.currentWorkspace) {
            let isLoading = isFetchingValuationDates || nextProps.isFetchingValuationDates;
            if (nextProps.currentWorkspace && !isLoading){
              getValuationDatesHandler(userKey, nextProps.currentWorkspace.id);
            }
        }
        
        // No valuation date is defined
        if (currentWorkspace && !valuationDate) {
            let justFinishedLoadingValuationDatesForThisDatabase = isFetchingValuationDates && !nextProps.isFetchingValuationDates;

            // You have (re)loaded valuation dates but there are none (db has no projects);
            if (justFinishedLoadingValuationDatesForThisDatabase) {
              dispatch(changeValuationDate('all', 'all'));
            } 

            // You need to load the valuation dates
            if (!isFetchingValuationDates){
              getValuationDatesHandler(userKey, currentWorkspace.id);
            }
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
        
        // VALUATION DATES HAVE CHANGED -> use the first one
        if (!isFetchingProjects && nextProps.valuationDates && nextProps.valuationDates.length && valuationDates !== nextProps.valuationDates) {
            let date = valuationDate;
            let shape = dataStructure;
            if (valuationDate === 'all') {
                shape = 'all';
            }
            else if (!nextProps.valuationDates.find(x=> x === valuationDate)){
                date = nextProps.valuationDates[0];  // use the first one
                shape = 'all';
            }
            dispatch(changeValuationDate(date, shape));
        }

        // VALUATION DATE OR SHAPE CHANGED -> reload projects
        let updateNeeded = (nextProps.valuationDate && valuationDate !== nextProps.valuationDate) || dataStructure !== nextProps.shape;
        updateNeeded = updateNeeded ||  (!this.state.currentProjects); // date and shape haven't changed but projects were never loaded
        if (!this.props.isFetchingProjects && !isFetchingProjects && currentWorkspace && nextProps.valuationDate && updateNeeded) {
            this.getProjects(nextProps.valuationDate);
        }
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace } = this.props;
        if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
            this.refreshPrintLists();
            return;
        }
    }

    componentWillUnmount() {
        const{dispatch}=this.props;
        window.removeEventListener('resize', this.reRender);
        dispatch(changeReviewLevelStatus());
    }

    getProjectData(projects) {
        let { valuationDates, projectsWithData } = getProjectDataShapes(projects),
            sortedDates = valuationDates.sort(this.dateSorter);

        let { shape:dataStructure } = this.props;

        let dataStructures = projectsWithData
            .map(p => p.dataShape)
            .filter((value, index, self) => self.indexOf(value) === index) // only unique values
            .sort();

        if (dataStructure && dataStructure !== 'all'){
            projectsWithData = projectsWithData.filter(p => p.dataShape === dataStructure);
        }
        this.props.setDataStructures(dataStructures);  // clunky way to set on parent so analysisToolbar has access...
        this.setState({ dataStructure, dataStructures, valuationDates: sortedDates, projectsHaveDataShape: true });
        return projectsWithData;
    }

    refreshPrintLists() {
        const { userKey, dispatch, currentWorkspace } = this.props;
        if (currentWorkspace){
            dispatch(retrieveExportsByListType({ userKey, databaseId: currentWorkspace.id, listType: "printLists"}));
        }
    }
  
    filterByValuationDate(date, projectedProjects) {
        let projects = projectedProjects || this.getProjectData(makeClone(this.props.projects));
        projects = projects.filter(project => date === moment(project.valuationDate).format('L'));
        this.setState({ currentProjects: projects, isFetching: false });
    }

    getProjects(date) {
        const { userKey,currentWorkspace, workspaceId, getProjectsByValuationDateHandler } = this.props,
            id = (currentWorkspace && currentWorkspace.id) || workspaceId;

        if (date === 'all') {
            //console.error(`dispatch(tryFetchProjects(${userKey}, ${id}));`)
            this.props.dispatch(tryFetchProjects(userKey, id));
        } else {
            //console.error(`getProjectsByValuationDateHandler(${userKey}, ${id}, ${moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')});`)
            getProjectsByValuationDateHandler(userKey, id, moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        }
    }

    refreshProjects() {
        this.props.dispatch(receiveProjects([]));  // clears the project list
        this.getProjects(this.props.valuationDate);
    }

    dateSorter(a, b) {
        return new Date(b) - new Date(a);
    }

    getPermissionChecker() {
      const { currentWorkspace, userPermissions, } = this.props;

      return createPermissionChecker(
          currentWorkspace ? [...userPermissions, ...currentWorkspace.permissions] : userPermissions);
    }

    reRender() {
        this.forceUpdate();
    }

    render() {
        const {
            apiUrl,
            baseUrl,
            checkingUniqueness,
            cloneProject,
            collectionSets,
            printLists,
            excelLists,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            copiedProjectId,
            copyingProject,
            currentUdoVersionNbr,
            currentWorkspace,
            dispatch,
            fetchScriptsHandler,
            getCheckoutStatusHandler,
            getCurrentUdoVersion,
            getProjectVersionsHandler,
            history,
            initiateBatchProcessHandler,
            isFetchingGenericLists,
            isFetchingMeasureMappers,
            isFetchingProjects,
            isFetchingSegments,
            isFetchingWorkspaces,
            location,
            measureMappers,
            params,
            projectsHaveDataShape,
            projectVersions,
            runRollForward,
            scripts,
            segments,
            shape:dataStructure,
            updateProjectVersionHandler,
            userKey,
            valuationDate,
            workspaceId,
            workspaces,
            categories
        } = this.props,
        {
            allProjects,
            alreadyFetched,
            currentProjects,
            dataStructures,
            editingMode,
            isFetching,
            valuationDates,            
        } = this.state;

        let childProps = {
            allProjects,
            alreadyFetched,
            apiUrl,
            baseUrl,
            categories,
            checkingUniqueness,
            cloneProject,
            collectionSets,
            printLists,
            excelLists,
            exhibitOptions,
            dataDefaults,
            exhibitDefaults,
            copiedProjectId,
            copyingProject,
            currentProjects,
            currentUdoVersionNbr,
            currentWorkspace,
            dataStructures,
            dispatch,
            editingMode,
            fetchScriptsHandler,
            getCheckoutStatusHandler,
            getCurrentUdoVersion,
            getProjectVersionsHandler,
            history,
            initiateBatchProcessHandler,
            isFetching,
            isFetchingGenericLists,
            isFetchingMeasureMappers,
            isFetchingProjects,
            isFetchingSegments,
            isFetchingWorkspaces,
            location,
            measureMappers,
            params,
            projectsHaveDataShape,
            projectVersions,
            runRollForward,
            scripts,
            segments,
            refreshProjectsHandler: () => {this.getProjects(valuationDate)},
            shape:dataStructure,
            updateProjectVersionHandler,
            userKey,
            valuationDate,
            valuationDates,
            verifyPermission: this.getPermissionChecker(),
            workspaceId,
            workspaces,
            refreshPrintLists: this.refreshPrintLists
        };
        
        return <Switch>
            <Route path="/arius/analysis/:databaseId/projects/:date/:shape" render={props => <AnalysisProjectList {...childProps}/>}/> 
            <Route path="/arius/analysis/projects" render={props => <AnalysisProjectList {...childProps}/>}/>
        </Switch>;
    }
}

export default radium(AnalysisTabMain);
