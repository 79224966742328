import React  from 'react';
import { connect as REDUX_CONNECT } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";

export function NavTab(props) {
    return <NavLink className="nav-item nav-link nav-link-arius" to={`${props.to}`}>{`${props.label}`}</NavLink>
}

export function NavTabs(props) {
    return props.children;
}

export function NavSideTabs(props) {
    return (
        <ul className="nav nav-tabs nav-side-tabs-arius flex-column">
            {props.children}
        </ul>
    );
}

export function connect(mapStateToProps, mapDispatchToProps) {
    return (comp) => withRouter(REDUX_CONNECT(mapStateToProps, mapDispatchToProps)(comp));
}