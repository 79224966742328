import { 
    RECEIVED_UPLOADS,
    FETCHING_UPLOADS,
    SAVING_UPLOADS,
    RECEIVED_COLUMNS_FOR_FILE
 } from '../../actions/analysis/analysis.actionTypes';

const uploadsReducer = (state = { items: [], isFetching: false, isSaving: false, columns: [] }, action) => {
  switch (action.type) {
    case FETCHING_UPLOADS:
      return Object.assign({}, state, { isFetching: true });
    case SAVING_UPLOADS:
      return Object.assign({}, state, { isSaving: true });
    case RECEIVED_UPLOADS:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        items: action.items,
        receivedAt: action.receivedAt,
      });
      case RECEIVED_COLUMNS_FOR_FILE:
        return Object.assign({}, state, {
          isFetching: false,
          columns: action.columns,
          receivedAt: action.receivedAt,
        });
    default:
      return state;
  }
};

export default uploadsReducer;
