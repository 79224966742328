import { callApi } from '../utilities/callApi';

// TODO REMOVE
export function retrieveUploads(userKey) {
    return callApi(
        '/uploads/analytics',
        { 'X-AE-USERKEY': userKey }
    );
}

// TODO REMOVE
export function deleteUpload(userKey, uploadId) {
    return callApi(
        `/uploads/analytics/${uploadId}`,
        { 'X-AE-USERKEY': userKey },
        'DELETE'
    );
}

// TODO REMOVE
export function uploadFile(userKey, file) {
    return callApi(
        '/uploads/analytics',
        { 'Content-Type': 'multipart/form-data', 'X-AE-USERKEY': userKey },
        'POST',
        file
    );
}

export function getModels(userKey) {
    return callApi(
        `/analytics/models`,
        {'X-AE-USERKEY': userKey },
        'GET'
    );
}

export function getResultsFiles(userKey, modelId) {
    return callApi(
        `/analytics/${modelId}/results`,
        {'X-AE-USERKEY': userKey },
        'GET'
    );
}

export function getAvailableParams(userKey, modelId) {
    return callApi(
        `/analytics/${modelId}/params`,
        {'X-AE-USERKEY': userKey },
        'GET'
    );
}

export function requestRun(userKey, request) {
    return callApi(
        '/analytics/runs',
        { 'X-AE-USERKEY': userKey },
        'POST',
        request
    );
}

export function requestUploadToTod(userKey, request) {
    return callApi(
        '/analytics/upload',
        { 'X-AE-USERKEY': userKey },
        'POST',
        request
    );
}

export function getConfigValidationMessage(userKey) {
    return callApi(
        `/analytics/validate`,
        {'X-AE-USERKEY': userKey },
        'GET'
    );
}
