import {
    FETCHING_EXTRACT_DEFINITIONS,
    RECEIVED_EXTRACT_DEFINITION_NAMES,  
    FETCHING_REPORTING_DEFINITIONS,
    RECEIVED_REPORTING_DEFINITIONS,
    INITIATING_EXTRACT,
    EXTRACT_INITIATED,
    RECEIVED_REPORTING_CSV_FILE,
    FETCHING_REPORTING_WIDE_CSV,
    FETCHING_REPORTING_NARROW_CSV,
    RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
    FETCHING_TABLE_DEFINITIONS,
    RECEIVED_TABLE_DEFINITIONS,
    FETCHING_REPORTING_TABLES,
    RECEIVED_REPORTING_TABLES,
    FETCHING_REPORTING_TABLE,
    RECEIVED_REPORTING_TABLE,
    FETCHING_AVAILABLE_TABLES,
    RECEIVED_AVAILABLE_TABLES,
    RECEIVED_RIGHT_COLUMNS,
    RECEIVED_LEFT_COLUMNS,
} from './analysis.actionTypes';
import * as extractsReportingApi from '../../serviceApi/analysisReporting.serviceApi';
import * as reportingApi from '../../serviceApi/reporting.serviceApi';
import { notifySuccess } from '../../utilities/notifier';

function fetchingExtractDefinitionNames() {
  return {
    type: FETCHING_EXTRACT_DEFINITIONS,
  };
}

function receivedExtractDefinitionNames(extractDefinitions) {
  return {
    type: RECEIVED_EXTRACT_DEFINITION_NAMES,
    extractDefinitions,
  };
}

function fetchingReportingDefinitions() {
    return {
      type: FETCHING_REPORTING_DEFINITIONS,
    };
  }
  
  function receivedReportingDefinitions(reportingDefinitions) {
    return {
      type: RECEIVED_REPORTING_DEFINITIONS,
      reportingDefinitions,
    };
  }

function initiatingExtract() {
  return {
    type: INITIATING_EXTRACT,
  };
}

function extractInitiated() {
  return {
    type: EXTRACT_INITIATED,
  };
}

function retrieveExtractDefinitions({ userKey, databaseId }) {
  return (dispatch) => {
    dispatch(fetchingExtractDefinitionNames());
    return extractsReportingApi
      .retrieveExtractDefinitions({ userKey, databaseId })
      .then((res) => {
        if (Array.isArray(res)) dispatch(receivedExtractDefinitionNames(res));
        else dispatch(receivedExtractDefinitionNames([]));
      });
  };
}

function retrieveReportingDefinitions({ userKey, databaseId }) {
    return (dispatch) => {
      dispatch(fetchingReportingDefinitions());
      return extractsReportingApi
        .retrieveReportingDefinitions({ userKey, databaseId })
        .then((res) => {
          if (Array.isArray(res)) dispatch(receivedReportingDefinitions(res));
          else dispatch(receivedReportingDefinitions([]));
        });
    };
  }

function extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId }, callback = ()=>{}) {
    return (dispatch) => {
        dispatch(initiatingExtract());
        return extractsReportingApi
        .extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId })
        .then((res) => {
            dispatch(extractInitiated());
            if (!res || res.error) return;
            callback();
            notifySuccess(res); 
        });
    };
}

function refreshReportingDatabase({ userKey, databaseId, definitionId }) {
    return (dispatch) => {
      dispatch(initiatingExtract());
      return extractsReportingApi
        .refreshReportingWithSelectedExtractDefinition({ userKey, databaseId, definitionId })
        .then((res) => {
          dispatch(extractInitiated());
          if (!res || res.error) return;
          notifySuccess(res); 
        });
    };
  }


function createExtractDefinition({ userKey, databaseId, req }) {
  return (dispatch) => {
    extractsReportingApi
      .createExtractDefinition({ userKey, databaseId, req })
      .then(() => dispatch(retrieveExtractDefinitions({ userKey, databaseId })));
  };
}

function editExtractDefinition({ userKey, databaseId, definitionId, req }) {
  return (dispatch) => {
    extractsReportingApi
      .editExtractDefinition({ userKey, databaseId, definitionId, req })
      .then(() => {
        dispatch(retrieveExtractDefinitions({ userKey, databaseId }));
      });
  };
}

function isExtractRunning(userKey, databaseId, definitionId, successCallback = ()=>{}) {
  return () => {
    extractsReportingApi
      .isExtractRunning(userKey, databaseId, definitionId)
      .then(data => successCallback(data));
  };
}

function deleteExtractDefinition({ userKey, databaseId, definitionId }) {
  return (dispatch) => {
    return extractsReportingApi
      .deleteExtractDefinition({ userKey, databaseId, definitionId })
      .then(() => {
        dispatch(retrieveExtractDefinitions({ userKey, databaseId }));
      });
  };
}

function initiatingCsvDownload(type) {
  return {
    type
  };
}

function receivedCsvFile(file, id, format, databaseName, extractDefinitionName) {
  if (!file) {
    return {
      type: RECEIVED_REPORTING_CSV_FILE,
      csv: null,
    };
  }

  const csvFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
  return {
    type: RECEIVED_REPORTING_CSV_FILE,
    csv: {
      id: id,
      file: csvFile,
      fileName: extractDefinitionName + ' - ' + databaseName + ' - ' + format + '.csv'
    }
  };
}

function exportToCsv({userKey, id, format, databaseName, extractDefinitionName, apiKey}) {
  return (dispatch) => {
    dispatch(initiatingCsvDownload(format === 'wide' ? FETCHING_REPORTING_WIDE_CSV : FETCHING_REPORTING_NARROW_CSV));
    extractsReportingApi.exportToCsv({
      userKey,
      format,
      databaseName,
      extractDefinitionName,
      apiKey
    }).then(csv => dispatch(receivedCsvFile(csv, id, format, databaseName, extractDefinitionName)));
  };
}

function receivedUserNamesForExtractDefinition(userNamesForExtractDefinition) {
  return {
    type: RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
    userNamesForExtractDefinition,
  };
}

function retrieveUserNamesForExtractDefinition(userKey) {
  return (dispatch) => {
    extractsReportingApi.retrieveUserNamesForExtractDefinition({userKey})
    .then((userNamesForExtractDefinition) => {
      if (Array.isArray(userNamesForExtractDefinition)) dispatch(receivedUserNamesForExtractDefinition(userNamesForExtractDefinition));
      else dispatch(receivedUserNamesForExtractDefinition([]));
    });
  };
}

function retrieveTableDefinitions({ userKey, databaseId }) {
    return (dispatch) => {
      dispatch({type: FETCHING_TABLE_DEFINITIONS});
      return reportingApi
        .retrieveTableDefinitions({ userKey, databaseId })
        .then((data) => {
          if (Array.isArray(data)) dispatch({type: RECEIVED_TABLE_DEFINITIONS, data});
          else dispatch({type: RECEIVED_TABLE_DEFINITIONS, data: []});
        });
    };
}

function createTableDefinition({ userKey, databaseId, req }) {
    return (dispatch) => {
        reportingApi
        .createTableDefinition({ userKey, databaseId, req })
        .then(() => dispatch(retrieveTableDefinitions({ userKey, databaseId })));
    };
}
  
function updateTableDefinition({ userKey, databaseId, req }) {
    return (dispatch) => {
        reportingApi
        .updateTableDefinition({ userKey, databaseId, req })
        .then(() => dispatch(retrieveTableDefinitions({ userKey, databaseId })));
    };
}

function requestTableLoad({ userKey, databaseId, req }) {
    return (dispatch) => {
        reportingApi
        .requestTableLoad({ userKey, databaseId, req })
        .then(() => {
            notifySuccess('Load requested');
            dispatch(retrieveTableDefinitions({userKey, databaseId}));
        });
    };
}

function retrieveReportingTables({ userKey, databaseId }) {
    return (dispatch) => {
      dispatch({type: FETCHING_REPORTING_TABLES});
      return reportingApi
        .retrieveReportingTables({ userKey, databaseId })
        .then((data) => {
          if (Array.isArray(data)) dispatch({type: RECEIVED_REPORTING_TABLES, data});
          else dispatch({type: RECEIVED_REPORTING_TABLES, data: []});
        });
    };
}

function retrieveReportingTable({ userKey, databaseId, reportingTableId }) {
    return (dispatch) => {
      dispatch({type: FETCHING_REPORTING_TABLE});
      return reportingApi
        .retrieveReportingTable({ userKey, databaseId, reportingTableId })
        .then((data) => {
          dispatch({type: RECEIVED_REPORTING_TABLE, data});
        });
    };
}

function createReportingTable({ userKey, databaseId, req }) {
    return (dispatch) => {
        reportingApi
        .createReportingTable({ userKey, databaseId, req })
        .then(() => dispatch(retrieveReportingTables({ userKey, databaseId })));
    };
}

function deleteReportingTable({ userKey, databaseId, reportingTableId }) {
  return (dispatch) => {
      reportingApi
      .deleteReportingTable({ userKey, databaseId, reportingTableId })
      .then(() => dispatch(retrieveReportingTables({ userKey, databaseId })));
  };
}

function isReportingRunning(userKey, databaseId, reportingDefinitionId, successCallback = ()=>{}) {
  return () => {
    reportingApi
      .isReportingRunning(userKey, databaseId, reportingDefinitionId)
      .then(data => successCallback(data));
  };
}

function updateReportingTable({ userKey, databaseId, req }) {
    return (dispatch) => {
        reportingApi
        .updateReportingTable({ userKey, databaseId, req })
        .then(() => dispatch(retrieveReportingTables({ userKey, databaseId })));
    };
}

function retrieveAvailableTables({ userKey, databaseId }) {
    return (dispatch) => {
      dispatch({type: FETCHING_AVAILABLE_TABLES});
      return reportingApi
        .getAvailableReportingTables({ userKey, databaseId })
        .then((data) => {
          if (Array.isArray(data)) dispatch({type: RECEIVED_AVAILABLE_TABLES, data});
          else dispatch({type: RECEIVED_AVAILABLE_TABLES, data: []});
        });
    };
}

function retrieveLeftColumns({ userKey, databaseId, req }) {
    return retrieveColumns({userKey, databaseId, req, actionType: RECEIVED_LEFT_COLUMNS});
}

function retrieveRightColumns({ userKey, databaseId, req }) {
    return retrieveColumns({userKey, databaseId, req, actionType: RECEIVED_RIGHT_COLUMNS});
}

function retrieveColumns({ userKey, databaseId, req, actionType} ){
    if (!req.physicalTableName){
        return (dispatch) => {
            dispatch({type: actionType, columns: [], files: []});
        }
    }
    if (req.isExtract){
        return (dispatch) => {
            return reportingApi
              .getExtractColumnsByTableName({ userKey, databaseId, physicalTableName: req.physicalTableName })
              .then((data) => {
                if (Array.isArray(data)) dispatch({type: actionType, columns: data, files: []});
                else dispatch({type: actionType, columns: [], files: []});
              });
          };
    } else {
        return (dispatch) => {
            return reportingApi
                .retrieveTableDefinition({ userKey, databaseId, tableDefinitionId: req.definitionId })
                .then((data) => {
                    dispatch({type: actionType, columns: data.columns, files: data.files});
                });
          };
    }
}

function deleteTableDefinition({ userKey, databaseId, tableDefinitionId }) {
    return (dispatch) => {
        reportingApi
        .deleteTableDefinition({ userKey, databaseId, tableDefinitionId })
        .then(() => dispatch(retrieveTableDefinitions({ userKey, databaseId })));
    };
}

function deleteTableLoad({ userKey, databaseId, tableDefinitionId, tableFileId }) {
    return (dispatch) => {
        reportingApi
        .deleteTableLoad({ userKey, databaseId, tableDefinitionId, tableFileId })
        .then(() => dispatch(retrieveTableDefinitions({ userKey, databaseId })));
    };
}

function requestRunReport({ userKey, databaseId, reportDefinitionId }) {
    return (dispatch) => {
        reportingApi
        .requestRunReport({ userKey, databaseId, reportDefinitionId })
        .then((res) => {
            if (res && !res.error) {
              notifySuccess('Run requested');
            }
            dispatch(retrieveReportingTables({userKey, databaseId}));
        });
    };
}

function downloadReport({ userKey, databaseId, reportDefinitionId, reportDefinitionName, databaseName, apiKey }) {
    return (dispatch) => {
        dispatch(initiatingCsvDownload(FETCHING_REPORTING_WIDE_CSV));
        reportingApi
        .downloadReport({ userKey, databaseName, reportDefinitionName, apiKey })
        .then(csv => dispatch(receivedReportCsvFile(csv, reportDefinitionId, reportDefinitionName, databaseName)))
    };
}

function receivedReportCsvFile(file, id, name, dbName) {
  if (!file) {
    return {
      type: RECEIVED_REPORTING_CSV_FILE,
      csv: null,
    };
  }
    const csvFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
    return {
        type: RECEIVED_REPORTING_CSV_FILE,
        csv: {
            id,
            file: csvFile,
            fileName: `${name} - ${dbName} - wide.csv`
        }
    };
}

function fetchReportsForTableDefinition(userKey, databaseId, tableDefinitionId, successCallback = ()=>{}) {
    return dispatch => {
      return reportingApi
        .getReportsByTableDefinition(userKey, databaseId, tableDefinitionId)
        .then(data => successCallback(data));
    };
}

function fetchReportsForExtractDefinition(userKey, databaseId, extractId, successCallback = ()=>{}) {
    return dispatch => {
      return reportingApi
        .getReportsByExtractDefinition(userKey, databaseId, extractId)
        .then(data => successCallback(data));
    };
}



export {
    createExtractDefinition,
    deleteExtractDefinition,
    editExtractDefinition,
    extractWithSelectedExtractDefinition,
    refreshReportingDatabase,
    retrieveExtractDefinitions,
    retrieveReportingDefinitions,
    exportToCsv,
    retrieveUserNamesForExtractDefinition,
    retrieveTableDefinitions,
    createTableDefinition,
    updateTableDefinition,
    requestTableLoad,
    retrieveReportingTables,
    retrieveReportingTable,
    createReportingTable,
    updateReportingTable,
    deleteReportingTable,
    retrieveAvailableTables,
    retrieveLeftColumns,
    retrieveRightColumns,
    deleteTableDefinition,
    deleteTableLoad,
    requestRunReport,
    downloadReport,
    fetchReportsForTableDefinition,
    fetchReportsForExtractDefinition,
    isExtractRunning,
    isReportingRunning,
};
