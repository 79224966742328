import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import AnalysisToolbar from './analysisToolbar';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { deleteScript } from '../../../actions/analysis/automation.actions';
import { showModal } from '../../../actions/modal.actions';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import { clearScripts } from '../../../actions/analysis/automation.actions';
import { Grid, Button } from '@arius';
import { notifyError } from '@app/utilities/notifier';
import { ListGroup,} from 'react-bootstrap';
import { COMMAND_IDS } from '../../../utilities/constants';

class Scripts extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        scripts: PropTypes.array,
        fetchScriptsHandler: PropTypes.func,
        isFetching: PropTypes.bool,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        params: PropTypes.object,
        isFetchingWorkSpaces: PropTypes.bool,
        dbChangeAllowed: PropTypes.bool,
        fetchMappersHandler: PropTypes.func,
        mappers: PropTypes.array,
        height: PropTypes.string,
        tableHeight: PropTypes.string,
    };
    constructor(props) {
        super(props);
        this.state = {
            openPanel: null,
            scripts: [],
            selectedWorkspaceId: null,
            alreadyFetched: false,
            mappers: [
                {
                value: '',
                label: 'Project default',
                },
            ],
            collectionSet: [{
                listName: '',
                exportListId: null,
            }],
        };
        if (props && props.params && props.params.scriptId) {
            this.editClickHandler(props.params.scriptId);
        }
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.editClickHandler = this.editClickHandler.bind(this);
        this.addClickHandler = this.addClickHandler.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.getGenericLists = this.getGenericLists.bind(this);
    }

    getGenericLists(commandId){
        const { collectionSets, exhibitOptions, dataDefaults, exhibitDefaults, } = this.props;
        switch (commandId) {
            case COMMAND_IDS.LOAD_COLLECTION_SET:
                return collectionSets;
            case COMMAND_IDS.LOAD_EXHIBIT_OPTIONS:
                return exhibitOptions;
            case COMMAND_IDS.LOAD_DATA_DEFAULTS:
                return dataDefaults;
            case COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS:
                return exhibitDefaults;
           default:
              break;
        }
    }

    componentWillMount() {
        const { dispatch, params, currentWorkspace, userKey, fetchScriptsHandler, scripts, fetchMappersHandler } = this.props;
        if (params.databaseId && !currentWorkspace) {
            dispatch(fetchWorkspace(userKey, params.databaseId));
            fetchMappersHandler({ userKey, databaseId: params.databaseId });
        }
        if (currentWorkspace && scripts && !scripts.length) {
            fetchScriptsHandler({
                databaseId: this.props.currentWorkspace && this.props.currentWorkspace.id,
                userKey,
            });
        }
        if (currentWorkspace) {
            fetchMappersHandler({ userKey, databaseId: currentWorkspace.id });
        }
    }

    componentDidMount() {
        const { scripts } = this.props, newState = this.state;

        if (scripts) {
            newState.scripts = scripts;
            if (scripts.length) {
                newState.alreadyFetched = true;
            }
        }

        this.setState(newState);
    }

    componentWillReceiveProps(nextProps) {
        const {
            scripts,
            currentWorkspace,
            fetchScriptsHandler,
            fetchMappersHandler,
            userKey,
            mappers,
            collectionSet,
        } = this.props,
        newState = this.state;
        let shouldSetState = false;

        if (scripts !== nextProps.scripts) {
            newState.scripts = nextProps.scripts;
            newState.alreadyFetched = true;
            shouldSetState = true;
        }

        if (currentWorkspace !== nextProps.currentWorkspace) {
            fetchScriptsHandler({
                databaseId: nextProps.currentWorkspace && nextProps.currentWorkspace.id,
                userKey,
            });
        }

        if (shouldSetState) {
            this.setState(newState);
        }
        if (nextProps.currentWorkspace && currentWorkspace !== nextProps.currentWorkspace) {
            fetchMappersHandler({ userKey, databaseId: nextProps.currentWorkspace.id });
        }
        if (nextProps.mappers && mappers !== nextProps.mappers) {
            const newMappersMap = nextProps.mappers.map((mapper) => { // eslint-disable-line 
                return {
                label: mapper.name,
                value: mapper.mapperId,
                };
            });
            newMappersMap.unshift({
                value: '',
                label: 'Project default',
            });
            this.setState({ mappers: newMappersMap });
        }
        if (nextProps.collectionSet && collectionSet !== nextProps.collectionSet) {
            const newCollectionSetMap = nextProps.collectionSet.map((cs) => { // eslint-disable-line
                return {
                exportListId: cs.exportListId,
                listName: cs.listName,
                };
            });
            this.setState({ collectionSet: newCollectionSetMap });
        }
    }

    changeWorkspaceHandler(workspaceId) {
        const { dispatch, userKey, browserHistory } = this.props;
        // this.setState({ selectedWorkspaceId: workspaceId });
        browserHistory.push(`/arius/automation/${workspaceId}/scripts`);
        dispatch(fetchWorkspace(userKey, workspaceId));
        dispatch(clearScripts());
    }

    deleteClickHandler(id) {
        const { dispatch, scripts, currentWorkspace } = this.props;

        if (scripts.length !== 0) {
        const selectedScript = scripts.find(s => s.batchScriptId === id),
            deleteMessageItems = [
            <li key={selectedScript.bacthScriptId} style={{ fontWeight: 800 }}>
                {selectedScript.scriptName}
            </li>,
            ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following script?'
        );

        const yesClickHandler = () => {
            const { userKey } = this.props,
            databaseId = currentWorkspace.id,
            scriptId = selectedScript.batchScriptId;
            dispatch(deleteScript({ userKey, databaseId, scriptId }));
            this.setState({ openPanel: null });
        };
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
        }
    }

    editClickHandler(id) {
        const { currentWorkspace, params, browserHistory } = this.props;
        let workspaceId;
        if (params && params.databaseId) {
            workspaceId = params.databaseId;
        } else {
            workspaceId = currentWorkspace.id;
        }
        browserHistory.push(`/arius/automation/${workspaceId}/scripts/${id}`);
    }

    addClickHandler() {
        const { params, browserHistory } = this.props;
        let workspaceId = params ? params.databaseId : null;
        if (workspaceId){
            browserHistory.push(`/arius/automation/${workspaceId}/scripts/new`);
        } else {
            notifyError('Must select a database');
        }
    }

    getGrid(rawData) {

        var data = rawData.map((r) => {return {...r,
            lmb: r.updateBy || r.createBy,
            lmd: r.updateBy ? r.updateDate : r.createDate 
        }});

        const columns = [
            { headerText: 'Name', field: 'scriptName'},
            { headerText: 'Last Modified By', field: 'lmb'},
            { headerText: 'Last Modified', field: 'lmd'},
            { headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
                columns={columns}
                data={data}
                height='calc(100vh - 225px)' 
                detailTemplate={this.getDetailTemplate}
            />
    }

    getActionItems(r) {
        return <span> 
            <Button toolTip="Edit" iconName="edit" onClick={()=> this.editClickHandler(r.batchScriptId)}/>
            <Button toolTip="Delete" iconName="delete" onClick={()=> this.deleteClickHandler(r.batchScriptId)}/>
        </span>
    }

    getDetailTemplate(script) {
        const {
            mappers,
          } = this.props;
          let idx = script.batchScriptId;
            return (
                <div>
                <span style={{ fontWeight: 'bold' }}>Description: </span>
                {script.scriptDescription}
                <ListGroup>
                {script.commands.map((command, i) => {
                let paramsJSX = '';
                if (command.parameters.length && command.id !== 4 && command.id !== 5) {
                    paramsJSX = (
                    <span>(
                        {
                        command.parameters.map((p, index) => {
                            if (command.id === COMMAND_IDS.LOAD_DATA_FROM_TOD) {
                            if (index === 1) {
                                const foundMapper = mappers.find((m) =>
                                m.value == p.value // eslint-disable-line eqeqeq
                                );
                                if (foundMapper) {
                                return <span key={`param-${i}-${index}-${foundMapper.value}-${idx}`}>{foundMapper.label},&nbsp;</span>;
                                }
                            }
                            } else if (command.id === COMMAND_IDS.LOAD_COLLECTION_SET
                                || command.id === COMMAND_IDS.LOAD_EXHIBIT_OPTIONS
                                || command.id === COMMAND_IDS.LOAD_DATA_DEFAULTS
                                || command.id === COMMAND_IDS.LOAD_EXHIBIT_DEFAULTS
                            ) {
                                const list = this.getGenericLists(command.id).find((cs) =>
                                    cs.exportListId == p.value // eslint-disable-line eqeqeq
                                );
                                if (list) {
                                    return <span key={`param-${i}-${index}-${list.exportListId}-${idx}`}>{list.listName}</span>;
                                }
                            }
                            if (index === command.parameters.length - 1 ) {
                            return <span key={`param-${i}-${index}-${p.value}-${idx}`}>{String(p.value)}</span>;
                            } else {
                            return <span key={`param-${i}-${index}-${p.value}-${idx}`}>{String(p.value)},&nbsp;</span>;
                            }
                        })
                        }
                    )</span>
                    );
                }
                return (
                    <ListGroup.Item
                    style={{ border: '0px' }}
                    key={`${script.batchScriptId}-${command}-${i}`}
                    >
                    {command.name}{paramsJSX}
                    </ListGroup.Item>
                );
                })}
            </ListGroup>
            </div>
        );
    }

    render() {
        const { 
            currentWorkspace,
            workspaces,
            dispatch,
            dbChangeAllowed,
        } = this.props,
        { scripts } = this.state;

        let message = currentWorkspace ? 'No records to display' : 'Please select a database';
        let tableJSX = (
            <div style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={message} />
            </div>
        );
        if (scripts && scripts.length) {
            tableJSX = this.getGrid(scripts);
        }

        return (
            <div className="list-container-arius">
                <AnalysisToolbar
                    disabled={!dbChangeAllowed}
                    currentWorkspace={currentWorkspace}
                    workspaces={workspaces}
                    dispatch={dispatch}
                    changeWorkspaceHandler={this.changeWorkspaceHandler}
                />
                <div className="list-header-arius">
                    <h4>All Scripts</h4>
                    <Button 
                        mode='add' 
                        onClick={this.addClickHandler}
                        toolTip='Create New Script'/>
                </div>
                {tableJSX}
            </div>
        );
    }
}

export default radium(Scripts);
