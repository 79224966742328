import {
    FETCHING_DOWNLOADS,
    RECEIVED_DOWNLOADS,
    RECEIVED_DOWNLOAD_TYPES,
} from './analysis.actionTypes';
import * as api from '@app/api/downloads.serviceApi';

function getAvailableDownloadTypes(userKey) {
    return (dispatch) => {
        return api.getAvailableDownloadTypes(userKey)
            .then(data => dispatch({ type: RECEIVED_DOWNLOAD_TYPES, data }));
    };
}

function getAvailableDownloads(userKey, downloadType, databaseId) {
    return (dispatch) => {
        dispatch({ type: FETCHING_DOWNLOADS });
        return api.getAvailableDownloads(userKey, downloadType, databaseId)
            .then(data => dispatch({ type: RECEIVED_DOWNLOADS, data }));
    }; 
}

function deleteExportFile(userKey, downloadType, databaseId, downloadId) {
    return (dispatch) => {
        return api.deleteExportFile(userKey, downloadType, databaseId, downloadId)
        .then(data => {
            dispatch(getAvailableDownloads(userKey, downloadType, databaseId));
        });
    };
}

export {
    getAvailableDownloadTypes,
    getAvailableDownloads,
    deleteExportFile,
};
