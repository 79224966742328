import React from 'react';
import { Link } from 'react-router-dom';
import { notifyError } from '../../../utilities/notifier';

export const notAuthorized = () => notifyError('You are not authorized to access this functionality');

export const ConditionalLink = (p) => {
    let {allow, ...props} = {...p};  // NOTE allow is being pulled out of props on purpose.
    if (allow) {
        return (<Link {...props} />);
    } else {
        return (<span onClick={notAuthorized} {...props}></span>);
    }
};

ConditionalLink.propTypes = {
  //allow: PropTypes.bool,
};
