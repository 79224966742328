import {
  UPDATED_GROUP,
  FETCHING_SUBSCRIPTION_USERS_FROM_SERVER,
  FETCHING_SUBSCRIPTION_USER_FROM_SERVER,
  RECEIVED_SUBSCRIPTION_USERS,
  RECEIVED_SUBSCRIPTION_USER,
  UPDATED_USER,
  RECEIVED_AUDIT_LOGS,
  RECEIVED_AUDIT_LOG_CONTENT,
  RECEIVED_ASSIGNED_ROLES,
  FETCHING_ASSIGNED_ROLES,
  RECEIVED_AVAILABLE_ROLES,
  FETCHING_AVAILABLE_ROLES,
  RECEIVED_ROLE_DESCRIPTIONS,
  FETCHING_ROLE_DESCRIPTIONS,
  RECEIVED_AVAILABLE_PERMISSIONS,
  FETCHING_AVAILABLE_PERMISSIONS,
  RECEIVED_AVAILABLE_USERS,
  RECEIVED_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_USERS,
  CLEAR_AVAILABLE_PERMISSIONS,
  UPDATED_USER_ROLE,
  RECEIVED_ROLE_DESCRIPTION,
  ASSIGNING_ROLES,
  CLEAR_ROLE_DESCRIPTION,
  AUDIT_LOG_CALL_MADE,
  AUDIT_LOG_CSV_CALL_MADE,
  RECEIVED_AUDIT_LOG_CSV,
} from '../actions/portalManagement/portalManagement.actionTypes';

import groupsReducer from './portalManagement/groups.reducer';
import rolesReducer from './portalManagement/roles.reducer';
import usersReducer from './portalManagement/subscriptionUsers.reducer';

const portalManagement = (
  state = {
    users: {
      isFetching: false,
      subscriptionUsers: [],
      allActiveAndInactiveUsers: [],
      subscriptionUser: {},
    },
    groups: {
      items: [],
      isFetching: false,
      isUpdated: false,
      current: null,
      users: [],
    },
    roles: {
      availableUsers: [],
      availableResources: [],
      assignedRoles: [],
      availablePermissions: [],
      roleDescriptions: [],
      isFetching: false,
      isUpdated: false,
    },
    auditLogContent: [],
    auditLogFetches: 0,
    auditLogCSVFileFetches: 0,
    auditLogCSVFile: null,
  },
  action,
) => {
  switch (action.type) {
    case UPDATED_GROUP:
      return Object.assign({}, state, {
        groups: groupsReducer(state.groups, action),
      });
    case FETCHING_SUBSCRIPTION_USERS_FROM_SERVER:
    case FETCHING_SUBSCRIPTION_USER_FROM_SERVER:
    case RECEIVED_SUBSCRIPTION_USERS:
    case RECEIVED_SUBSCRIPTION_USER:
    case UPDATED_USER:
      return Object.assign({}, state, {
        users: usersReducer(state.users, action),
      });
    case RECEIVED_AUDIT_LOGS:
      return Object.assign({}, state, {
        auditLogs: action.logs,
        auditLogContent: null,
      });
    case AUDIT_LOG_CALL_MADE:
      return Object.assign({}, state, { 
        auditLogFetches: action.auditLogFetches,
      });
    case RECEIVED_AUDIT_LOG_CONTENT:
      return Object.assign({}, state, {
        auditLogContent: action.content,
        auditLogFetches: action.auditLogFetches,
      });
    case AUDIT_LOG_CSV_CALL_MADE:
      return Object.assign({}, state, { 
        auditLogCSVFileFetches: action.auditLogCSVFileFetches,
      });
    case RECEIVED_AUDIT_LOG_CSV:
      return Object.assign({}, state, { 
        auditLogCSVFileFetches: action.auditLogCSVFileFetches,
        auditLogCSVFile: action.auditLogCSVFile,
      });
    case RECEIVED_ASSIGNED_ROLES:
    case FETCHING_ASSIGNED_ROLES:
    case RECEIVED_AVAILABLE_ROLES:
    case FETCHING_AVAILABLE_ROLES:
    case RECEIVED_ROLE_DESCRIPTIONS:
    case FETCHING_ROLE_DESCRIPTIONS:
    case RECEIVED_AVAILABLE_PERMISSIONS:
    case FETCHING_AVAILABLE_PERMISSIONS:
    case RECEIVED_AVAILABLE_USERS:
    case RECEIVED_AVAILABLE_RESOURCES:
    case FETCHING_AVAILABLE_RESOURCES:
    case FETCHING_AVAILABLE_USERS:
    case CLEAR_AVAILABLE_PERMISSIONS:
    case UPDATED_USER_ROLE:
    case ASSIGNING_ROLES:
    case RECEIVED_ROLE_DESCRIPTION:
    case CLEAR_ROLE_DESCRIPTION:
      return Object.assign({}, state, {
        roles: rolesReducer(state.roles, action),
      });
    default:
      return state;
  }
};

export default portalManagement;
