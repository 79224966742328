import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import * as Colors from '@app/utilities/colors';
import Loader from '@app/shared/presentational/loader';
import { ProgressBar } from 'react-bootstrap';
import { showModal } from '@app/actions/modal.actions';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import BulkDeleteEditor from '@app/shared/presentational/uploadsBulkDeleteEditor';
import UploadsGrid from '@app/shared/presentational/uploadsGrid';
import * as api from '@app/api/uploads.ae.serviceApi';
import { Button } from '@arius';

class UploadsList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        uploads: PropTypes.array.isRequired,
        isSaving: PropTypes.bool,
        isFetching: PropTypes.bool,
        getUploads: PropTypes.func,
        deleteFile: PropTypes.func,
        uploadNewFile: PropTypes.func,
        errorMessage: PropTypes.string,
        clearError: PropTypes.func,
        uploadNewFile2: PropTypes.func,
        hasWriteAccess: PropTypes.bool,
    };
    static defaultProps = {
        uploads: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            isDescending: false,
            columnSelected: '',
            uploadForm: (
                <form id="file-upload-form-0">
                <input type="file" id="file-upload-input-0" style={{ visibility: 'hidden' }}
                    onChange={(e) => this.onFileInputChange(e)}
                    accept=".csv,text/plain,text/csv" />
                </form>
            ),
            uploadFormId: 0,
            method: null,
            selectedFileIds: []
        };
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.selectUploads = this.selectUploads.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSaving } = this.props;
        if (prevProps.isSaving && !isSaving) {
            const uploadFormId = this.state.uploadFormId + 1;
            this.setState({
                uploadForm: (
                <form id={`file-upload-form-${uploadFormId}`}>
                    <input type="file" id={`file-upload-input-${uploadFormId}`}style={{ visibility: 'hidden' }}
                    onChange={(e) => this.onFileInputChange(e)}
                    accept=".csv,text/plain,text/csv" />
                </form>
                ),
                uploadFormId,
        });
        }

        if (prevState.uploadForm !== this.state.uploadForm) {
            const input = document.getElementById(`file-upload-input-${this.state.uploadFormId}`);
            input.value = '';
        }
    }

    refresh() {
        const { userKey, getUploads } = this.props;
        getUploads(userKey);
    }

    onFileInputChange(e) {
        const method = this.state.method;
        if (method) {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                const { userKey, uploadNewFile2, clearError } = this.props;
                const data = new FormData();

                data.append('file', file);

                clearError();
                uploadNewFile2(userKey, data);

                this.setState({ isUploading: true });
            }
        } else {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                const { userKey, uploadNewFile, clearError } = this.props;
                const data = new FormData();

                data.append('file', file);

                clearError();
                uploadNewFile(userKey, data);

                this.setState({ isUploading: true });
            }
        }
    }

    selectUploads(selected) {
        let selectedFileIds = selected.map(x => x.id);
        this.setState({selectedFileIds, nSelections: selected.length});
    }

    uploadFile() {
        this.setState({ method: false });
        const input = document.getElementById(`file-upload-input-${this.state.uploadFormId}`);
        if (input) {
            input.click();
        }
    }

    deleteFile(o) {
        let id = o.id;
        const { dispatch, uploads } = this.props;
        if (id && uploads.length > 0) {
            const selectedUpload = uploads.find(u => u.id === id);
            let deleteMessageItems = [(
                <li key={selectedUpload.id} style={{ fontWeight: 800 }}>{selectedUpload.name}</li>
            )];

            deleteMessageItems.unshift('Are you sure you want to delete the following file?');

            const yesClickHandler = () => {
                const { userKey, deleteFile } = this.props;
                deleteFile(userKey, id);
            };
            const noClickHandler = () => {};
            const action = showModal('confirmation', deleteMessageItems, yesClickHandler, noClickHandler);
            dispatch(action);
        }
    }

    render() {
        const { isSaving, isFetching, errorMessage, hasWriteAccess, uploads, userKey } = this.props;
        const { uploadForm, selectedFileIds, nSelections } = this.state;

        let contentMarkup = (<div>You have no uploaded files</div>);

        if (isFetching) {
            contentMarkup = (<Loader loadingItem="Uploads" />);
        }


        if (uploads.length > 0) {
            contentMarkup = <UploadsGrid
                uploads = {uploads}
                deleteHandler = {this.deleteFile}
                selectUploadsHandler = {this.selectUploads}
                canDelete = {hasWriteAccess}
                hideDetails={true}
            />
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>CSV Uploads</h4>
                <div style={{display: 'flex'}}>
                    <BulkDeleteEditor
                        selectedFileIds= {selectedFileIds}
                        userKey={userKey}
                        canDelete={hasWriteAccess}
                        nSelections={nSelections}
                        refreshHandler={this.refresh}
                        deleteHandler={api.deleteMultipleUploadFiles}
                    />
                    <Button toolTip="Upload CSV Files" mode="add" onClick={hasWriteAccess ? this.uploadFile : notAuthorized}/>
                </div>
            </div>
            <div style={{ minHeight: 50 }}>
            {isSaving && !errorMessage ? (
                <div>
                <ProgressBar animated now={100} />
                <h5 style={{ textAlign: 'center' }}>Loading...</h5>
                </div>
            ) : (<span></span>)}
                <h5 style={{ textAlign: 'center', color: Colors.red }}>{ errorMessage }</h5>
            </div>
            {contentMarkup}
            {uploadForm}
        </div>
        )
    }
}

export default radium(UploadsList);
