import { callApi, callApiPassingErrors, ServerError } from '../utilities/callApi';

export function createScript({
  userKey,
  scriptName,
  scriptDescription,
  commands,
  databaseId,
  createBy,
}) {
  return callApi(
    `/aebatchscripts/${databaseId}/scripts`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    {
      scriptName,
      scriptDescription,
      commands,
      createBy,
    }
  );
}

export function updateScript(
  {
    batchScriptId,
    userKey,
    scriptName,
    scriptDescription,
    commands,
    databaseId,
    updateBy,
  },
) {
  return callApi(
    `/aebatchscripts/${databaseId}/scripts/${batchScriptId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    {
      batchScriptId,
      scriptName,
      scriptDescription,
      commands,
      updateBy,
    }
  );
}

export function getScripts({ userKey, databaseId }) {
  return callApiPassingErrors(`/aebatchscripts/${databaseId}/scripts`, {
    'X-AE-USERKEY': userKey,
  }).catch(err => {
    if (err instanceof ServerError) {
      return null;
    } else {
      throw err;
    }
  });
}

export function getCommandStatuses({ userKey, databaseId, jobId }) {
  return callApi(`/workspaces/${databaseId}/operationsstatus/${jobId}`, {
    'X-AE-USERKEY': userKey,
  });
}

export function getMappers({ userKey, databaseId }) {
  return callApi(`/workspaces/${databaseId}/measures`, {
    'X-AE-USERKEY': userKey,
  });
}

export function deleteScript({ userKey, databaseId, scriptId }) {
  return callApi(
    `/aebatchscripts/${databaseId}/scripts/${scriptId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function runRollForwardBatchProcess({ userKey, databaseId, scriptId, projects }) {
  return callApi(
    `/aebatchscripts/${databaseId}/aebatch/start/${scriptId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    projects,
  );
}

export function cancelBatchProcess({ userKey, databaseId, batchJobId }) {
  return callApi(
    `/aebatchscripts/${databaseId}/batchJobs/${batchJobId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
  );
}
