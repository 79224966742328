import React, { Component } from 'react';
import radium from 'radium';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { Button } from '@arius';
import PurpleButton from '@app/shared/presentational/purpleButton';
import Loader from '@app/shared/presentational/loader';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS} from '@app/utilities/permissions';
import { notifyError } from '@app/utilities/notifier';

class UploadsBulkDeleteEditor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isWaiting: false,
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.launchModal = this.launchModal.bind(this);
    }
    
    save() {
        const { userKey, refreshHandler, selectedFileIds, deleteHandler } = this.props;
        let req = { fileIds: selectedFileIds };
        deleteHandler(userKey, req).then(
            () => {
                this.setState({ isWaiting: false });
                refreshHandler();
                this.handleClose();
            });
        this.setState({ isWaiting: true });
    }

    handleOpen(){
        const { canDelete }=this.props;
        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({ isWaiting: false });
    }

    handleClose(){
        this.setState({show: false});
    }

    launchModal(){
        const { canDelete }=this.props;

        if (!canDelete){
            notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
            return;
        }
        this.setState({comments: '', message: 'test', show: true});
    }

    componentDidUpdate() {
        const {projectBulkDeleteRequest}=this.props;

        if (projectBulkDeleteRequest === 'success'){
            this.handleClose();
        }
    }
  
    render() {

        const { show, isWaiting } = this.state;
        const { selectedFileIds, nSelections } = this.props;
        let isValid = selectedFileIds.length > 0;

        const msgStyle = {
            fontWeight: 'bold',
        };

        let n = selectedFileIds.length;
        let subText = n !== nSelections ? ` (${n} file${n > 1 ? 's' : ''})` : '';
        
        let form = !isValid ? <span style={{fontWeight: 'bold'}}>Please select at least 1 upload</span> : (
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{marginBottom: '10px'}}>
                        <span style={msgStyle}>{`${nSelections} upload${nSelections > 1 ? 's' : ''} selected`}
                            {subText}</span>
                    </div>
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        <span style={msgStyle}>{`Are you sure you want to delete selected upload${n > 1 ? 's' : ''}?`}</span>
                    </div>
                </fieldset> 
            </div>            
        );

        let body = isWaiting ? (<Loader loadingText="Please wait" />) : form;

        let buttons = isValid ? (              
            [
                <Button
                    key="confirmButton"
                    className={'dialog-button btn-danger'}
                    variant='arius'
                    onClick={this.save}>Yes</Button>,
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>No</Button>
            ]) : (
                <Button
                    key="nobutton"
                    className={'dialog-button'}
                    variant='arius'
                    onClick={this.handleClose}>Ok</Button>
            );

        return (
            <span>
                <PurpleButton 
                    id="launch" 
                    clickHandler={this.launchModal}
                    message="Delete selected uploads" 
                    type="delete">
                </PurpleButton>
                <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>Delete selected uploads</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>{buttons}</Modal.Footer>
                </Modal>
            </span>
            
        );
        }
    }
    
    export default radium(UploadsBulkDeleteEditor);
