import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';

class CustomCheckbox extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    inputStyle: PropTypes.object,
    allChecked: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    this.selectCheck = this.selectCheck.bind(this);
  }

  componentDidMount() {
    if (this.props.checked) {
      this.setState({ checked: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.checked !== nextProps.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  selectCheck() {
    const { checked } = this.state, { onClick } = this.props;
    onClick();
    this.setState({ checked: !checked });
  }

  render() {
    const { checked } = this.state, { inputStyle } = this.props;
    return (
      <input
          style={[inputStyle, { marginTop: '6px' }]}
          type="checkbox"
          checked={checked}
          onChange={()=> this.selectCheck()}
        />
    )
  }
}

export default radium(CustomCheckbox);
