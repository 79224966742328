import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import Loader from '@app/shared/presentational/loader';
import { Grid } from '@arius';
import { Form } from 'react-bootstrap';
import { DATA_MODIFY } from '@app/utilities/permissions';
import { createPermissionChecker } from '@app/utilities/permissions';
import { 
    fetchLinkedDatabaseMapping,
    saveLinkedDatabaseColumnMapping,
} from '@app/actions/tod/todDatabases.actions';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { notifySuccess } from '@app/utilities/notifier';

class LinkDatabaseEdit extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        params: PropTypes.object.isRequired,
        userKey: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            databaseId: null,
            secondaryDatabaseId: null,
            createNew: false,
            databases: [],
            canEdit: true,
            columnMapping: null,
        };
    };

    componentDidMount() {
        const {
            params,
            browserHistory,
            databases,
            currentDatabase,
        } = this.props;

        const databaseId = parseInt(params.databaseId, 10);
        const secondaryDatabaseId = parseInt(params.secondaryDatabaseId, 10);
        const createNew = !secondaryDatabaseId;

        const dbs = databases.filter(db => db.useADX === currentDatabase.useADX && db.workspaceId !== currentDatabase.id);

        if (databaseId) {
            this.setState({
                isLoading: true,
                databaseId: databaseId,
                secondaryDatabaseId: secondaryDatabaseId,
                createNew,
                databases: dbs,
            });

            this.loadMapping(databaseId, secondaryDatabaseId);

        } else {
            browserHistory.goBack();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {
            isFetchingLinkedDatabaseMapping,
            isSavingLinkedDatabaseColumnMapping,
            savedLinkedDatabaseMapping,
        } = this.props;

        const {
            isLoading,
            canEdit,
        } = this.state;

        if (!isFetchingLinkedDatabaseMapping && isLoading){
            this.setState({
                isLoading: false,
            });

            this.setColumnMapping();
        }

        if (prevProps.isSavingLinkedDatabaseColumnMapping && 
            (!isSavingLinkedDatabaseColumnMapping && savedLinkedDatabaseMapping)) {
            notifySuccess("Mapping is saved");
        }

        const verifyPermission = this.getPermissionChecker();
        const allowEdit = verifyPermission(DATA_MODIFY);
        if (canEdit !== allowEdit) {
            this.setState({
                canEdit: allowEdit,
            });
        }
    }

    loadMapping = (id, secondaryId) => {
        const { userKey, dispatch } = this.props;
        dispatch(fetchLinkedDatabaseMapping(userKey, id, secondaryId));
    };

    saveMapping = () => {
        const { userKey, dispatch, linkedDatabaseMapping, browserHistory } = this.props;
        const { databaseId, secondaryDatabaseId, columnMapping } = this.state;

        const secondaryColumns = linkedDatabaseMapping ? linkedDatabaseMapping.secondaryColumns : null;

        if (linkedDatabaseMapping && linkedDatabaseMapping.mapping && secondaryColumns) {
            const mapping = linkedDatabaseMapping.mapping;
            for (var i = 0; i < mapping.length; i++) {
                const column1Guid = mapping[i].column1Guid;
                const m = columnMapping.find(cm => cm.column1Guid === column1Guid);
                if (m && m.column2Guid) {
                    const secondaryColumn = secondaryColumns.find(sc => sc.factColumnGUID === m.column2Guid);
                    mapping[i].column2Guid = m.column2Guid;
                    mapping[i].column2Name = secondaryColumn.factColumnDisplayName;
                    mapping[i].column2Type = secondaryColumn.columnTypeID;
                } else {
                    mapping[i].column2Guid = null;
                    mapping[i].column2Name = null;
                    mapping[i].column2Type = null;
                }
            }

            dispatch(saveLinkedDatabaseColumnMapping(userKey, browserHistory, databaseId, secondaryDatabaseId, linkedDatabaseMapping));
        }
    };

    setColumnMapping = () => {
        const { linkedDatabaseMapping } = this.props;

        if (linkedDatabaseMapping && linkedDatabaseMapping.mapping) {
            const mapping = linkedDatabaseMapping.mapping.filter(m => m.column2Guid);
            let columnMapping = mapping.map( m => ({column1Guid: m.column1Guid, column2Guid: m.column2Guid}));
            if (!columnMapping || columnMapping.length === 0) {
                columnMapping = [{column1Guid: null, column2Guid: null}];
            }
            this.setState({ columnMapping });
        }
    }

    changeSecondaryDatabase = (e) => {
        const { databaseId } = this.state;
		const dbId = e.target.value;
        this.setState({
            isLoading: true,
            secondaryDatabaseId: dbId,
        });
		this.loadMapping(databaseId, dbId);
    };

    getPrimaryColumns = (row) => {
        const { linkedDatabaseMapping } = this.props;
        const { canEdit, columnMapping } = this.state;

        const primaryColumns = linkedDatabaseMapping ? linkedDatabaseMapping.mapping : null;
        const mapping1 = columnMapping[0];

        return  (
            <Form.Control as="select"
                placeholder="select"
                value={mapping1 ? mapping1.column1Guid ? mapping1.column1Guid : 'default' : 'default'}
                disabled={!canEdit}
                onChange={(e) => this.onPrimaryColumnChanged(row, e)}
            >
                <option value='default'>not mapped</option>
                    {primaryColumns ? primaryColumns.map((col, index) => (
                        <option key={`${index}-${col.column1Guid}`} value={col.column1Guid}>
                            {col.column1Name}
                        </option>
                )) : ('')}
          </Form.Control>
        );
    }

    getSecondaryColumns = (row) => {
        const { linkedDatabaseMapping } = this.props;
        const { canEdit, columnMapping } = this.state;

        const secondaryColumns = linkedDatabaseMapping ? linkedDatabaseMapping.secondaryColumns : null;
        const mapping1 = columnMapping[0];

        return  (
            <Form.Control as="select"
                placeholder="select"
                value={mapping1 ? mapping1.column2Guid ? mapping1.column2Guid : 'default' : 'default'}
                disabled={!canEdit}
                onChange={(e) => this.onSecondaryColumnChanged(row, e)}
            >
                <option value='default'>not mapped</option>
                    {secondaryColumns ? secondaryColumns.map((col, index) => (
                        <option key={`${index}-${col.factColumnGUID}`} value={col.factColumnGUID}>
                            {col.factColumnDisplayName}
                        </option>
                )) : ('')}
          </Form.Control>
        );
    }

    onPrimaryColumnChanged = (row, e) => {
        const { columnMapping } = this.state;
        const columnGuid = e.target.value;

        const column2Guid = row ? row.column2Guid || null : null;

        columnMapping[row.index] = {column1Guid: columnGuid === 'default' ? null:columnGuid, column2Guid: column2Guid};
        this.setState({ columnMapping });
    };

    onSecondaryColumnChanged = (row, e) => {
        const { columnMapping } = this.state;
        const columnGuid = e.target.value;

        const column1Guid = row ? row.column1Guid || null : null;

        columnMapping[row.index] = {column1Guid: column1Guid, column2Guid: columnGuid === 'default' ? null:columnGuid};
        this.setState({ columnMapping });
    }


    getColumnsGrid2 = () => {
        const { columnMapping } = this.state;

        let columns = [
            { headerText: 'Primary Database Column Name', template: this.getPrimaryColumns},
            { headerText: 'Secondary Database Column Name', template: this.getSecondaryColumns}
        ];

        return (
        <div>
            <h5>Select the column used to map the two databases</h5>

            <Grid 
                columns={columns}
                data={columnMapping}
                height='calc(100vh - 200px)' 
                allowPaging={false}
            />

        </div>
        );
    }

    getPermissionChecker = () => {
        const { currentDatabase, userPermissions } = this.props;
        return createPermissionChecker(
            currentDatabase ? [...userPermissions, ...currentDatabase.permissions] : userPermissions);
    }

    render(){

        const {
            linkedDatabaseMapping,
            browserHistory,
            isSavingLinkedDatabaseColumnMapping,
        } = this.props;

        const {
            isLoading,
            databaseId,
            createNew,
            databases,
        } = this.state;

        const db = linkedDatabaseMapping ? linkedDatabaseMapping.primaryDatabase : null;
        const secondaryDb = linkedDatabaseMapping ? linkedDatabaseMapping.secondaryDatabase : null;
        const verifyPermission = this.getPermissionChecker();

        const duplicatedMeasures = linkedDatabaseMapping ? linkedDatabaseMapping.duplicatedMeasures : null;

        return (
            <div className="list-container-arius" style={{ padding: 10 }}>
                <div className="list-header-arius" style={{ marginBottom: 10 }}>
                    <h4>Edit Linked Database
                        {db ? 
                            ': ' + db.databaseName
                            : ('')
                        }
                        
                    </h4>

                </div>
                <Form.Group
				controlId="selectDatabaseControl"
				style={{ width: '100%', marginTop: 10 }}
			>
				<Form.Label>Secondary Database</Form.Label>
				<div style={{ display: 'flex', alignItems: 'stretch'}}>
                    <Form.Control
                        style={{flexGrow: 1, maxWidth:'35vw'}}
                        as="select"
                        placeholder="select"
                        value={secondaryDb ? secondaryDb.databaseId : 'default'}
                        onChange={this.changeSecondaryDatabase}
                        disabled={!createNew}
				    >
                        <option value="default">Select a database</option>
                        {databases.map(db => (
                            <option key={`${db.workspaceId}`} value={db.workspaceId}>
                            {db.workspaceName}
                            </option>
                        ))}
                    </Form.Control>
                    {duplicatedMeasures && duplicatedMeasures.length > 0 ?
                    <div style={{marginLeft: 10, marginRight: 10, color: '#800020', flexGrow: 8, fontSize:14 }}>This database has measure names that match those in the primary database.  Arius will default to loading in data from the primary database if a mapped measure is found in both databases.</div>
                    : ('')}
                </div>
			</Form.Group>

            { isLoading ?
                <Loader loadingItem='Linked Mapping Info' />
                : isSavingLinkedDatabaseColumnMapping ? 
                <span>
                    <i className={`fa fa-refresh fa-spin menu-icon`}/>Saving mapping info...
                </span>
                : this.getColumnsGrid2()
            }

            <SaveButtons
                    saveHandler={this.saveMapping}
                    isSaveButtonDisabled={!verifyPermission(DATA_MODIFY)}
                    backButtonHander={() =>
                        browserHistory.push(`/tod/databases/${databaseId}/link`)}
                    backButtonText="Back to Linked Databases"
                />
            </div>
        );
    }

}



export default radium(LinkDatabaseEdit);