import * as React from 'react';
import { Modal, Form } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

class ProjectTaskParamsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedPrintListId: -1
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.executeTask = this.executeTask.bind(this);
    }

    get isValid() {
        const { taskId } = this.props;
        const { selectedPrintListId } = this.state;

        switch(taskId.toString()) {
            case "1":  // Export to APJ
                return true;
            case "2":  // Export to PDF
                return selectedPrintListId && parseInt(selectedPrintListId, 10) > 0;
            default:
                return false;
        }
    }

    getParamControls() {
        // currently the only task with parameters is PrintToPDF
        const { taskId, printLists } = this.props;
        const { selectedPrintListId } = this.state;
        switch(taskId.toString()) {
            case "1":  // Export to APJ
                return null;
            case "2":  // Export to PDF
                if (!printLists || !printLists.length){
                    return <span>There are no print lists saved to this database</span>
                }
                return <Form.Group controlId="selectedPrintListId">
                    <Form.Label>Select a print list:</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={this.handleChange}
                        value={selectedPrintListId}
                    >
                        <option value={-1}>-- select a print list --</option>
                        {printLists.map((s, idx) => (
                            <option key={`pli_${idx}`} value={s.exportListId}>{s.listName}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            default:
        }
    }

    executeTask() {
        // currently the only task with parameters is Export to PDF
        const { executeTaskHandler, taskId } = this.props;
        const { selectedPrintListId } = this.state;
        switch(taskId.toString()) {
            case "1":  // Export to APJ
                executeTaskHandler();
                break;
            case "2":  // Export to PDF
                executeTaskHandler(selectedPrintListId);
                return;
            default:
        }
    }

    handleOpen() {
        this.props.refreshPrintLists();
        this.setState({ selectedPrintListId: -1 });
    }

    handleChange(e) {
        const {id, value } = e.target;
        this.setState({[id]: value});
    }

    render() {
        const { show, closeHandler, printLists } = this.props;
        let cancelMessage = !printLists || !printLists.length ? 'Ok' : 'Cancel';

        return (
            <Modal show={show} onHide={closeHandler} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
                    <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                        <Modal.Title><small>{`Export to PDF`}</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {this.getParamControls()}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <button key="confirmButton"
                        disabled={!this.isValid}
                        hidden = {!printLists || !printLists.length}
                        className="btn btn-info dialog-button"
                        onClick={this.executeTask}
                    >Run Task</button>
                    <button key="nobutton"
                        className="btn btn-info dialog-button"
                        onClick={closeHandler}>{cancelMessage}</button>
                    </Modal.Footer>
            </Modal>
        );
    }
}

export default ProjectTaskParamsModal;