import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

class ProjectReviewConfirmationModal extends Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            available: null,
            assigned: null,    
        };
   
        this.save= this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleCommentsChange = this.handleCommentsChange.bind(this);
    }

    handleCommentsChange(e) {
        const comments = e.target.value;
        this.setState({ comments: comments });  
    }

    save() {
        const {saveCallback}=this.props;
        const { comments } = this.state;
        saveCallback(comments);
    }

    handleOpen(){
        this.setState({comments: ''});
    }

    handleClose(){
        this.props.closeCallback();
    }
  
    render() {

        const {show, title, message, isFinalReview}=this.props;
        const {comments} = this.state;
        const finalMessage = isFinalReview ? (
            <div className="col-sm-12" style={{marginBottom: '20px'}}>
                <h4>This action will move this project to Complete</h4>
            </div>
        ) : (<span></span>);

        return (
            
            <Modal 
                show={show} 
                onHide={this.handleClose} 
                onEnter={this.handleOpen} 
                size='lg'>
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{marginBottom: '20px'}}>
                        <h5>{message}</h5>
                    </div>
                    {finalMessage}
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group">
                            <label htmlFor="roleDescription">Add a comment:</label>
                            <textarea style={{height:'150px'}}
                                id="comments"
                                value={comments}
                                onChange={this.handleCommentsChange}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>
                </fieldset> 
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button key="confirmButton"
                    className="btn btn-info dialog-button"
                    onClick={this.save}>Yes</button>
                <button key="nobutton"
                    className="btn btn-info dialog-button"
                    onClick={this.handleClose}>No</button>
            </Modal.Footer>
        </Modal>
        );
        }
    }
  
    export default ProjectReviewConfirmationModal;

