import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Link } from 'react-router-dom';
import {  NavTab, NavSideTabs } from '@app/utilities/routing';
import * as GlobalStyles from '@app/utilities/globalStyles';

class Sidebar extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { baseUrl } = this.props;
        return (
        <div className='sidebar' style={GlobalStyles.sidebar}>
            <div className="analysis-home-link" style={{marginTop: 20, padding: '0px 20px' }}>
                <Link to={"/arius"}>
                    <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ paddingRight: 2 }}
                    aria-hidden="true"
                    />
                    Analysis Home
                </Link>
            </div>
            <NavSideTabs>
                <NavTab label='Projects' to={`${baseUrl}/projects`}/>
                <NavTab label='Collection Sets' to={`${baseUrl}/lists/collectionsets`}/>
                <NavTab label='Print Lists' to={`${baseUrl}/lists/printlists`}/>
                <NavTab label='Excel Lists' to={`${baseUrl}/lists/excellists`}/>
                <NavTab label='Exhibit Options' to={`${baseUrl}/lists/exhibitoptions`}/>
                <NavTab label='Data Defaults' to={`${baseUrl}/lists/datadefaults`}/>
                <NavTab label='Exhibit Defaults' to={`${baseUrl}/lists/exhibitdefaults`}/>
                <NavTab label='Review Levels' to={`${baseUrl}/reviewlevels`}/>
                <NavTab label='Measure Mappers' to={`${baseUrl}/mappers`}/>
                <NavTab label='Project Categories' to={`${baseUrl}/categories`}/>
            </NavSideTabs> 
        </div>
        );
    }
}

export default radium(Sidebar);
