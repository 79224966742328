import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import { clearErrorMessage } from '@app/actions/application.actions';
import {
  fetchDatabaseMappings,
  fetchDatabase,
  deleteDataMapper,
  fetchDateFormats,
  fetchDateFormatDelimiters,
  createDataMapper,
  updateDataMapper,
  fetchDatbaseColumns,
  fetchDataMapper,
  fetchColumnTypes,
  selectMapper,
} from '@app/actions/tod/todDatabases.actions';
import {
  fetchUploads,
  fetchColumnsForFile,
} from '@app/actions/tod/uploads.actions';
import MappingsList from '@app/tod/mappingsList';
import CreateMapper from '@app/tod/createMapper.js';

class DbMapper extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    userKey: PropTypes.string.isRequired,
    application: PropTypes.object,
    getDatabaseMappings: PropTypes.func,
    getDatabase: PropTypes.func,
    getDateFormats: PropTypes.func,
    getDateFormatDelimiters: PropTypes.func,
    getColumnTypes: PropTypes.func,
    columnTypes: PropTypes.array,
    currentDb: PropTypes.object,
    mappings: PropTypes.object,
    dataMappers: PropTypes.array,
    isFetching: PropTypes.bool,
    deleteMapper: PropTypes.func,
    getUploads: PropTypes.func,
    getColumnsForCsvFile: PropTypes.func,
    dateFormats: PropTypes.array,
    dateFormatDelimiters: PropTypes.array,
    currentMapper: PropTypes.object,
    uploads: PropTypes.array,
    fileColumns: PropTypes.array,
    createNewMapper: PropTypes.func,
    getDatabaseColumns: PropTypes.func,
    getMapper: PropTypes.func,
    updateMapper: PropTypes.func,
    isSaving: PropTypes.bool,
    errorMessage: PropTypes.string,
    clearApplicationError: PropTypes.func,
    selectMapperHandler: PropTypes.func,
  };

  componentDidMount() {
    const {
      userKey,
      getDatabaseMappings,
      getDatabase,
      getDateFormats,
      getDateFormatDelimiters,
      getColumnTypes,
      match, 
      columnTypes,
    } = this.props;
    let params = match ? match.params : {};

    getDatabase(userKey, params.databaseId);
    getDatabaseMappings(userKey, params.databaseId);
    getDateFormats(userKey);
    getDateFormatDelimiters(userKey);

    if (columnTypes.length === 0) {
      getColumnTypes(userKey);
    }
  }

  componentDidUpdate() {
    const { isFetching } = this.props;
    if (isFetching) {
      return;
    }

    // TODO - WDD seems like the database should only be fetched on mount, so commenting this out for now...
    // if (params.databaseId) {
    //   const databaseId = parseInt(params.databaseId, 10);
    //   if (databaseId) {
    //     if (!currentDb || currentDb.id !== databaseId) {
    //         console.error('DID UPDATE, gettingdb: ', params.databaseId, currentDb);
    //       getDatabase(userKey, databaseId);
    //     }
    //   }
    // }
  }

  render() {
    const {
      userKey,
      history,
      dispatch,
      currentDb,
      mappings,
      dataMappers,
      isFetching,
      deleteMapper,
      getUploads,
      getColumnsForCsvFile,
      dateFormats,
      dateFormatDelimiters,
      currentMapper,
      uploads,
      fileColumns,
      createNewMapper,
      getDatabaseColumns,
      columnTypes,
      getMapper,
      updateMapper,
      isSaving,
      getDatabase,
      errorMessage,
      clearApplicationError,
      selectMapperHandler,
    } = this.props,
      // TODO: Fix TOD Permissions
      isUserDbAdmin = true;

    let childProps = {
        browserHistory: history,
        getDatabase,
        userKey,
        dispatch,
        currentDb,
        mappings,
        dataMappers,
        isFetching,
        deleteMapper,
        getUploads,
        getColumnsForCsvFile,
        dateFormats,
        dateFormatDelimiters,
        currentMapper,
        fileColumns,
        uploads,
        createNewMapper,
        getDatabaseColumns,
        columnTypes,
        getMapper,
        updateMapper,
        isSaving,
        errorMessage,
        clearApplicationError,
        isUserDbAdmin,
        selectMapperHandler,
    }


    return (
        <Switch>
            <Route path="/tod/databases/:databaseId/mappings/new" 
                render={props => <CreateMapper {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases/:databaseId/mappings/:mappingId" 
                render={props => <CreateMapper {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/tod/databases/:databaseId/mappings" 
                render={props => <MappingsList {...{...childProps, params: props.match.params}}/>}/>
        </Switch>
    )
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  mappings: state.tod.databases.mappings,
  dataMappers: state.tod.databases.mappings.items,
  currentDb: state.tod.databases.current,
  currentMapper: state.tod.databases.mappings.current,
  isFetching: state.tod.databases.isFetching,
  isSaving: state.tod.databases.mappings.isSaving,
  dateFormats: state.tod.databases.dateFormats,
  dateFormatDelimiters: state.tod.databases.dateFormatDelimiters,
  uploads: state.tod.uploads.items,
  fileColumns: state.tod.uploads.columns,
  columnTypes: state.tod.databases.columnTypes,
  errorMessage: state.application.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  getDatabaseMappings: (userKey, databaseId) => {
    dispatch(fetchDatabaseMappings(userKey, databaseId));
  },
  getDatabaseColumns: (userKey, databaseId, factTableGuid) => {
    dispatch(fetchDatbaseColumns(userKey, databaseId, factTableGuid));
  },
  getColumnTypes: userKey => {
    dispatch(fetchColumnTypes(userKey));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  deleteMapper: (userKey, databaseId, mapperId) => {
    dispatch(deleteDataMapper(userKey, databaseId, mapperId));
  },
  getMapper: (userKey, databaseId, mapperId) => {
    dispatch(fetchDataMapper(userKey, databaseId, mapperId));
  },
  getUploads: userKey => {
    dispatch(fetchUploads(userKey));
  },
  getColumnsForCsvFile: (userKey, fileId) => {
    dispatch(fetchColumnsForFile(userKey, fileId));
  },
  getDateFormats: userKey => {
    dispatch(fetchDateFormats(userKey));
  },
  getDateFormatDelimiters: userKey => {
    dispatch(fetchDateFormatDelimiters(userKey));
  },
  createNewMapper: (
    {
      userKey,
      browserHistory,
      databaseId,
      mapperName,
      mapperDescription,
      factTableGuid,
      columns,
    },
  ) => {
    dispatch(
      createDataMapper({
        userKey,
        browserHistory,
        databaseId,
        mapperName,
        mapperDescription,
        factTableGuid,
        columns,
      }),
    );
  },
  updateMapper: (
    {
      userKey,
      browserHistory,
      databaseId,
      mapperId,
      mapperName,
      mapperDescription,
      factTableGuid,
      columns,
    },
  ) => {
    dispatch(
      updateDataMapper({
        userKey,
        browserHistory,
        databaseId,
        mapperId,
        mapperName,
        mapperDescription,
        factTableGuid,
        columns,
      }),
    );
  },
  clearApplicationError: () => {
    dispatch(clearErrorMessage());
  },
  selectMapperHandler: (mapper, databaseId) => {
    dispatch(selectMapper(mapper, databaseId));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DbMapper);
