import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { notifyError } from '@app/utilities/notifier';
import RollupPickerModal from './rollupPickerModal';

class ManualEntryControl extends Component {
    static propTypes = {
        id: PropTypes.string,
        values: PropTypes.string
    };
    constructor(props) {
        super(props);
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.importFile = this.importFile.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    handleChange(e) {
        const { handleChange } = this.props;
        let v = e.target.value;
        v = v.split(',,').join(','); // replaceALL...
        v = v.split(',').join('\n'); // replaceALL...
        let values = v.split('\n');
        handleChange({target: {id: this.props.id, value: JSON.stringify(values)}}); 
    }

    importFile(evt) {
        var files = evt.target.files; // FileList object
    
        if (!files || files.length !== 1){
            notifyError('Error importing file');
            return;
        }

        var reader = new FileReader();

        reader.onload = (e) => {
            // TODO - handle parsing, verifying file format, etc
           //console.error('the text: ', reader.result);
           this.handleChange({target: {value: reader.result}});
        }
        
        reader.readAsText(files[0]);
    }

    onDrop = (evt, target) => {
        evt.stopPropagation();
        evt.preventDefault();

        var files = evt.dataTransfer.files; // FileList object.
        this.importFile({target: {files}});
    }

    handleDragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
      }

    render(){
        const {values, column} = this.props;
        let data = values ? JSON.parse(values) : [];
        let displayStr = data.join('\n');
        return (
        <div className="col-sm-12" style={{}}>
            <div className="row form-group">
                <div className="col-sm-12">
                    <label className="pull-left" htmlFor="roleDescription">Enter values:</label>
                    <div className="pull-right">
                        <RollupPickerModal 
                            handleChange={(values => this.handleChange({target: {value: values}}))}
                            columnName={column ? column.name : null}
                        ></RollupPickerModal>
                        <label className="btn btn-outline-secondary btn-file" style={{marginBottom: 5}}>
                            Import From CSV File 
                            <input type="file" style={{display: 'none'}} onChange={this.importFile}
                                accept=".csv,text/plain,text/csv" />
                        </label>
                    </div>
                </div>
                
                <textarea style={{height: 'calc(100vh - 470px)'}}
                    id="values"
                    value={displayStr}
                    onChange={this.handleChange}
                    // onDragOver={this.handleOnDragOver}
                    // onDrop={this.onDrop}
                    className="form-control"
                    type="text"
                />
            </div>
        </div>

        );
    }
}
const mapStateToProps = state => ({
});
    
const mapDispatchToProps = dispatch => ({
    dispatch,
});
    
export default connect(mapStateToProps, mapDispatchToProps)(ManualEntryControl);

