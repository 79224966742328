import { callApi } from '../utilities/callApi';

export function retrievePerformanceInfo(userKey) {
    return callApi(
      '/performance',
      { 'X-AE-USERKEY': userKey },
    );
}

export function retrieveDatabaseOptions(userKey) {
    return callApi(
      '/performance/options/database',
      { 'X-AE-USERKEY': userKey }
    );
}

export function retrieveElasticPoolOptions(userKey) {
    return callApi(
      '/performance/options/elasticpool',
      { 'X-AE-USERKEY': userKey },
    );
}

export function retrieveAppServicePlanOptions(userKey, appServicePlanName) {
    return callApi(
      `/performance/options/appserviceplan/${appServicePlanName}`,
      { 'X-AE-USERKEY': userKey }
    );
}

export function updateDatabase(userKey, request) {
    return callApi(
      `/performance/database`,
      { 'X-AE-USERKEY': userKey },
      'PUT',
      request,
    );
}

export function updateElasticPool(userKey, request) {
    return callApi(
      `/performance/elasticpool`,
      { 'X-AE-USERKEY': userKey },
      'PUT',
      request,
    );
}

export function updateAppServicePlan(userKey, request) {
    return callApi(
      `/performance/appserviceplan`,
      { 'X-AE-USERKEY': userKey },
      'PUT',
      request,
    );
}

export function retrievePerformanceJobStatus(userKey, jobId) {
  return callApi(
    `/performance/status/${jobId}`,
    { 'X-AE-USERKEY': userKey },
    'GET', // method
    null, // body
    true, // silentError, don't show errors
  );
}

export function powerBIAction(userKey, actionRequest) {
  return callApi(
    `/performance/powerbi/action`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    actionRequest,
  );
}

export function updatePowerBI(userKey, request) {
  return callApi(
    `/performance/powerbi`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    request,
  );
}

export function retrievePowerBIOptions(userKey, capacityName) {
  return callApi(
    `/performance/options/powerbi/${capacityName}`,
    { 'X-AE-USERKEY': userKey }
  );
}