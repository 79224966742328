import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import Loader from '@app/shared/presentational/loader';
import { COMMAND_STATUSES } from '@app/utilities/constants';
import * as Colors from '@app/utilities/colors';
import moment from 'moment';
import {
  ButtonGroup,
  Button,
} from 'react-bootstrap';
import { Grid } from '@arius';
import * as automationServiceApi from '@app/api/automation.serviceApi';

function prepareInitialized(cs) {
    const unrecognized = (cs.statusParams.unrecognized || []).map(f => <li>{f}</li>);
    const inputs = [];
    const sdfs = [];
    const isdfs = [];
    for (let i = 0; i < cs.statusParams.data.length; i++) {
        const t = cs.statusParams.data[i];
        switch (t.dataType) {
            case 1: 
                inputs.push(<li>{t.name}({t.id})</li>);
                break;
            case 2: 
                sdfs.push(<li>{t.name}({t.id})</li>);
                break;
            case 3: 
                isdfs.push(<li>{t.name}({t.id})</li>);
                break;
            default:
                break;
        }
    }
    const sUnrec = unrecognized.length === 0 ? null : <span>Unrecognized<ul>{unrecognized}</ul></span>;
    const sInputs = inputs.length === 0 ? null : <span>Inputs<ul>{inputs}</ul></span>;
    const sFactors = sdfs.length === 0 ? null : <span>Selected factors<ul>{sdfs}</ul></span>;
    const sInterp = isdfs.length === 0 ? null : <span>Interpolated factors<ul>{isdfs}</ul></span>;
    return { 
        ...cs,
        statusMessage: (<div>{sUnrec}{sInputs}{sFactors}{sInterp}</div>),
        status: unrecognized.length > 0 ? COMMAND_STATUSES.FAILED : cs.status,
    };
}

function prepareDataLoaded(cs) {
    return cs;
}

function fromJson(val) {
    try {
        return typeof val === 'string' ? JSON.parse(val) : val;
    } catch (ex) {
        return val;
    }
}

function prepareApplyToSegments(cs) {

    const selector = [];
    for (const p in cs.commandParams) {
        if (cs.commandParams.hasOwnProperty(p)) {
            selector.push(<li><span className="lbl">{p}: </span>{cs.commandParams[p]}</li>);
        }
    }

    const found = cs.statusParams.matched || fromJson(cs.statusParams.params) || [];
    const locked = cs.statusParams.locked || [];

    let msg1 = null;
    if (found.length > 0) {
        msg1 = (<span>Matching segments:<ul>{found.map(s => <li>{s}</li>)}</ul></span>);
    }
    
    let msg2 = null;
    if (locked.length > 0) {
        msg2 = <span>Locked tables:<ul>{locked.map(s => <li>{s}</li>)}</ul></span>;
    }

    let statusMessage = null;
        
    if (!msg1&!msg2) {
        statusMessage = <span className="errorStr">No segments found</span>
    } else {
        statusMessage = <div>{msg1}{msg2}</div>
    }

    return { 
        ...cs, 
        statusMessage: statusMessage, 
        commandMessage: <span>Apply to segments{selector.length > 0 ? <ul>{selector}</ul> : null}</span>,
        status: found.length > 0 ? cs.status : COMMAND_STATUSES.FAILED,
    };
}

class StatusTaskDetail extends Component {
    static propTypes = {
        height: PropTypes.string,
        commandStatuses: PropTypes.array,
        isFetchingCommandStatuses: PropTypes.bool,
        job: PropTypes.object,
        project: PropTypes.object,
        fetchCommandStatuses: PropTypes.func,
        userKey: PropTypes.string,
        currentWorkspace: PropTypes.object,
        maxHeight: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isFetchingCommandStatuses: false,
            commandStatuses: []
        };
        this.buildStatusMessage = this.buildStatusMessage.bind(this);
        this.fetchCommandStatuses = this.fetchCommandStatuses.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    componentDidMount() {
        this.fetchCommandStatuses();
    }

    buildStatusMessage(cs) {
        try {
            if (cs.statusParams) {
                switch (cs.command) {
                case 'Initialize':
                    return prepareInitialized(cs);
                case 'DataLoaded':
                    return prepareDataLoaded(cs);
                case 'ApplyToSegments':
                    return prepareApplyToSegments(cs);
                default:
                    break;
                }
            }
            return cs;
        } catch (ex) {
            return cs;
        }
    }

    fetchCommandStatuses() {
        const { userKey, currentWorkspace, job } = this.props;
        let jobId = job ? job.operationId : 0;
        let databaseId = currentWorkspace ? currentWorkspace.id : 0;
        this.setState({ isFetchingCommandStatuses: true });
        automationServiceApi.getCommandStatuses({ userKey, databaseId, jobId }).then((data) => {
            this.setState({commandStatuses: data, isFetchingCommandStatuses: false});
        })
    }

    getStatusIcon(status){
        let statusIcon = '';
        switch (status) {
            case COMMAND_STATUSES.NOT_EXECUTED:
                break;
            case COMMAND_STATUSES.FAILED:
                statusIcon = <i className="fa fa-times" style={{ color: 'red' }} aria-hidden="true"></i>;
                break;
            case COMMAND_STATUSES.EXECUTING:
                statusIcon = (
                    <span>
                    <i className="fa fa-cog fa-spin fa-fw" style={{ color: Colors.purple }}></i>
                    <span className="sr-only">Loading...</span>
                    </span>
                );
                break;
            case COMMAND_STATUSES.COMPLETED:
                statusIcon = <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true"></i>;
                break;
            case COMMAND_STATUSES.CANCELLED:
                statusIcon = (
                    <span className="fa-stack fa-lg">
                    <i className="fa fa-cog fa-stack-1x"></i>
                    <i className="fa fa-ban fa-stack-2x text-danger"></i>
                    </span>
                );
                break;
            default:
                break;
        }
        return statusIcon;
    }
    
    getGrid() {
        const { commandStatuses } = this.state;

        let columns = [
            { headerText: 'Command',
                template: (r) => (
                        <span style={{
                            margin: 8,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                    }}>{r.commandMessage || r.command}</span>
                    )},
            { headerText: 'Last Updated', field: 'lastUpdated'},
            { headerText: 'Status', width: '150px', template: (r) => (
                <span>{r.statusMessage || r.status}</span>
            )},
            { headerText: '  ', width: '100px', template: (r) => (
                <span>{this.getStatusIcon(r.status)}</span>
            )},
        ];

        return <Grid 
            columns={columns}
            data={commandStatuses.map(this.buildStatusMessage)}
            height='auto'
            allowSorting={false}
        />
    }

    getInfoRow(){
        const { job, } = this.props;
        return (
        <div style={{height: 45}}>
            <span style={{ color: Colors.purple }}><b>{job.operationCode}</b>&nbsp;</span>
            <span>submitted by&nbsp;</span>
            <span style={{ color: Colors.purple }}><b>{job.requestor}</b>&nbsp;</span>
            <span>at&nbsp;</span>
            <span style={{ color: Colors.purple }}><b>{moment.utc(job.requestTime).local().format('L LT')}</b>&nbsp;</span>
            <span style={{ float: 'right' }}>
                <ButtonGroup>
                <Button
                    onClick={this.fetchCommandStatuses}
                    variant="arius">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                </Button>
                </ButtonGroup>
            </span>
        </div>
        );
    }

    getLoader() {
        const { isFetchingCommandStatuses } = this.state;
        return (<div style={{ 
                display: isFetchingCommandStatuses ? 'flex' : 'none', 
                justifyContent: 'center', width: '100%', margin: 'auto' }}>
                    <Loader loadingItem="Command Reports" />
        </div>);
    }

    render() {
        const { job } = this.props;
        const { isFetchingCommandStatuses } = this.state;

        if (job.isExtract || job.isBackendReport){
            return null;
        }
        return (
            <div className="task-grid-detail">
                {this.getLoader()}
                
                <span style={{display: !isFetchingCommandStatuses ? 'block' : 'none', }}>
                    {this.getInfoRow()}
                    {this.getGrid()}
                </span>
            </div>
        );
    }
}

export default radium(StatusTaskDetail);
