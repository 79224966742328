import { callApi } from '../utilities/callApi';


// UserSettings
export function fetchApiKey(userKey) {
  return callApi(
    '/apikeys',
    { 'X-AE-USERKEY': userKey }
  );
}

export function requestNewKey(userKey) {
  return callApi(
    '/apikeys',
    { 'X-AE-USERKEY': userKey },
    'POST'
  );
}

export function deleteApiKey(userKey) {
  return callApi(
    '/apikeys',
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

// GeneralSettings
