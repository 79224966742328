import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import moment from 'moment';
import { saveAs } from 'file-saver';
import DetailRow from './todDatabaseDetailRow';
import TodDatabaseInfoModal from './todDatabaseInfoModal';
import DatabaseBackupModal
  from '../../shared/presentational/databaseBackup/databaseBackupModal';
import { showModal } from '../../../actions/modal.actions';
import { notifyError } from '../../../utilities/notifier';
import { SITE_CREATETODDATABASE } from '../../../utilities/permissions';
import Loader from '../../shared/presentational/loader';
import { Grid, Button } from '@arius';
import NoResults from '../../shared/presentational/noResults';
import ActionItems from '@app/tod/todDatabaseActions';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { 
    clearLinkedDatabases 
} from '@app/actions/tod/todDatabases.actions';
import PurpleButton from '@app/shared/presentational/purpleButton';

class TodDatabasesList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        params: PropTypes.object.isRequired,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool,
        columnTypes: PropTypes.array,
        application: PropTypes.object,
        getDatabases: PropTypes.func,
        getCurrentDbVersion: PropTypes.func,
        selectDatabaseHandler: PropTypes.func,
        currentDatabase: PropTypes.object,
        getDatabase: PropTypes.func,
        getDatabaseColumns: PropTypes.func,
        getColumnTypes: PropTypes.func,
        deleteDatabaseHandler: PropTypes.func,
        databases: PropTypes.array,
        clearNotificationHandler: PropTypes.func,
        newSaveHandler: PropTypes.func,
        updateSaveHandler: PropTypes.func,
        serverNotification: PropTypes.object,
        uploads: PropTypes.array,
        getUploads: PropTypes.func,
        getColumnsForCsvFile: PropTypes.func,
        fileColumns: PropTypes.array,
        getDatabaseInfo: PropTypes.func,
        deleteValuationDateHandler: PropTypes.func,
        deleteDataLoadHandler: PropTypes.func,
        getBackups: PropTypes.func,
        createBackupHandler: PropTypes.func,
        deleteBackupHandler: PropTypes.func,
        deleteArchivedBackupHandler: PropTypes.func,
        restoreBackupHandler: PropTypes.func,
        restoreArchivedBackupHandler: PropTypes.func,
        archiveBackupHandler: PropTypes.func,
        backups: PropTypes.array,
        archivedBackups: PropTypes.array,
        isSaving: PropTypes.bool,
        errorMessage: PropTypes.string,
        validateFormulas: PropTypes.func,
        clearErrorMessageHandler: PropTypes.func,
        verifyPermission: PropTypes.func,
        dataLoadDeletesInProgress: PropTypes.array,
        valuationDateDeletesInProgress: PropTypes.array,
        getPointInTimeRestoreRange: PropTypes.func,
        restorePointInTimeHandler: PropTypes.func,

        retrieveLogFile: PropTypes.func,
        deleteLogFileFromServer: PropTypes.func,
        logFile: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            databaseInfoModalOpen: false,
            databaseBackupModalOpen: false,
            columnSelected: '',
            isDescending: false,
            alreadyFetched: false,
            modalDatabaseId: null,
            permissions: [],
        };
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.closeDatabaseInfoModal = this.closeDatabaseInfoModal.bind(this);
        this.openDatabaseInfoModal = this.openDatabaseInfoModal.bind(this);
        this.closeDatabaseBackupModal = this.closeDatabaseBackupModal.bind(this);
        this.openDatabaseBackupModal = this.openDatabaseBackupModal.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
        this.downloadLogFile = this.downloadLogFile.bind(this);
    }

    componentDidMount() {
        if (this.props.databases && this.props.databases.length) {
            this.setState({ alreadyFetched: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { databases } = this.props;
        if (databases !== nextProps.databases) {
            this.setState({ alreadyFetched: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { linkedDatabases, dataLoadLogFile } = this.props;

        if (
            prevProps.errorMessage !== this.props.errorMessage &&
            this.props.errorMessage
        ) {
            notifyError(this.props.errorMessage);
            this.props.clearErrorMessageHandler();
        }

        if (linkedDatabases){
            this.clearLinkedDatabases();
        }

        if (prevProps.dataLoadLogFile !== dataLoadLogFile){
            saveAs(dataLoadLogFile, 'DataLoadLogFile.txt');
        }
    }

    downloadLogFile() {
        const { retrieveDataLoadLogFile, userKey } = this.props;
        retrieveDataLoadLogFile(userKey);
    }

    clearLinkedDatabases = () => {
        const { dispatch } = this.props;
        dispatch(clearLinkedDatabases());
    }

    deleteClickHandler(id, e) {
        e.stopPropagation();

        const { dispatch, databases } = this.props;

        if (databases.length !== 0) {
            const selectedDatabase = databases.find(ws => ws.workspaceId === id),
            deleteMessageItems = [
            <li key={selectedDatabase.workspaceId} style={{ fontWeight: 800 }}>
                {selectedDatabase.workspaceName}
            </li>,
            ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following databases?'
            );

            const yesClickHandler = () => {
                const { userKey, deleteDatabaseHandler } = this.props;
                deleteDatabaseHandler(userKey, id);
            };
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    closeDatabaseInfoModal() {
        this.setState({ databaseInfoModalOpen: false });
    }

    closeDatabaseBackupModal(refreshCurrentDatabase) {
        if (refreshCurrentDatabase) {
            const { modalDatabaseId } = this.state;
            const { userKey, getDatabase } = this.props;
            getDatabase(userKey, modalDatabaseId);
        }

        this.setState({ databaseBackupModalOpen: false, modalDatabaseId: null});
    }

    openDatabaseInfoModal(e, currentDatabase) {    
        const { userKey, getDatabaseInfo, databases } = this.props,
        databaseId = currentDatabase.id || currentDatabase.workspaceId;
        this.props.selectDatabaseHandler(currentDatabase);
        getDatabaseInfo(userKey, databaseId, currentDatabase.factTableGuid);
        const selectedDatabase = databases.find(
            db => db.workspaceId === databaseId
        );
        this.setState({ databaseInfoModalOpen: true, permissions: selectedDatabase.permissions });
    }

    openDatabaseBackupModal(id) {
        this.setState({ databaseBackupModalOpen: true, modalDatabaseId: id });
    }

    getActionItems(database) {
        const {
            deleteClickHandler,
            userPermissions,
            browserHistory
        } = this.props;

        return <ActionItems 
            database={database} 
            userPermissions={userPermissions}
            deleteClickHandler={deleteClickHandler}
            openBackupModalHandler={this.openDatabaseBackupModal}
            viewInfoHandler={this.openDatabaseInfoModal}
            browserHistory={browserHistory}
            deleteHandler={this.deleteClickHandler}
        />;
    }

    getLinkIndicatorTemplate = (r) => {
        const { browserHistory } = this.props;

        let tooltip = "";
        if (r.databaseLinkIndicator === 1) {
            tooltip = "primary database";
        } else if (r.databaseLinkIndicator === 2) {
            tooltip = "secondary database";
        } else if (r.databaseLinkIndicator === 3) {
            tooltip = "primary/secondary database";
        }

        const path = `/tod/databases/${r.workspaceId}/link`;

        return(
        <span> {
            r.databaseLinkIndicator > 0
                ? <OverlayTrigger
                    placement="right"
                    rootClose={true}
                    overlay={<Tooltip className='tool-tip-fix'>{tooltip}</Tooltip>}
                  >
                    <Badge pill variant="info"
                            id={`toolTip-linkindicator-${r.workspaceId}`}
                            style={{cursor:'pointer'}}
                            onClick={() => {
                                //if (isAllowed) {
                                    browserHistory.push(path);
                                //} else {
                                //    notAuthorized();
                                //}
                            }}
                        >{r.databaseLinkIndicator}
                    </Badge>
                </OverlayTrigger>
                : ''
        } </span>
        );
    };

    getGrid(data) {

        const columns = [
            { field: 'workspaceName', headerText: 'Name', template: (database) => {
                return <span>{database.workspaceName} {database.isOnline ? '' : ' (Offline)'}</span>
            }},
            { field: 'databaseLinkIndicator', headerText: '', width: 30,
                template: this.getLinkIndicatorTemplate,
                enableTooltips: false,
            },

            { field: 'workspaceDescription', headerText: 'Description'},
            { field: 'createdBy', headerText: 'Created By'},
            { field: 'createdDate', headerText: 'Created'},
            { field: 'lastValuationDate', headerText: 'Latest Valuation', 
                // don't convert to local time, just take the date
                template: (r)=> { return <span>{moment(r.lastValuationDate).isValid() ? 
                    moment(r.lastValuationDate).format('MM/DD/YYYY') : ''}</span>}
            },
            { headerText: 'Actions', template: this.getActionItems},
        ];

        return <Grid 
                columns={columns}
                data={data}
                height='calc(100vh - 180px)' 
                detailTemplate={this.getDetailTemplate}
            />
    }

    getDetailTemplate(row) {
        const { columnTypes } = this.props;
        return <DetailRow row={row} columnTypes={columnTypes}/>;
    }

    render() {
        const {
            databases,
            isFetching,
            currentDatabase,
            userKey,
            deleteValuationDateHandler,
            deleteDataLoadHandler,
            serverNotification,
            clearNotificationHandler,
            getBackups,
            createBackupHandler,
            deleteBackupHandler,
            deleteArchivedBackupHandler,
            restoreBackupHandler,
            restorePointInTimeHandler,
            undoPITRHandler,
            restoreArchivedBackupHandler,
            archiveBackupHandler,
            getDatabases,
            verifyPermission,
            dataLoadDeletesInProgress,
            valuationDateDeletesInProgress,
            getPointInTimeRestoreRange,
            retrieveLogFile,
            deleteLogFileFromServer,
            logFile,
            jobStatus,
        } = this.props,
        { modalDatabaseId } = this.state;

        const modalDatabase = databases.find(db => db.workspaceId === modalDatabaseId) || databases.find(db => db.id === modalDatabaseId);
        const { alreadyFetched } = this.state;
        let contentMarkup = <div style={{ width: '65vw', margin: '5vh auto' }}><Loader loadingItem="Databases" /></div>;

        if (!isFetching || alreadyFetched) {
            contentMarkup = (
                <div style={{ width: '65vw', margin: '5vh auto' }}>
                <NoResults />
                </div>
            );
            if (databases.length > 0) {
                contentMarkup = this.getGrid(databases);
            }
        }
        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>Manage Triangles on Demand Databases</h4>
                <div style={{display: 'flex'}}>
                    <PurpleButton 
                        id="downloadReport" 
                        clickHandler={this.downloadLogFile} 
                        message="Download data load log file" 
                        type="download"
                        waiting={false}>
                    </PurpleButton>
                    <Button
                        path="/tod/databases/new"
                        isAllowed={verifyPermission(SITE_CREATETODDATABASE)}
                        toolTip={"Add new Database"}
                        mode="add"
                    />
                </div>
            </div>
            {contentMarkup}
            <TodDatabaseInfoModal
                userKey={userKey}
                database={currentDatabase}
                modalId="databaseInfoModal"
                showModal={this.state.databaseInfoModalOpen}
                closeHandler={this.closeDatabaseInfoModal}
                permissions={this.state.permissions}
                deleteValuationDateHandler={deleteValuationDateHandler}
                deleteDataLoadHandler={deleteDataLoadHandler}
                dataLoadDeletesInProgress={dataLoadDeletesInProgress}
                valuationDateDeletesInProgress={valuationDateDeletesInProgress}
            />
            <DatabaseBackupModal
                userKey={userKey}
                modalId="todBackupModal"
                showModal={this.state.databaseBackupModalOpen}
                database={modalDatabase}
                closeHandler={this.closeDatabaseBackupModal}
                serverNotification={serverNotification}
                clearNotificationHandler={clearNotificationHandler}
                getBackupsHandler={getBackups}
                createBackupHandler={createBackupHandler}
                availableBackups={modalDatabase ? modalDatabase.backups : []}
                archivedBackups={modalDatabase ? modalDatabase.archived : []}
                deleteBackupHandler={deleteBackupHandler}
                restoreBackupHandler={restoreBackupHandler}
                restoreArchivedHandler={restoreArchivedBackupHandler}
                archiveBackupHandler={archiveBackupHandler}
                deleteArchivedHandler={deleteArchivedBackupHandler}
                getDatabases={getDatabases}
                getRestorePointInTimeRangeHandler={getPointInTimeRestoreRange}
                earliestRestorePoint={modalDatabase ? modalDatabase.earliestRestorePoint : ''}
                latestRestorePoint={modalDatabase ? modalDatabase.latestRestorePoint : ''}
                restorePointInTimeHandler={restorePointInTimeHandler}
                undoPITRHandler={undoPITRHandler}
                retrieveLogFile={retrieveLogFile}
                deleteLogFileFromServer={deleteLogFileFromServer}
                logFile={logFile}
                jobStatus={jobStatus}
                jobStatusPrefix = {'TOD'}
            />
        </div>
        );
    }
}

export default radium(TodDatabasesList);
