import {
  RECEIVED_MESSAGE_FROM_SERVER,
  CLEARING_SERVER_NOTIFICATIONS } from '../actions/actionTypes';

const serverNotification = (state = {
  message: '',
  subject: null,
  receivedAt: null,
  data: null }, action) => {
  switch (action.type) {
    case CLEARING_SERVER_NOTIFICATIONS:
      return Object.assign({}, state, {
        message: '',
        receivedAt: null,
        subject: null,
        data: null,
      });
    case RECEIVED_MESSAGE_FROM_SERVER:
      return Object.assign({}, state, {
        message: action.message,
        receivedAt: action.receivedAt,
        subject: action.subject,
        data: action.data,
      });
    default:
      return state;
  }
};

export default serverNotification;
