import {
  FETCHING_SUBSCRIPTION_USERS_FROM_SERVER,
  FETCHING_SUBSCRIPTION_USER_FROM_SERVER,
  RECEIVED_SUBSCRIPTION_USERS,
  RECEIVED_SUBSCRIPTION_USER,
  UPDATED_USER,
} from '../../actions/portalManagement/portalManagement.actionTypes';

const usersReducer = (state = {
  isFetching: false,
  subscriptionUsers: [],
  allActiveAndInactiveUsers: [],
  subscriptionUser: {},
}, action) => {
  switch (action.type) {
    case FETCHING_SUBSCRIPTION_USER_FROM_SERVER:
    case FETCHING_SUBSCRIPTION_USERS_FROM_SERVER:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVED_SUBSCRIPTION_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        isUserUpdated: false,
        subscriptionUsers: action.subscriptionUsers,
        allActiveAndInactiveUsers: action.allActiveAndInactiveUsers,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_SUBSCRIPTION_USER:
      return Object.assign({}, state, {
        isFetching: false,
        isUserUpdated: false,
        subscriptionUser: action.subscriptionUser,
        receivedAt: action.receivedAt,
      });
    case UPDATED_USER:
      return Object.assign({}, state, {
        isFetching: false,
        isUserUpdated: true,
        subscriptionUser: action.subscriptionUser,
        receivedAt: action.receivedAt,
      });
    default:
      return state;
  }
};

export default usersReducer;
