import { callApi } from '../utilities/callApi';

export function getAnalysisTasks(userKey){
  return callApi(
    `analysis/tasks`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function initiateTask(userKey, databaseId, initiateTaskRequest){
  return callApi(
    `analysis/tasks/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    initiateTaskRequest,
  );
}
