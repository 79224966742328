import { DataManager, UrlAdaptor, DataUtil } from '@syncfusion/ej2-data';
import { getToken } from './adalAdapter';
import { getAppSettings } from '../config';


function getBaseServiceUrl() {
  return getAppSettings().then(settings => {
    const { baseServiceUrl } = settings;
    return `https://${baseServiceUrl}`;
  });
}

function getSecureDataManager(userKey, path) {
    var dm = null;
    return new Promise((resolve, reject) => {
        getBaseServiceUrl().then( base => {
            getToken().then(token =>{
                dm = new SecureDataManager(`${base}/${path}`, [{ Authorization: `Bearer ${token}`, 'X-AE-USERKEY': userKey }])
                resolve(dm);
            });
        })
    });
}


class SecureDataManager extends DataManager {
    constructor(url, headers) {
        
        let adaptor = new UrlAdaptor();
        super({url, adaptor, headers});
        this._additionalPayload = {};
        adaptor.beforeSend = (dm, request, settings) => {
            let data = JSON.parse(settings.data);
            data = {...data, ...this._additionalPayload };
            settings.data = JSON.stringify(data);
        }   
    }
}   

class SecureDataManagerFactory {
    constructor(userKey, token) {
        this._userKey = userKey;
        this._token = token;
        getBaseServiceUrl().then( base => {
            this._baseUrl = base;
        });
    }

    get baseUrl() {return this._baseUrl;}
    get token() {return this._token;}

    getDataManager(path, additionalPayload = {}){
        if (!this._baseUrl) { return null;}        
        let url = `${this._baseUrl}/${path}`;
        let headers = [{ Authorization: `Bearer ${this._token}`, 'X-AE-USERKEY': this._userKey }];
        let adaptor = new UrlAdaptor();
        let dm = new DataManager({url, adaptor, headers});
        adaptor.beforeSend = (dm, request, settings) => {
            // WORKAROUND FROM SYNCFUSION TO KEEP DATAMANGER FROM PARSING DATES
            DataUtil.parse.jsonReviver = function (field, value) { 
                return value; 
            }; 
            // END WORKAROUND
            let data = JSON.parse(settings.data);
            data = {...data, ...additionalPayload };
            settings.data = JSON.stringify(data);
        }  

        getToken().then(token => {
            this._token = token;  // keep the token refreshed 
        });

        return dm;    
    }
}   

export { getSecureDataManager, SecureDataManagerFactory };