import {
  RECEIVE_SEGMENTS,
  FETCHING_SEGMENTS_FROM_SERVER,
  CLEAR_SEGMENTS,
} from './analysis.actionTypes';
import * as segmentServiceApi from '../../serviceApi/segments.serviceApi';

function fetchingFromServer() {
  return {
    type: FETCHING_SEGMENTS_FROM_SERVER,
  };
}

function receiveSegments(json) {
  return {
    type: RECEIVE_SEGMENTS,
    segments: json,
    receivedAt: Date.now(),
  };
}

function clearSegments() {
  return {
    type: CLEAR_SEGMENTS,
  };
}

function fetchSegments(userKey, workspaceId, projectId) {
  return dispatch => {
    dispatch(clearSegments());
    dispatch(fetchingFromServer());
    return segmentServiceApi
      .retrieveSegments(userKey, workspaceId, projectId)
      .then((json) => {
        dispatch(receiveSegments(json));
      });
  };
}

function fetchSegmentsForWorkspace(userKey, workspaceId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return segmentServiceApi
      .retrieveSegmentsForWorkspace(userKey, workspaceId)
      .then(json => dispatch(receiveSegments(json)));
  };
}

function shouldFetchSegments(state) {
  const segments = state.segments;

  if (!segments) {
    return true;
  }

  if (segments.isFetching) {
    return false;
  }

  return true;
}

function tryFetchSegments(userKey, workspaceId, projectId) {
  return (dispatch, getState) => {
    if (shouldFetchSegments(getState())) {
      return dispatch(fetchSegments(userKey, workspaceId, projectId));
    }
    return null;
  };
}

function deleteSegment(userKey, workspaceId, projectId, segmentIds) {
  return dispatch =>
    new Promise(resolve => {
      segmentIds.forEach(s => {
        segmentServiceApi.deleteSegment(userKey, workspaceId, projectId, s);
      });
      resolve();
    }).then(() => dispatch(fetchSegments(userKey, workspaceId, projectId)));
}

export {
  tryFetchSegments,
  fetchSegmentsForWorkspace,
  deleteSegment,
  fetchSegments,
};
