import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Link } from 'react-router-dom';
import * as GlobalStyles from '../../../utilities/globalStyles';
import {  NavTab, NavSideTabs } from '@app/utilities/routing';

class AutomationTabSidebar extends Component {
    static propTypes = {
      dispatch: PropTypes.func
    };
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
        const { baseUrl } = this.props;
        return (
        <div className='sidebar' style={GlobalStyles.sidebar}>
            <div className="analysis-home-link" style={{marginTop: 20, padding: '0px 20px' }}>
                <Link to={"/arius"}>
                    <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ paddingRight: 2 }}
                    aria-hidden="true"
                    />
                    Analysis Home
                </Link>
            </div>
            <NavSideTabs>
                <NavTab label='Status' to={`${baseUrl}/status`}/>
                <NavTab label='Manage Scripts' to={`${baseUrl}/scripts`}/>
            </NavSideTabs> 
        </div>
        );
    }
}

export default radium(AutomationTabSidebar);
